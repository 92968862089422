import React from "react";
import PropTypes from 'prop-types';

import "../../styles/IconButton.scss";

const IconButton = (props) => {
	const { className, children } = props;

	return (
		<button {...props} className={`icon-button ripple-effect ${className ? className : ""}`}>
			{children}
		</button>
	);
};

IconButton.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	className: PropTypes.string
}

export default IconButton;
