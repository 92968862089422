import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

import { fetchCompanyDetails as fetchCompanyDetailsRequest, updateCompanyFeatures as updateCompanyFeaturesRequest } from '../actions/app';
import { getCompanyDetails } from '../selectors/app';
import Snackbar from '../components/common/Snackbar';

const ManageFeatures = (props) => {
    const { fetchCompanyDetails, companyDetails, updateCompanyFeatures } = props;

    const location = useLocation();

    const [companyFeatures, setCompanyFeatures] = useState([]);
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [snackSuccessMsg, setSnackSuccessMsg] = useState('');

    const handleSuccessSnackClick = () => {
        setSuccessSnackOpen(true);
    };

    const handleSuccessSnackClose = () => {
        setSuccessSnackOpen(false);
    };

    useEffect(() => {
        fetchCompanyDetails({ companyId: location.state.company._id });
    }, []);

    useEffect(() => {
        if (companyDetails.bots) {
            const newCompanyFeatures = { ...companyDetails.defaultFeatures };
            Object.keys(newCompanyFeatures).forEach((ncf) => {
                if (Object.prototype.hasOwnProperty.call(companyDetails.features, ncf)) {
                    if (typeof companyDetails.features[ncf] === 'boolean') {
                        if (companyDetails.features[ncf]) {
                            newCompanyFeatures[ncf] = 2;
                        } else {
                            newCompanyFeatures[ncf] = 0;
                        }
                    } else {
                        newCompanyFeatures[ncf] = companyDetails.features[ncf];
                    }
                }
            });
            setCompanyFeatures({ ...newCompanyFeatures });
        }
    }, [companyDetails]);

    const saveChanges = () => {
        const data = {
            features: companyFeatures,
            companyId: location.state.company._id
        };

        updateCompanyFeatures(data);
        handleSuccessSnackClick();
        setSnackSuccessMsg('Company features updated successfully');
    }

    // const handleFeatureChange = (e, feature) => {
    //     const newCompanyFeatures = {...companyFeatures};
    //     newCompanyFeatures[feature] = e.target.checked;
    //     setCompanyFeatures({ ...newCompanyFeatures });
    // }

    const handleFeatureNumberChange = (e, feature) => {
        const newPlanFeatures = { ...companyFeatures };
        if (e.target.value) {
            newPlanFeatures[feature] = parseInt(e.target.value, 10);
        } else {
            newPlanFeatures[feature] = 0;
        }
        //console.log(newPlanFeatures);
        setCompanyFeatures({ ...newPlanFeatures });
    }

    return (
        <div style={{ flex: 1, padding: '20px 20px 0', display: 'flex', flexDirection: 'column', height: '100vh', boxSizing: 'border-box' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <div style={{ fontSize: 20, fontWeight: '600' }}>Company Features</div>
                    <div style={{ fontSize: 16, fontWeight: '600', color: '#8b8b8b' }}>{companyDetails.name ? companyDetails.name : ''}</div>
                </div>
                <div style={{ padding: '5px 15px', background: '#479bd2', borderRadius: 5, cursor: 'pointer', display: 'inline-block', textDecoration: 'none', color: '#fff' }} onClick={saveChanges}>Save Changes</div>
            </div>
            <div style={{ background: '#fff', borderRadius: 20, marginTop: 20, padding: 20, flex: 1, overflowY: 'auto' }}>
                <div>
                    <div style={{ display: 'flex', borderBottom: '1px solid #c4c4c4' }}>
                        <div style={{ flex: 1, padding: '10px 20px', flexShrink: 0 }}>S. No.</div>
                        <div style={{ flex: 4, padding: '10px 20px', flexShrink: 0 }}>Name</div>
                        <div style={{ flex: 2, padding: '10px 20px', flexShrink: 0 }}>Action</div>
                    </div>
                    {
                        Object.keys(companyFeatures).map((feature, i) => (
                            <div style={{ display: 'flex', borderBottom: '1px solid #c4c4c4' }} key={i}>
                                <div style={{ flex: 1, padding: '10px 20px' }}>{i + 1}</div>
                                <div style={{ flex: 4, padding: '10px 20px' }}>{feature}</div>
                                <div style={{ flex: 2, padding: '10px 20px' }}>
                                    {
                                        !feature.includes('Count') ? (
                                            <select value={companyFeatures[feature]} onChange={(e) => handleFeatureNumberChange(e, feature)}>
                                                <option value={2} selected={parseInt(companyFeatures[feature], 10) === 2}>Active</option>
                                                <option value={1} selected={parseInt(companyFeatures[feature], 10) === 1}>Locked</option>
                                                <option value={0} selected={parseInt(companyFeatures[feature], 10) === 0}>Inactive</option>
                                            </select>
                                        ) : (
                                            <input type='number' onChange={(e) => handleFeatureNumberChange(e, feature)} value={companyFeatures[feature]} />
                                        )
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={handleSuccessSnackClose} msg={snackSuccessMsg ? snackSuccessMsg : ''} type="success" />
        </div>
    )
}

ManageFeatures.propTypes = {
    fetchCompanyDetails: PropTypes.func,
    companyDetails: PropTypes.object,
    updateCompanyFeatures: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
    companyDetails: getCompanyDetails()
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanyDetails: (data) => dispatch(fetchCompanyDetailsRequest(data)),
    updateCompanyFeatures: (data) => dispatch(updateCompanyFeaturesRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageFeatures);