import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from "@mui/material";

import axios from "../../utils/axios";
import Checkbox from './Checkbox';
import Modal from "./CustomModal";
import Select from "./Select";
import Button from "./Button";
import WalkthroughOverlay from "./WalkthroughOverlay";
import Switch from "./SwitchBtn";

import { updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest, updateWalkthroughFlows as updateWalkthroughFlowsRequest, fetchPreScreeningBots as fetchPreScreeningBotsRequest } from '../../actions/app';
import { getCompanyFeatures, getCurrentWalkthroughStep, getCurrentWalkthroughType, getPreScreeningBots, getSaasPlanDetails, getUserPlan } from "../../selectors/app";
import { checkCompanyFeatureAccess, checkUserPlanAccess } from "../../utils/featureAccess";

import clockIcon from '../../assets/Icons/Time.png';
import ArrowUp from '../../assets/Icons/arrow-up.svg';
import ArrowDown from '../../assets/Icons/arrow-down.svg';
import { useEffect } from "react";
import { redirectUnauthorisedUser } from "../../helper/helperFunction";
import { useLocation } from "react-router-dom";

const CustomInviteModal = (props) => {
    const { open, onClose, companyFeatures, userPlan, saasPlanDetails, preScreeningBots, setErrMsg, handleSnackClick, currentWalkthroughStep, currentWalkthroughType, updateCurrentWalkthroughStep, change, setChange, tempInvObjArr, showWalkthrough, tempRemObjArr, mailList, setMailList, updateCurrentWalkthroughType, updateWalkthroughFlows, tempPsInv, fetchPreScreeningBots } = props;
    const mediaQueryMobile = window.matchMedia('(max-width: 768px)');
    const [allowPreScreening] = useState(checkCompanyFeatureAccess(companyFeatures, 'preScreening', null) && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'preScreening', null));

    const location = useLocation();

    const todaysDate = new Date();
    const [scheduleInviteParams, setScheduleInviteParams] = useState({
        date: todaysDate.toISOString().slice(0, 10),
        time: todaysDate.getHours() + ":" + todaysDate.getMinutes(),
    });
    const [scheduleInviteModal, setScheduleInviteModal] = useState(false);
    const [remindCheckBox, setRemindCheckBox] = useState(false);
    const [reminderSchedule, setReminderSchedule] = useState(1);
    const [remindRepeatCheckBox, setRemindRepeatCheckBox] = useState(false);
    const [selectedInvTemp, setSelectedInvTemp] = useState(null);
    const [selectedPsInvTemp, setSelectedPsInvTemp] = useState(null)
    const [selectedAutoRemTemp, setSelectedAutoRemTemp] = useState(null);
    const [selectedPreScreeningBot, setSelectedPreScreeningBot] = useState(null);
    const [autoRemindHours, setAutoRemindHours] = useState(24);
    const [deadlineInvite, setDeadlineInvite] = useState(5);
    const [deadlineCheckBox, setDeadlineCheckBox] = useState(false);
    const [preScreeningCheckBox, setPreScreeningCheckBox] = useState(false);
    const [showScheduleInvite, setShowScheduleInvite] = useState(false);
    const [prescreeningBotsObjArr, setPrescreeningBotsObjArr] = useState([]);
    const [prescreeningTempObjArr, setPrescreeningTempObjArr] = useState([]);
    const [convo, setConvo] = useState([]);
    const [bot, setBot] = useState();
    const [workflowProductId, setWorkFlowProductId] = useState();

    const onOpenScheduleInviteModal = () => setScheduleInviteModal(true);

    const onCloseScheduleInviteModal = () => setScheduleInviteModal(false);

    const sendInvteSchedule = () => {
        if (remindCheckBox && autoRemindHours === '') {
            setErrMsg('Please add the time after which reminder mails should be triggered.');
            handleSnackClick();
        } else if (preScreeningCheckBox && (!selectedPreScreeningBot || selectedPreScreeningBot == null)) {
            setErrMsg('Please select a pre-screening bot, or disable the option of adding pre-screening stage.');
            handleSnackClick();
        } else if (preScreeningCheckBox && (selectedPsInvTemp === null)) {
            setErrMsg('Please select a pre-screening template.');
            handleSnackClick();
        } else {
            const uri = "/company/workflow/candidate/schedule-invite";
            let sstime = scheduleInviteParams.date + ' ' + scheduleInviteParams.time + ':00'
            let stime = new Date(sstime)
            const getData = {
                workflowId: location.state.workflowId,
                bot: bot,
                stime: stime.getTime(),
                emailIndex: selectedInvTemp,
                candlist: mailList,
                remind: (remindCheckBox ?
                    { shouldRemind: true, autoRemTemplate: selectedAutoRemTemp, autoRemHours: autoRemindHours, rescheduleReminder: remindRepeatCheckBox ? reminderSchedule : null }
                    :
                    { shouldRemind: false, autoRemTemplate: null, autoRemHours: null, rescheduleReminder: null }),
                deadline: (deadlineCheckBox ?
                    { hasDeadline: true, deadlineInvite: deadlineInvite }
                    :
                    { hasDeadline: false, deadlineInvite: null }),
                preScreening: preScreeningCheckBox ?
                    { sendPreScreening: true, preScreeningBot: selectedPreScreeningBot }
                    :
                    { sendPreScreening: false }
            };

            axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
                .then(() => {
                    if(change) {
                        setChange(change + 1);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    redirectUnauthorisedUser(err, history);
                });

            setMailList([]);
            onCloseModal3();
            onCloseScheduleInviteModal();
        }
    }

    const onCloseModal3 = () => {
        setRemindCheckBox(false);
        setSelectedInvTemp(null);
        setSelectedAutoRemTemp(null)
        setAutoRemindHours(24);
        setShowScheduleInvite(false);
        setPreScreeningCheckBox(false);
        setSelectedPreScreeningBot(null);
        setSelectedPsInvTemp(null);
        setDeadlineCheckBox(false);
        setDeadlineInvite(5);
        onClose();
    }

    const handleRemindCheckBox = (event) => {
        setRemindCheckBox(event.target.checked);
        if (currentWalkthroughStep > 0 && currentWalkthroughType === 'invite') {
            updateCurrentWalkthroughStep(8);
        }
    };

    const handleDeadlineCheckBox = (event) => {
        setDeadlineCheckBox(event.target.checked);
        if (currentWalkthroughStep > 0 && currentWalkthroughType === 'invite') {
            updateCurrentWalkthroughStep(0);
            updateCurrentWalkthroughType('');
            let wArr = [];
            if (localStorage.getItem('walkthroughFlows')) {
                wArr = JSON.parse(localStorage.getItem('walkthroughFlows'));

            }
            wArr.push('invite');
            localStorage.setItem('walkthroughFlows', JSON.stringify(wArr));
            updateWalkthroughFlows(wArr);
        }
    };

    const handlePreScreeningCheckBox = (event) => {
        setPreScreeningCheckBox(event.target.checked);
        if (currentWalkthroughStep > 0 && currentWalkthroughType === 'invite') {
            updateCurrentWalkthroughStep(7);
        }
    };

    const sendTest = () => {
        if (remindCheckBox && autoRemindHours === '') {
            setErrMsg('Please add the time after which reminder mails should be triggered.');
            handleSnackClick();
        } else if (preScreeningCheckBox && (!selectedPreScreeningBot || selectedPreScreeningBot == null)) {
            setErrMsg('Please select a pre-screening bot, or disable the option of adding pre-screening stage.');
            handleSnackClick();
        } else if (preScreeningCheckBox && (selectedPsInvTemp === null)) {
            setErrMsg('Please select a pre-screening template.');
            handleSnackClick();
        } else {
            const uri = "/company/workflow/candidate/invite";
            const getData = {
                workflowId: location.state.workflowId,
                bot: bot,
                emailIndex: selectedInvTemp,
                candlist: mailList,
                remind: (remindCheckBox ?
                    { shouldRemind: true, autoRemTemplate: selectedAutoRemTemp, autoRemHours: autoRemindHours, rescheduleReminder: remindRepeatCheckBox ? reminderSchedule : null }
                    :
                    { shouldRemind: false, autoRemTemplate: null, autoRemHours: null, rescheduleReminder: null }),
                deadline: (deadlineCheckBox ?
                    { hasDeadline: true, deadlineInvite: deadlineInvite }
                    :
                    { hasDeadline: false, deadlineInvite: null }),
                preScreening: preScreeningCheckBox ?
                    { sendPreScreening: true, preScreeningBot: selectedPreScreeningBot }
                    :
                    { sendPreScreening: false }
            };

            axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
                .then(() => {
                    if(change) {
                        setChange(change + 1);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    redirectUnauthorisedUser(err, history);
                });

            setMailList([]);
            onCloseModal3();
        }
    }

    const handleInviteModalValidation = () => {
        if (selectedInvTemp == null) return true;
        else {
            if (!remindCheckBox) {
                return false;
            } else if (remindCheckBox) {
                if (selectedAutoRemTemp == null) return true;
                else return false;
            }
        }
    }

    useEffect(() => {
        const psboArr = [];
        preScreeningBots.forEach((psb) => {
            psboArr.push({
                label: psb.name,
                value: psb._id
            })
        });

        setPrescreeningBotsObjArr([...psboArr]);
    }, [preScreeningBots]);

    useEffect(() => {
        const pstoa = [];
        tempPsInv.forEach((tpi, index) => {
            pstoa.push({
                label: tpi.name,
                value: index
            })
        });

        setPrescreeningTempObjArr([...pstoa]);
    }, [tempPsInv]);

    useEffect(() => {
        axios.get('/bot/all').then((response) => {
            if (response.data.length > 0) {
                setConvo(response.data);
            }
        }).catch((err) => {
            console.log(err);
            redirectUnauthorisedUser(err, history);
        });

        fetchPreScreeningBots({ history });
    }, []);

    useEffect(() => {
        if (open) {
            convo.forEach((convoItem) => {
                if (workflowProductId.isCustom) {
                    if (convoItem.productId === workflowProductId.customId) {
                        setBot(convoItem);
                    }
                } else if (workflowProductId.isMegacase) {
                    if (convoItem.productId === workflowProductId.role) {
                        setBot(convoItem);
                    }
                } else {
                    if (convoItem.productId === workflowProductId) {
                        setBot(convoItem);
                    }
                }
            });
        }
    }, [open]);

    useEffect(() => {
		axios.get(("/workflow/" + location.state.workflowId))
			.then((res) => {
				setWorkFlowProductId(res.data.productId);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [location.state.workflowId]);

    return (
        <>
            <Modal
                open={scheduleInviteModal}
                onClose={onCloseScheduleInviteModal}
                classNames={{ modal: 'scheduleInviteModal' }}
                center
            >
                <div className="modal-heading-workflow">
                    Schedule Invite
                </div>
                <p className="schedule-subheading">To schedule your invite manually select a date and time in the future to invite your candidate.</p>
                <div className="date-time-box">
                    <div>
                        <TextField
                            id="manageInvitation-selector-31"
                            label="Date"
                            type="date"
                            value={scheduleInviteParams.date}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                style: { padding: '0.5rem 0.8rem' }
                            }}
                            onChange={(e) => setScheduleInviteParams({ ...scheduleInviteParams, date: e.target.value })}
                        />
                    </div>
                    <div>
                        <TextField
                            id="manageInvitation-selector-32"
                            label="Time"
                            type="time"
                            value={scheduleInviteParams.time}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300,
                                style: { padding: '0.5rem 0.8rem' }
                            }}
                            sx={{ width: 150 }}
                            onChange={(e) => setScheduleInviteParams({ ...scheduleInviteParams, time: e.target.value })}
                        />
                    </div>
                </div>
                <div className="schedule-invite-btns-box">
                    <div className="cancel-button" onClick={onCloseScheduleInviteModal}>Cancel</div>
                    <div className="schedule-btn" onClick={() => sendInvteSchedule()} id="manageInvitation-selector-33">Schedule</div>
                </div>
            </Modal>
            <Modal open={open} onClose={onClose} classNames={{ modal: 'invitationModal' }} center>
                <p className="modal-heading-workflow">
                    Customise Invitation
                </p>
                <div
                    className="invitationModal__wrapper"
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !handleInviteModalValidation()) {
                            e.preventDefault();
                            sendTest();
                        }
                    }}
                >
                    <div style={{ margin: "15px 0", borderRadius: 25, zIndex: currentWalkthroughStep === 5 ? 1000 : '', position: 'relative' }}>
                        <Select
                            id="manageInvitation-selector-7"
                            label="Select invitation template"
                            selectedValue={tempInvObjArr.find(e => e.value === selectedInvTemp)?.label || ''}
                            onChangeSelect={(val) => {
                                setSelectedInvTemp(val.value);
                                if (currentWalkthroughStep > 0 && currentWalkthroughType === 'invite') {
                                    updateCurrentWalkthroughStep(6);
                                }
                            }}
                            styles={{
                                root: {
                                    borderRadius: 25,
                                    border: '1px solid #bbb',
                                    width: '100%',
                                    padding: mediaQueryMobile.matches ? '0.3rem 0 0.3rem 1rem' : '0.3rem 0'
                                },
                                wrapper: {
                                    borderBottom: 'unset'
                                }
                            }}
                            dropdownData={tempInvObjArr}
                        />
                    </div>
                    <hr style={{ margin: mediaQueryMobile.matches ? '0 0 10px 0' : '20px 0', width: '100%', border: 0, height: 0, borderTop: '1px solid rgb(0, 0, 0, 0.12)' }} />
                    {allowPreScreening ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: mediaQueryMobile.matches ? 16 : preScreeningCheckBox ? 16 : 30, position: 'relative' }}>
                            <div>
                                <p style={{ fontSize: mediaQueryMobile.matches ? 16 : 18, fontWeight: 'bold' }}>Pre-screening</p>
                                <h4 style={{ color: "rgb(150,150,150)", fontWeight: "400" }}>Send pre-screening invite</h4>
                            </div>
                            <div style={{ alignSelf: 'flex-start', zIndex: currentWalkthroughStep === 6 ? 1000 : '', position: 'relative' }}>
                                <Switch
                                    id="manageInvitation-selector-24"
                                    checked={preScreeningCheckBox}
                                    onChange={handlePreScreeningCheckBox}
                                />
                            </div>
                        </div>) : ""
                    }
                    {
                        preScreeningCheckBox ? (
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: mediaQueryMobile.matches ? 'column' : 'row', justifyContent: 'center' }}>
                                <div style={{ minWidth: mediaQueryMobile.matches ? '100%' : 230, margin: mediaQueryMobile.matches ? '0 0 8px 0' : '0 16px 0 0' }}>
                                    <Select
                                        label="Select pre-screening bot"
                                        selectedValue={prescreeningBotsObjArr.find(e => e.value === selectedPreScreeningBot)?.label || ''}
                                        onChangeSelect={(val) => {
                                            // if (val.value === 'addPreScreening') {
                                            // 	history.push('/pre-screening/dashboard')
                                            // } else {
                                            // 	setSelectedPreScreeningBot(val.value)
                                            // }
                                            setSelectedPreScreeningBot(val.value)
                                        }}
                                        styles={{
                                            root: {
                                                borderRadius: 25,
                                                border: '1px solid #bbb',
                                                width: '100%',
                                                padding: mediaQueryMobile.matches ? '0.3rem 0 0.3rem 1rem' : '0.3rem 0'
                                            },
                                            wrapper: {
                                                borderBottom: 'unset'
                                            }
                                        }}
                                        dropdownAddItem={{ link: '/prescreening/dashboard', text: 'Add Pre-screening bot' }}
                                        dropdownData={prescreeningBotsObjArr}
                                    />
                                </div>
                                <div style={{ minWidth: 230, borderRadius: 25, width: mediaQueryMobile.matches ? '100%' : 'unset', margin: mediaQueryMobile.matches ? '0 0 16px 0' : 0 }}>
                                    <Select
                                        label="Select pre-screening template"
                                        selectedValue={prescreeningTempObjArr.find(e => e.value === selectedPsInvTemp)?.label || ''}
                                        onChangeSelect={(val) => setSelectedPsInvTemp(val.value)}
                                        styles={{
                                            root: {
                                                borderRadius: 25,
                                                border: '1px solid #bbb',
                                                width: '100%',
                                                padding: mediaQueryMobile.matches ? '0.3rem 0 0.3rem 1rem' : '0.3rem 0'
                                            },
                                            wrapper: {
                                                borderBottom: 'unset'
                                            }
                                        }}
                                        dropdownData={prescreeningTempObjArr}
                                    />
                                </div>
                            </div>
                        ) : ''
                    }
                    <hr style={{ margin: mediaQueryMobile.matches ? '0 0 10px 0' : '20px 0', width: '100%', border: 0, height: 0, borderTop: '1px solid rgb(0, 0, 0, 0.12)' }} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: mediaQueryMobile.matches ? 16 : remindCheckBox ? 16 : 30, marginTop: 20, position: 'relative' }}>
                        <div>
                            <p style={{ fontSize: mediaQueryMobile.matches ? 16 : 18, fontWeight: 'bold' }}>Auto-reminder</p>
                            <h4 style={{ color: "rgb(150,150,150)", fontWeight: "400" }}>Schedule reminder mails</h4>
                        </div>
                        <div style={{ alignSelf: 'flex-start', zIndex: currentWalkthroughStep === 7 ? 1000 : '', position: 'relative' }}>
                            <Switch
                                id="manageInvitation-selector-9"
                                checked={remindCheckBox}
                                onChange={handleRemindCheckBox}
                            />
                        </div>
                    </div>
                    {remindCheckBox ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: mediaQueryMobile.matches ? 'flex-start' : 'center', marginBottom: mediaQueryMobile.matches ? 8 : 20, flexDirection: mediaQueryMobile.matches ? 'column' : 'row' }}>
                                <div style={{ minWidth: mediaQueryMobile.matches ? '100%' : 230 }}>
                                    <Select
                                        id="manageInvitation-selector-10"
                                        label="Select reminder template"
                                        selectedValue={tempRemObjArr.find(e => e.value === selectedAutoRemTemp)?.label || ''}
                                        onChangeSelect={(val) => setSelectedAutoRemTemp(val.value)}
                                        styles={{
                                            root: {
                                                borderRadius: 25,
                                                border: '1px solid #bbb',
                                                width: '100%',
                                                padding: mediaQueryMobile.matches ? '0.3rem 0 0.3rem 1rem' : '0.3rem 0'
                                            },
                                            wrapper: {
                                                borderBottom: 'unset'
                                            }
                                        }}
                                        dropdownData={tempRemObjArr}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', margin: mediaQueryMobile.matches ? '16px 0 8px 0' : 0 }}>
                                    <p style={{ marginRight: "10px" }}>Remind After</p>
                                    <TextField
                                        style={{ width: "90px" }}
                                        variant="standard"
                                        value={autoRemindHours}
                                        onChange={(e) => setAutoRemindHours(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <img
                                                        src={clockIcon}
                                                        style={{ height: '1.5rem', color: '#555' }}
                                                        alt=''
                                                    ></img>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <p>hrs</p>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: mediaQueryMobile.matches ? 16 : 30, marginTop: mediaQueryMobile.matches ? 0 : 20 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={remindRepeatCheckBox}
                                        onChange={(e) => setRemindRepeatCheckBox(e.target.checked)}
                                        label="Repeat After"
                                    />
                                    <TextField
                                        style={{ width: "20px", marginLeft: '10px' }}
                                        disabled={!remindRepeatCheckBox}
                                        variant="standard"
                                        value={reminderSchedule}
                                        onChange={(e) => setReminderSchedule(e.target.value)}
                                    />
                                    <p>Days</p>
                                </div>
                            </div>
                        </>
                    ) : ''}
                    <hr style={{ margin: mediaQueryMobile.matches ? '0 0 10px 0' : '20px 0', width: '100%', border: 0, height: 0, borderTop: '1px solid rgb(0, 0, 0, 0.12)' }} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30, marginTop: 20, position: 'relative' }}>
                        <div>
                            <p style={{ fontSize: mediaQueryMobile.matches ? 16 : 18, fontWeight: 'bold' }}>Deadline</p>
                            <h4 style={{ color: "rgb(150,150,150)", fontWeight: "400" }}>Set deadline for submission</h4>
                        </div>
                        {deadlineCheckBox &&
                            <TextField
                                style={{ width: 60, margin: '0 1rem' }}
                                label="Days"
                                variant="standard"
                                value={deadlineInvite}
                                onChange={(e) => { setDeadlineInvite(e.target.value) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <img
                                                src={clockIcon}
                                                style={{ height: '1.5rem', color: '#555' }}
                                                alt=''
                                            ></img>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                        <div style={{ alignSelf: 'flex-start', zIndex: currentWalkthroughStep === 8 ? 1000 : '', position: 'relative' }}>
                            <Switch
                                id="manageInvitation-selector-9"
                                checked={deadlineCheckBox}
                                onChange={handleDeadlineCheckBox}
                            />
                        </div>
                    </div>
                </div>
                <div className="button-div">
                    <Button
                        id="manageInvitation-selector-12"
                        className="text-transform-none"
                        style={{
                            backgroundColor: '#479BD2',
                            margin: mediaQueryMobile.matches ? '0 0 0 20px' : '0 10px',
                            fontWeight: 'bold',
                            fontFamily: "inherit",
                            letterSpacing: "0.01em",
                            borderRadius: '25px',
                            padding: "6px 20px",
                            fontSize: mediaQueryMobile.matches ? 14 : 16,
                        }}
                        size="small"
                        variant="contained"
                        disabled={handleInviteModalValidation()}
                        onClick={sendTest}
                    >
                        Invite now
                    </Button>
                    <div
                        id="manageInvitation-selector-6"
                        className="invite-later-btn"
                    >
                        <div className="invite-later-text" onClick={onClose}>Invite later</div>
                        {showScheduleInvite
                            ?
                            <img
                                id='manageInvitation-selector-30'
                                src={ArrowUp}
                                alt='solid-arrow-up-icon'
                                className="arrowdropdownicon"
                                onClick={() => setShowScheduleInvite(false)}
                            />
                            :
                            <img
                                id='manageInvitation-selector-29'
                                src={ArrowDown}
                                alt='solid-arrow-down-icon'
                                className="arrowdropdownicon"
                                onClick={() => setShowScheduleInvite(true)}
                            />
                        }
                        <div className="schedule-invite-box" style={{ display: showScheduleInvite ? 'flex' : 'none' }} onClick={onOpenScheduleInviteModal}>
                            <img src={clockIcon} alt="schedule-icon" />
                            <p>Schedule Invite</p>
                        </div>
                    </div>
                </div>
                {
                    currentWalkthroughStep > 2 && currentWalkthroughType === 'invite' && showWalkthrough ? (
                        <WalkthroughOverlay />
                    ) : ''
                }
            </Modal>
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    userPlan: getUserPlan(),
    companyFeatures: getCompanyFeatures(),
    saasPlanDetails: getSaasPlanDetails(),
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType(),
    preScreeningBots: getPreScreeningBots()
});

const mapDispatchToProps = (dispatch) => ({
    updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
    updateWalkthroughFlows: (data) => dispatch(updateWalkthroughFlowsRequest(data)),
    fetchPreScreeningBots: (data) => dispatch(fetchPreScreeningBotsRequest(data)),
});

CustomInviteModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    companyFeatures: PropTypes.object,
    userPlan: PropTypes.object,
    saasPlanDetails: PropTypes.object,
    preScreeningBots: PropTypes.array,
    setErrMsg: PropTypes.func,
    handleSnackClick: PropTypes.func,
    currentWalkthroughStep: PropTypes.number,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughStep: PropTypes.func,
    change: PropTypes.number,
    setChange: PropTypes.func,
    tempInvObjArr: PropTypes.array,
    showWalkthrough: PropTypes.bool,
    tempRemObjArr: PropTypes.array,
    mailList: PropTypes.array,
    setMailList: PropTypes.func,
    updateCurrentWalkthroughType: PropTypes.func,
    updateWalkthroughFlows: PropTypes.func,
    tempPsInv: PropTypes.array,
    fetchPreScreeningBots: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomInviteModal);
