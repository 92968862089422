import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router-dom';

import { fetchBots as fetchBotsRequest, fetchCompanyDetails as fetchCompanyDetailsRequest, updateCompanyDetails as updateCompanyDetailsRequest } from '../actions/app';
import { getCompanyDetails, getConvoList } from '../selectors/app';
import Switch from "../components/common/SwitchBtn";
import Snackbar from '../components/common/Snackbar';

const ManageBots = (props) => {
	const { fetchBots, convo, fetchCompanyDetails, companyDetails, updateCompanyDetails } = props;

	const history = useHistory();
	const location = useLocation();

	const [companyBots, setCompanyBots] = useState([]);
	const [successSnackOpen, setSuccessSnackOpen] = useState(false);
	const [snackSuccessMsg, setSnackSuccessMsg] = useState('');

	const handleSuccessSnackClick = () => {
		setSuccessSnackOpen(true);
	};

	const handleSuccessSnackClose = () => {
		setSuccessSnackOpen(false);
	};

	useEffect(() => {
		fetchBots({ history });
		fetchCompanyDetails({ companyId: location.state.company._id });
	}, []);

	useEffect(() => {
		if (companyDetails.bots) {
			setCompanyBots([...companyDetails.bots]);
		}
	}, [companyDetails]);

	const saveChanges = () => {
		const data = {
			bots: companyBots,
			companyId: location.state.company._id
		};

		updateCompanyDetails(data);
		handleSuccessSnackClick();
		setSnackSuccessMsg('Company bots updated successfully');
	}

	const handleFeatureChange = (e, botId) => {
		const newCompanyBots = [...companyBots];
		if (e.target.checked) {
			newCompanyBots.push(botId);
		} else {
			const botIndex = newCompanyBots.indexOf(botId);
			newCompanyBots.splice(botIndex, 1);
		}
		setCompanyBots([...newCompanyBots]);
	}

    return (
        <div style={{ flex: 1, padding: '20px 20px 0', display: 'flex', flexDirection: 'column', height: '100vh', boxSizing: 'border-box' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div>
					<div style={{ fontSize: 20, fontWeight: '600' }}>Company Bots</div>
					<div style={{ fontSize: 16, fontWeight: '600', color: '#8b8b8b' }}>{companyDetails.name ? companyDetails.name : ''}</div>
				</div>
				<div style={{ padding: '5px 15px', background: '#479bd2', borderRadius: 5, cursor: 'pointer', display: 'inline-block', textDecoration: 'none', color: '#fff' }} onClick={saveChanges}>Save Changes</div>
			</div>
			<div style={{ background: '#fff', borderRadius: 20, marginTop: 20, padding: 20, flex: 1, overflowY: 'auto' }}>
				<div>
					<div style={{ display: 'flex', borderBottom: '1px solid #c4c4c4' }}>
						<div style={{ flex: 1, padding: '10px 20px', flexShrink: 0 }}>S. No.</div>
						<div style={{ flex: 4, padding: '10px 20px', flexShrink: 0 }}>Name</div>
						<div style={{ flex: 2, padding: '10px 20px', flexShrink: 0 }}>Action</div>
					</div>
					{
						convo.map((bot, i) => (
							<div style={{ display: 'flex', borderBottom: '1px solid #c4c4c4' }} key={i}>
								<div style={{ flex: 1, padding: '10px 20px' }}>{i + 1}</div>
								<div style={{ flex: 4, padding: '10px 20px' }}>{bot.name}</div>
								<div style={{ flex: 2, padding: '10px 20px' }}>
									<Switch checked={companyBots.indexOf(bot._id) > -1} onChange={(e) => handleFeatureChange(e, bot._id)} />
								</div>
							</div>
						))
					}
				</div>
			</div>
			<Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={handleSuccessSnackClose} msg={snackSuccessMsg ? snackSuccessMsg : ''} type="success"/>
        </div>
    )
}

ManageBots.propTypes = {
	fetchBots: PropTypes.func,
	convo: PropTypes.array,
	fetchCompanyDetails: PropTypes.func,
	companyDetails: PropTypes.object,
	updateCompanyDetails: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	convo: getConvoList(),
	companyDetails: getCompanyDetails()
});

const mapDispatchToProps = (dispatch) => ({
	fetchBots: (data) => dispatch(fetchBotsRequest(data)),
	fetchCompanyDetails: (data) => dispatch(fetchCompanyDetailsRequest(data)),
	updateCompanyDetails: (data) => dispatch(updateCompanyDetailsRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageBots);
