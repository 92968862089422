import { fromJS } from 'immutable';
import {
	ADD_NOTIFICATION,
	ADD_USER,
	SET_CONVO,
	SET_WORKFLOW_FUNNEL_DATA,
	SET_CANDIDATES,
	SET_SUCCESS_MSG,
	RESET_SUCCESS_MSG,
	RESET_RERENDER,
	IS_AUTHENTICATED,
	AUTH_LOADING,
	AUTH_ERROR_MSG,
	AUTH_SUCCESS_MSG,
	SET_REPORT_MODEL,
	SET_NOTIFICATIONS,
	SETTINGS_LOADING,
	SET_DRIVE_DETAILS,
	SET_SUPERUSER,
	SET_COMPANIES,
	SET_FEEDBACK,
	SET_MY_DETAILS,
	SET_PRE_SCREENING_BOTS,
	SET_SAAS_PLAN_LIST,
	SET_SAAS_PLAN_DETAILS,
	SET_DASHBOARD_LOGO,
	SET_JOB_ROLE_DETAILS,
	SET_CREATE_JOB_LOADING,
	SET_TRIAL_USERS_LIST,
	SET_COMPANY_DETAILS,
	SET_COMPANY_BOTS,
	SET_COMPANY_NAME,
	SET_APPLICANTS_LOADING,
	SET_SUPPORT_MSGS,
	SET_CURRENT_WALKTHROUGH_STEP,
	SET_CURRENT_WALKTHROUGH_TYPE,
	SET_WALKTHROUGH_FLOWS,
	SET_WORKFLOWS,
	SET_CHAT_EVENTS,
	SET_WALKTHROUGH_WELCOME,
	SET_OTP_VERIFICATION_STATUS,
	EDIT_DRIVE_NAME_LOADING,
	VERIFY_CODE_SUCCESS,
	SET_SKILL_CODES,
	SET_COMPANY_USERS,
} from '../constants/app';

let userName = localStorage.getItem('userName');
let userRole = localStorage.getItem('userRole');
let driveInvitedCount = localStorage.getItem('driveInvitedCount');
let driveSubmittedCount = localStorage.getItem('driveSubmittedCount');
let userPlan = localStorage.getItem('userPlan');
let totalAssessments = localStorage.getItem('totalAssessments');
let dashboardLogo = localStorage.getItem('dashboardLogo');
let companyFeatures = localStorage.getItem('companyFeatures');
let walkthroughFlows = localStorage.getItem('walkthroughFlows');

const initialState = fromJS({
	companyBots: [],
	convo: [],
	userName: userName ? userName : null,
	userRole: userRole ? userRole : null,
	funnelData: {},
	driveInvitedCount: driveInvitedCount ? driveInvitedCount : 0,
	driveSubmittedCount: driveSubmittedCount ? driveSubmittedCount : 0,
	candidates: [],
	successMsg: "",
	rerender: 0,
	isAuthenticated: false,
	authLoading: false,
	authErrorMsg: '',
	authSuccessMsg: '',
	isReportModelOpen: false,
	notifications: [],
	settingsLoading: true,
	showVerificationSuccess: false,
	userVerificationLoading: true,
	assessmentHistory: [],
	driveDetails: {},
	userPlan: (userPlan && userPlan !== "undefined") ? JSON.parse(userPlan) : {},
	totalAssessments: totalAssessments ? parseInt(totalAssessments, 10) : 0,
	superUser: [],
	companies: [],
	feedback: [],
	myDetails: {},
	preScreeningBots: [],
	saasPlanList: [],
	saasPlanDetails: {},
	dashboardLogo: dashboardLogo ? dashboardLogo : 'https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/website_videos/logo.png',
	jobRoleDetails: {},
	createJobLoading: false,
	trialsUsersList: [],
	companyDetails: {},
	companyFeatures: (companyFeatures && companyFeatures !== "undefined") ? JSON.parse(companyFeatures) : {},
	applicantsLoading: false,
	currentWalkthroughStep: 0,
	currentWalkthroughType: '',
	supportMsgList: [],
	walkthroughFlows: walkthroughFlows ? JSON.parse(walkthroughFlows) : [],
	workflowList: [],
	chatEventsList: [],
	walkthroughWelcome: localStorage.getItem('walkthrough') ? (localStorage.getItem('walkthrough') === 'true') : true,
	resetPwdOtpVerificationStatus: false,
	editDriveNameLoading: false,
	verifyCodeSuccess: false,
	skillCodeList: {},
	companyUserList: []
});

const AppReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CONVO:
			return state
				.set('convo', fromJS(action.data));

		case SET_COMPANY_BOTS:
			return state
				.set('companyBots', fromJS(action.data));

		case ADD_USER:
			return state
				.set('userName', action.data.userName)
				.set('userRole', action.data.userRole)
				.set('userPlan', fromJS(action.data.userPlan))
				.set('companyFeatures', fromJS(action.data.companyFeatures))
				.set('totalAssessments', action.data.totalAssessments);

		case ADD_NOTIFICATION:
			return state
				.set('driveInvitedCount', action.data.driveInvitedCount)
				.set('driveSubmittedCount', action.data.driveSubmittedCount);

		case SET_WORKFLOW_FUNNEL_DATA:
			return state
				.set('funnelData', fromJS(action.data));

		case SET_CANDIDATES:
			return state
				.set('candidates', fromJS(action.data));

		case SET_SUCCESS_MSG:
			return state
				.set('successMsg', action.successMsg)
				.set('rerender', 1);

		case RESET_SUCCESS_MSG:
			return state
				.set('successMsg', "");

		case RESET_RERENDER:
			return state
				.set('rerender', 0);

		case IS_AUTHENTICATED:
			return state
				.set('isAuthenticated', action.data);

		case AUTH_LOADING:
			return state
				.set('authLoading', action.data);

		case AUTH_ERROR_MSG:
			return state
				.set('authErrorMsg', action.data);

		case AUTH_SUCCESS_MSG:
			return state
				.set('authSuccessMsg', action.data);

		case SET_REPORT_MODEL:
			return state
				.set('isReportModelOpen', action.data);

		case SET_NOTIFICATIONS:
			return state
				.set('notifications', fromJS(action.data));

		case SETTINGS_LOADING:
			return state
				.set('settingsLoading', action.data);

		case SET_DRIVE_DETAILS:
			return state
				.set('driveDetails', fromJS(action.data));

		case SET_SUPERUSER:
			return state
				.set('superUser', fromJS(action.data));

		case SET_COMPANIES:
			return state
				.set('companies', fromJS(action.data));

		case SET_FEEDBACK:
			return state
				.set('feedback', fromJS(action.data));

		case SET_MY_DETAILS:
			return state
				.set('myDetails', fromJS(action.data))
				.set('userName', fromJS(action.data.name));

		case SET_PRE_SCREENING_BOTS:
			return state
				.set('preScreeningBots', fromJS(action.data));

		case SET_SAAS_PLAN_LIST:
			return state
				.set('saasPlanList', fromJS(action.data));

		case SET_SAAS_PLAN_DETAILS:
			return state
				.set('saasPlanDetails', fromJS(action.data));

		case SET_DASHBOARD_LOGO:
			return state
				.set('dashboardLogo', action.data);

		case SET_JOB_ROLE_DETAILS:
			return state
				.set('jobRoleDetails', fromJS(action.data));

		case SET_CREATE_JOB_LOADING:
			return state
				.set('createJobLoading', action.data);

		case SET_TRIAL_USERS_LIST:
			return state
				.set('trialsUsersList', fromJS(action.data));

		case SET_COMPANY_NAME:
			return state
				.set('companyName', action.data);

		case SET_COMPANY_DETAILS:
			return state
				.set('companyDetails', fromJS(action.data));

		case SET_APPLICANTS_LOADING:
			return state
				.set('applicantsLoading', action.data);

		case SET_CURRENT_WALKTHROUGH_STEP:
			console.log(action.data)
			return state
				.set('currentWalkthroughStep', action.data);

		case SET_CURRENT_WALKTHROUGH_TYPE:
			console.log(action.data)
			return state
				.set('currentWalkthroughType', action.data);

		case SET_SUPPORT_MSGS:
			return state
				.set('supportMsgList', fromJS(action.data));

		case SET_WALKTHROUGH_FLOWS:
			return state
				.set('walkthroughFlows', fromJS(action.data));

		case SET_WORKFLOWS:
			return state
				.set('workflowList', fromJS(action.data));

		case SET_CHAT_EVENTS:
			return state
				.set('chatEventsList', fromJS(action.data));

		case SET_WALKTHROUGH_WELCOME:
			return state
				.set('walkthroughWelcome', action.data);

		case SET_OTP_VERIFICATION_STATUS:
			return state
				.set('resetPwdOtpVerificationStatus', action.data);

		case VERIFY_CODE_SUCCESS:
			return state
				.set('verifyCodeSuccess', action.data);

		case EDIT_DRIVE_NAME_LOADING:
			return state
				.set('editDriveNameLoading', action.data);

		case SET_SKILL_CODES:
			return state
				.set('skillCodeList', fromJS(action.data));

		case SET_COMPANY_USERS:
			return state
				.set('companyUserList', fromJS(action.data));

		default:
			return state;
	}
};

export default AppReducer;
