import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";

import Button from '../components/common/Button';
import Modal from '../components/common/CustomModal';
import { getCompaniesList, getFeedbackList, getWorkflowList } from '../selectors/app';
import { fetchCompanies as fetchCompaniesRequest, getFeedback as getFeedbackRequest, fetchWorkflows as fetchWorkflowsRequest } from '../actions/app';

import emptyRowImg from '../assets/emptyrow1.png';

const Feedback = (props) => {
	const { feedbackList, getFeedback, fetchCompanies, allCompanies, fetchWorkflows, workflowList } = props;

	const history = useHistory();
	const location = {
		state: {
			role: "super-admin"
		}
	}

	const [viewModal, setViewModal] = useState(false);
	const [pageSize, setPageSize] = useState(15);
	const [currentFeed, setCurrentFeed] = useState([]);

	const onOpenViewModal = () => setViewModal(true);

	const onCloseViewModal = () => setViewModal(false);

	useEffect(() => {
		if (location.state.role != "super-admin") {
			history.push("/invalid");
		}
	}, []);


	useEffect(() => {
		fetchCompanies();
		getFeedback();
	}, []);

	const onChangeCompany = (e) => {
		fetchWorkflows({ companyId: e.target.value });
        getFeedback({ companyId: e.target.value });
    }

	const onChangeWorkflow = (e) => {
        getFeedback({ workflowId: e.target.value });
    }

	useEffect(() => {
		if (currentFeed.length == 0) return;

		onOpenViewModal();
	}, [currentFeed]);

	const columns = [
		{
			field: "rating",
			headerName: "Rating",
			headerAlign: "left",
			width: 130,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row datagrid__row-center">
							{params.row.rating}
						</p>
					</>
				);
			}
		},
		{
			field: "feedback",
			headerName: "Feedback",
			headerAlign: "left",
			flex: 1,
			minWidth: 250
		},
		{
			field: "createdDate",
			headerName: "Date",
			headerAlign: "left",
			width: 200,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row datagrid__row-center">
							{renderDate(params.row.createdDate)}
						</p>
					</>
				);
			}
		},
		{
			field: "action",
			headerName: "Actions",
			minWidth: 140,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<div className="datagrid__row datagrid__row-center">
						<Button
							className="btn-datagrid"
							size="small"
							variant="contained"
							onClick={() => setCurrentFeed([params.row])}
						>
							View
						</Button>
					</div>
				)
			}
		},
	]

	function renderDate(date) {
		let createdDate = new Date(date);
		return (
			<>{createdDate.toLocaleString()}</>
		)
	}

	function NoRowsOverlay() {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", width: "80vw", minHeight: "100vh" }}>
			<Modal open={viewModal} onClose={onCloseViewModal} center classNames={{ modal: 'modal__container' }}>
				<div className="modal__heading">
					Info
				</div>
				{
					currentFeed.length != 0 ?
						Object.keys(currentFeed[0]).map(key => (
							<>
								<div>
									<span><b>{key} : </b></span>
									<span>{key == "createdDate" ? renderDate(currentFeed[0][key]) : currentFeed[0][key]}</span>
								</div><br />
							</>
						))
						:
						""
				}
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseViewModal}
					>
						Ok
					</Button>
				</div>
			</Modal>
			<div className="notificationbar" />
			<div className="actionbar" />
			<div style={{ padding: 40, fontSize: 24, fontWeight: 'bold', background: '#fff' }}>Behaviour Analytics</div>
			<div style={{ display: 'flex', marginTop: 25, justifyContent: 'space-between' }}>
				<select onChange={onChangeCompany}>
					<option>Select Company</option>
					{
						allCompanies.map((comp) => (
							<option key={comp._id} value={comp._id}>{comp.name}</option>
						))
					}
				</select>
				<select onChange={onChangeWorkflow}>
                    <option>Select Workflow</option>
                    {
                        workflowList.map((wf) => (
                            <option key={wf._id} value={wf._id}>{wf.name}</option>
                        ))
                    }
                </select>
			</div>
			<div className="datagrid__container">
				<DataGrid
					sortingOrder={['desc', 'asc', null]}
					components={{ NoRowsOverlay }}
					getRowId={(row) => row._id}
					rows={feedbackList}
					disableSelectionOnClick
					columns={columns}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					className="datagrid__scroll scroll-blue"
				/>
			</div>
		</div>
	);
}

Feedback.propTypes = {
	feedbackList: PropTypes.array,
	getFeedback: PropTypes.func,
	fetchCompanies: PropTypes.func,
	allCompanies: PropTypes.array,
	fetchWorkflows: PropTypes.func,
	workflowList: PropTypes.array
}

const mapStateToProps = createStructuredSelector({
	feedbackList: getFeedbackList(),
	allCompanies: getCompaniesList(),
	workflowList: getWorkflowList()
});

const mapDispatchToProps = (dispatch) => ({
	getFeedback: (data) => dispatch(getFeedbackRequest(data)),
	fetchCompanies: () => dispatch(fetchCompaniesRequest()),
	fetchWorkflows: (data) => dispatch(fetchWorkflowsRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
