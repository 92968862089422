import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Drawer, Menu } from '@mui/material';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';

import AccountMenu from "../components/AccountMenu";
import axios from '../utils/axios';
import Avatar from '../components/common/Avatar';
import BottomBar from '../components/common/BottomBar';
import Button from '../components/common/Button';
import ChangePassword from '../components/common/ChangePassword';
import DataTable from '../components/common/DataTable';
import Header from '../components/header/header';
import IconButton from '../components/common/IconButton';
import Modal from '../components/common/CustomModal';
import NotificationBell from "../components/notifications/NotificationBell";
import CustomSelect from '../components/common/Select';
import Snackbar from '../components/common/Snackbar';
import WalkthroughOverlay from '../components/common/WalkthroughOverlay';
import WalkthroughWelcome from '../components/common/WalkthroughWelcome';
import { checkCompanyFeatureAccess, checkUserPlanAccess } from "../utils/featureAccess";
import { checkEmptyString, validateEmail, validateSpoofText } from '../utils/validations';
import { getUserName, getUserRole, getUserPlan, getSaasPlanDetails, getCompanyFeatures, getCurrentWalkthroughStep, getCurrentWalkthroughType } from '../selectors/app';
import { redirectUnauthorisedUser } from '../helper/helperFunction';
import { updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest, updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest, updateWalkthroughFlows as updateWalkthroughFlowsRequest } from '../actions/app';

import Edit from '../assets/Icons/Edit.png';
import InfoIcon from '../assets/info-roles.png';
import loadingGif from '../assets/loading.gif';
import Trash from '../assets/Icons/Trash.png';
import emptyRowImg from '../assets/emptyrow2.png';

import "../styles/team.scss";

const NoRowsOverlay = () => {
	return (
		<div
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%,-50%)",
				textAlign: "center"
			}}
		>
			<img
				src={emptyRowImg}
				alt="no rows"
				style={{
					height: "350px",
					width: "auto"
				}}
			/>
			<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
		</div>
	)
}

const Team = (props) => {
	const { notification, userRole, userPlan, saasPlanDetails, companyFeatures, userName, currentWalkthroughStep, updateCurrentWalkthroughStep, currentWalkthroughType, updateCurrentWalkthroughType, updateWalkthroughFlows } = props;

	const history = useHistory();

	const [open, setOpen] = useState(false);
	const [role2, setRole2] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [change, setChange] = useState(0);
	const [changeName] = useState(false);
	const [rows, updateRows] = useState([]);
	const [changePwdOpen, setChangePwdOpen] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const mediaQueryMobile = window.matchMedia('(max-width: 768px)');
	const [anchorEl, setAnchorEl] = useState(null);
	const openEl = Boolean(anchorEl);
	const [changeRole, setChangeRole] = useState("");
	const [openChangeRoleModal, setOpenChangeRoleModal] = useState(false);
	const [inviteLoading, setInviteLoading] = useState(false);
	const [showWalkthrough, setShowWalkthrough] = useState(false);
	const [workflows, setWorkflows] = useState([]);
	const [workflowsSelected, setWorkflowsSelected] = useState([]);


	const editNameRef = useRef(null);

	const openToast = () => {
		setOpen(true);
	}

	const roles = [
		{ value: 'Admin', label: "Admin" },
		{ value: "Member", label: "Member" },
		{ value: "Admin-restricted", label: "Admin (rest.)" }
	];

	if (checkCompanyFeatureAccess(companyFeatures, 'masking', null) && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'masking', null))
		roles.push({ value: "Bias-free", label: "Bias-free" })

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);

		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
		else {
			let temp = [];

			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...rows.filter((row) => selectionModel[i] == row.id));
			}

			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (mediaQueryMobile.matches) {
			setAnchorEl(null);
			setEditModal(true);
		}
		else {
			if (selectionModel.length == 1) setEditModal(true);
			else {
				setSnackErr('Please select atleast one team member');
				handleSnackClick();
			}
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setGreenSnackOpen(false);
		setSuccessMsg(null);
	};

	const nameEdit = function (row) {
		if (!checkEmptyString(editNameRef.current.value) && validateSpoofText(editNameRef.current.value)) {
			axios.post(("/update-company-user/"), { ...row, name: editNameRef.current.value })
				.then(() => {
					let index = -1;
					rows.map((val, i) => {
						if (val.id == selectionModel[0]) index = i;
					})
					rows[index].name = editNameRef.current.value;
					selectedRow[0].name = editNameRef.current.value;
					editNameRef.current.value = '';

					onCloseEditModal();
					handleGreenSnackClick();
					setSuccessMsg('Updated Name Successfully');
				})
				.catch(err => {
					console.log(err);
					redirectUnauthorisedUser(err, history);
					handleSnackClick();
					setSnackErr('Could not update name at the moment');
				})
		} else {
			handleSnackClick();
			setSnackErr('Enter valid name');
		}
	}

	const roleEdit = function (row, value) {
		console.log('row', row, 'value', value);
		axios.post(("/update-company-user/"), { ...row, role: value })
			.then(() => {
				let index = -1;
				rows.map((val, i) => {
					if (val.id == row.id) index = i;
				})
				rows[index].role = value;

				setChangeRole(value);
				onCloseEditModal();
				handleGreenSnackClick();
				setSuccessMsg('Updated Role Successfully');
			})
			.catch(err => {
				console.log(err);
				handleSnackClick();
				setSnackErr('Could not update role at the moment');
			})
	}

	const onOpenDeleteModal = () => {
		if (selectionModel.length >= 1) setDeleteModal(true);
		else {
			setSnackErr('Please select atleast one team member');
			handleSnackClick();
		}
	};

	const onCloseDeleteModal = () => {
		setDeleteModal(false);
	};

	useEffect(() => {
		axios.get("/company-user").then((res) => {
			filterResponse(res.data);
			setTimeout(() => {
				if (currentWalkthroughStep === 2 && currentWalkthroughType === 'team') {
					document.getElementById('teamStep2').scrollIntoView();
					setShowWalkthrough(true);
				}
			}, 0);
		}).catch((err) => {
			console.log(err)
			redirectUnauthorisedUser(err, history);
		})
	}, [change]);

	useEffect(() => {
		axios.get('/company').then((response) => {
			let tempWorkflows = [];

			response.data.workflows.reverse().forEach((wf) => {
				tempWorkflows.push({ value: wf._id, label: wf.name })
			});
			setWorkflows([...tempWorkflows]);
		}).catch((err) => {
			console.log(err);

			redirectUnauthorisedUser(err, history);
		});
	}, []);

	const sendData = () => {
		updateCurrentWalkthroughStep(0);
		updateCurrentWalkthroughType('');
		let wArr = [];
		if (localStorage.getItem('walkthroughFlows')) {
			wArr = JSON.parse(localStorage.getItem('walkthroughFlows'));

		}
		wArr.push('team');
		localStorage.setItem('walkthroughFlows', JSON.stringify(wArr));
		updateWalkthroughFlows(wArr);
		const uri = "/company/member/";
		const emailValid = validateEmail(email);
		setInviteLoading(true);

		if (checkEmptyString(name) || !validateSpoofText(name)) {
			setSnackErr("Please Enter Valid Name");
			handleSnackClick();
			setInviteLoading(false);
			return;
		} else if (!emailValid.isValid) {
			setSnackErr("Invalid Email");
			handleSnackClick();
			setInviteLoading(false);
			return;
		} else if (checkEmptyString(role2)) {
			setSnackErr("Please Enter Role");
			handleSnackClick();
			setInviteLoading(false);
			return;
		} else {
			const getData = {
				memberName: name,
				memberEmail: email.toLowerCase(),
				memberRole: role2,
				companyName: 'Dummyy',
				workflowsSelected
			};

			axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
				.then(() => {
					setChange(change + 1);
					setEmail('');
					setName('');
					setRole2('');
					setSuccessMsg("Added new member successfully")
					handleGreenSnackClick();
					setInviteLoading(false);
				})
				.catch((err) => {
					console.log(err)
					redirectUnauthorisedUser(err, history);
					setSnackErr("Not able to add member at the moment");
					handleSnackClick();
					setInviteLoading(false);
				});
		}
	}

	const deleteData = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		})

		axios.post('/delete-company-user', { id: IDs })
			.then(res => {
				setSuccessMsg(`Deleted ${res.data.deletedCount} member successfully`);
				handleGreenSnackClick();
				setChange(change + 1);
			})
			.catch(err => {
				console.log(err);
				redirectUnauthorisedUser(err, history);
				setSnackErr('Could not delete member at the moment');
				handleSnackClick();
			});
	};

	const column = [
		{
			field: "id",
			headerName: "S. No",
			//flex: 1,
			width: 100,
			headerAlign: 'left',
			//align: 'center',
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "5ch", width: "100%" }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: "name",
			headerName: "Name",
			width: 200,
			//flex: 2,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: 16, fontWeight: "400", width: '100%' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.name}</div>
							<span className='tooltiptext datagrid'>{params.row.name}</span>
						</div>
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			headerAlign: 'left',
			//flex: 2,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: 16, fontWeight: "400", width: '100%' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.email}</div>
							<span className='tooltiptext datagrid'>{params.row.email}</span>
						</div>
					</div>
				);
			},
		},
		{
			field: "role",
			headerName: "Role",
			width: 150,
			headerAlign: "center",
			//align: 'center',
			renderCell: (params) => {
				return (
					<div>
						<select name="options" style={{ padding: "5px 20px", "background": "#FFFFFF", "boxShadow": "1px 1px 1px rgba(0, 0, 0, 0.25)", "borderRadius": "17px", "fontFamily": "Mulish", "fontStyle": "normal", "fontSize": "15px", "lineHeight": "20px", "color": "#C6C6C6", outline: "none", border: "none" }} onChange={(e) => roleEdit(params.row, e.target.value)} disabled={!userRole.includes('Admin')} value={params.row.role || 'Admin'}>
							<option value="Admin">Admin</option>
							<option value="Bias-free">Bias-free</option>
							<option value="Member">Member</option>
							<option value="Admin-restricted">Admin (rest.)</option>
						</select>
					</div>
				)
			}
		}
	]

	if (userRole.includes('Admin')) {
		column.push({
			field: "action",
			headerName: "Action",
			headerAlign: "center",
			//align: 'center',
			//flex: 2,
			width: 150,
			renderCell: (params) => {
				return (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					{/* <Button
						className="reinvite-btn"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							marginRight: '10px',
							cursor: 'pointer'
						}}
						size="small"
						variant="contained"
						onClick={() => { setSelectedRow([params.row]); onOpenChangePwd(); }}
					>
						<span style={{ fontSize: '14px', color: '#fff' }}>Reset Password</span>
					</Button> */}
					<Button
						className="reinvite-btn tooltip"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							cursor: 'pointer'
						}}
						size="small"
						variant="contained"
						onClick={() => { setSelectedRow([params.row]); setDeleteModal(true); }}
					>
						<span style={{ fontSize: '14px', color: '#fff' }}>Remove</span>
						<span className='tooltiptext' style={{ fontWeight: 'normal', width: 120, whiteSpace: 'break-spaces', marginLeft: '-70px' }}>Remove the access from dashboard</span>
					</Button>
				</div>)
			}
		})
	}

	const filterResponse = (filterData) => {
		let temp = [];

		for (let i = 0; i < filterData.length; i++) {
			const data = {
				id: i + 1,
				name: filterData[i].name,
				email: filterData[i].email,
				role: filterData[i].role,
				_id: filterData[i]._id
			}
			temp.push(data);
		}

		updateRows(temp);
	}

	const onCloseChangePwd = () => {
		setChangePwdOpen(false);
	}

	// const onOpenChangePwd = () => {
	// 	setChangePwdOpen(true);
	// }

	const onChangeInviteName = (e) => {
		setName(e.target.value);
		if (currentWalkthroughStep > 0 && currentWalkthroughType === 'team') {
			updateCurrentWalkthroughStep(3);
		}
	}

	const onChangeInviteEmail = (e) => {
		setEmail(e.target.value);
		if (currentWalkthroughStep > 0 && currentWalkthroughType === 'team') {
			updateCurrentWalkthroughStep(4);
		}
	}

	const onChangeInviteRole = (val) => {
		setRole2(val.value);
		if (currentWalkthroughStep > 0 && currentWalkthroughType === 'team') {
			updateCurrentWalkthroughStep(5);
		}
	}

	return (
		<div className='teamContainer'>
			{mediaQueryMobile.matches && <Header />}
			{mediaQueryMobile.matches ?
				<div className='team-box'>
					<p className='team-box__heading'>Manage team</p>
					<div className='team-box__content'>
						{rows.length === 0 ?
							<>
								<img
									src={emptyRowImg}
									alt="no rows"
									style={{
										height: "auto",
										width: "100%"
									}}
								/>
								<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E", textAlign: "center" }}>No data to show</p>
							</> : null}
						{rows.map((row, index) => (
							<div key={index} className='team-box__row'>
								<div className='team-box__row-left'>
									<Avatar
										name={row.name}
										styles={{
											text: {
												color: "#fff",
												marginRight: "1rem",
											}
										}}
									/>
									<div>
										<p className='team-box__row-left-text-name'>{row.name}</p>
										<p className='team-box__row-left-text-role'>{row.role}</p>
									</div>
								</div>
								{userRole.includes('Admin') ?
									<div
										className='team-box__row-right'
										id="edit-btn"
										aria-controls={openEl ? 'edit-options-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={openEl ? 'true' : undefined}
										onClick={(e) => {
											setAnchorEl(e.currentTarget);
											setSelectedRow([row]);
											setChangeRole(row.role);
										}}
									>
										<img src={Edit} alt="edit icon" width={30} height={30} />
									</div>
									: null}
							</div>
						))}
						<Menu
							id="edit-options-menu"
							anchorEl={anchorEl}
							open={openEl}
							onClose={() => setAnchorEl(null)}
							MenuListProps={{
								'aria-labelledby': 'edit-btn',
							}}
							PaperProps={{
								style: {
									boxShadow: 'none',
									border: '1px solid #ccc'
								}
							}}
						>
							<MenuItem onClick={onOpenEditModal}>Rename</MenuItem>
							<MenuItem onClick={() => {
								setAnchorEl(null);
								setOpenChangeRoleModal(true);
							}}>
								Change Role
							</MenuItem>
							<MenuItem onClick={() => {
								setAnchorEl(null);
								setDeleteModal(true);
							}}>
								Remove
							</MenuItem>
						</Menu>
					</div>
					{userRole.includes('Admin') ?
						<>
							<div className="team-box__invite-btn-box">
								<button className='team-box__invite-btn' onClick={() => setOpenDrawer(true)}>Invite Member</button>
							</div>
							<Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor={'bottom'} PaperProps={{
								className: 'team-box__drawer'
							}}>
								<div className="heading-team">
									<div className="workflow-name" style={{ fontWeight: "600", marginBottom: "10px", fontSize: "20px" }}>
										Invite Member
									</div>
								</div>
								<div className='content-team'>
									<div className='content-team__container'>
										<input className='content-team__input' value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
										<input className='content-team__input' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email Id" />
										<p className='content-team__label'>Select Role</p>
										<div className='content-team__select-box'>
											<CustomSelect
												selectedValue={role2 ? role2 : 'Member'}
												onChangeSelect={(val) => setRole2(val.value)}
												dropdownData={
													roles.map((role) => {
														return {
															label: role.label,
															value: role.value,
														}
													})
												}
												classNames={{
													root: 'content-team__role-select'
												}}
												styles={{
													wrapper: {
														borderBottom: 'unset',
													},
													dropdown: {
														zIndex: 5,
													}
												}}
											/>
										</div>
										<Button
											className="content-team__invite-btn"
											size="small"
											variant="contained"
											disabled={inviteLoading}
											onClick={() => { userRole.includes('Admin') ? sendData() : openToast() }}
										>
											<span>Invite</span>
											{inviteLoading && <img src={loadingGif} alt="loading gif" className='content-team__loading-gif' />}
										</Button>
									</div>
								</div>
							</Drawer>
						</>
						: null}
				</div>
				: null}
			{!mediaQueryMobile.matches ?
				<div className="productList" style={{ overflowY: 'auto', position: 'relative' }}>
					<div>
						<div className="inner-div" style={{ marginBottom: "10px" }}>
							<div className='top-icon-box'>
								<WalkthroughWelcome />
								{
									userRole.includes('Admin') ? (
										<div className='tooltip'>
											<IconButton aria-label="edit" onClick={onOpenEditModal}>
												<img src={Edit} alt="" width={30} height={30} />
											</IconButton>
											<span className="tooltiptext">Edit</span>
										</div>
									) : ''
								}
								{
									userRole.includes('Admin') ? (
										<div className='tooltip'>
											<IconButton aria-label="delete" onClick={onOpenDeleteModal}>
												<img src={Trash} alt="" width={30} height={30} />
											</IconButton>
											<span className='tooltiptext'>Delete</span>
										</div>
									) : ''
								}
								<NotificationBell notification={notification} />
								<AccountMenu />
							</div>
						</div>
						<div style={{ padding: '20px 20px 0' }}>
							<div className="inner-div-details" style={{ marginBottom: '10px' }}>
								<h2>{
									changeName ?
										(
											<div className="workflow-name" style={{ fontSize: 18, fontWeight: "600" }}>{userRole}</div>
										) :
										(
											<div className="workflow-name" style={{ fontSize: 18, fontWeight: "600" }}>
												{userName}
											</div>
										)
								}</h2>
								<p>{userRole}</p>
							</div>
							<div style={{ height: 'max-content', padding: '10px', background: '#fff', borderRadius: '25px', overflow: 'hidden' }}>
								<DataTable
									loading={false}
									noRowsComponent={<NoRowsOverlay />}
									rows={rows}
									columns={column}
									checkboxSelection={userRole.includes('Admin')}
									onSelectionModelChange={(newSelectionModel) => {
										setSelectionModel(newSelectionModel);
									}}
									selectionModel={selectionModel}
									pageSize={15}
								/>
							</div>
						</div>
					</div>
					{
						userRole.includes('Admin') ?
							<div style={{ marginTop: 20, padding: '0 0 0 20px', position: 'relative' }}>
								<div className="heading-team">
									<div className="workflow-name" style={{ fontWeight: "600", marginBottom: 10, fontSize: 20 }}>
										Invite Members
									</div>
								</div>
								<div id='teamStep2' style={{ background: "#FFFFFF", boxShadow: "4px 8px 18px rgba(0, 0, 0, 0.25)", borderRadius: 35, padding: 30, marginBottom: 20, position: 'relative', zIndex: (currentWalkthroughStep > 1 && currentWalkthroughStep < 6) && currentWalkthroughType === 'team' ? 1000 : 1 }}>
									<div style={{ display: "flex" }}>
										<div style={{ width: "22vw", margin: '0 0 10px 10px', fontWeight: '600', fontFamily: "inherit", color: "#334D6E" }}>
											Name
										</div>
										<div style={{ width: "22vw", margin: '0 0 10px 10px', fontWeight: '600', fontFamily: "inherit", color: "#334D6E" }}>
											Email
										</div>
										<div style={{ width: "22vw", margin: '0 0 10px 10px', fontWeight: '600', fontFamily: "inherit", color: "#334D6E" }}>
											Role
										</div>
									</div>
									<div style={{ display: "flex", height: 35, paddingTop: 3, fontSize: 16 }}>
										<input style={{ "background": "#FFFFFF", "boxShadow": "1px 1px 4px rgba(0, 0, 0, 0.25)", "borderRadius": "17px", border: "none", width: "15vw", padding: "0px 2vw", textDecoration: "none" }} value={name} onChange={onChangeInviteName} placeholder="Enter Name" />
										<input style={{ "background": "#FFFFFF", "boxShadow": "1px 1px 4px rgba(0, 0, 0, 0.25)", "borderRadius": "17px", border: "none", width: "15vw", padding: "0px 2vw", textDecoration: "none", marginLeft: "3vw" }} value={email} onChange={onChangeInviteEmail} placeholder="Enter Email Address" />
										<div style={{ display: 'flex', marginLeft: "2vw", width: "20vw" }}>
											<div>
												<CustomSelect
													selectedValue={role2 ? role2 : 'Select Role'}
													onChangeSelect={onChangeInviteRole}
													dropdownData={
														roles.map((role) => {
															return {
																label: role.label,
																value: role.value,
															}
														})
													}
													styles={{
														root: {
															background: "#FFF",
															boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
															borderRadius: "17px",
															marginLeft: "2vw",
															border: "none",
															width: "unset",
															textDecoration: "none",
															height: "35px",
															display: "flex",
															alignItems: "center",
															paddingRight: '4px'
														},
														wrapper: {
															width: 'max-content',
															borderBottom: 'unset',
														}
													}}
												/>
											</div>
											<div style={{ marginLeft: 15, display: 'flex', cursor: 'pointer' }}>
												<div className='tooltip'>
													<img src={InfoIcon} alt='' />
													<span
														className='tooltiptext'
														style={{
															backgroundColor: '#f5f5f9',
															color: 'rgba(0, 0, 0, 0.87)',
															width: 'max-content',
															maxWidth: 300,
															fontSize: 12,
															border: '1px solid #dadde9',
															bottom: '125%',
															top: 'unset',
															marginLeft: '-150px',
															textAlign: 'left'
														}}
													>
														<div>Admin will have the authority to perform all actions like inviting, shortlisting etc.</div>
														<br />
														<div>Bias-free will have access to everything an admin has, but won’t have visibility of candidate’s name and email ID, to promote unbiased evaluation.</div>
														<br />
														<div>Member will have the authority to view, but not perform any action.</div>
													</span>
												</div>
											</div>
										</div>
										<Button
											className="reinvite-btn tooltip"
											style={{
												background: '#479BD2',
												fontWeight: 'bold',
												fontFamily: "inherit",
												letterSpacing: "0.01em",
												borderRadius: '25px',
												padding: '5px 12px',
											}}
											size="small"
											variant="contained"
											onClick={() => { userRole.includes('Admin') ? sendData() : openToast() }}
										>
											<span style={{ fontSize: '16px', color: '#fff', padding: '0 10px' }}>Invite</span>
											<span className='tooltiptext' style={{ fontWeight: 'normal', width: 120 }}>Send email with access credentials</span>
										</Button>
									</div>
									{
										role2 === 'Admin-restricted' ? (
											<div style={{ marginTop: 50 }}>
												<div style={{ fontWeight: "600", marginBottom: 10, fontSize: 20 }}>Add Drive Access</div>
												<Select
													className="add-wf-select remove-bg"
													isMulti
													options={workflows}
													value={workflowsSelected}
													onChange={setWorkflowsSelected}
													style={{
														control: () => ({ width: 300 })
													}}
												/>
											</div>
										) : ''
									}
								</div>
							</div> : <></>
					}
				</div>
				: null}
			<Snackbar open={open} autoHideDuration={2500} onClose={() => setOpen(false)} msg={'You need to be an Admin to perform this Action'} type="error" />
			<ChangePassword
				open={changePwdOpen}
				onClose={onCloseChangePwd}
				handleGreenSnackClick={handleGreenSnackClick}
				setSuccessMsg={setSuccessMsg}
			/>
			<Modal open={openChangeRoleModal} onClose={() => setOpenChangeRoleModal(false)} center>
				<p className='modal-text'>Change role</p>
				{selectedRow.length == 1 ?
					(
						<CustomSelect
							selectedValue={changeRole}
							onChangeSelect={(val) => roleEdit(selectedRow[0], val.value)}
							dropdownData={
								roles.map((role) => {
									return {
										label: role.label,
										value: role.value,
									}
								})
							}
							styles={{
								root: {
									width: '100%',
									border: '1px solid gray',
									borderRadius: '1rem'
								},
								wrapper: {
									borderBottom: 'unset',
									marginLeft: '1rem'
								}
							}}
						/>
					)
					: 'Loading'}
			</Modal>
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit member&apos;s name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						<span style={{ fontSize: '16px' }}>Ok</span>
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						<span style={{ fontSize: '16px' }}>Cancel</span>
					</Button>
				</div>
			</Modal>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to remove this member ?
				</p>
				<div className="button-div">
					{selectedRow.length != 0 ?
						(<Button
							className="modal-button"
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px'
							}}
							size="small"
							variant="contained"
							onClick={() => {
								onCloseDeleteModal()
								deleteData(selectedRow)
							}}>
							Yes
						</Button>) : 'Loading'}
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px',
							margin: '0 10px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
						}}>
						No
					</Button>
				</div>
			</Modal>
			<Snackbar open={open} autoHideDuration={2500} onClose={() => setOpen(false)} msg={'You need to be an Admin to perform this Action'} type="error" />
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose} msg={snackErr ? snackErr : ''} type="error" />
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose} msg={successMsg ? successMsg : ''} type="success" />
			{mediaQueryMobile.matches && <BottomBar />}
			{
				currentWalkthroughStep > 0 && currentWalkthroughType === 'team' && showWalkthrough ? (
					<WalkthroughOverlay />
				) : ''
			}
		</div>
	)
}

Team.propTypes = {
	notification: PropTypes.object,
	userRole: PropTypes.string,
	userPlan: PropTypes.object,
	saasPlanDetails: PropTypes.object,
	companyFeatures: PropTypes.object,
	userName: PropTypes.string,
	currentWalkthroughStep: PropTypes.number,
	updateCurrentWalkthroughStep: PropTypes.func,
	currentWalkthroughType: PropTypes.string,
	updateCurrentWalkthroughType: PropTypes.func,
	updateWalkthroughFlows: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	userRole: getUserRole(),
	userName: getUserName(),
	saasPlanDetails: getSaasPlanDetails(),
	companyFeatures: getCompanyFeatures(),
	userPlan: getUserPlan(),
	currentWalkthroughStep: getCurrentWalkthroughStep(),
	currentWalkthroughType: getCurrentWalkthroughType()
});


const mapDispatchToProps = (dispatch) => ({
	updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
	updateCurrentWalkthroughType: (data) => dispatch(updateCurrentWalkthroughTypeRequest(data)),
	updateWalkthroughFlows: (data) => dispatch(updateWalkthroughFlowsRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
