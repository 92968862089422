import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import axios from '../../../utils/axios';
import RangeSlider from '../../../components/common/RangeSlider';
import SwitchBtn from '../../../components/common/SwitchBtn';
import CustomSelect from '../../common/Select';
import Button from '../../../components/common/Button';
import IconButton from "../../../components/common/IconButton";
import WalkthroughOverlay from '../../common/WalkthroughOverlay';
import { checkCompanyFeatureAccess, checkUserPlanAccess } from '../../../utils/featureAccess';
import useFocusNext from '../../../utils/focusNext';
import { industryData, jobRolesData, salesStagesData, workFunctionData } from '../newDriveData';
import { getUserPlan, getSaasPlanDetails, getCompanyFeatures } from '../../../selectors/app';
import { validateCreateNewDrive } from '../../../utils/validations';

import Edit from '../../../assets/Icons/Edit.png';
import InfoIcon from '../../../assets/info-roles.png';
import { redirectUnauthorisedUser } from '../../../helper/helperFunction';

const AddCustomAssessment = (props) => {
    const { goBack, openInterviewGuide, openVideoQuestionForm, addVideoCount, setAddVideoCount, videoQuestions, setShowCustomAssessmentEditBtn, customFieldsData, openCustomAssessmentForm, companyName, change, setChange, onClose, setSnackErr, handleSnackClick, driveRole, setDriveRole, userPlan, saasPlanDetails, setDefaultSettings, setWorkfunctionSelected, workfunctionOption, workfunctionSelected, jobRole, setJobRole, keyTask, finalVal, setKeyTask, setFinalVal, functionalSkill, setFunctionalSkill, showKeyTask, setShowKeyTask, salesStages, setSalesStages, coreSkill, setCoreSkill, name, desc, setName, setDesc, industry, setIndustry, workflowFixed, setWorkflowFixed, showCustomAssessmentEditBtn, companyFeatures, currentWalkthroughStep, updateCurrentWalkthroughStep, nextWalkthroughStep, setNextWalkthroughStep, currentWalkthroughType } = props;
    const mediaQueryMobile = window.matchMedia('(max-width:768px)');

    const focusNextRef = useFocusNext();
    const [showWalkthroughNextBtn, setShowWalkthroughNextBtn] = useState(true);
    const [addDriveLoading, setAddDriveLoading] = useState(false);

    const onChangeName = (e) => {
        setName(e.target.value);
    }

    const onChangeDesc = (e) => {
        setDesc(e.target.value);
    }

    const onSubmitWorkflow = () => {
        if (currentWalkthroughStep > 0 && currentWalkthroughType === 'workflow') {
            updateCurrentWalkthroughStep(8);
        }
        const errorResult = validateCreateNewDrive(name, desc);
        let weightageSum = 0;

        if (errorResult.isValid) {
            if (driveRole === 'Associate consultant' || driveRole === 'Consultant') {
                coreSkill.forEach((cs) => {
                    weightageSum += parseInt(cs.weightage, 10);
                });

                if (weightageSum === 100) {
                    sendWorkflow();
                } else {
                    setSnackErr('Sum of weightage is not equal to 100');
                    handleSnackClick();
                }
            } else {
                if (driveRole === '') {
                    setSnackErr('Enter valid role');
                    handleSnackClick();
                } else {
                    sendWorkflow();
                }
            }
        } else {
            Object.keys(errorResult.errors).forEach((item) => {
                setSnackErr(errorResult.errors[item]);
            });
            handleSnackClick();
        }
    }

    const sendWorkflow = () => {
        const uri = "/company/workflow/";
        const videoQsArr = [];
        videoQuestions.forEach((vq) => {
            if (vq.question) {
                videoQsArr.push(vq);
            }
        });

        if (videoQsArr.length === videoQuestions.length) {
            const getData = {
                workflowName: name,
                workflowDescription: desc,
                productId: {
                    role: driveRole,
                    workfunction: driveRole != "Associate consultant" && driveRole != "Consultant" ? workfunctionSelected : {},
                    functionalSkill: driveRole == "Associate consultant" || driveRole == "Consultant" ? functionalSkill : {},
                    coreSkill: driveRole == "Associate consultant" || driveRole == "Consultant" ? coreSkill : {},
                    keyTask: finalVal,
                    isMegacase: true,
                    isCustom: false,
                    videoQs: videoQsArr.length > 0 ? true : false,
                    workflowFixed: !workflowFixed
                },
                companyName: companyName,
            };

            if (!checkCompanyFeatureAccess(companyFeatures, 'driveVariable')) {
                getData.productId.workflowFixed = false;
            }

            if (videoQsArr.length > 0) {
                getData.productId.videoQsArr = videoQsArr;
            }

            if (jobRole) {
                getData.productId.jobTitle = jobRole;
                getData.productId.stages = salesStages;
            }

            if (Object.keys(customFieldsData).length > 0) {
                getData.productId.customFields = customFieldsData;
            }

            if (driveRole === 'Project Manager') {
                getData.productId.industry = industry;
            }

            setAddDriveLoading(true);
            axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
                .then(() => {
                    setAddDriveLoading(false);
                    setChange(change + 1);
                    // if (currentWalkthroughStep > 0) {
                    // history.push({
                    //     pathname: "/workflows/" + res.data.name,
                    //     state: {
                    //         workflowId: res.data._id,
                    //         workflowName: res.data.name,
                    //         sidebar: "quickLook"
                    //     }
                    // })
                    // }
                })
                .catch((err) => {
                    setAddDriveLoading(false);
                    console.log(err);
                    setSnackErr('Error in adding drive');
                    handleSnackClick();
                    redirectUnauthorisedUser(err, history);
                });

            onClose();
        } else {
            setSnackErr(`Please enter the question for video by clicking on 'Add'`);
            handleSnackClick();
        }
    }

    const onChangeWorkflowFixed = (e) => {
        setWorkflowFixed(e.target.checked);
    }

    const addKeyTask = function (role, workFunction, keyTask) {
        if (!(finalVal[role][workFunction].find((val) => val == keyTask))) {
            setFinalVal(
                {
                    [role]: {
                        ...finalVal[role],
                        [workFunction]: [...finalVal[role][workFunction], keyTask]
                    }
                }
            )
        }
    }

    const removeKeyTask = (role, workFunction, keyTask) => {
        const index = finalVal[role][workFunction].indexOf(keyTask);
        if (index != -1) {
            let arr = [...finalVal[role][workFunction]];
            arr.splice(index, 1);
            setFinalVal(
                {
                    [role]: {
                        ...finalVal[role],
                        [workFunction]: arr
                    }
                }
            )
        }
    }

    const onChangeWeightage = (e, val) => {
        const newCoreSkill = [...coreSkill];
        let index = 0;
        newCoreSkill.forEach((ncs, i) => {
            if (ncs.value === val.value) {
                index = i;
            }
        });
        newCoreSkill[index].weightage = parseInt(e.target.value, 10);
        setCoreSkill([...newCoreSkill]);
    }

    const onClickDefaultSettings = () => {
        setDefaultSettings();
        setKeyTask({});
        setFinalVal({})
        setFunctionalSkill([]);
        setCoreSkill([]);
    }

    return (
        <>
            <div
                className='customAssessmentDrive__wrapper'
                tabIndex={1}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !addDriveLoading) {
                        e.preventDefault();
                        onSubmitWorkflow();
                    }
                }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                    <div className='back-btn' onClick={goBack}>
                        <div></div>
                    </div>
                    <div className='title'>Create new drive</div>
                </div>
                <div id='workflowStep3' style={{ zIndex: currentWalkthroughStep === 3 ? 1000 : 1, position: 'relative' }}>
                    <input type="text" onChange={onChangeName} value={name} placeholder='Name of drive' ref={focusNextRef} />
                </div>
                <div style={{ zIndex: currentWalkthroughStep === 3 ? 1000 : 1, position: 'relative' }}>
                    <input type="text" onChange={onChangeDesc} value={desc} placeholder='Add description' ref={focusNextRef} />
                </div>
                <div id='workflowStep4' className='selectRoleContainer' style={{ zIndex: currentWalkthroughStep === 4 ? 1000 : 10 }}>
                    <div style={{ width: mediaQueryMobile.matches ? '100%' : 200 }}>Select Role</div>
                    <CustomSelect
                        selectedValue={driveRole ? driveRole : 'Select Role'}
                        onChangeSelect={(val) => {
                            setDriveRole(val.value);
                            setFinalVal({ [val.value]: {} })
                            setKeyTask({});
                            setWorkfunctionSelected([]);
                            setFunctionalSkill([]);
                            setJobRole('');
                            setShowKeyTask(false);
                            setCoreSkill([]);
                        }}
                        dropdownData={[
                            { label: 'Product Manager', value: 'Product Manager' },
                            { label: 'Project Manager', value: 'Project Manager' },
                            { label: 'Sales and Marketing', value: 'Sales and Marketing' },
                            { label: 'Customer Success', value: 'Customer Success' },
                            { label: 'Associate consultant', value: 'Associate consultant' },
                            { label: 'Consultant', value: 'Consultant' }
                        ]}
                        styles={{
                            root: {
                                borderRadius: 30,
                                position: 'relative',
                                zIndex: 10,
                                width: mediaQueryMobile.matches ? '100%' : 300,
                                flex: 10,
                                margin: mediaQueryMobile.matches ? '0.5rem 0' : '0 25px',
                                border: '1px solid rgb(196, 196, 196)'
                            },
                            wrapper: {
                                borderBottom: 'none',
                                textAlign: 'left'
                            },
                            dropdown: {
                                zIndex: 1
                            }
                        }}
                    />
                    {
                        driveRole ? (
                            <div style={{ marginRight: 25, cursor: 'pointer' }} onClick={openInterviewGuide}>
                                <img src={InfoIcon} alt='' style={{ width: 32 }} />
                            </div>
                        ) : ''
                    }
                    {
                        driveRole !== 'Sales and Marketing' ? (
                            <Button
                                style={{
                                    backgroundColor: '#479BD2',
                                    borderRadius: 16,
                                    padding: '8px 20px',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    width: 175,
                                    textAlign: 'center',
                                    boxSizing: 'border-box',
                                    fontFamily: 'inherit',
                                    fontSize: 16,
                                    whiteSpace: 'nowrap',
                                    lineHeight: 1.2
                                }}
                                onClick={onClickDefaultSettings}
                                size="small"
                                variant="contained"
                            >
                                Default Settings
                            </Button>
                        ) : ''
                    }
                </div>
                {
                    driveRole === 'Sales and Marketing' ? (
                        <div className="customJobRoleContainer" style={{ zIndex: currentWalkthroughStep === 4 ? 1000 : 9 }}>
                            <div style={{ width: mediaQueryMobile.matches ? '100%' : 210, marginBottom: mediaQueryMobile.matches ? '0.5rem' : 'unset' }}>Job Role</div>
                            <CustomSelect
                                selectedValue={jobRole ? (jobRolesData.filter((jr) => jr.value === jobRole)[0].label) : 'Select Job Role'}
                                onChangeSelect={(val) => {
                                    setJobRole(val.value);
                                    setShowKeyTask(false);
                                }}
                                dropdownData={jobRolesData}
                                styles={{
                                    root: {
                                        borderRadius: 30,
                                        border: '1px solid #c4c4c4',
                                        position: 'relative',
                                        zIndex: 9,
                                        width: mediaQueryMobile.matches ? '100%' : 300,
                                        flex: mediaQueryMobile.matches ? 'unset' : 3,
                                        margin: mediaQueryMobile.matches ? '0 0 0.5rem 0' : '0 25px 0.5rem 25px'
                                    },
                                    wrapper: {
                                        borderBottom: 'none',
                                        padding: '0 15px',
                                        textAlign: 'left'
                                    },
                                    dropdown: {
                                        zIndex: 1
                                    }
                                }}
                            />
                            {
                                jobRole !== 'Custom' ? (
                                    <Button
                                        style={{
                                            backgroundColor: '#479BD2',
                                            borderRadius: 16,
                                            padding: '8px 20px',
                                            color: '#fff',
                                            cursor: 'pointer',
                                            width: 175,
                                            textAlign: 'center',
                                            boxSizing: 'border-box',
                                            fontSize: 16,
                                            whiteSpace: 'nowrap',
                                            fontFamily: 'inherit',
                                            lineHeight: 1.2
                                        }}
                                        onClick={() => {
                                            setJobRole('');
                                            setKeyTask({});
                                            setFinalVal({})
                                            setWorkfunctionSelected([]);
                                            setFunctionalSkill([]);
                                            setCoreSkill([]);
                                        }}
                                        size="small"
                                        variant="contained"
                                    >
                                        Default Settings
                                    </Button>
                                ) : ''
                            }
                        </div>
                    ) : ''
                }
                <hr style={{ borderTop: '1px solid #c4c4c4', marginTop: 20 }} />
                {
                    driveRole === 'Project Manager' ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, }}>
                            <div style={{ width: mediaQueryMobile.matches ? '100%' : 210 }}>Select Industry</div>
                            <CustomSelect
                                selectedValue={industry ? (industryData.filter((id) => id.value === industry)[0].label) : 'Select Industry'}
                                onChangeSelect={(val) => {
                                    setIndustry(val.value);
                                }}
                                dropdownData={industryData}
                                styles={{
                                    root: {
                                        borderRadius: 30,
                                        border: '1px solid #c4c4c4',
                                        position: 'relative',
                                        zIndex: 8,
                                        width: mediaQueryMobile.matches ? '100%' : 300,
                                        margin: mediaQueryMobile.matches ? '0 0 0 8px' : '0 25px'
                                    },
                                    wrapper: {
                                        borderBottom: 'none',
                                        padding: '0 15px',
                                        textAlign: 'left'
                                    },
                                    dropdown: {
                                        zIndex: 1,
                                        height: mediaQueryMobile.matches ? '200px' : 'unset',
                                        overflowY: mediaQueryMobile.matches ? 'scroll' : 'unset'
                                    }
                                }}
                            />
                        </div>
                    ) : ''
                }
                {
                    (driveRole && driveRole != "Associate consultant" && driveRole != "Consultant" && driveRole != "Sales and Marketing") || (jobRole && jobRole !== 'Custom') ? (
                        <div className='criticalWFContainer' style={{ zIndex: currentWalkthroughStep === 5 ? 1000 : 7 }}>
                            <div style={{ width: 210 }} className="tooltip">
                                Critical work function
                                <span className='tooltiptext'>CWF - Principle responsibilities in the job role</span>
                            </div>
                            <Select
                                className="add-wf-select remove-bg"
                                isMulti
                                options={workfunctionOption.length == 0 ? [] : workfunctionOption}
                                value={workfunctionSelected}
                                onChange={setWorkfunctionSelected}
                                style={{
                                    control: () => ({ width: 300 })
                                }}
                            />
                            <Button
                                style={{
                                    backgroundColor: '#479BD2',
                                    borderRadius: 16,
                                    padding: '8px 20px',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    fontFamily: 'inherit',
                                    fontSize: 16,
                                    whiteSpace: 'nowrap',
                                    lineHeight: 1.2,
                                    flexShrink: 0,
                                    width: 175
                                }}
                                size="small"
                                variant="contained"
                                className="tooltip"
                                onClick={() => setShowKeyTask(!showKeyTask)}
                            >
                                Specify Key Task
                                <span className='tooltiptext' style={{ marginLeft: '-85px', width: 150, whiteSpace: 'break-spaces' }}>KT - Detailed tasks that has to be done to adhere to every critical work function</span>
                            </Button>
                        </div>
                    ) : ''
                }
                {
                    driveRole == "Associate consultant" || driveRole == "Consultant" ? (
                        <div style={{ zIndex: currentWalkthroughStep === 5 ? 1000 : 1, position: 'relative', background: '#fff', padding: '5px 0' }}>
                            <div className="workflow__newmodal-main workflow__newmodal-main-alignstart" style={{ marginTop: 25 }}>
                                <label className="workflow__newmodal-label">Functional skills</label>
                                <div className="workflow__newmodal-select workflow__newmodal-select-dropdown" style={{ position: 'relative', zIndex: 6 }}>
                                    <Select
                                        isMulti
                                        options={[
                                            { value: 'Management consultant/generalist', label: 'Management consultant/generalist' },
                                            { value: 'Strategy', label: 'Strategy' },
                                            { value: 'Marketing /Sales /Pricing', label: 'Marketing /Sales /Pricing' },
                                            { value: 'Operations', label: 'Operations' },
                                            { value: 'Technology & Digital', label: 'Technology & Digital' },
                                            { value: 'Corporate Finance & Accounting', label: 'Corporate Finance & Accounting' },
                                            { value: 'People & Organization', label: 'People & Organization' },
                                        ]}
                                        value={functionalSkill}
                                        onChange={setFunctionalSkill}
                                        maxMenuHeight={150}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: mediaQueryMobile.matches ? 30 : 50 }}>
                                <label className="workflow__newmodal-label">Select core skills</label>
                                {/* <div className="workflow__newmodal-select workflow__newmodal-select-dropdown" style={{ position: 'relative', zIndex: 5 }}>
                                <Select
                                    isMulti
                                    options={[
                                        { value: 'Structuring', label: 'Structuring' },
                                        { value: 'Insights', label: 'Insights' },
                                        { value: 'Synthesis', label: 'Synthesis' },
                                        { value: 'Quantitative', label: 'Quantitative' },
                                        { value: 'Framing', label: 'Framing' },
                                    ]}
                                    value={coreSkill}
                                    onChange={setCoreSkill}
                                    style={{
                                        control: styles => ({ ...styles, width: '380px' })
                                    }}
                                    maxMenuHeight={150}
                                />
                            </div> */}
                            </div>
                        </div>
                    ) : ""
                }
                {
                    jobRole === 'Custom' ? (
                        <div className="workflow__newmodal-main workflow__newmodal-main-alignstart" style={{ marginTop: 20 }}>
                            <label className="workflow__newmodal-label">Pick relevant stages of sales cycle</label>
                            <div className="workflow__newmodal-select workflow__newmodal-select-dropdown" style={{ position: 'relative', zIndex: 4 }}>
                                <Select
                                    isMulti
                                    options={salesStagesData}
                                    value={salesStages}
                                    onChange={setSalesStages}
                                    style={{
                                        control: styles => ({ ...styles, width: '380px' })
                                    }}
                                    maxMenuHeight={150}
                                />
                            </div>
                        </div>
                    ) : ''
                }
                <div style={{ position: 'relative', zIndex: currentWalkthroughStep === 5 ? 1000 : 1, background: '#fff' }}>
                    {
                        showKeyTask && Object.keys(keyTask).length > 0 ?
                            Object.keys(keyTask).map((val, index) => {
                                const selectedDriveRole = jobRole !== '' ? jobRole : driveRole;

                                return (
                                    <div key={index} style={{ marginTop: 20 }}>
                                        <div style={{ display: 'flex', paddingLeft: 16, justifyContent: 'space-between', marginBottom: 20 }}>
                                            <p style={{ fontSize: 16, color: '#010101' }}>{workFunctionData[selectedDriveRole][val].label}</p>
                                        </div>
                                        <div style={{ backgroundColor: '#f8f8f8', padding: 16, borderRadius: 40, marginTop: 10 }}>
                                            <div style={{ fontSize: 14, color: '#595959' }}>De-select the ones that are not relevant to the role</div>
                                            {
                                                keyTask[val].map((value, i) =>
                                                (
                                                    <div
                                                        className={`key-task ${finalVal[selectedDriveRole][val].indexOf(value) > -1 ? 'key-task-activekey' : ''}`}
                                                        key={i}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                addKeyTask(selectedDriveRole, val, value);
                                                            }}
                                                            style={{ cursor: finalVal[selectedDriveRole][val].indexOf(value) > -1 ? 'default' : 'pointer', display: 'flex', flex: 1, fontSize: 14 }}
                                                        >
                                                            {workFunctionData[selectedDriveRole][val].keyTasks.filter((kt) => value === kt.value)[0].label}
                                                        </div>
                                                        {
                                                            finalVal[selectedDriveRole][val].indexOf(value) > -1 ? (
                                                                <div
                                                                    style={{ marginLeft: 10, fontSize: 14, cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        removeKeyTask(selectedDriveRole, val, value);
                                                                    }}
                                                                >
                                                                    x
                                                                </div>
                                                            ) : ''
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            ) : ''
                    }
                </div>
                {
                    coreSkill.length > 0 ? (
                        <div className='skillsRangeContainer' style={{ zIndex: currentWalkthroughStep === 5 ? 1000 : 0 }}>
                            <div>
                                {
                                    coreSkill.map((cs) => (
                                        <div style={{ display: 'flex', padding: '25px 0', alignItems: 'center' }} key={cs.label}>
                                            <div style={{ flex: 1 }}>{cs.label}</div>
                                            {
                                                checkUserPlanAccess(userPlan.name, saasPlanDetails, 'addWeightage') ? (
                                                    <div style={{ flex: 2, justifyContent: 'center', display: 'flex' }}>
                                                        <RangeSlider
                                                            onChangeSlider={(e) => onChangeWeightage(e, cs)}
                                                            helperText="Add Weightage"
                                                            minValue={10}
                                                            maxValue={50}
                                                            rangeValue={cs.weightage}
                                                        />
                                                    </div>
                                                ) : ''
                                            }
                                            {/* <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex', cursor: 'pointer', color: '#858585' }} onClick={() => removeCoreSkill(cs)}>Remove</div> */}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ) : ''
                }
                {
                    // checkUserPlanAccess(userPlan.name, saasPlanDetails, 'addVideoQuestion') ? (
                    <div id="workflowStep6" style={{ background: '#fff', 'position': 'relative', zIndex: currentWalkthroughStep === 6 ? 1000 : 2, padding: '5px 0' }}>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                            <label className="workflow__newmodal-label" style={{ width: 210 }}>Add custom video questions</label>
                            <CustomSelect
                                selectedValue={addVideoCount}
                                onChangeSelect={(val) => {
                                    setAddVideoCount(val.value);
                                }}
                                dropdownData={[
                                    { label: '0', value: 0 },
                                    { label: '1', value: 1 },
                                    { label: '2', value: 2 }
                                ]}
                                styles={{
                                    root: {
                                        borderRadius: 30,
                                        border: '1px solid #c4c4c4',
                                        position: 'relative',
                                        zIndex: 3,
                                        width: 300,
                                        flex: 1,
                                        margin: '0 25px'
                                    },
                                    wrapper: {
                                        borderBottom: 'none',
                                        padding: '0 25px 0 15px',
                                        textAlign: 'left'
                                    },
                                    dropdown: {
                                        zIndex: 1
                                    }
                                }}
                            />
                            <Button
                                style={{
                                    backgroundColor: '#479BD2',
                                    borderRadius: 16,
                                    padding: '8px 20px',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    width: 175,
                                    textAlign: 'center',
                                    boxSizing: 'border-box',
                                    fontFamily: 'inherit',
                                    fontSize: 16,
                                    lineHeight: 1.2
                                }}
                                className="tooltip"
                                onClick={openVideoQuestionForm}
                                size="small"
                                variant="contained"
                            >
                                {videoQuestions.length > 0 && videoQuestions.length === addVideoCount ? 'Edit' : 'Add'}
                                <span className="tooltiptext" style={{ marginLeft: '-90px' }}>Add your questions for video section here</span>
                            </Button>
                        </div>
                    </div>
                    // ) : ''
                }
                <hr style={{ borderTop: '1px solid #c4c4c4', marginTop: 20 }} />
                {
                    checkCompanyFeatureAccess(companyFeatures, 'driveVariable') ? (
                        <div style={{ background: '#fff', padding: '5px 0', position: 'relative' }}>
                            <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                                <div className='variabilityTitle'>Variability</div>
                                <div className='variabilitySubtitle'>Make unique assessments for each candidate to avoid cheating</div>
                                <SwitchBtn onChange={onChangeWorkflowFixed} checked={workflowFixed} />
                            </div>
                        </div>
                    ) : ''
                }
                {
                    (driveRole === 'Project Manager' || driveRole === 'Product Manager') && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'customContent') ? (
                        <div className="customVideoContainer">
                            <div className='customAssHeading'>Customise problem statement</div>
                            <div className='customAssText'>Add custom fields to customize your assessment</div>
                            {Object.keys(customFieldsData).length > 0}
                            <SwitchBtn onChange={(e) => setShowCustomAssessmentEditBtn(e.target.checked)} checked={showCustomAssessmentEditBtn} />
                            {
                                showCustomAssessmentEditBtn ? (
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25, cursor: 'pointer', width: 65, flexShrink: 0 }} onClick={openCustomAssessmentForm}>
                                        <IconButton aria-label="edit">
                                            <img src={Edit} alt="" width={30} height={30} />
                                        </IconButton>
                                        <div style={{ marginLeft: 10, color: '#B6B6B6' }}>Edit</div>
                                    </div>
                                ) : <div style={{ marginLeft: 25, width: 65, flexShrink: 0 }}></div>
                            }
                        </div>
                    ) : ''
                }
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50, position: 'relative', zIndex: currentWalkthroughStep === 7 ? 1000 : 1 }}>
                    <Button className='action' onClick={onSubmitWorkflow} size="small" variant="contained" disabled={addDriveLoading} id="workflowStep7">Submit</Button>
                </div>
            </div>
            {
                currentWalkthroughStep > 1 && currentWalkthroughStep <= 8 ? (
                    <WalkthroughOverlay
                        onClickSkip={() => updateCurrentWalkthroughStep(2)}
                        setShowWalkthroughNextBtn={setShowWalkthroughNextBtn}
                        showWalkthroughNextBtn={showWalkthroughNextBtn && currentWalkthroughStep !== 7}
                        onClickDone={() => { updateCurrentWalkthroughStep(nextWalkthroughStep); setNextWalkthroughStep(nextWalkthroughStep + 1); }}
                    />
                ) : ''
            }
        </>
    )
}

AddCustomAssessment.propTypes = {
    goBack: PropTypes.func,
    openInterviewGuide: PropTypes.func,
    openVideoQuestionForm: PropTypes.func,
    addVideoCount: PropTypes.number,
    setAddVideoCount: PropTypes.func,
    videoQuestions: PropTypes.array,
    setShowCustomAssessmentEditBtn: PropTypes.func,
    customFieldsData: PropTypes.object,
    openCustomAssessmentForm: PropTypes.func,
    companyName: PropTypes.string,
    change: PropTypes.number,
    setChange: PropTypes.func,
    onClose: PropTypes.func,
    setSnackErr: PropTypes.func,
    handleSnackClick: PropTypes.func,
    driveRole: PropTypes.string,
    setDriveRole: PropTypes.func,
    userPlan: PropTypes.object,
    saasPlanDetails: PropTypes.object,
    setDefaultSettings: PropTypes.func,
    setWorkfunctionSelected: PropTypes.func,
    workfunctionOption: PropTypes.array,
    workfunctionSelected: PropTypes.array,
    jobRole: PropTypes.string,
    setJobRole: PropTypes.func,
    keyTask: PropTypes.object,
    finalVal: PropTypes.object,
    setKeyTask: PropTypes.func,
    setFinalVal: PropTypes.func,
    functionalSkill: PropTypes.array,
    setFunctionalSkill: PropTypes.func,
    showKeyTask: PropTypes.bool,
    setShowKeyTask: PropTypes.func,
    salesStages: PropTypes.array,
    setSalesStages: PropTypes.func,
    coreSkill: PropTypes.array,
    setCoreSkill: PropTypes.func,
    name: PropTypes.string,
    desc: PropTypes.string,
    setName: PropTypes.func,
    setDesc: PropTypes.func,
    industry: PropTypes.string,
    setIndustry: PropTypes.func,
    workflowFixed: PropTypes.bool,
    setWorkflowFixed: PropTypes.func,
    showCustomAssessmentEditBtn: PropTypes.bool,
    companyFeatures: PropTypes.object,
    currentWalkthroughStep: PropTypes.number,
    updateCurrentWalkthroughStep: PropTypes.func,
    nextWalkthroughStep: PropTypes.number,
    setNextWalkthroughStep: PropTypes.func,
    currentWalkthroughType: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
    userPlan: getUserPlan(),
    saasPlanDetails: getSaasPlanDetails(),
    companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomAssessment);