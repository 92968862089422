import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../utils/axios';
import Button from '../../../components/common/Button';
import WalkthroughOverlay from '../../common/WalkthroughOverlay';
import { validateCreateNewDrive } from '../../../utils/validations';
import useFocusNext from "../../../utils/focusNext";
import { redirectUnauthorisedUser } from '../../../helper/helperFunction';

const AddDefaultDrive = (props) => {
    const { goBack, bot, companyName, change, setChange, onClose, setSnackErr, handleSnackClick, currentWalkthroughStep, updateCurrentWalkthroughStep, currentWalkthroughType } = props;

    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [addDriveLoading, setAddDriveLoading] = useState(false);
    const [nextWalkthroughStep, setNextWalkthroughStep] = useState(7);
    const focusNextRef = useFocusNext();

    const onChangeName = (e) => {
        setName(e.target.value);
    }

    const onChangeDesc = (e) => {
        setDesc(e.target.value);
    }

    const sendWorkflowDefault = () => {
        const errorResult = validateCreateNewDrive(name, desc);
        if (currentWalkthroughStep > 0 && currentWalkthroughType === 'workflow') {
            updateCurrentWalkthroughStep(8);
        }

        if (errorResult.isValid) {
            const uri = "/company/workflow/";
            const getData = {
                workflowName: name,
                workflowDescription: desc,
                productId: {
                    isMegacase: false,
                    isCustom: true,
                    customId: bot.productId
                },
                problem: bot.description,
                title: bot.name,
                companyName: companyName,
                assessmentTime: bot.assessmentTime,
                sectionCount: bot.sectionCount,
                nextSectionMessage: bot.nextSectionMessage,
                nextSectionBtnText: bot.nextSectionBtnText
            }
            if (bot.features) {
                getData.features = bot.features;
            }
            setAddDriveLoading(true);
            axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
                .then(() => {
                    setAddDriveLoading(false);
                    setName('');
                    setDesc('');
                    setChange(change + 1);
                })
                .catch((err) => {
                    console.log(err);
                    setAddDriveLoading(false);
                    redirectUnauthorisedUser(err, history);
                });

            onClose();
        } else {
            Object.keys(errorResult.errors).forEach((item) => {
                setSnackErr(errorResult.errors[item]);
            });
            handleSnackClick();
        }
    }

    return (
        <div
            tabIndex={0}
            className='default-form'
            onKeyDown={(e) => {
                if (e.key === 'Enter' && e.target.id !== "testNewDriveFlow-selector-4" && !addDriveLoading) {
                    e.preventDefault();
                    sendWorkflowDefault();
                }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                <div className='back-btn' onClick={goBack}>
                    <div></div>
                </div>
                <div className='title'>Create new drive</div>
            </div>
            <div
                id='workflowStep3'
                style={{
                    position: 'relative',
                    zIndex: currentWalkthroughStep === 3 ? 1000 : 1,
                }}
            >
                <input
                    type="text"
                    onChange={onChangeName}
                    value={name}
                    placeholder='Name of drive'
                    id="testNewDriveFlow-selector-3"
                    ref={focusNextRef}
                />
            </div>
            <div style={{ zIndex: currentWalkthroughStep === 3 ? 1000 : 1, position: 'relative' }}>
                <textarea
                    onChange={onChangeDesc}
                    value={desc}
                    placeholder='Add description'
                    id="testNewDriveFlow-selector-4"
                    ref={focusNextRef}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    className='action'
                    onClick={sendWorkflowDefault}
                    style={{ padding: '10px 40px', borderRadius: 40, zIndex: currentWalkthroughStep === 7 ? 1000 : 1, position: 'relative' }}
                    id="testNewDriveFlow-selector-5"
                    disabled={addDriveLoading}
                >
                    Create
                </Button>
            </div>
            {
                currentWalkthroughStep > 1 && currentWalkthroughStep <= 8 ? (
                    <WalkthroughOverlay
                        showWalkthroughNextBtn={currentWalkthroughStep === 3}
                        onClickDone={() => { updateCurrentWalkthroughStep(nextWalkthroughStep); setNextWalkthroughStep(nextWalkthroughStep + 1); }}
                    />
                ) : ''
            }
        </div>
    )
}

AddDefaultDrive.propTypes = {
    goBack: PropTypes.func,
    bot: PropTypes.object,
    companyName: PropTypes.string,
    change: PropTypes.number,
    setChange: PropTypes.func,
    onClose: PropTypes.func,
    setSnackErr: PropTypes.func,
    handleSnackClick: PropTypes.func,
    currentWalkthroughStep: PropTypes.number,
    updateCurrentWalkthroughStep: PropTypes.func,
    currentWalkthroughType: PropTypes.string
}

export default AddDefaultDrive;
