import React from 'react';
import PropTypes from 'prop-types';
import { Document, Image, Page, Text, View, Font, StyleSheet } from '@react-pdf/renderer';

import { getBucket, getJobRole } from '../utils/reports';
import { percentageColor, reportGrade, reportRoles } from '../constants/reports';

import ReportBg from '../assets/report-bg.jpg';
import WorkFunction1 from '../assets/work-function-1.png';
import WorkFunction2 from '../assets/work-function-2.png';
import WorkFunction3 from '../assets/work-function-3.png';
import WorkFunction4 from '../assets/work-function-4.png';


Font.register({
	family: 'Open Sans',
	fonts: [
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
	]
});

const styles = StyleSheet.create({
	page: {
		position: 'relative',
		padding: '80pt 0 50pt',
		fontFamily: 'Open Sans',
	},
	header: {
		height: '60pt',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0
	},
	headerWrapper: {
		marginRight: '30pt',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	headerTextOne: {
		color: '#fff',
		fontSize: '12pt'
	},
	headerTextTwo: {
		color: '#fff',
		fontSize: '12pt'
	},
	pageWrapper: {
		position: 'relative',
		zIndex: 2,
		lineHeight: 1.5
	}
});

const PdfReport = (props) => {
	const { doughnutRef, barChartRef, candidateData, reportWorkFunctions, detailedReport } = props;

	const jobRole = getJobRole(candidateData?.productId || "");
	const driveName = candidateData?.title;
	const totalScore = parseInt((candidateData?.test?.result?.total || 0) * 100);
	const workFunctions = [];
	Object.keys(candidateData?.test?.result || {}).forEach((item) => {
		if (item !== 'total' && item !== 'typing') {
			workFunctions.push(item);
		}
	});
	const getTimeTaken = (x, y, z = 30) => {
		let time = parseInt(((new Date(x).getTime() - new Date(y).getTime()) / 60000))
		if (time - z > 1)
			time = 30
		return time + " minutes"
	}
	const getScoreChart = () => {
		return doughnutRef.current?.toBase64Image("image/png");
	};

	const getScoreBar = () => {
		return barChartRef.current?.toBase64Image("image/png");
	};

	const getSkills = (wf) => {
		if (wf.associatedSkills) {
			const associatedSkills = wf.associatedSkills.map((as) => as.title);
			return associatedSkills.join(', ');
		} else if (wf.skills) {
			return wf.skills;
		} else {
			return '';
		}
	}

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.header} fixed>
					<View style={styles.headerWrapper}>
						<Text style={styles.headerTextOne}>{driveName}</Text>
						<Text style={styles.headerTextTwo}>{reportRoles[jobRole]}</Text>
					</View>
				</View>
				<View style={{ position: 'absolute', left: 0, right: '60pt', bottom: '30pt', zIndex: 1 }} fixed>
					<Text style={{ textAlign: 'right', fontSize: '12pt' }} render={({ pageNumber }) => (
						`${pageNumber}`
					)} />
				</View>
				<View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 3 }} fixed>
					<Image src={ReportBg} style={{ width: '100%', height: '100%' }} />
				</View>
				<View style={styles.pageWrapper}>
					<View style={{ padding: '0 80pt', fontSize: '12pt' }}>
						<Text style={{ marginTop: '25pt', color: '#134f5c', fontSize: '14pt', fontWeight: 700, textAlign: 'center' }}>REPORT</Text>
						<View style={{ marginTop: '20pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderWidth: '1pt' }}>
							<View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Name</Text>
								</View>
								<View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
									<Text>{candidateData?.name}</Text>
								</View>
							</View>
							<View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Candidate ID</Text>
								</View>
								<View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
									<Text>{candidateData?.id}</Text>
								</View>
							</View>
							<View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Start time</Text>
								</View>
								<View style={{ width: '25%', color: '#7d7d7d', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>{new Date(candidateData?.test?.createdDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toUpperCase()}</Text>
								</View>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Date</Text>
								</View>
								<View style={{ width: '25%', color: '#7d7d7d', padding: '5pt' }}>
									<Text>{new Date(candidateData?.test?.endDate).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}</Text>
								</View>
							</View>
							<View style={{ display: 'flex', flexDirection: 'row' }}>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Attempt duration</Text>
								</View>
								<View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
									<Text>{getTimeTaken(candidateData?.test?.endDate, candidateData?.test?.createdDate)}</Text>
								</View>
							</View>
						</View>
						<Text style={{ marginTop: '30pt', color: '#134f5c', fontSize: '14pt', fontWeight: 700, textAlign: 'center', textTransform: 'uppercase' }}>Performance Overview</Text>
						<View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
							<View style={{ position: 'relative', padding: '10pt', display: 'flex', flexDirection: 'column', alignItems: 'center', borderStyle: 'solid', borderColor: '#f1f1f1', borderWidth: '1pt' }}>
								<Text style={{ position: 'absolute', left: '45pt', top: '45pt', fontSize: '20pt' }}>{totalScore}%</Text>
								<Image style={{ width: '100pt', height: '100pt' }} src={getScoreChart()} />
								<Text style={{ marginTop: '5pt', fontSize: '10pt', textAlign: 'center' }}>{reportGrade[getBucket(totalScore)]}</Text>
							</View>
						</View>
						<Text style={{ marginTop: '30pt', color: '#134f5c', fontSize: '12pt', fontWeight: 700, textAlign: 'center' }}>Total Score</Text>
						{
							workFunctions.map((item, i) => {
								if (Object.prototype.hasOwnProperty.call(reportWorkFunctions, item.toLowerCase())) {
									const title = reportWorkFunctions[item.toLowerCase()].label;
									const skillScore = parseInt(candidateData?.test?.result[item] * 100);

									return (
										<View style={{ display: 'flex', flexDirection: 'row', padding: '0 20pt', alignItems: 'center', marginTop: '10pt' }} key={title}>
											<View>
												<Image src={i % 2 === 0 ? WorkFunction1 : WorkFunction2} style={{ width: '24pt', height: '24pt' }} />
											</View>
											<View style={{ width: '350pt', marginLeft: '15pt' }}>
												<Text style={{ fontSize: '12pt', width: '350pt' }}>{title}</Text>
												<View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2pt' }}>
													<View style={{ position: 'relative', display: 'flex', flex: 1 }}>
														<View style={{ backgroundColor: '#F1F1F1', height: '10pt' }} />
														<View style={{ backgroundColor: percentageColor[getBucket(skillScore)], position: 'absolute', top: 0, bottom: 0, left: 0, right: `${(100 - skillScore)}%` }} />
													</View>
													<Text style={{ fontSize: '14pt', color: '#696969', marginLeft: '10pt' }}>{skillScore}%</Text>
												</View>
											</View>
										</View>
									)
								} else {
									return <View key={i} />
								}
							})
						}
						{
							detailedReport ? (
								<Text style={{ textAlign: 'center', marginTop: '15pt', fontSize: '14pt', fontWeight: 700 }} break>Category breakdown</Text>
							) : <View />
						}
						{
							detailedReport ? (
								<Text style={{ marginTop: '40pt', fontSize: '12pt', fontWeight: 700 }}>Critical work functions</Text>
							) : <View />
						}
						{
							detailedReport ? workFunctions.map((item, i) => {
								if (Object.prototype.hasOwnProperty.call(reportWorkFunctions, item.toLowerCase()) && item.toLowerCase() !== 'typ') {
									const title = reportWorkFunctions[item.toLowerCase()].label;
									const skillScore = parseInt(candidateData?.test?.result[item] * 100);
									const skills = getSkills(reportWorkFunctions[item.toLowerCase()]);

									return (
										<View style={{ marginTop: '20pt', fontSize: '12pt' }} key={item}>
											<Text style={{ marginLeft: '24pt', fontWeight: 700 }}>{String.fromCharCode(97 + i)}. {title}</Text>
											{
												reportWorkFunctions[item.toLowerCase()].typicalTasks ? (
													<View style={{ display: 'flex', flexDirection: 'row', marginTop: '10pt' }}>
														<View style={{ marginRight: '10pt', display: 'flex', flex: '0 1 24pt' }}>
															<Image src={WorkFunction3} style={{ width: '24pt', height: '24pt' }} />
														</View>
														<View style={{ display: 'flex', flexDirection: 'column' }}>
															<Text style={{ marginTop: '2pt' }}>Associated key tasks:</Text>
															<View style={{ marginLeft: '10pt' }}>
																{
																	reportWorkFunctions[item.toLowerCase()].typicalTasks.map((tt) => (
																		<View key={tt} style={{ display: 'flex', flexDirection: 'row' }} wrap={false}>
																			<View style={{ height: '5pt', width: '5pt', borderRadius: '5pt', backgroundColor: '#000', marginTop: '7pt', marginRight: '8pt' }} />
																			<Text>{tt}</Text>
																		</View>
																	))
																}
															</View>
														</View>
													</View>
												) : (
													<View style={{ display: 'flex', flexDirection: 'row', marginTop: '10pt', alignItems: 'center' }}>
														<View style={{ marginRight: '10pt', display: 'flex', flex: '0 1 24pt' }}>
															<Image src={WorkFunction3} style={{ width: '24pt', height: '24pt' }} />
														</View>
														<View style={{ display: 'flex', flexDirection: 'row', flex: '1 0 auto' }}>
															<Text>Associated skills: {skills}</Text>
														</View>
													</View>
												)
											}
											<View style={{ display: 'flex', flexDirection: 'row', marginTop: '5pt', alignItems: 'center' }}>
												<View style={{ marginRight: '10pt', height: '24pt', width: '24pt', backgroundColor: percentageColor[getBucket(skillScore)], borderRadius: '4pt', fontSize: '15pt', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
													<Text style={{ textAlign: 'center' }}>{skillScore}</Text>
												</View>
												<View>
													<Text>Candidate&apos;s performance: {reportGrade[getBucket(skillScore)]}</Text>
												</View>
											</View>
											<View style={{ display: 'flex', flexDirection: 'row', marginTop: '5pt', alignItems: 'center' }}>
												<View style={{ marginRight: '10pt', display: 'flex', flex: '0 1 24pt' }}>
													<Image src={WorkFunction4} style={{ width: '24pt', height: '24pt' }} />
												</View>
												<View style={{ display: 'flex', flexDirection: 'row', flex: '1 0 auto' }}>
													<Text>{reportWorkFunctions[item.toLowerCase()].remarks ? reportWorkFunctions[item.toLowerCase()].remarks[getBucket(skillScore)] : ''}</Text>
												</View>
											</View>
										</View>
									)
								} else if (item.toLowerCase() === 'typ') {
									const title = reportWorkFunctions[item.toLowerCase()].label;
									const skillScore = parseInt(candidateData?.test?.result[item] * 100);
									let accuracy = 0;
									let wordsPerMinute = 0;
									if (candidateData?.test.result.typing && candidateData?.test.result.typing.acc) {
										accuracy = parseInt(candidateData?.test.result.typing.acc * 100)
									}

									if (candidateData?.test.result.typing && candidateData?.test.result.typing.wpm) {
										wordsPerMinute = parseInt(candidateData?.test.result.typing.wpm)
									}

									return (
										<View style={{ marginTop: '20pt', fontSize: '12pt' }} key={item}>
											<Text style={{ marginLeft: '24pt', fontWeight: 700 }}>{String.fromCharCode(97 + i)}. {title}</Text>
											<View style={{ display: 'flex', flexDirection: 'row', marginTop: '10pt', alignItems: 'center' }}>
												<View style={{ marginRight: '10pt', display: 'flex', flex: '0 1 24pt' }}>
													<Image src={WorkFunction3} style={{ width: '24pt', height: '24pt' }} />
												</View>
												<View style={{ display: 'flex', flexDirection: 'row', flex: '1 0 auto' }}>
													<Text>Accuracy: {accuracy}%</Text>
												</View>
											</View>
											<View style={{ display: 'flex', flexDirection: 'row', marginTop: '5pt', alignItems: 'center' }}>
												<View style={{ marginRight: '10pt', display: 'flex', flex: '0 1 24pt' }}>
													<Image src={WorkFunction4} style={{ width: '24pt', height: '24pt' }} />
												</View>
												<View style={{ display: 'flex', flexDirection: 'row', flex: '1 0 auto' }}>
													<Text>Words Per Minute: {wordsPerMinute}</Text>
												</View>
											</View>
											<View style={{ display: 'flex', flexDirection: 'row', marginTop: '5pt', alignItems: 'center' }}>
												<View style={{ marginRight: '10pt', height: '24pt', width: '24pt', backgroundColor: percentageColor[getBucket(skillScore)], borderRadius: '4pt', fontSize: '15pt', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
													<Text style={{ textAlign: 'center' }}>{skillScore}</Text>
												</View>
												<View>
													<Text>Candidate&apos;s performance: {reportGrade[getBucket(skillScore)]}</Text>
												</View>
											</View>
										</View>
									)
								} else {
									return <View key={i} />
								}
							}) : <View />
						}
						{
							barChartRef.current && detailedReport ? (
								<View wrap={false}>
									<Text style={{ marginTop: '100pt', fontSize: '14pt', fontWeight: 700, color: '#134f5c', textAlign: 'center', marginBottom: '40pt' }}>Time map</Text>
									<Image src={getScoreBar()} style={{ width: '400pt', height: 'auto', margin: '0 auto' }} />
									<Text style={{ fontSize: '10pt', color: '#134f5c', marginTop: '40pt' }}>The candidate’s time map shows the time spent by the candidate on each question, compared against the expected time allocation. This shows you where the candidate spent more time than required, which might affect their overall performance</Text>
								</View>
							) : <View />
						}
						{
							detailedReport ? (
								<Text style={{ marginTop: '15pt', fontSize: '14pt', fontWeight: 700, color: '#134f5c', textAlign: 'center' }} break>Interviewer&apos;s Guide</Text>
							) : <View />
						}
						{
							detailedReport ? (
								<Text style={{ marginTop: '20pt' }}>Our assessment is built on the principle of testing how well candidates are able to perform key tasks associated with the role. This enables us to observe directly how they are likely to perform in the job, as well as to identify strengths and gaps in their core skills. More details on the skill sets needed in this role can be found below:</Text>
							) : <View />
						}
						{
							detailedReport ? workFunctions.map((wf, i) => {
								if (Object.prototype.hasOwnProperty.call(reportWorkFunctions, wf.toLowerCase())) {
									return reportWorkFunctions[wf.toLowerCase()].typicalTasks ? (
										<View>
											<Text style={{ marginTop: '25pt', fontWeight: 700, color: '#134f5c', textAlign: 'center' }}>{reportWorkFunctions[wf.toLowerCase()].label.toUpperCase()}</Text>
											<Text style={{ marginTop: '20pt', fontWeight: 700, color: '#134f5c' }}>Typical Tasks:</Text>
											<View style={{ marginTop: '5pt', marginLeft: '10pt' }} wrap={false}>
												{
													reportWorkFunctions[wf.toLowerCase()].typicalTasks.map((tt) => (
														<View key={tt} style={{ display: 'flex', flexDirection: 'row' }}>
															<View style={{ height: '5pt', width: '5pt', borderRadius: '5pt', backgroundColor: '#000', marginTop: '7pt', marginRight: '8pt' }} />
															<Text>{tt}</Text>
														</View>
													))
												}
											</View>
											<Text style={{ marginTop: '20pt', fontWeight: 700, color: '#134f5c' }}>Associated Skills:</Text>
											<View style={{ marginTop: '5pt', marginLeft: '10pt' }}>
												{
													reportWorkFunctions[wf.toLowerCase()].associatedSkills.map((as) => (
														<View key={as.title} style={{ display: 'flex', flexDirection: 'row' }} wrap={false}>
															<View style={{ height: '5pt', width: '5pt', borderRadius: '5pt', backgroundColor: '#000', marginTop: '7pt', marginRight: '8pt' }} />
															<Text><Text style={{ fontWeight: 700, color: '#134f5c' }}>{as.title}:</Text> {as.detail}</Text>
														</View>
													))
												}
											</View>
										</View>
									) : (
										Object.prototype.hasOwnProperty.call(reportWorkFunctions[wf.toLowerCase()], 'relevantQuestions') ? (
											<View>
												<Text style={{ marginTop: '35pt', fontWeight: 700, color: '#134f5c' }}>{reportWorkFunctions[wf.toLowerCase()].label}</Text>
												<Text style={{ marginTop: '7pt' }}>{reportWorkFunctions[wf].description}</Text>
												<Text style={{ marginTop: '20pt', fontWeight: 700, color: '#000' }}>Relevant questions that can be asked in the interview:</Text>
												<View style={{ marginTop: '5pt' }}>
													{
														reportWorkFunctions[wf.toLowerCase()].relevantQuestions.map((rq) => (
															<View key={rq} style={{ display: 'flex', flexDirection: 'row' }}>
																<View style={{ height: '5pt', width: '5pt', borderRadius: '5pt', backgroundColor: '#000', marginTop: '7pt', marginRight: '8pt' }} />
																<Text>{rq}</Text>
															</View>
														))
													}
												</View>
											</View>
										) : <View />
									)
								} else {
									return <View key={i} />
								}
							}) : <View />
						}
					</View>
				</View>
			</Page>
		</Document >
	)
};

PdfReport.propTypes = {
	doughnutRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	barChartRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	candidateData: PropTypes.object,
	reportWorkFunctions: PropTypes.object,
	detailedReport: PropTypes.bool
}

export default PdfReport;
