import { takeLatest, all, put } from 'redux-saga/effects';

import axios from "../utils/axios";
import {
    SET_CONVO,
    GET_CANDIDATES_BY_TYPE,
    SET_CANDIDATES,
    SET_SUCCESS_MSG,
    EDIT_CANDIDATE_NAME,
    DELETE_CANDIDATE,
    EDIT_DRIVE_NAME,
    USER_SIGN_IN,
    AUTH_LOADING,
    IS_AUTHENTICATED,
    AUTH_ERROR_MSG,
    FETCH_NOTIFICATIONS,
    SET_NOTIFICATIONS,
    UPDATE_NOTIFICATION,
    SETTINGS_LOADING,
    VERIFY_USER,
    SEND_FORGOT_PASSWORD_LINK,
    SET_PASSWORD,
    USER_LOGOUT,
    GET_DRIVE_DETAILS,
    SET_DRIVE_DETAILS,
    ADD_USER,
    ADD_COMPANY,
    SET_COMPANIES,
    ADD_BOTS,
    DELETE_BOTS,
    GET_SUPERUSER,
    SET_SUPERUSER,
    ADD_SUPERUSER,
    DELETE_SUPERUSER,
    GET_FEEDBACK,
    SET_FEEDBACK,
    FETCH_MY_DETAILS,
    SET_MY_DETAILS,
    UPDATE_MY_DETAILS,
    CHANGE_MY_PASSWORD,
    ADD_PRE_SCREENING_BOT,
    DELETE_PRE_SCREENING_BOT,
    FETCH_PRE_SCREENING_BOTS,
    SET_PRE_SCREENING_BOTS,
    FETCH_SAAS_PLAN_LIST,
    SET_SAAS_PLAN_LIST,
    FETCH_SAAS_PLAN_DETAILS,
    SET_SAAS_PLAN_DETAILS,
    UPDATE_SAAS_PLAN_DETAILS,
    EDIT_PRE_SCREENING_BOT,
    UPDATE_ASSESSMENT_BOT_LOGO,
    UPDATE_DASHBOARD_LOGO,
    SET_DASHBOARD_LOGO,
    CREATE_WORKFLOW_JOB_ROLE,
    SET_JOB_ROLE_DETAILS,
    SET_CREATE_JOB_LOADING,
    SEND_PRE_SCREENING_TEST,
    FETCH_TRIAL_USERS_LIST,
    SET_TRIAL_USERS_LIST,
    SEND_UPDATE_ALL,
    FETCH_COMPANY_DETAILS,
    SET_COMPANY_DETAILS,
    UPDATE_COMPANY_FEATURES,
    SET_COMPANY_BOTS,
    FETCH_BOTS,
    FETCH_COMPANY_BOTS,
    UPDATE_BOT_DETAILS,
    UPDATE_COMPANY_DETAILS,
    UPDATE_COMPANY_GUIDELINES,
    FETCH_COMPANIES,
    SEND_SUPPORT_MSG,
    UPDATE_PRESCREENING_DETAILS,
    SET_APPLICANTS_LOADING,
    FETCH_SUPPORT_MSGS,
    SET_SUPPORT_MSGS,
    FETCH_WORKFLOWS,
    SET_WORKFLOWS,
    FETCH_CHAT_EVENTS,
    SET_CHAT_EVENTS,
    VERIFY_CODE,
    SET_OTP_VERIFICATION_STATUS,
    CHANGE_USER_PASSWORD,
    EDIT_DRIVE_NAME_LOADING,
    RESTART_CANDIDATE_TEST,
    VERIFY_CODE_SUCCESS,
    FETCH_SKILL_CODES,
    ADD_SKILL_CODE,
    UPDATE_SKILL_CODE,
    DELETE_SKILL_CODE,
    SET_SKILL_CODES,
    FETCH_COMPANY_USERS,
    UPDATE_COMPANY_USER,
    DELETE_COMPANY_USER,
    SET_COMPANY_USERS,
    DEACTIVATE_COMPANY,
    ACTIVATE_COMPANY,
    UPDATE_WORKFLOW_ADMIN,
    UPDATE_CANDIDATE_RESUME,
    UPDATE_VIDEO_ANSWER
} from '../constants/app';
import { redirectUnauthorisedUser } from '../helper/helperFunction';

function* fetchBots(action) {
    const { data } = action;

    try {
        let response = yield axios.get('/bot/all');
        yield put({ type: SET_CONVO, data: response.data });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* fetchCompanyBots(action) {
    const { data } = action;

    try {
        let response = yield axios.get('/company/bots');
        yield put({ type: SET_COMPANY_BOTS, data: response.data });
    } catch (e) {
        console.log(e);
        if (data && data.history) {
            redirectUnauthorisedUser(e, data.history);
        }
    }
}

function* getCandidatesByType(action) {
    const { reqData, history, pageType } = action.data;

    if (pageType === 'settings') {
        yield put({ type: SETTINGS_LOADING, data: true });
    }
    yield put({ type: SET_APPLICANTS_LOADING, data: true });
    yield put({ type: SET_CANDIDATES, data: [] });

    try {
        let response = yield axios.post('/gettype', reqData);
        yield put({ type: SET_CANDIDATES, data: response.data });
        yield put({ type: SET_APPLICANTS_LOADING, data: false });
        if (pageType === 'settings') {
            yield put({ type: SETTINGS_LOADING, data: false });
        }
    } catch (e) {
        console.log(e);

        yield put({ type: SETTINGS_LOADING, data: false });
        yield put({ type: SET_APPLICANTS_LOADING, data: false });
        redirectUnauthorisedUser(e, history);
    }
}

function* editCandidateName(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/update-candidate', { ...data.row, name: data.newName });
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "Name edited successfully" });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* deleteCandidate(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/delete-candidate', { id: data.id });
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: `Deleted ${response.data.deletedCount} candidates successfully` });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* editDriveName(action) {
    const { data } = action;

    yield put({ type: EDIT_DRIVE_NAME_LOADING, data: true });

    try {
        let response = yield axios.put('/workflow/' + data.row.workflowId, { ...data.row, name: data.newName });
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "Edited drive name successfully" });
        yield put({ type: EDIT_DRIVE_NAME_LOADING, data: false });
    } catch (e) {
        console.log(e);
        yield put({ type: EDIT_DRIVE_NAME_LOADING, data: false });
        redirectUnauthorisedUser(e, data.history);
    }
}

function* userSignIn(action) {
    const { userData, history } = action.data;

    yield put({ type: AUTH_LOADING, data: true });

    try {
        let response = yield axios.post('/auth/login/', userData);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.accessToken);
        localStorage.setItem('company', response.data.message.company);
        localStorage.setItem('userName', response.data.message.name);
        localStorage.setItem('email', response.data.message.email);
        localStorage.setItem('userRole', response.data.message.role);
        localStorage.setItem('companyFeatures', JSON.stringify(response.data.message.companyFeatures));
        if (response.data.message.plan) {
            localStorage.setItem('userPlan', JSON.stringify(response.data.message.plan));
        }
        localStorage.setItem('totalAssessments', response.data.message.totalAssessments);

        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: IS_AUTHENTICATED, data: true });
        yield put({
            type: ADD_USER, data: {
                userName: response.data.message.name,
                userRole: response.data.message.role,
                userPlan: response.data.message.plan ? response.data.message.plan : {},
                totalAssessments: parseInt(response.data.message.totalAssessments, 10),
                companyFeatures: response.data.message.companyFeatures
            }
        });
        yield put({ type: IS_AUTHENTICATED, data: true });

        if (response.data.message.role === "Super Admin")
            history.push('/companies');
        else
            history.push('/workflows');
    } catch (error) {
        console.log(error);
        console.log(error.response);
        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: IS_AUTHENTICATED, data: false });
        yield put({ type: AUTH_ERROR_MSG, data: error.response.data.message });
    }
}

function* fetchNotifications(action) {
    const { data } = action;

    try {
        let response = yield axios.get('/notification/all/');
        yield put({ type: SET_NOTIFICATIONS, data: response.data });
    } catch (error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* updateNotification(action) {
    const { notificationData, notifications, history } = action.data;

    try {
        yield axios.post('/notification/edit/', notificationData);

        const newNotificationList = [...notifications];
        newNotificationList.forEach((item) => {
            if (item.notificationId === notificationData.notificationId) {
                item.status = 'Read';
                return;
            }
        });

        yield put({ type: SET_NOTIFICATIONS, data: newNotificationList });
    } catch (error) {
        console.log(error);
        redirectUnauthorisedUser(error, history);
    }
}

function* verifyUser(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/company-user/verify/', data);

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.accessToken);
        localStorage.setItem('email', response.data.message.email);
        localStorage.removeItem('couponCode');
    } catch (error) {
        console.log(error);
        console.log(error.response);
    }
}

function* sendForgotPasswordLink(action) {
    const { data } = action;

    try {
        yield axios.post('/auth/company-user/forgot-password', data);
    } catch (error) {
        console.log(error);
        console.log(error.response);
    }
}

function* setPassword(action) {
    const { userData, history } = action.data;


    try {
        yield axios.post('/company-user/set-password/', userData);

        history.push('/free/signin');
    } catch (error) {
        console.log(error);
        console.log(error.response);
    }
}

function* logoutUser(action) {
    const { userType, history } = action.data;

    try {
        yield axios.get('/auth/logout');

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('company');
        localStorage.removeItem('userName');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userPlan');
        localStorage.removeItem('totalAssessments');

        yield put({ type: IS_AUTHENTICATED, data: false });

        if (userType === 'free') {
            history.push('/free/signin');
        } else {
            history.push('/signin');
        }
    } catch (error) {
        console.log(error);
        console.log(error.response);
        if (userType === 'free') {
            history.push('/free/signin');
        } else {
            history.push('/signin');
        }
    }
}

function* fetchDriveDetails(action) {
    const { data } = action;

    yield put({ type: SET_DRIVE_DETAILS, data: {} });

    try {
        let response = yield axios.get('/workflow/' + data.workflowId);

        yield put({ type: SET_DRIVE_DETAILS, data: response.data });
    } catch (error) {
        console.log(error);
        yield put({ type: SET_DRIVE_DETAILS, data: {} });
        redirectUnauthorisedUser(error, data.history);
    }
}

function* fetchCompanies() {
    try {
        let response = yield axios.get('/all-company');
        yield put({ type: SET_COMPANIES, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* addCompany(data) {
    try {
        yield axios.post('/company', data.data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Company added successfully" });
        yield put({ type: FETCH_COMPANIES });
    } catch (e) {
        console.log(e);
    }
}

function* deactivateCompany(action) {
    const { data } = action;

    try {
        yield axios.post('/deactivate-company', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Company deactivated successfully" });
        yield put({ type: FETCH_COMPANIES });
    } catch (e) {
        console.log(e);
    }
}

function* activateCompany(action) {
    const { data } = action;

    try {
        yield axios.post('/activate-company', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Company activated successfully" });
        yield put({ type: FETCH_COMPANIES });
    } catch (e) {
        console.log(e);
    }
}

function* getSuperUser() {
    try {
        let response = yield axios.get('/super-user/all');
        yield put({ type: SET_SUPERUSER, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* addSuperUser(data) {
    try {
        let response = yield axios.post('/addSuperUser', data.data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "User added successfully" });
    } catch (e) {
        console.log(e);
    }
}

function* deleteSuperUser(data) {
    try {
        let response = yield axios.post('/deleteSuperUser', data.data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "User deleted successfully" });
    } catch (e) {
        console.log(e);
    }
}

function* addBots(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/bot/add', data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "bot added successfully" });
        yield put({ type: FETCH_BOTS });
    } catch (e) {
        console.log(e);
    }
}

function* deleteBots(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/bot/delete', data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "bot deleted successfully" });

        yield put({ type: FETCH_BOTS });
    } catch (e) {
        console.log(e);
    }
}

function* getFeedback(action) {
    const { data } = action;
    
    try {
        let response = yield axios.post('/feedback/all', data);
        yield put({ type: SET_FEEDBACK, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* fetchMyDetails(action) {
    const { data } = action;
    try {
        let response = yield axios.get('/my-details');
        yield put({ type: SET_MY_DETAILS, data: response.data });
    } catch (error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* updateMyDetails(action) {
    const { data } = action;

    try {
        yield axios.post('/update-my-details', data.userData);
        yield put({ type: FETCH_MY_DETAILS });
    } catch (error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* changeMyPassword(action) {
    const { data } = action;

    try {
        yield axios.post('/auth/change-password', data.changePwdData);
    } catch (error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* changeUserPassword(action) {
    const { data } = action;

    try {
        const response = yield axios.post('/auth/company-user/change-password', data.userData);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "Password changed successfully" });
        data.history.push('/signin');
    } catch (error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* fetchPreScreeningBots(action) {
    const { data } = action;
    try {
        let response = yield axios.get('/pre-screening-bot/all');
        yield put({ type: SET_PRE_SCREENING_BOTS, data: response.data });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* addPreScreeningBot(action) {
    const { data } = action;

    try {
        yield axios.post('/pre-screening-bot/add', data.botData);
        yield put({ type: FETCH_PRE_SCREENING_BOTS });
        localStorage.setItem('updatePreScreeningBots', true);
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* editPreScreeningBot(action) {
    const { data } = action;

    try {
        yield axios.post('/pre-screening-bot/edit', data.botData);
        yield put({ type: FETCH_PRE_SCREENING_BOTS });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* deletePreScreeningBot(action) {
    const { data } = action;

    try {
        yield axios.post('/pre-screening-bot/delete', { id: data.id });
        yield put({ type: FETCH_PRE_SCREENING_BOTS });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* fetchSaasPlanList() {
    try {
        let response = yield axios.get('/user-plan/all');
        yield put({ type: SET_SAAS_PLAN_LIST, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* fetchSaasPlanDetails(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/user-plan/details', data);
        if (response && response.data) {
            yield put({ type: SET_SAAS_PLAN_DETAILS, data: response.data });
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateSaasPlanDetails(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/user-plan/edit', data);
        yield put({ type: FETCH_SAAS_PLAN_DETAILS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* updateAssessmentBotLogo(action) {
    const { data } = action;

    try {
        yield axios.post('/workflow/logo', data.formData, { 'Content-Type': 'multipart/form-data' });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* updateDashboardBotLogo(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/company/logo', data.formData, { 'Content-Type': 'multipart/form-data' });
        localStorage.setItem('dashboardLogo', response.data.url);
        yield put({ type: SET_DASHBOARD_LOGO, data: response.data.url });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* createWorkflowJobRole(action) {
    const { data } = action;
    yield put({ type: SET_CREATE_JOB_LOADING, data: true });
    try {
        let response = yield axios.post('/workflow/job-role', data.formData);
        yield put({ type: SET_JOB_ROLE_DETAILS, data: response.data.jobRoleDetails });
        yield put({ type: SET_CREATE_JOB_LOADING, data: false });
    } catch (e) {
        console.log(e);
        yield put({ type: SET_CREATE_JOB_LOADING, data: false });
        redirectUnauthorisedUser(e, data.history);
    }
}

function* sendPreScreeningTest(action) {
    const { data } = action;

    try {
        yield axios.post('/pre-screening/test-invite', data.botData);
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* fetchTrialUsersList() {
    try {
        const response = yield axios.get('/jd-user/all');
        yield put({ type: SET_TRIAL_USERS_LIST, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* sendUpdateAll(action) {
    const { data } = action;

    try {
        yield axios.post('/notification/all', data);
    } catch (e) {
        console.log(e);
    }
}

function* fetchCompanyDetails(action) {
    const { data } = action;

    try {
        let response = yield axios.get('/company/' + data.companyId);
        yield put({ type: SET_COMPANY_DETAILS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* updateCompanyFeatures(action) {
    const { data } = action;

    try {
        yield axios.post('/company/features', data);
    } catch (e) {
        console.log(e);
    }
}

function* updateBotDetails(action) {
    const { data } = action;

    try {
        yield axios.post('/bot/edit', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "bot updated successfully" });
        yield put({ type: FETCH_BOTS });
    } catch (e) {
        console.log(e);
    }
}

function* updateCompanyDetails(action) {
    const { data } = action;

    try {
        yield axios.post('/company/' + data.companyId, data);
        if(data.name) {
            yield put({ type: FETCH_COMPANIES });
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateCompanyPrescreeningDetails(action) {
    const { data } = action;

    try {
        yield axios.post('/company/prescreening', data.formData, { 'Content-Type': 'multipart/form-data' });
    } catch (e) {
        console.log(e);
    }
}

function* updateCompanyGuidelines(action) {
    const { data } = action;

    try {
        yield axios.post('/company/guidelines', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Guidelines edited successfully" });
        yield put({ type: FETCH_COMPANIES });
    } catch (e) {
        console.log(e);
    }
}

function* sendSupportMsg(action) {
    const { data } = action;

    try {
        yield axios.post('/support-msg/add', data.formData);
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* fetchSupportMsgs() {
    try {
        let response = yield axios.get('/support-msg/all');
        yield put({ type: SET_SUPPORT_MSGS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* fetchWorkflows(action) {
    const { data } = action;
    
    try {
        let response = yield axios.post('/workflow/all', data);
        yield put({ type: SET_WORKFLOWS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* fetchChatEvents(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/workflow/candidates', data);
        yield put({ type: SET_CHAT_EVENTS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* verifyCode(action) {
    const { data } = action;

    try {
        const response = yield axios.post('/auth/company-user/verify-otp', data);
        yield put({ type: SET_OTP_VERIFICATION_STATUS, data: response.data.codeVerification });
        yield put({ type: VERIFY_CODE_SUCCESS, data: true });
    } catch (e) {
        console.log(e);
        yield put({ type: VERIFY_CODE_SUCCESS, data: false });
    }
}

function* restartCandidateTest(action) {
    const { data } = action;

    try {
        yield axios.post('/candidate/restart-test', data);
    } catch (e) {
        console.log(e);
    }
}

function* fetchSkillCodes() {
    try {
        let response = yield axios.get('/skill-code/all');
        yield put({ type: SET_SKILL_CODES, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* addSkillCode(action) {
    const { data } = action;

    try {
        yield axios.post('/skill-code/add', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Skill Code added successfully" });
        yield put({ type: FETCH_SKILL_CODES });
    } catch (e) {
        console.log(e);
    }
}

function* updateSkillCode(action) {
    const { data } = action;

    try {
        yield axios.post('/skill-code/edit', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Skill Code updated successfully" });
        yield put({ type: FETCH_SKILL_CODES });
    } catch (e) {
        console.log(e);
    }
}

function* deleteSkillCode(action) {
    const { data } = action;

    try {
        yield axios.post('/skill-code/delete', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Skill Code deleted successfully" });
        yield put({ type: FETCH_SKILL_CODES });
    } catch (e) {
        console.log(e);
    }
}

function* fetchCompanyUsers(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/company-user/all', data);
        yield put({ type: SET_COMPANY_USERS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* updateCompanyUser(action) {
    const { data } = action;

    try {
        yield axios.post('/company-user/edit', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Company User updated successfully" });
    } catch (e) {
        console.log(e);
    }
}

function* deleteCompanyUser(action) {
    const { data } = action;

    try {
        yield axios.post('/company-user/delete', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Company User deleted successfully" });
        yield put({ type: FETCH_COMPANY_USERS, data: { companyId: data.companyId } });
    } catch (e) {
        console.log(e);
    }
}

function* updateWorkflowAdmin(action) {
    const { data } = action;

    try {
        yield axios.post('/workflow/update', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Workflow Updated successfully" });
        yield put({ type: FETCH_WORKFLOWS, data: { companyId: data.companyId } });
    } catch (e) {
        console.log(e);
    }
}

function* updateCandidateResume(action) {
    const { data } = action;

    try {
        yield axios.post('/candidate/upload-resume', data.formData, { 'Content-Type': 'multipart/form-data' });
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Resume Updated successfully" });

        if(data.refreshRows) {
            data.refreshRows();
        }
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* updateVideoAnswer(action) {
    const { data } = action;

    try {
        yield axios.post('/test/update-video', data.formData, { 'Content-Type': 'multipart/form-data' });
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Video Answer Updated successfully" });

        if(data.refreshRows) {
            data.refreshRows();
        }
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

export default function* appSaga() {
    yield all([yield takeLatest(FETCH_BOTS, fetchBots)]);
    yield all([yield takeLatest(FETCH_COMPANY_BOTS, fetchCompanyBots)]);
    yield all([yield takeLatest(GET_CANDIDATES_BY_TYPE, getCandidatesByType)]);
    yield all([yield takeLatest(EDIT_CANDIDATE_NAME, editCandidateName)]);
    yield all([yield takeLatest(DELETE_CANDIDATE, deleteCandidate)]);
    yield all([yield takeLatest(EDIT_DRIVE_NAME, editDriveName)]);
    yield all([yield takeLatest(USER_SIGN_IN, userSignIn)]);
    yield all([yield takeLatest(FETCH_NOTIFICATIONS, fetchNotifications)]);
    yield all([yield takeLatest(UPDATE_NOTIFICATION, updateNotification)]);
    yield all([yield takeLatest(VERIFY_USER, verifyUser)]);
    yield all([yield takeLatest(SEND_FORGOT_PASSWORD_LINK, sendForgotPasswordLink)]);
    yield all([yield takeLatest(CHANGE_USER_PASSWORD, changeUserPassword)]);
    yield all([yield takeLatest(SET_PASSWORD, setPassword)]);
    yield all([yield takeLatest(USER_LOGOUT, logoutUser)]);
    yield all([yield takeLatest(GET_DRIVE_DETAILS, fetchDriveDetails)]);
    yield all([yield takeLatest(FETCH_COMPANIES, fetchCompanies)]);
    yield all([yield takeLatest(ADD_COMPANY, addCompany)]);
    yield all([yield takeLatest(DEACTIVATE_COMPANY, deactivateCompany)]);
    yield all([yield takeLatest(ACTIVATE_COMPANY, activateCompany)]);
    yield all([yield takeLatest(ADD_BOTS, addBots)]);
    yield all([yield takeLatest(DELETE_BOTS, deleteBots)]);
    yield all([yield takeLatest(GET_SUPERUSER, getSuperUser)]);
    yield all([yield takeLatest(ADD_SUPERUSER, addSuperUser)]);
    yield all([yield takeLatest(DELETE_SUPERUSER, deleteSuperUser)]);
    yield all([yield takeLatest(GET_FEEDBACK, getFeedback)]);
    yield all([yield takeLatest(FETCH_MY_DETAILS, fetchMyDetails)]);
    yield all([yield takeLatest(UPDATE_MY_DETAILS, updateMyDetails)]);
    yield all([yield takeLatest(CHANGE_MY_PASSWORD, changeMyPassword)]);
    yield all([yield takeLatest(ADD_PRE_SCREENING_BOT, addPreScreeningBot)]);
    yield all([yield takeLatest(EDIT_PRE_SCREENING_BOT, editPreScreeningBot)]);
    yield all([yield takeLatest(DELETE_PRE_SCREENING_BOT, deletePreScreeningBot)]);
    yield all([yield takeLatest(FETCH_PRE_SCREENING_BOTS, fetchPreScreeningBots)]);
    yield all([yield takeLatest(FETCH_SAAS_PLAN_LIST, fetchSaasPlanList)]);
    yield all([yield takeLatest(FETCH_SAAS_PLAN_DETAILS, fetchSaasPlanDetails)]);
    yield all([yield takeLatest(UPDATE_SAAS_PLAN_DETAILS, updateSaasPlanDetails)]);
    yield all([yield takeLatest(UPDATE_ASSESSMENT_BOT_LOGO, updateAssessmentBotLogo)]);
    yield all([yield takeLatest(UPDATE_DASHBOARD_LOGO, updateDashboardBotLogo)]);
    yield all([yield takeLatest(CREATE_WORKFLOW_JOB_ROLE, createWorkflowJobRole)]);
    yield all([yield takeLatest(SEND_PRE_SCREENING_TEST, sendPreScreeningTest)]);
    yield all([yield takeLatest(FETCH_TRIAL_USERS_LIST, fetchTrialUsersList)]);
    yield all([yield takeLatest(SEND_UPDATE_ALL, sendUpdateAll)]);
    yield all([yield takeLatest(FETCH_COMPANY_DETAILS, fetchCompanyDetails)]);
    yield all([yield takeLatest(UPDATE_COMPANY_FEATURES, updateCompanyFeatures)]);
    yield all([yield takeLatest(UPDATE_BOT_DETAILS, updateBotDetails)]);
    yield all([yield takeLatest(UPDATE_COMPANY_DETAILS, updateCompanyDetails)]);
    yield all([yield takeLatest(UPDATE_COMPANY_GUIDELINES, updateCompanyGuidelines)]);
    yield all([yield takeLatest(SEND_SUPPORT_MSG, sendSupportMsg)]);
    yield all([yield takeLatest(UPDATE_PRESCREENING_DETAILS, updateCompanyPrescreeningDetails)]);
    yield all([yield takeLatest(FETCH_SUPPORT_MSGS, fetchSupportMsgs)]);
    yield all([yield takeLatest(FETCH_WORKFLOWS, fetchWorkflows)]);
    yield all([yield takeLatest(FETCH_CHAT_EVENTS, fetchChatEvents)]);
    yield all([yield takeLatest(VERIFY_CODE, verifyCode)]);
    yield all([yield takeLatest(RESTART_CANDIDATE_TEST, restartCandidateTest)]);
    yield all([yield takeLatest(FETCH_SKILL_CODES, fetchSkillCodes)]);
    yield all([yield takeLatest(ADD_SKILL_CODE, addSkillCode)]);
    yield all([yield takeLatest(UPDATE_SKILL_CODE, updateSkillCode)]);
    yield all([yield takeLatest(DELETE_SKILL_CODE, deleteSkillCode)]);
    yield all([yield takeLatest(FETCH_COMPANY_USERS, fetchCompanyUsers)]);
    yield all([yield takeLatest(UPDATE_COMPANY_USER, updateCompanyUser)]);
    yield all([yield takeLatest(DELETE_COMPANY_USER, deleteCompanyUser)]);
    yield all([yield takeLatest(UPDATE_WORKFLOW_ADMIN, updateWorkflowAdmin)]);
    yield all([yield takeLatest(UPDATE_CANDIDATE_RESUME, updateCandidateResume)]);
    yield all([yield takeLatest(UPDATE_VIDEO_ANSWER, updateVideoAnswer)]);
}
