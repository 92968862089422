/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Bar, Doughnut } from "react-chartjs-2";
import { useTour } from '@reactour/tour';
import { useDropzone } from 'react-dropzone';

import AccountMenu from '../components/AccountMenu';
import Avatar from '../components/common/Avatar';
import Button from '../components/common/Button';
import IconButton from '../components/common/IconButton';
import NotificationBell from '../components/notifications/NotificationBell';
import Modal from '../components/common/CustomModal';
import Snackbar from '../components/common/Snackbar';
import AnalyseModal from '../components/common/AnalyseModal';
import UploadResumeModal from '../components/applicant/UploadResumeModal';
import CustomInviteModal from '../components/common/CustomInviteModal';
import Loader from '../components/common/Loader';

import axios from '../utils/axios';
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	editCandidateName as editCandidateNameRequest,
	updateCandidateResume as updateCandidateResumeRequest,
	updateVideoAnswer as updateVideoAnswerRequest
} from '../actions/app';
import { doughnutData, getCandidateStatus } from '../utils/reports';
import { barChartData, doughnutOptions, reportWorkFunctions } from '../constants/reports';
import { getApplicantsLoading, getCandidatesList, getUserRole } from '../selectors/app';
import { redirectUnauthorisedUser } from '../helper/helperFunction';

import Edit from '../assets/Icons/Edit.png';
import LeftArrow from '../assets/backarrow.png';
import NoData from '../assets/emptyrow2.png';
import RightArrow from '../assets/forwardarrow.png';

import "../styles/Applicant.scss";

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '60px 30px',
	borderWidth: 2,
	borderRadius: 16,
	borderColor: '#4E4E4E',
	borderStyle: 'dashed',
	backgroundColor: '#fff',
	color: '#333333',
	fontSize: 16,
	fontWeight: 600,
	marginTop: 20,
	outline: 'none',
	transition: 'border .24s ease-in-out'
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

const formatScore = (row) => {
	let score = 0.0;
	if (row?.test && row.test?.result && Object.prototype.hasOwnProperty.call(row.test.result, 'total')) score = row.test.result.total;
	if (score > 0) score *= 100
	return score.toFixed();
}

const Nodata = () => {
	return (
		<div className='no-data'>
			<img src={NoData} alt='no-data-img' />
			<p>No data to show</p>
		</div>
	)
}

const getCandVideo = (key) => {
	axios.post('/cand-video', { key }).then((res) => {
		window.open(res.data.signedUrl)
	}).catch((err) => {
		redirectUnauthorisedUser(err, history);
	})
}

const getQuestionText = (row) => {
	let txt = ''

	if (Array.isArray(row.ques)) {
		let ele = row.ques[row.ques.length - 1].split('***::')
		txt = ele[ele.length - 1]
	}
	else if (typeof (row.ques) == 'string') {
		txt = row.ques.split('***::')[row.ques.split('***::').length - 1]
	}

	if (txt.length > 200) {
		txt = txt.substring(0, 200) + '...'
	}
	return txt.replaceAll(';;', ' ').replaceAll('::', ' ')
}

const Applicant = (props) => {
	const {
		notification,
		userRole,
		editCandidateName,
		updateCandidateResume,
		updateVideoAnswer,
		candidates,
		getCandidatesByType,
		loading,
	} = props;

	const { id } = useParams();
	const { isOpen } = useTour();
	const history = useHistory();
	const location = useLocation();
	const doughnutRef = useRef();
	const barChartRef = useRef();

	const [initialIndex, setInitialIndex] = useState(candidates.findIndex(item => item._id === id));
	const [candidate, setCandidate] = useState(candidates[initialIndex]);
	const [candidateChatArr, setCandidateChatArr] = useState([]);
	const [candidatePsChatArr, setCandidatePsChatArr] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [editDriveName, setEditDriveName] = useState('');
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [errSnackOpen, setErrSnackOpen] = useState(false);
	const [successMsg, setSuccessMsg] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [seeMore, setSeeMore] = useState({
		prescreening: false,
		screening: false
	});
	const [proctoringUrl, setProctoringUrl] = useState(`https://myproctvideos.s3.ap-southeast-1.amazonaws.com/${candidate?.code}.avi`);
	const [reportModal, setReportModal] = useState(false);
	const [barData, setBarData] = useState({ ...barChartData });
	const [totalScore, setTotalScore] = useState(0);
	const [uploadResumeModal, setUploadResumeModal] = useState(false)
	const [videoAnsUploadModal, setVideoAnsUploadModal] = useState(false);
	const [activeVideoQuestion, setActiveVideoQuestion] = useState();
	const [videoAnswerFile, setVideoAnswerFile] = useState();
	const [videoQuestionsArr, setVideoQuestionsArr] = useState([]);
	const [openInviteModal, setOpenInviteModal] = useState(false);
	const [tempInv, setTempInv] = useState([]);
	const [tempRem, setTempRem] = useState([]);
	const [tempPsInv, setTempPsInv] = useState([]);
	const [mailList, setMailList] = useState([]);

	const onOpenInviteModal = () => setOpenInviteModal(true);
	const onCloseInviteModal = () => setOpenInviteModal(false);

	const handleGreenSnackOpen = () => setGreenSnackOpen(true);
	const handleErrSnackOpen = () => setErrSnackOpen(true);
	const handleGreenSnackClose = () => setGreenSnackOpen(false);
	const handleErrSnackClose = () => setErrSnackOpen(false);

	const onOpenEditModal = () => {
		setEditModal(true);
		setEditDriveName(candidate?.name);
	}
	const onCloseEditModal = () => {
		setEditModal(false);
		setEditDriveName('');
	}

	const nameEdit = () => {
		if (!editDriveName) {
			setSuccessMsg('');
			setErrMsg('Candidate Name cannot be empty');
			handleErrSnackOpen();
		}
		else {
			editCandidateName({ row: candidate, newName: editDriveName, history });
			onCloseEditModal();
			refreshRows();
		}
	}

	const refreshRows = () => {
		const sidebar = location.state.sidebar;
		const reqData = {
			status:
				sidebar === 'applicants' ? 'candidates' :
					sidebar === 'reviewApplicants' ? 'review' : null,
			id: location.state.workflowId
		};

		getCandidatesByType({ reqData, history });
	}

	useEffect(() => {
		if(candidates.length > 0) {
			refreshRows();
		}
	}, []);

	useEffect(() => {
		if (candidates.length > 0) {
			const newInitialIndex = candidates.findIndex(item => item._id === id);
			setInitialIndex(newInitialIndex);
			const newCandidate = candidates[newInitialIndex];
			setCandidate({ ...newCandidate });
		}
	}, [candidates]);

	const navigateApplicant = (id) => {
		const newInitialIndex = candidates.findIndex(item => item._id === id);
		setInitialIndex(newInitialIndex);
		const newCandidate = candidates[newInitialIndex];
		setCandidate({ ...newCandidate });
		history.push({
			pathname: `/applicant/${id}`,
			state: {
				sidebar: location.state.sidebar,
				workflowId: location.state.workflowId,
				workflowName: location.state.workflowName
			}
		})
	}

	useEffect(() => {
		if (candidate?.test?.chat?.length > 0) {
			const newChatArr = [];
			const newPsChatArr = [];
			const cids = [];
			const newVideoQuestionsArr = [];
			candidate?.test.chat.forEach((item) => {
				if (!cids.includes(item.cid)) {
					cids.push(item.cid);
					newChatArr.push(item);
					if (item.qtype && item.qtype.includes('vid')) {
						newVideoQuestionsArr.push(item);
					}
				}
			});
			if (candidate?.preScreeningTest) {
				candidate?.preScreeningTest.chat.forEach((item) => {
					newPsChatArr.push(item);
				});
			}
			setCandidateChatArr([...newChatArr]);
			setCandidatePsChatArr([...newPsChatArr]);
			setVideoQuestionsArr([...videoQuestionsArr])

			axios.post('/cand-proct-video', { key: `${candidate?.test.userId}.avi` })
				.then((res) => {
					setProctoringUrl(res.data.signedUrl)
				})
				.catch((err) => {
					console.log(err);
				});
		}

		const data1 = [];
		data1.push({ name: candidate?.name, email: candidate?.email, _id: candidate?._id });
		setMailList(data1);
	}, [candidate]);

	const changeStatus = (status) => {
		axios.post('/statusupdatebyid', { candidates: [candidate], status: status })
			.then(() => {
				setSuccessMsg("Status updated successfully");
				handleGreenSnackOpen();
				refreshRows();
			})
			.catch(err => {
				console.log(err);
				redirectUnauthorisedUser(err, history);
				setErrMsg("Could not update status at the moment");
				handleErrSnackOpen();
			});
	}

	const onOpenReportModal = () => {
		if (candidate?.productId && candidate?.productId?.customId === 'Video') {
			setTotalScore(0);
		} else {
			setTotalScore(parseInt((candidate?.test?.result?.total || 0) * 100));
		}

		const newBarData = { ...barData };

		newBarData.labels = [];
		newBarData.datasets[0].data = [];
		newBarData.datasets[1].data = [];

		if (candidate?.test.chat.length > 0 && Object.prototype.hasOwnProperty.call(candidate?.test.chat[0], 'idealqtime')) {
			const newChatArr = [];
			const cids = [];
			candidate?.test.chat.forEach((item) => {
				if (!cids.includes(item.cid)) {
					cids.push(item.cid);
					newChatArr.push(item);
				}
			});

			newChatArr.forEach((item, index) => {
				newBarData.labels.push('Q' + (1 + index));
				newBarData.datasets[0].data.push(item.idealqtime);
				newBarData.datasets[1].data.push(item.userqtime);
			});
		}

		setBarData({ ...newBarData });
		setReportModal(true);
	}

	const onCloseReportModal = () => setReportModal(false);

	const onOpenVideoAnsUploadModal = () => {
		setVideoAnsUploadModal(true);
	}

	const onCloseVideoAnsUploadModal = () => {
		setVideoAnsUploadModal(false);
	}

	const onDrop = useCallback(acceptedFiles => {
		const dname = candidate?.company + "/" + candidate?.code + "/" + activeVideoQuestion;
		const files = acceptedFiles.map((file) => {
			return new File([file], dname + file.name.slice(-4), { type: file.type });
		});

		setVideoAnswerFile(files[0]);
	}, [])

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({ accept: 'video/mp4', maxFiles: 1, onDrop });

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject,
		isDragAccept
	]);

	const actionBtns = [
		{
			name: candidate?.resume
				? `<a href='https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/${candidate?.resume}' download='CV_${candidate?.name.split(' ').join('_')}'>Open Resume</a>`
				: 'Upload Resume',
			action: () => !candidate?.resume ? setUploadResumeModal(true) : null
		},
		{
			name:
				candidate?.status === 'Added' ?
					'Send Screening Invite'
					: 'Screening Invite Sent'
			,
			action: () => onOpenInviteModal(),
			disabled: candidate?.status !== 'Added',
		},
		{
			name: 'Screening Report',
			action: () => onOpenReportModal(),
			disabled: candidate?.status !== 'Complete',
		},
		{
			name: candidate?.status === 'Added' || candidate?.status === 'Invited' || candidate?.status === 'PS Passed' || candidate?.status === 'PS Failed' ? 'Proctoring Video' : `<a rel="noreferrer" href='${proctoringUrl}' target="_blank">Proctoring Video</a>`,
			action: () => null,
			disabled: candidate?.status === 'Added' || candidate?.status === 'Invited' || candidate?.status === 'PS Passed' || candidate?.status === 'PS Failed',
		},
		{
			name: 'Upload Video Answers',
			action: () => onOpenVideoAnsUploadModal(),
			disabled: videoQuestionsArr.length === 0
		},
	];

	const updateVideoAnswerFn = () => {
		const formData = new FormData();
		formData.append('testId', candidate?.test._id);
		formData.append('file', videoAnswerFile);
		updateVideoAnswer({ formData, history, refreshRows });
		onCloseVideoAnsUploadModal();
	}

	useEffect(() => {
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'inviteEmail' }).then((res) => {
			setTempInv(res.data.mails);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		})
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'remindEmail' }).then((res) => {
			setTempRem(res.data.mails);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		})
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'psInviteEmail' }).then((res) => {
			setTempPsInv(res.data.mails);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		})
	}, []);

	const tempInvObjArr = tempInv?.length &&
		tempInv.map((con, index) => {
			return {
				label: con.name,
				value: index,
			}
		}) || []

	const tempRemObjArr = tempRem?.length &&
		tempRem.map((con, index) => {
			return {
				label: con.name,
				value: index,
			}
		}) || [];

	if (loading) return (
		<div className='loading-wrapper'>
			<Loader />
		</div>
	)
	return (
		<>
			<div className='applicant-profile'>
				<div className="inner-div">
					<div className='top-icon-box'>
						{
							userRole.includes('Admin') ? (
								<div className="tooltip">
									<IconButton aria-label="edit" onClick={onOpenEditModal}>
										<img src={Edit} alt="edit-icon" width={30} height={30} />
									</IconButton>
									<span className="tooltiptext">Edit</span>
								</div>
							) : null
						}
						<NotificationBell notification={notification} />
						<AccountMenu />
					</div>
				</div>
				{
					candidate?.status === 'Complete' ? (
						candidate?.chat?.test && candidate?.chat?.test?.result && Object.prototype.hasOwnProperty.call(candidate?.chat?.test?.result, 'total') && !(candidate?.chat?.productId && candidate?.chat?.productId?.customId && (candidate?.chat?.productId?.customId.toLowerCase().includes('bcgriseconsulting') || candidate?.chat?.productId?.customId.toLowerCase().includes('bcgriseyoga'))) ? (
							<>
								{
									candidate?.chat?.productId && candidate?.chat?.productId?.customId === 'Video' ? null : (
										<Doughnut ref={doughnutRef} data={doughnutData(totalScore)} options={doughnutOptions} style={{ width: 100, height: 100, display: 'none' }} />
									)
								}
								{
									barData?.labels.length > 0 ? (
										<Bar ref={barChartRef} data={barData} options={barData.options} style={{ display: 'none' }} />
									) : null
								}
							</>
						) : null
					) : ''
				}
				<div className='applicant-profile-wrapper'>
					<div className='applicant-profile-wrapper-header'>
						<button className='left-arrow' onClick={() => navigateApplicant(candidates[initialIndex - 1]._id)} disabled={initialIndex === 0}>
							<img src={LeftArrow} alt='back-button' />
						</button>
						<div className='candidate'>
							<div className='candidate-info'>
								<Avatar name={candidate?.name} styles={{
									text: {
										backgroundColor: '#F3F3F3',
										border: '2px solid #CECECE',
										color: '#858585',
										fontSize: 24
									}
								}} />
								<div className='candidate-info-basic'>
									<p className='name'>{candidate?.name}</p>
									<p className='email'>{candidate?.email}</p>
								</div>
							</div>
							<div className='candidate-data'>
								<p className='candidate-data-status'>
									Application Status:&nbsp;
									<span>{getCandidateStatus(candidate?.status)}</span>
								</p>
								<p className='candidate-data-score'>
									Score:&nbsp;
									<span>{formatScore(candidate)}%</span>
								</p>
							</div>
						</div>
						<button className='right-arrow' onClick={() => navigateApplicant(candidates[initialIndex + 1]._id)} disabled={initialIndex === candidates.length - 1}>
							<img src={RightArrow} alt='forward-button' />
						</button>
					</div>
					<div className='applicant-profile-wrapper-body'>
						<div className='applicant-profile-wrapper-body-left'>
							{actionBtns.map((btn) => (
								<button key={btn.name} className='applicant-profile-wrapper-body-left-btn' onClick={btn.action} disabled={btn.disabled ? btn.disabled : false} dangerouslySetInnerHTML={{ __html: btn.name }} />
							))}
						</div>
						<div className='applicant-profile-wrapper-body-right'>
							<div className='applicant-profile-wrapper-body-right-wrapper'>
								<div className='header'>
									<p className='header-title'>Pre-Screening Transcript</p>
									<p className='header-status pass'>{candidate?.preScreeningTest ? 'Passed' : 'Not Applicable'}</p>
								</div>
								{candidatePsChatArr.length > 0 ?
									<>
										{candidatePsChatArr
											.slice(0, !seeMore.prescreening ? 2 : candidatePsChatArr.length)
											.map((row, index) => (
												<div key={index} className='quest-ans-row'>
													<p className='quest-no'>Q.{index + 1}</p>
													<div className='quest-ans'>
														<p className='quest'>{getQuestionText(row)}</p>
														{(typeof row.ans === 'string') ?
															<p className='ans'>
																{(row.ans.includes('.mp4') || row.ans.includes('.webm') || row.ans.includes('.mp3')) ? <a rel='noreferrer' style={{ color: 'inherit' }} href="javascript:void(0)" target="_blank" onClick={(e) => { e.preventDefault(); getCandVideo(row.ans) }}>Click Here</a> : row.ans.replaceAll('::', ". ")}
															</p>
															: null}
													</div>
												</div>
											))
										}
										{candidatePsChatArr.length > 2 ?
											<p
												className='show-more-btn'
												onClick={() => setSeeMore({ ...seeMore, prescreening: !seeMore.prescreening })}
											>
												{seeMore.prescreening ? 'Show Less' : 'Show More'}
											</p>
											: null}
									</>
									: <Nodata />}
							</div>
							<div className='applicant-profile-wrapper-body-right-wrapper'>
								<p className='header-title'>Screening Transcript</p>
								{candidateChatArr.length > 0 ?
									<>
										{candidateChatArr
											.slice(0, !seeMore.screening ? 2 : candidateChatArr.length)
											.map((row, index) => (
												<div key={index} className='quest-ans-row'>
													<p className='quest-no'>Q.{index + 1}</p>
													<div className='quest-ans'>
														<p className='quest'>{getQuestionText(row)}</p>
														{(typeof row.ans === 'string') ?
															<p className='ans'>
																{(row.ans.includes('.mp4') || row.ans.includes('.webm') || row.ans.includes('.mp3')) ? <a rel='noreferrer' style={{ color: 'inherit' }} href="javascript:void(0)" target="_blank" onClick={(e) => { e.preventDefault(); getCandVideo(row.ans) }}>Click Here</a> : row.ans.replaceAll('::', ". ")}
															</p>
															: null}
													</div>
												</div>
											))
										}
										{candidateChatArr.length > 2 ?
											<p
												className='show-more-btn'
												onClick={() => setSeeMore({ ...seeMore, screening: !seeMore.screening })}
											>
												{seeMore.screening ? 'Show Less' : 'Show More'}
											</p>
											: null}
									</>
									: <Nodata />}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal open={editModal} onClose={onCloseEditModal} center classNames={{ modal: 'editModalApplicants' }}>
				<p className="modal-text">
					Edit candidate&apos;s name
				</p>
				<div className="input-div-workflow">
					<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
					<input
						type="text"
						value={editDriveName}
						onChange={(e) => setEditDriveName(e.target.value)}
						placeholder="Name"
						style={{ backgroundColor: "#F2F2F2", borderRadius: 30 }}
					/>
				</div>
				<div className="button-div">
					<Button
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={nameEdit}
					>
						Ok
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<UploadResumeModal
				uploadResumeModal={uploadResumeModal}
				setUploadResumeModal={setUploadResumeModal}
				updateCandidateResume={updateCandidateResume}
				candidate={candidate}
				refreshRows={refreshRows}
			/>
			<Modal
				open={videoAnsUploadModal}
				onClose={onCloseVideoAnsUploadModal}
				center
			>
				<p className='modal-heading-workflow'>Select Video</p>
				<div className='video-ans-modal'>
					<div className='video-ans-modal-left'>
						{videoQuestionsArr.map((item, index) => (
							<div
								key={index}
								className={`video-question-wrapper ${activeVideoQuestion === item.cid ? 'active' : null}`}
								onClick={(() => setActiveVideoQuestion(item.cid))}
							>
								<p className='question-no'>Question {index + 1}</p>
								<p className='question'>{getQuestionText(item.ques)}</p>
							</div>
						))}
					</div>
					<div className='video-ans-modal-right'>
						<p className='heading'>Upload video response</p>
						<div {...getRootProps({ style })}>
							<input {...getInputProps()} />
							{
								isDragActive ?
									<p>Drop the video here ...</p> :
									<p>Browse to Upload (.mp4 format)</p>
							}
						</div>
						<p style={{ marginTop: 16, textAlign: 'center', fontWeight: 500, fontSize: 12, color: '#919191' }}>{videoAnswerFile ? videoAnswerFile.name : null}</p>
					</div>
					<Button
						style={{
							fontFamily: "inherit",
							borderRadius: '25px',
							padding: '5px 12px',
							display: 'block',
							margin: '20px auto 0 auto'
						}}
						size="small"
						variant="contained"
						disabled={!videoAnswerFile || !activeVideoQuestion}
						onClick={updateVideoAnswerFn}
					>
						Ok
					</Button>
				</div>
			</Modal>
			{
				candidate ? (
					candidate.status === 'Complete' ? (
						<AnalyseModal
							open={reportModal}
							onClose={onCloseReportModal}
							chat={candidate}
							changeStatus={changeStatus}
							doughnutRef={doughnutRef}
							barChartRef={barChartRef}
							isOpen={isOpen}
							workFunctions={reportWorkFunctions}
						/>
					) : ''
				) : ''
			}
			{
				candidate?.status === 'Added' ? (
					<CustomInviteModal
						open={openInviteModal}
						onClose={onCloseInviteModal}
						showWalkthrough={false}
						tempInvObjArr={tempInvObjArr}
						setErrMsg={setErrMsg}
						handleSnackClick={handleErrSnackClose}
						mailList={mailList}
						setMailList={setMailList}
						tempPsInv={tempPsInv}
					/>
				) : ''
			}
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose} msg={successMsg} type="success" />
			<Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose} msg={errMsg} type="error" />
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	userRole: getUserRole(),
	candidates: getCandidatesList(),
	loading: getApplicantsLoading(),
});

const mapDispatchToProps = (dispatch) => ({
	editCandidateName: (data) => dispatch(editCandidateNameRequest(data)),
	updateCandidateResume: (data) => dispatch(updateCandidateResumeRequest(data)),
	updateVideoAnswer: (data) => dispatch(updateVideoAnswerRequest(data)),
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data))
});

Applicant.propTypes = {
	notification: PropTypes.object,
	userRole: PropTypes.string,
	editCandidateName: PropTypes.func,
	updateCandidateResume: PropTypes.func,
	updateVideoAnswer: PropTypes.func,
	candidates: PropTypes.array,
	getCandidatesByType: PropTypes.func,
	loading: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(Applicant);