import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { changeMyPassword as changeMyPasswordRequest } from '../../actions/app';

import '../../styles/changePassword.scss';

const ChangePassword = (props) => {
	const { setErrorAlertMsg, onErrorAlertOpen, setSuccessAlertMsg, onSuccessAlertOpen, changeMyPassword } = props;

	const history = useHistory();
	const isSafari = window.safari !== undefined;

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [reNewPassword, setReNewPassword] = useState('');
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showReNewPassword, setShowReNewPassword] = useState(false);

	const saveChanges = () => {
		if(currentPassword === '') {
			setErrorAlertMsg('Enter valid current password');
			onErrorAlertOpen();
		} else if(newPassword === '') {
			setErrorAlertMsg('Enter valid new password');
			onErrorAlertOpen();
		} else if(newPassword !== reNewPassword) {
			setErrorAlertMsg('New Passwords are not equal');
			onErrorAlertOpen();
		} else if(currentPassword === newPassword) {
			setErrorAlertMsg('New password can not be same as old password');
			onErrorAlertOpen();
		} else {
			const changePwdData = {
				oldPassword: currentPassword,
				newPassword
			};

			changeMyPassword({ changePwdData, history });
			setSuccessAlertMsg('Password changed successfully');
			onSuccessAlertOpen();
		}
	}

	return (
		<div className='change-password'>
			<div className='input-group'>
				<input type={showCurrentPassword ? 'text' : 'password'} placeholder='Current password' onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} />
				<div style={{ position: 'absolute', right: (isSafari ? 40 : 15), top: 6, fontSize: 12, color: '#479bd2', cursor: 'pointer' }} onClick={() => setShowCurrentPassword(!showCurrentPassword)}>{showCurrentPassword ? 'Hide' : 'Show'}</div>
			</div>
			<div className='input-group'>
				<input type={showNewPassword ? 'text' : 'password'} placeholder='New password' onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
				<div style={{ position: 'absolute', right: (isSafari ? 40 : 15), top: 6, fontSize: 12, color: '#479bd2', cursor: 'pointer' }} onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? 'Hide' : 'Show'}</div>
			</div>
			<div className='input-group'>
				<input type={showReNewPassword ? 'text' : 'password'} placeholder='Re-enter New password' onChange={(e) => setReNewPassword(e.target.value)} value={reNewPassword} />
				<div style={{ position: 'absolute', right: (isSafari ? 40 : 15), top: 6, fontSize: 12, color: '#479bd2', cursor: 'pointer' }} onClick={() => setShowReNewPassword(!showReNewPassword)}>{showReNewPassword ? 'Hide' : 'Show'}</div>
			</div>
			<div className='change-password-actions'>
				<div onClick={saveChanges}>Save changes</div>
			</div>
		</div>
	)
}

ChangePassword.propTypes = {
	setErrorAlertMsg: PropTypes.func,
	onErrorAlertOpen: PropTypes.func,
	setSuccessAlertMsg: PropTypes.func,
	onSuccessAlertOpen: PropTypes.func,
	changeMyPassword: PropTypes.func
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
	changeMyPassword: (data) => dispatch(changeMyPasswordRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
