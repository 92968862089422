import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/common/CustomModal';
import IconButton from '../../components/common/IconButton';
import { Collapse } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { styled } from '@mui/material/styles';

import EmptyTab from "../reports/EmptyTab";
import { getCompanyFeatures } from '../../selectors/app';

const ExpandMore = styled((props) => {
    const { ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
//
const AnalyseModal = (props) => {
    const { open, onClose, chat } = props;

    const [expanded, setExpanded] = useState("");
    const mediaQueryMobile = window.matchMedia('(max-width: 768px)');

    const handleExpandClick = index => {
        if (expanded === index) {
            setExpanded("")
        } else {
            setExpanded(index)
        }
    }

    return (
        <Modal open={open} onClose={onClose} center classNames={{ modal: 'customModalChats' }}>
            <div className="container-team">
                <div className="heading-team">
                    <div>
                        <span style={{ fontSize: mediaQueryMobile.matches ? 20 : 24, fontWeight: "600", color: "#479BD2" }}>{chat.name}</span>
                    </div>
                </div>
                <div>
                    <div className="report-tabs-container">
                        <div className="report-tabs dashboard-forth-step">
                            {
                                chat.preScreeningTest ? (
                                    <div className="tooltip">
                                        <div
                                            className='active-report-tab'>
                                            Pre-Screening Chats
                                        </div>
                                        <span className="tooltiptext">View Transcript</span>
                                    </div>
                                ) : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="scrollblue">
                    {
                        chat?.preScreeningTest?.chat?.length > 0 ? chat.preScreeningTest.chat.map((row, index) => {
                            return index % 2 !== 0 && row.isMyMessage ? (
                                <div key={index} className="row-team">
                                    <div style={{ display: "flex", marginBottom: "15px" }}>
                                        <p style={{ flex: "1", fontWeight: "600" }}>
                                            Q.{Math.floor((index / 2)) + 1} &nbsp;
                                            {chat.preScreeningTest.chat[index - 1].content}
                                        </p>
                                        <div style={{ width: "50px" }}>
                                            <ExpandMore
                                                expand={index === expanded}
                                                onClick={() => { handleExpandClick(index) }}
                                                aria-expanded={index === expanded}
                                                aria-label="expand"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={24} height={24}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                </svg>
                                            </ExpandMore>
                                        </div>
                                    </div>
                                    <Collapse in={index === expanded} timeout="auto">
                                        <div style={{ color: "rgb(110,110,110)" }}>
                                            <b>Answer- </b> {row.content}
                                        </div>
                                    </Collapse>
                                </div>
                            ) : ''
                        })
                            : <EmptyTab text={"Couldn't find chats for this candidate"} />
                    }
                </div>
            </div>
        </Modal>
    )
}

AnalyseModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    chat: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
    companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseModal);
