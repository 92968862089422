import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory, withRouter } from 'react-router-dom';

const LocationCheck = (props) => {
	const { children } = props;

	const location = useLocation();
	const history = useHistory();

	if (!location.state) {
		history.push("/workflows");
		return (<></>)
	}

	return (<>{children}</>);
}

LocationCheck.propTypes = {
	children: PropTypes.array
}

export default withRouter(LocationCheck);
