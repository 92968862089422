import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Button from '../components/common/Button';
import DataTable from "../components/common/DataTable";
import Modal from '../components/common/CustomModal';
import { getSupportMsgList } from '../selectors/app';
import { fetchSupportMsgs as fetchSupportMsgsRequest } from '../actions/app';

import emptyRowImg from '../assets/emptyrow1.png';

const SupportMsgs = (props) => {
	const { supportMsgList, fetchSupportMsgs } = props;

	const [currentSupportMsg, setCurrentSupportMsg] = useState([]);
	const [viewModal, setViewModal] = useState(false);
	const [supportMsgArr, setSupportMsgArr] = useState([]);

	useEffect(() => {
		fetchSupportMsgs();
	}, []);

	useEffect(() => {
		const newSupportMsgArr = [];
		supportMsgList.forEach((sml) => {
			const newObj = {
				name: sml.userId && sml.userId.name,
				email: sml.email,
				desc: sml.desc,
				createdDate: sml.createdDate,
				screenshot: ('https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/' + sml.screenshot)
			};
			newSupportMsgArr.push(newObj);
		});
		setSupportMsgArr([...newSupportMsgArr]);
	}, [supportMsgList]);

	const onOpenViewModal = () => setViewModal(true);

	const onCloseViewModal = () => setViewModal(false);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			headerAlign: "left",
			width: 200,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row">
							{params.row.name}
						</p>
					</>
				);
			}
		},
		{
			field: "email",
			headerName: "Email",
			headerAlign: "left",
			width: 200,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row">
							<div className="tooltip" style={{ width: '100%' }}>
								<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.email}</div>
								<span className='tooltiptext datagrid'>{params.row.email}</span>
							</div>
						</p>
					</>
				);
			}
		},
		{
			field: "desc",
			headerName: "Description",
			headerAlign: "left",
			flex: 1,
			width: 250,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.desc}</div>
						</p>
					</>
				);
			}
		},
		{
			field: "createdDate",
			headerName: "Date",
			headerAlign: "left",
			width: 200,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row">
							{renderDate(params.row.createdDate)}
						</p>
					</>
				);
			}
		},
		{
			field: "action",
			headerName: "Actions",
			minWidth: 140,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<div className="datagrid__row datagrid__row-center">
						<Button
							className="btn-datagrid"
							size="small"
							variant="contained"
							onClick={() => setCurrentSupportMsg([params.row])}
						>
							View
						</Button>
					</div>
				)
			}
		},
	]

	const renderDate = (date) => {
		const createdDate = new Date(date);

		return (
			<>{createdDate.toLocaleString()}</>
		)
	}

	const NoRowsOverlay = () => {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	useEffect(() => {
		if (currentSupportMsg.length == 0) return;

		onOpenViewModal();
	}, [currentSupportMsg]);

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", width: "80vw", minHeight: "100vh" }}>
			<Modal open={viewModal} onClose={onCloseViewModal} center classNames={{ modal: 'modal__container' }}>
				<div className="modal__heading">
					Info
				</div>
				{
					currentSupportMsg.length != 0 ?
						Object.keys(currentSupportMsg[0]).map(key => (
							<>
								<div>
									<span><b>{key} : </b></span>
									<span>{key == "createdDate" ? renderDate(currentSupportMsg[0][key]) : currentSupportMsg[0][key]}</span>
								</div><br />
							</>
						))
						:
						""
				}
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseViewModal}
					>
						Ok
					</Button>
				</div>
			</Modal>

			<div className="notificationbar">
			</div>
			<div className="actionbar" />

			<div className="datagrid__container">
				<DataTable
					loading={false}
					noRowsComponent={<NoRowsOverlay />}
					rows={supportMsgArr}
					columns={columns}
					checkboxSelection={false}
					pageSize={15}
				/>
			</div>

		</div>
	);
}

SupportMsgs.propTypes = {
	supportMsgList: PropTypes.array,
	fetchSupportMsgs: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	supportMsgList: getSupportMsgList()
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupportMsgs: () => dispatch(fetchSupportMsgsRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportMsgs);
