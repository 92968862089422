import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { TourProvider } from '@reactour/tour';

import Analytics from "./containers/Analytics";
import Applicant from "./containers/Applicant.jsx";
import AllApplicants from "./containers/AllApplicants";
import BehaviourAnalytics from './containers/BehaviourAnalytics';
import Bots from './containers/Bots';
import Company from "./containers/Company";
import Feedback from "./containers/Feedback";
import ReviewedApplicants from "./containers/ReviewedApplicants";
import LocationCheck from "./LocationCheck";
import ManageBots from './containers/ManageBots';
import ManageFeatures from './containers/ManageFeatures';
import ManageCompanyUsers from './containers/ManageCompanyUsers';
import ManageNotifications from './containers/ManageNotifications';
import ManageWorkflows from './containers/ManageWorkflows';
import MyAccount from "./containers/MyAccount";
import ManageInvitations from "./containers/ManageInvitations";
import ManageSkillCodes from "./containers/ManageSkillCodes";
import PreScreeningDashboard from "./containers/PreScreeningDashboard";
import QuickMenu from "./containers/QuickMenu";
import RestartTest from './containers/RestartTest';
import ReviewApplicants from "./containers/ReviewApplicants";
import SaasPlanDetails from './containers/SaasPlanDetails';
import SaasPlans from "./containers/SaasPlans";
import Settings from "./containers/Settings";
import Sidebar from "./components/sidebar/Sidebar";
import Sidebar1 from "./components/sidebar/Sidebar1";
import SidebarSuperAdmin from "./components/sidebar/SidebarSuperAdmin";
import SignIn from './containers/SignIn';
import Snackbar from "./components/common/Snackbar";
import SuperUser from './containers/SuperUser';
import SupportMsgs from './containers/SupportMsgs';
import Team from "./containers/Team";
import TrialUsers from "./containers/TrialUsers";
import Workflow from "./containers/Workflow";
import VideoSubmission from "./containers/VideoSubmission";
import VideoApplicant from "./containers/VideoApplicant.jsx";
import { getSaasPlanDetails, getSuccessMsg, getUserPlan } from './selectors/app';
import { resetSuccessMsg as resetSuccessMsgRequest, fetchSaasPlanDetails as fetchSaasPlanDetailsRequest } from './actions/app';

const AppRouter = (props) => {
	const { resetSuccessMsg, successMsg, userPlan, fetchSaasPlanDetails, saasPlanDetails } = props;

	const history = useHistory();
	const location = useLocation();

	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [shouldShowTour, setShouldShowTour] = useState(localStorage.getItem('userName') ? false : true);
	const [notification, setNotification] = useState({});
	const [listening, setListening] = useState(false);

	const refObj = useRef();

	useEffect(() => {
		if (!listening) {
			const events = new EventSource(`${process.env.REACT_APP_BASE_SERVER_URL_FULL}events/${localStorage.getItem('email')}`);

			events.onmessage = (event) => {
				const parsedData = JSON.parse(event.data);

				if (!Array.isArray(parsedData)) {
					setNotification(parsedData);
				}
			};

			setListening(true);
		}
	}, [listening]);

	useEffect(() => {
		if (userPlan.name && !saasPlanDetails.name) {
			const data = {
				planName: userPlan.name
			};

			fetchSaasPlanDetails(data);
		}
	}, [userPlan, saasPlanDetails]);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setGreenSnackOpen(false);
		resetSuccessMsg();
	};

	useEffect(() => {
		if (!successMsg) return;

		handleGreenSnackClick();
	}, [successMsg]);

	const styles = {
		popover: (base, state) => {
			return {
				...base,
				background: "linear-gradient(180deg, #215E80 0%, #077991 100%)",
				borderRadius: 40,
				paddingLeft: 35,
				paddingRight: 35,
				paddingTop: 20,
				paddingBottom: 20,
				maxWidth: 600,
				margin: 30,
				...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
			}
		},
	}

	const opositeSide = {
		top: 'bottom',
		bottom: 'top',
		right: 'left',
		left: 'right',
	}

	const doArrow = (position, verticalAlign, horizontalAlign) => {
		if (!position || position === 'custom') {
			return {}
		}

		const side = 30;
		const isVertical = position === 'top' || position === 'bottom';
		const width = isVertical ? side : side * 0.866;
		const height = isVertical ? side * 0.866 : side;
		const color = 'rgba(33, 95, 129, 1)';
		const spaceFromSide = 10;
		const obj = isVertical ? {
			'borderLeft': `${width / 2}px solid transparent`, // CSS Triangle width
			'borderRight': `${width / 2}px solid transparent`, // CSS Triangle width
			[`border${position[0].toUpperCase()}${position.substring(1)}`]:
				`${height}px solid ${color}`, // CSS Triangle height
			[isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
				width + spaceFromSide, // space from side
			[opositeSide[position]]: -height,
		} : {
			'borderTop': `${height / 2}px solid transparent`, // CSS Triangle width
			'borderBottom': `${height / 2}px solid transparent`, // CSS Triangle width
			[`border${position[0].toUpperCase()}${position.substring(1)}`]:
				`${width}px solid ${color}`, // CSS Triangle height
			[isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
				height + spaceFromSide, // space from side
			[opositeSide[position]]: -width,
		};

		return {
			'&::after': {
				content: "''",
				width: 0,
				height: 0,
				position: 'absolute',
				...obj,
			},
		}
	}

	const onBeforeClose = () => {
		if (location.pathname !== "workflows") history.push("/workflows");
	}

	return (
		<>
			<TourProvider
				steps={[]}
				showNavigation={false}
				showCloseButton={false}
				showButtons={false}
				showNumber={false}
				disableInteraction={true}
				showBadge={false}
				onClickMask={() => { }}
				styles={styles}
				position="bottom"
				startAt={0}
				beforeClose={onBeforeClose}
			>
				<Switch>
					<Redirect exact from='/' to='/signin' />
					<Route exact path='/signin'><SignIn /></Route>
					<Route exact path="/forgot-password"><SignIn /></Route>
					<Route exact path="/companies">
						<SidebarSuperAdmin />
						<Company />
					</Route>
					<Route exact path="/bots">
						<SidebarSuperAdmin />
						<Bots />
					</Route>
					<Route exact path="/user-plans">
						<SidebarSuperAdmin />
						<SaasPlans />
					</Route>
					<Route exact path="/user-plans/:userPlanId">
						<SidebarSuperAdmin />
						<SaasPlanDetails />
					</Route>
					<Route path="/manage-notifications">
						<SidebarSuperAdmin />
						<ManageNotifications />
					</Route>
					<Route exact path="/superUser">
						<SidebarSuperAdmin />
						<SuperUser />
					</Route>
					<Route exact path="/feedback">
						<SidebarSuperAdmin />
						<Feedback />
					</Route>
					<Route path="/support-msgs">
						<SidebarSuperAdmin />
						<SupportMsgs />
					</Route>
					<Route exact path="/workflows">
						<Sidebar1 />
						<Workflow shouldShowTour={shouldShowTour} setShouldShowTour={setShouldShowTour} notification={notification} refObj={refObj} />
					</Route>
					<Route exact path="/analytics">
						<LocationCheck>
							<Sidebar />
							<Analytics notification={notification} />
						</LocationCheck>
					</Route>
					<Route exact path="/workflows/:workflowId">
						<LocationCheck>
							<Sidebar />
							<QuickMenu notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/applicant/:id">
						<LocationCheck>
							<Sidebar />
							<Applicant notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/video-applicant/:id">
						<LocationCheck>
							<Sidebar />
							<VideoApplicant notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/applicants">
						<LocationCheck>
							<Sidebar />
							<AllApplicants notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/new-applicants">
						<LocationCheck>
							<Sidebar />
							<ManageInvitations notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/review-applicants">
						<LocationCheck>
							<Sidebar />
							<ReviewApplicants notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/settings">
						<LocationCheck>
							<Sidebar />
							<Settings notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/finalized">
						<LocationCheck>
							<Sidebar />
							<ReviewedApplicants notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/video-submission">
						<LocationCheck>
							<Sidebar />
							<VideoSubmission notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/team">
						<LocationCheck>
							<Sidebar1 />
							<Team notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/my-account">
						<Sidebar1 />
						<MyAccount notification={notification} />
					</Route>
					<Route path="/prescreening/dashboard">
						<Sidebar1 />
						<PreScreeningDashboard notification={notification} />
					</Route>
					<Route path="/jd-users">
						<TrialUsers />
					</Route>
					<Route path="/manage-bots">
						<SidebarSuperAdmin />
						<ManageBots />
					</Route>
					<Route path="/manage-features">
						<SidebarSuperAdmin />
						<ManageFeatures />
					</Route>
					<Route path="/behaviour-analytics">
						<SidebarSuperAdmin />
						<BehaviourAnalytics />
					</Route>
					<Route path="/restart-test">
						<SidebarSuperAdmin />
						<RestartTest />
					</Route>
					<Route path="/manage-skill-codes">
						<SidebarSuperAdmin />
						<ManageSkillCodes />
					</Route>
					<Route path="/manage-company-users">
						<SidebarSuperAdmin />
						<ManageCompanyUsers />
					</Route>
					<Route path="/manage-workflows">
						<SidebarSuperAdmin />
						<ManageWorkflows />
					</Route>
				</Switch>
			</TourProvider>

			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose} msg={successMsg} type="success" />
		</>
	);
}

const mapStateToProps = createStructuredSelector({
	successMsg: getSuccessMsg(),
	userPlan: getUserPlan(),
	saasPlanDetails: getSaasPlanDetails()
});

const mapDispatchToProps = (dispatch) => ({
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	fetchSaasPlanDetails: (data) => dispatch(fetchSaasPlanDetailsRequest(data)),
});

AppRouter.propTypes = {
	successMsg: PropTypes.string,
	resetSuccessMsg: PropTypes.func,
	userPlan: PropTypes.object,
	fetchSaasPlanDetails: PropTypes.func,
	saasPlanDetails: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
