import React from 'react';
import PropTypes from 'prop-types';
import { Document, Image, Page, Text, View, Font, StyleSheet } from '@react-pdf/renderer';

import ReportBg from '../assets/report-bg.jpg';
import WorkFunction3 from '../assets/work-function-3.png';

Font.register({
	family: 'Open Sans',
	fonts: [
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
	]
});

const styles = StyleSheet.create({
	page: {
		position: 'relative',
		padding: '80pt 0 40pt',
		fontFamily: 'Open Sans',
	},
	pageWrapper: {
		position: 'relative',
		zIndex: 2,
		lineHeight: 1.5
	},
	header: {
		height: '60pt',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0
	},
	headerWrapper: {
		marginRight: '30pt',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	headerTextOne: {
		color: '#fff',
		fontSize: '12pt'
	},
	headerTextTwo: {
		color: '#fff',
		fontSize: '12pt'
	}
});

const PDFTranscript = (props) => {
	const { candidateData } = props;
	const driveName = candidateData.title;
	const probStat = candidateData.problem.split(';;');

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.header} fixed>
					<View style={styles.headerWrapper}>
						<Text style={styles.headerTextOne}>{driveName}</Text>
						<Text style={styles.headerTextTwo}>Consulting</Text>
					</View>
				</View>
				<View style={{ position: 'absolute', left: 0, right: '60pt', bottom: '30pt', zIndex: 1 }} fixed>
					<Text style={{ textAlign: 'right', fontSize: '12pt' }} render={({ pageNumber }) => (
						`${pageNumber}`
					)} />
				</View>
				<View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 3 }} fixed>
					<Image src={ReportBg} style={{ width: '100%', height: '100%' }} />
				</View>
				<View style={styles.pageWrapper}>
					<View style={{ padding: '0 80pt 20t', fontSize: '12pt' }}>
						<Text style={{ marginTop: '35pt', color: '#134f5c', fontSize: '14pt', fontWeight: 700, textAlign: 'center' }}>Interview Transcript</Text>
						<View style={{ marginTop: '30pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderWidth: '1pt' }}>
							<View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Name</Text>
								</View>
								<View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
									<Text>{candidateData.name}</Text>
								</View>
							</View>
							<View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Candidate ID</Text>
								</View>
								<View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
									<Text>{candidateData.id}</Text>
								</View>
							</View>
							<View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Start time</Text>
								</View>
								<View style={{ width: '25%', color: '#7d7d7d', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>{new Date(candidateData.test.createdDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toUpperCase()}</Text>
								</View>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Date</Text>
								</View>
								<View style={{ width: '25%', color: '#7d7d7d', padding: '5pt' }}>
									<Text>{new Date(candidateData.test.endDate).toDateString()}</Text>
								</View>
							</View>
							<View style={{ display: 'flex', flexDirection: 'row' }}>
								<View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
									<Text>Attempt duration</Text>
								</View>
								<View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
									<Text>{parseInt(((new Date(candidateData.test.endDate).getTime() - new Date(candidateData.test.createdDate).getTime()) / 60000)) + ' minutes'}</Text>
								</View>
							</View>
						</View>
						<View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '40pt' }}>
							<Image src={WorkFunction3} style={{ width: '24pt', marginRight: '16pt' }} />
							<Text style={{ fontWeight: 700, color: '#134f5c' }}>Scenario</Text>
						</View>
						<View style={{ padding: '0 40pt', marginTop: '30pt' }}>
							<View>
								{
									probStat.map((ps, i) => {
										const allLine = ps.split('::');
										return (
											<View key={i}>
												{
													allLine.map((al, j) => (
														<Text key={j}>{al.replace(/\*/g, "")}</Text>
													))
												}
											</View>
										)
									})
								}
							</View>
							{
								candidateData.test.chat.map((tc) => {
									const quesArr = Array.isArray(tc.ques) ? tc.ques : tc.ques.split(';;');
									const ansArr = tc.ans.split(';;');
									const respArr = tc.resp.split(';;');
									return (
										<View key={tc.cid} style={{ marginTop: '40pt' }} wrap>
											<Text style={{ fontWeight: 700, color: '#134f5c' }}>Virtual Trainer : </Text>
											{
												quesArr.map((ps, i) => {
													if (ps.includes("data:image/")) {
														return (
															<Image src={ps} key={i} style={{ marginTop: '20pt', width: '100%', height: 'auto', maxHeight: "60%" }} />
														)
													}
													else {
														const allLine = ps.split('::');
														return (
															<View key={i} style={{ marginTop: '20pt' }} >
																{
																	allLine.map((al, j) => {
																		return (
																			<View key={j}>
																				{
																					al.split('***').map((item, k) => (
																						<Text key={k} style={{ marginTop: '10pt' }}>{item.split('**')[0]}<Text style={{ fontWeight: 700 }}>{item.split('**')[1]}</Text></Text>
																					))
																				}
																			</View>
																		)
																	})
																}
															</View>
														)
													}
												})
											}
											<Text style={{ fontWeight: 700, color: '#134f5c', marginTop: '30pt' }}>Your response : </Text>
											{
												ansArr.map((ps, i) => {
													const allLine = ps.split('::');
													return (
														<View key={i} style={{ marginTop: '20pt' }} >
															{
																allLine.map((al, j) => {
																	return (
																		<View key={j}>
																			{
																				al.split('***').map((item, k) => (
																					<Text key={k} style={{ marginTop: '10pt' }}>{item.split('**')[0]}<Text style={{ fontWeight: 700 }}>{item.split('**')[1]}</Text></Text>
																				))
																			}
																		</View>
																	)
																})
															}
														</View>
													)
												})
											}
											<Text style={{ fontWeight: 700, color: '#134f5c', marginTop: '30pt' }}>Virtual Trainer Feedback : </Text>
											{
												respArr.map((ps, i) => {
													const allLine = ps.split('::');
													return (
														<View key={i} style={{ marginTop: '20pt' }}>
															{
																allLine.map((al, j) => {
																	return (
																		<View key={j}>
																			{
																				al.split('***').map((item, k) => (
																					<Text key={k} style={{ marginTop: '10pt' }}>{item.split('**')[0]}<Text style={{ fontWeight: 700 }}>{item.split('**')[1]}</Text></Text>
																				))
																			}
																		</View>
																	)
																})
															}
														</View>
													)
												})
											}
										</View>
									)
								})
							}
						</View>
					</View>
				</View>
			</Page>
		</Document>
	)
}

PDFTranscript.propTypes = {
    candidateData: PropTypes.object,
}

export default PDFTranscript;
