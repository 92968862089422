import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Drawer } from '@mui/material';

import Avatar from '../common/Avatar';
import Button from '../common/Button';
import Snackbar from '../common/Snackbar';
import SupportModal from '../common/SupportModal';
import WalkthroughOverlay from '../common/WalkthroughOverlay';
import {
	getDashboardLogo,
	getUserName,
	getUserPlan,
	getSaasPlanDetails,
	getCompanyFeatures,
	getCurrentWalkthroughStep,
	getCurrentWalkthroughType
} from '../../selectors/app';
import { sendSupportMsg as sendSupportMsgRequest, updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest } from '../../actions/app';

import ContactsIcon from '../../assets/Icons/contacts.png';
import DashboardActive from '../../assets/Icons/dashboard-active.png';
import Logo from '../../assets/Logo.png';
import PreScreening from '../../assets/pre-screening.svg';
import PreScreeningActive from '../../assets/pre-screening-active.svg';
import TasksIcon from '../../assets/Icons/tasks.png';
import WidgetAlt from '../../assets/Icons/Widget_alt.png';

import "../../styles/sidebar.scss";

const Sidebar = (props) => {
	const { hidden = false, userName, dashboardLogo, openDrawer, closeDrawer, currentWalkthroughStep, currentWalkthroughType, updateCurrentWalkthroughStep } = props;

	const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

	const location = useLocation();

	const [contactModal, setContactModal] = useState();
	const [errSnackOpen, setErrSnackOpen] = useState(false);
	const [successSnackOpen, setSuccessSnackOpen] = useState(false);
	const [snackErrMsg, setSnackErrMsg] = useState('');
	const [snackSuccessMsg, setSnackSuccessMsg] = useState('');

	const handleSuccessSnackClose = () => {
		setSuccessSnackOpen(false);
	};

	const handleErrSnackClose = () => {
		setErrSnackOpen(false);
	};

	const onClickManageInvitations = () => {
		if(currentWalkthroughType === 'invite' && currentWalkthroughStep > 0) {
			updateCurrentWalkthroughStep(3);
		}
	}

	const onClickReviewedApplicants = () => {
		if(currentWalkthroughType === 'sendemails' && currentWalkthroughStep > 0) {
			updateCurrentWalkthroughStep(3);
		}
	}

	const onClickSettings = () => {
		if((currentWalkthroughType === 'whitelabelling' || currentWalkthroughType === 'videoproctoring' || currentWalkthroughType === 'automaticemails') && currentWalkthroughStep > 0) {
			updateCurrentWalkthroughStep(3);
		}
	}

	const onClickReviewApllicants = () => {
		if(currentWalkthroughType === 'evaluate' && currentWalkthroughStep > 0) {
			updateCurrentWalkthroughStep(3);
		}
	}

	const onClickTeam = () => {
		if(currentWalkthroughType === 'team' && currentWalkthroughStep > 0) {
			updateCurrentWalkthroughStep(2);
		}
	}

	return (
		<>
			{
				mediaQueryMobile.matches ? (
					<>
						<Drawer open={openDrawer} onClose={closeDrawer}>
							<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '80vw' }}>
								<div style={{ display: 'flex', alignItems: 'center', padding: '10px 16px' }}>
									<Avatar
										name={userName}
										styles={{
											text: {
												color: "#fff",
												marginRight: "1rem",
											}
										}}
									/>
									<h4>
										Welcome,
										<br /> {userName}
									</h4>
								</div>
								<hr style={{ width: '100%', border: 0, height: 0, borderTop: '1px solid rgb(0, 0, 0, 0.12)' }} />
								<div style={{ display: 'flex', flexDirection: 'column', flex: '1', paddingBottom: 0 }}>
									<div style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
										<div
											className={
												window.location.pathname === "/workflows"
													? "sidebarMenu activesidebarMenu"
													: "sidebarMenu"
											}
										>
											<Link
												to={{
													pathname: "/workflows",
													state: { sidebar: "workflows" },
												}}
												className="link"
											>
												<Button>
													<img src={DashboardActive} alt="" />
													<span>&nbsp;Recruitment Drives</span>
												</Button>
											</Link>
										</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
										<div
											className={
												location.pathname === "/prescreening/dashboard"
													? "sidebarMenu activesidebarMenu"
													: "sidebarMenu"
											}
										>
											<Link
												to={{
													pathname: "/prescreening/dashboard",
													state: { sidebar: "preScreening" }
												}}
												className="link"
											>
												<Button>
													<img src={location.pathname === "/prescreening/dashboard" ? PreScreeningActive : PreScreening} alt="" style={{ margin: "0 -2px -2px" }} />
													<span>&nbsp;Pre-screening Stage</span>
												</Button>
											</Link>
										</div>
									</div>
									<div className='dashboard-fifth-step' style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
										<div
											className={location.state.sidebar === "quickLook" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}
											style={{ display: hidden ? "none" : "" }}
										>
											<Link
												to={{
													pathname: "/workflows/" + location.state.workflowName,
													state: {
														workflowId: location.state.workflowId,
														workflowName: location.state.workflowName,
														sidebar: "quickLook"
													}
												}}
												className="link"
											>
												<Button>
													<img src={TasksIcon} alt="" />
													<span>&nbsp;Quick Overview</span>
												</Button>
											</Link>
										</div>
									</div>
									<div style={{ marginLeft: 20 }}>
										<div style={{ display: 'flex', alignItems: 'center', listStyleType: 'none' }}>
											<Link
												to={{
													pathname: "/applicants",
													state: {
														workflowId: location.state.workflowId,
														workflowName: location.state.workflowName,
														sidebar: "applicants"
													}
												}}
												className="link"
												id="applicantsFlow-selector-1"
											>
												<li
													className={location.state.sidebar === "applicants" ? "activeSidebarListItem" : "sidebarListItem"}
													style={{ color: "#555", fontSize: "0.9rem" }}
												>
													All Applicants
												</li>
											</Link>
										</div>
										<div style={{ display: 'flex', alignItems: 'center', listStyleType: 'none' }} className='dashboard-sixth-step'>
											<Link to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "finalised" } }} className="link" id="reviewedFlow-selector-1">
												<li className={location.state.sidebar === "finalised" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
													Reviewed
												</li>
											</Link>
										</div>
										<div style={{ display: 'flex', alignItems: 'center', listStyleType: 'none' }} className='dashboard-seventh-step'>
											<Link to={{ pathname: "/analytics", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "analytics" } }} className="link" id="analytics-flow-selector-4">
												<li className={location.state.sidebar === "analytics" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
													Analytics
												</li>
											</Link>
										</div>
										<div style={{ display: 'flex', alignItems: 'center', listStyleType: 'none' }} className='dashboard-eighth-step'>
											<Link
												to={{
													pathname: "/settings",
													state: {
														workflowId: location.state.workflowId,
														workflowName: location.state.workflowName,
														sidebar: "settings"
													}
												}}
												className="link"
												id="settingsFlow-selector-1"
											>
												<li
													className={location.state.sidebar === "settings" ? "activeSidebarListItem" : "sidebarListItem"}
													style={{ color: "#555", fontSize: "0.9rem" }}
												>
													Settings & Drive Details
												</li>
											</Link>
										</div>
									</div>
									<div className='dashboard-second-step' style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
										<div className={window.location.pathname === "/new-applicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
											<Link className="link" to={{ pathname: "/new-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "invitations" } }} id="manageInvitation-selector-1">
												<Button>
													<img src={DashboardActive} alt="" />
													<span>&nbsp;Manage Invitations</span>
												</Button>
											</Link>
										</div>
									</div>
									<div className='dashboard-third-step' style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
										<div className={location.state.sidebar === "reviewApplicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
											<Link className="link" to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "reviewApplicants" } }} id="reviewApplicantsFlow-selector-1">
												<Button>
													<img src={WidgetAlt} alt="" style={{ marginLeft: "-2px" }} />
													<span>&nbsp;Review Applicants</span>
												</Button>
											</Link>
										</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
										<div
											className={
												window.location.pathname === "/team"
													? "sidebarMenu activesidebarMenu"
													: "sidebarMenu"
											}
										>
											<Link
												to={{ pathname: "/team", state: { sidebar: "team" } }}
												style={{ textDecoration: "none" }}
											>
												<Button>
													<img src={ContactsIcon} alt="" />
													<span>&nbsp;Manage Team</span>
												</Button>
											</Link>
										</div>
									</div>
									<div style={{ padding: '15px 10px', borderTop: '1px solid #EBEFF2', marginTop: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 11, color: '#334D6E', fontWeight: '400', marginRight: 15 }}>All rights reserved ©</div>
										<div style={{ display: 'flex' }}>
											<img src={Logo} alt='' style={{ height: 14 }} />
										</div>
									</div>
								</div>
							</div>
						</Drawer>
					</>
				) : (
					<div style={{ width: "20vw", color: "black" }}>
						<div className="sidebar" style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
							<div className="sidebarWrapper" style={{ flex: 1, position: 'relative' }}>
								<div className="logo-div">
									<img style={{ height: "auto", width: "11vw" }} src={dashboardLogo} alt="" />
								</div>
								<div className="user-div">
									<div className="user-details">
										<h4 style={{ padding: '0 8px', fontSize: '14px' }}>Welcome,<br /> {userName}</h4>
									</div>
								</div>
								<div className="sidebarMenu">
									<Link to={{ pathname: "/workflows", state: { sidebar: "workflows" } }} className="link">
										<div className='tooltip'>
											<Button>
												<img src={DashboardActive} alt="" />&nbsp;
												<span>Recruitment Drives</span>
											</Button>
											<span className="tooltiptext">List of all drives</span>
										</div>
									</Link>
								</div>

								<div className={window.location.pathname === "/prescreening/dashboard" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
									<Link to={{ pathname: "/prescreening/dashboard", state: { sidebar: "preScreening" } }} className="link">
										<div className='tooltip'>
											<Button>
												<img src={window.location.pathname === "/prescreening/dashboard" ? PreScreeningActive : PreScreening} alt="" style={{ margin: "0 -2px -2px" }} />&nbsp;
												<span>Pre-screening Stage</span>
											</Button>
											<span className="tooltiptext" style={{ width: '10vw' }}>Add pre-screening questions</span>
										</div>
									</Link>
								</div>

								<div className={location.state.sidebar === "quickLook" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ display: hidden ? "none" : "", position: 'relative' }}>
									<Link to={{ pathname: "/workflows/" + location.state.workflowName, state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }} className="link">
										<Button className='dashboard-fifth-step'>
											<img src={TasksIcon} alt="" />&nbsp;
											<span>Quick Overview</span>
										</Button>
									</Link>
									<ul className="sidebarList" style={{ position: 'relative' }}>
										<Link to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "applicants" } }} className="link" id="applicantsFlow-selector-1">
											<li className={location.state.sidebar === "applicants" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
												<div className="tooltip">
													All Applicants
													<span className="tooltiptext" style={{ width: '8vw' }}>View all applicants</span>
												</div>
											</li>
										</Link>
										<Link to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "finalised" } }} className="link dashboard-sixth-step" id="reviewedFlow-selector-1" style={{ position: 'relative', zIndex: currentWalkthroughStep === 2 && currentWalkthroughType === 'sendemails' ? 1000 : 'auto', background: '#fff', display: 'flex' }} onClick={onClickReviewedApplicants}>
											<li className={location.state.sidebar === "finalised" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
												<div className="tooltip">
													Reviewed
													<span className="tooltiptext" style={{ width: '15vw' }}>View and send mails to reviewed candidates</span>
												</div>
											</li>
										</Link>
										<Link to={{ pathname: "/analytics", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "analytics" } }} className="link dashboard-seventh-step" id="analytics-flow-selector-4">
											<li className={location.state.sidebar === "analytics" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
												<div className="tooltip">
													Analytics
													<span className="tooltiptext" style={{ width: '12vw' }}>Graphs to help you understand drive’s performance</span>
												</div>
											</li>
										</Link>
										<Link to={{ pathname: "/settings", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "settings" } }} className="link dashboard-eighth-step" id="settingsFlow-selector-1" style={{ position: 'relative', zIndex: currentWalkthroughStep === 2 && (currentWalkthroughType === 'whitelabelling' || currentWalkthroughType === 'videoproctoring' || currentWalkthroughType === 'automaticemails') ? 1000 : 'auto', background: '#fff', display: 'flex' }} onClick={onClickSettings}>
											<li className={location.state.sidebar === "settings" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
												<div className="tooltip">
													Settings & Drive Details
													<span className="tooltiptext" style={{ width: '12vw', marginLeft: -10, left: 0 }}>Choose specific settings for the drive</span>
												</div>
											</li>
										</Link>
									</ul>
								</div>
								<div className={window.location.pathname === "/new-applicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ position: 'relative', zIndex: currentWalkthroughStep === 2 && currentWalkthroughType === 'invite' ? 1000 : 'auto', background: '#fff' }}>
									<Link className="link" to={{ pathname: "/new-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "invitations" } }} id="manageInvitation-selector-1" onClick={onClickManageInvitations}>
										<div className="tooltip">
											<Button className="dashboard-second-step">
												<img src={DashboardActive} alt="" />&nbsp;
												<span>Manage Invitations</span>
											</Button>
											<span className="tooltiptext" style={{ width: '10vw' }}>Add and invite candidates</span>
										</div>
									</Link>
								</div>
								<div className={location.state.sidebar === "reviewApplicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ position: 'relative', zIndex: currentWalkthroughStep === 2 && currentWalkthroughType === 'evaluate' ? 1000 : 'auto', background: '#fff' }}>
									<Link className="link" to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "reviewApplicants" } }} id="reviewApplicantsFlow-selector-1" style={{ display: 'flex' }} onClick={onClickReviewApllicants}>
										<div className="tooltip">
											<Button className='dashboard-third-step'>
												<img src={WidgetAlt} alt="" style={{ marginLeft: "-2px" }} />&nbsp;
												<span>Review Applicants</span>
											</Button>
											<span className="tooltiptext" style={{ width: '12vw' }}>Review applicants performance and view reports</span>
										</div>
									</Link>
								</div>
								<div className={window.location.pathname === "/team" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ position: 'relative' }} id='teamStep1'>
									<Link to={{ pathname: "/team", state: { sidebar: "team" } }} style={{ textDecoration: "none", position: 'relative', zIndex: currentWalkthroughStep === 1 && currentWalkthroughType === 'team' ? 1000 : 'auto', background: '#fff', display: 'flex' }} onClick={onClickTeam}>
										<div className="tooltip">
											<Button>
												<img src={ContactsIcon} alt="" />&nbsp;
												<span>Manage Team</span>
											</Button>
											<span className="tooltiptext" style={{ width: '15vw' }}>Add your team members to the dashboard</span>
										</div>
									</Link>
								</div>
							</div>
							<Button size="small" variant='contained' style={{ fontSize: 14, width: 'fit-content', margin: '10px auto', fontWeight: '700', borderRadius: 10 }} onClick={() => setContactModal(true)} id="supportFlow-selector-1">Need support ?</Button>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px 10px', borderTop: '1px solid #EBEFF2' }}>
								<div style={{ fontSize: 11, color: '#334D6E', fontWeight: '400', marginRight: 15 }}>All rights reserved ©</div>
								<div style={{ display: 'flex' }}>
									<img src={Logo} alt='' style={{ height: 14 }} />
								</div>
							</div>
						</div>
						<SupportModal
							open={contactModal}
							onClose={() => setContactModal(false)}
							setErrSnackOpen={setErrSnackOpen}
							setSuccessSnackOpen={setSuccessSnackOpen}
							setSnackErrMsg={setSnackErrMsg}
							setSnackSuccessMsg={setSnackSuccessMsg}
						/>
						<Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={handleSuccessSnackClose} msg={snackSuccessMsg ? snackSuccessMsg : ''} type="success" />
						<Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose} msg={snackErrMsg ? snackErrMsg : ''} type="error" />
						{
							(currentWalkthroughStep === 2 && ['invite', 'sendemails', 'whitelabelling', 'videoproctoring', 'automaticemails', 'evaluate'].includes(currentWalkthroughType)) ? (
								<WalkthroughOverlay />
							) : ''
						}
						{
							(currentWalkthroughStep === 1 && (currentWalkthroughType === 'team')) ? (
								<WalkthroughOverlay />
							) : ''
						}
					</div>
				)
			}
		</>
	);
}

Sidebar.propTypes = {
	userName: PropTypes.string,
	dashboardLogo: PropTypes.string,
	hidden: PropTypes.bool,
	openDrawer: PropTypes.func,
	closeDrawer: PropTypes.func,
	companyFeatures: PropTypes.object,
	userPlan: PropTypes.object,
	saasPlanDetails: PropTypes.object,
	currentWalkthroughStep: PropTypes.number,
	currentWalkthroughType: PropTypes.string,
	updateCurrentWalkthroughStep: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	userName: getUserName(),
	dashboardLogo: getDashboardLogo(),
	saasPlanDetails: getSaasPlanDetails(),
	companyFeatures: getCompanyFeatures(),
	userPlan: getUserPlan(),
	currentWalkthroughStep: getCurrentWalkthroughStep(),
	currentWalkthroughType: getCurrentWalkthroughType()
});

const mapDispatchToProps = (dispatch) => ({
	sendSupportMsg: (data) => dispatch(sendSupportMsgRequest(data)),
	updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
