import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './App';

if(process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: "https://bae364e7aa1a455fba306308e608e379@o1398692.ingest.sentry.io/6726098",
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}


ReactDOM.render(<App />, document.getElementById('root'));
