import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import Modal from '../common/CustomModal';
import Button from '../common/Button';
import { useLocation } from 'react-router-dom';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '60px 30px',
	borderWidth: 2,
	borderRadius: 16,
	borderColor: '#4E4E4E',
	borderStyle: 'dashed',
	backgroundColor: '#fff',
	color: '#333333',
	fontSize: 16,
	fontWeight: 600,
	marginTop: 20,
	outline: 'none',
	transition: 'border .24s ease-in-out'
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

const UploadResumeModal = (props) => {
	const { uploadResumeModal, updateCandidateResume, candidate, setUploadResumeModal, refreshRows } = props;

	const location = useLocation();

	const [uploadResumeFile, setUploadResumeFile] = useState();

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject,
		isDragAccept
	]);

	const onDrop = useCallback(acceptedFiles => {
		const dname = location.state.workflowId + '_candidate_' + new Date().getTime();
		const files = acceptedFiles.map((file) => {
			return new File([file], dname + file.name.slice(-4), { type: file.type });
		});

		setUploadResumeFile(files[0]);
	}, [])

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({ accept: 'application/pdf', maxFiles: 1, onDrop });

	const updateCandidateResumeFn = () => {
		const formData = new FormData();
		formData.append('candidateId', candidate._id);
		formData.append('pdf', uploadResumeFile);
		updateCandidateResume({ formData, history, refreshRows });
		onCloseUploadResumeModal();
	}

	const onCloseUploadResumeModal = () => {
		setUploadResumeFile();
		setUploadResumeModal(false);
	}
	
	return (
		<Modal
			open={uploadResumeModal}
			onClose={onCloseUploadResumeModal}
			center
			styles={{
				modal: {
					width: '100%',
					maxWidth: 500
				}
			}}
		>
			<p className='modal-heading-workflow'>Upload</p>
			<div {...getRootProps({ style })} style={baseStyle}>
				<input {...getInputProps()} />
				{
					isDragActive ?
						<p>Drop the resume here ...</p> :
						<p>Browse to Upload (.pdf format)</p>
				}
			</div>
			<p style={{ marginTop: 16, textAlign: 'center', fontWeight: 500, fontSize: 12, color: '#919191' }}>{uploadResumeFile ? uploadResumeFile.name : ''}</p>
			<Button
				style={{
					fontFamily: "inherit",
					borderRadius: '25px',
					padding: '5px 12px',
					display: 'block',
					margin: '20px auto 0 auto'
				}}
				size="small"
				variant="contained"
				disabled={!uploadResumeFile}
				onClick={updateCandidateResumeFn}
			>
				Ok
			</Button>
		</Modal>
	)
}

UploadResumeModal.propTypes = {
	uploadResumeModal: PropTypes.bool,
	updateCandidateResume: PropTypes.func,
	candidate: PropTypes.object,
	setUploadResumeModal: PropTypes.func,
	refreshRows: PropTypes.func
}

export default UploadResumeModal;
