import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/common/CustomModal';
import IconButton from '../../components/common/IconButton';
import { Collapse } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';

import axios from '../../utils/axios';
import BCGReport from '../BCGReport';
import EmptyTab from "../reports/EmptyTab";
import InterviewerGuide from './InterviewerGuide';
import PdfReport from '../PdfReport';
import ReportTab from '../reports/ReportTab';
import VideoCard from '../videoSubmission/VideoCard';
import { getCompanyFeatures } from '../../selectors/app';
import { redirectUnauthorisedUser } from '../../helper/helperFunction';

import downloadactiveicon from '../../assets/downloadactive.png';
import PDFTranscript from '../PdfTranscript';
import { checkCompanyFeatureAccess } from '../../utils/featureAccess';

const ExpandMore = styled((props) => {
	const { ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));
//
const AnalyseModal = (props) => {
	const { open, onClose, chat, changeStatus, doughnutRef, barChartRef, isOpen, companyFeatures, setChange, change, driveDetails, workFunctions } = props;

	const history = useHistory();
	const location = useLocation();

	const [currentReportTab, setCurrentReportTab] = useState(0);
	const [expanded, setExpanded] = useState("");
	const [videoCardData, setVideoCardData] = useState([]);
	const [freeTextData, setFreeTextData] = useState([]);
	const [gotVidResult, setGotVidResult] = useState(true);
	const [candidateChatArr, setCandidateChatArr] = useState([]);
	const [videoCardLoading, setVideoCardLoading] = useState(false);
	const mediaQueryMobile = window.matchMedia('(max-width: 768px)');

	const handleReportScroll = () => {
		if (currentReportTab != 0) return;

		const scrollDown = document.getElementById('scroll-down-animation');

		if (scrollDown) {
			scrollDown.style.display = "none";
		}
	}
	const getTimeTaken = (x, y, z = 30) => {
		let time = parseInt(((new Date(x).getTime() - new Date(y).getTime()) / 60000))
		if (time - z > 1)
			time = 30
		return time
	}
	const getQuestionText = (row, cond) => {
		let txt = ''

		if (Array.isArray(row.ques)) {
			let ele = row.ques[row.ques.length - 1].split('***::')
			txt = ele[ele.length - 1]
		}
		else if (typeof (row.ques) == 'string') {
			txt = row.ques.split('***::')[row.ques.split('***::').length - 1]
		}

		if (!cond && txt.length > 200) {
			txt = txt.substring(0, 200) + '...'
		}
		return txt.replaceAll(';;', ' ').replaceAll('::', ' ')
	}

	const handleExpandClick = index => {
		if (expanded === index) {
			setExpanded("")
		} else {
			setExpanded(index)
		}
	}

	const getCandVideo = (key) => {
		axios.post('/cand-video', { key }).then((res) => {
			window.open(res.data.signedUrl)
		}).catch((err) => {
			redirectUnauthorisedUser(err, history);
		})
	}

	useEffect(() => {
		if (!chat?.test) {
			setGotVidResult(false)
			return;
		}
		async function addVideoCardData() {
			setVideoCardLoading(true);
			let tempArr = [];
			const cids = [];
			for (let i = 0; i < chat?.test.chat?.length; i++) {
				let val = chat?.test.chat[i];
				if (val.ans && !val.ans.Workbook && !val.ans.sheetid && (val.ans.includes('.mp4') || val.ans.includes('.webm') || val.ans.includes('.mp3'))) {
					let viddata = { key: val.ans }
					if (chat?.productId && chat?.productId.customId && chat?.productId.customId.toLowerCase().includes("chatbotbcgriseai"))
						viddata['case'] = chat?.productId.customId
					let res = await axios.post('/cand-video', viddata)
					let tempData = { ques: val.ques, score: val.score, src: res.data.signedUrl, cid: val.cid, notes: val.notes };
					if (!cids.includes(val.cid)) {
						cids.push(val.cid);
						tempArr.push(tempData);
					}
				}
			}

			setVideoCardData(tempArr);
			setVideoCardLoading(false);
		}
		addVideoCardData();

		if (chat?.test?.chat?.length > 0) {
			const newChatArr = [];
			const newFreeTextChatArr = [];
			const cids = [];
			chat?.test.chat?.forEach((item) => {
				if (!cids.includes(item.cid)) {
					cids.push(item.cid);
					newChatArr.push(item);
					if (item.qtype === 'txt0') {
						newFreeTextChatArr.push(item);
					}
				}
			});
			setCandidateChatArr([...newChatArr]);
			setFreeTextData([...newFreeTextChatArr]);
		}
	}, [chat]);

	useEffect(() => {
		if (!chat?.test)
			setGotVidResult(false)
		else
			setGotVidResult(true)
	}, [videoCardData]);

	useEffect(() => {
		if (companyFeatures['reports']) {
			setCurrentReportTab(0);
		} else if (companyFeatures['videoSubmissions']) {
			setCurrentReportTab(1);
		} else if (companyFeatures['transcripts']) {
			setCurrentReportTab(2);
		} else if (companyFeatures['interviewersGuide']) {
			setCurrentReportTab(3);
		} else if (companyFeatures['freeTextSubmissions']) {
			setCurrentReportTab(4);
		}
		if (chat?.productId && chat?.productId.customId === 'Video') {
			setCurrentReportTab(1);
		}
	}, [companyFeatures]);

	const onClickApprove = (cid, chatIndex) => {
		axios.post('/save-free-text-score', { cid, testid: chat.test._id, score: freeTextData[chatIndex].score }).then((res) => {
			console.log(res);
		}).catch((err) => {
			redirectUnauthorisedUser(err, history);
		})
	}

	const onChangeFreeTextScore = (e, chatIndex) => {
		const newFreeTextChatArr = [...freeTextData];
		newFreeTextChatArr[chatIndex].score = e.target.value;
		setFreeTextData([...newFreeTextChatArr]);
	}

	return (
		<Modal open={open} onClose={onClose} center classNames={{ modal: 'customModalChats' }}>
			<div className="container-team">
				<div className="heading-team">
					<div>
						<span style={{ fontSize: mediaQueryMobile.matches ? 20 : 24, fontWeight: "600", color: "#479BD2" }}>{chat?.name}</span>
						{chat?.test ?
							<span style={{ borderLeft: "1px solid rgb(150, 150, 150)", marginLeft: "10px", paddingLeft: "10px", fontSize: "16px", fontWeight: "500" }}>
								Time taken: {getTimeTaken(chat?.test.endDate, chat?.test.createdDate)} mins
							</span>
							: ""}
					</div>
					<div className='action-btns-box'>
						<div className="tooltip">
							<IconButton>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									width={24}
									height={24}
									strokeWidth={1.5}
									stroke="currentColor"
									onClick={(e) => {
										e.stopPropagation();
										changeStatus('Shortlisted', [chat]);
									}}
									className="check-btn"
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
								</svg>
							</IconButton>
							<span className='tooltiptext'>Shortlist</span>
						</div>
						<div className="tooltip">
							<IconButton>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									width={24}
									height={24}
									strokeWidth={1.5}
									stroke="currentColor"
									onClick={(e) => {
										e.stopPropagation();
										changeStatus('Rejected', [chat]);
									}}
									className="cancel-btn"
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
								</svg>
							</IconButton>
							<span className="tooltiptext">Reject</span>
						</div>
						<div className="tooltip">
							<IconButton>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									width={24}
									height={24}
									strokeWidth={1.5}
									stroke="currentColor"
									onClick={(e) => {
										e.stopPropagation();
										changeStatus('Kept In View', [chat]);
									}}
									className="visibility-btn"
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
									<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
								</svg>
							</IconButton>
							<span className="tooltiptext">Keep in view</span>
						</div>
					</div>
				</div>
				<div>
					<div className="report-tabs-container">
						<div className="report-tabs dashboard-forth-step">
							{
								companyFeatures['reports'] ? (
									chat?.productId && chat?.productId.customId === 'Video' ? '' : (
										<div className="tooltip">
											<div
												className={currentReportTab == 0 ? 'active-report-tab' : ''}
												onClick={() => setCurrentReportTab(0)}>
												Report
											</div>
											<span className="tooltiptext">View report</span>
										</div>
									)
								) : ''
							}
							{
								companyFeatures['videoSubmissions'] ? (
									<div className="tooltip">
										<div
											className={currentReportTab == 1 ? 'active-report-tab' : ''}
											onClick={() => setCurrentReportTab(1)}>
											Video submissions
										</div>
										<span className='tooltiptext'>View and score video submissions</span>
									</div>
								) : ''
							}
							{
								companyFeatures['transcripts'] ? (
									chat?.productId && chat?.productId.customId === 'Video' ? '' : (
										<div className="tooltip">
											<div
												className={currentReportTab == 2 ? 'active-report-tab' : ''}
												onClick={() => setCurrentReportTab(2)}>
												Chats
											</div>
											<span className="tooltiptext">View transcript</span>
										</div>
									)
								) : ''
							}
							{
								companyFeatures['interviewersGuide'] ? (
									chat?.productId && chat?.productId.customId === 'Video' ? '' : (
										<div className="tooltip">
											<div
												className={currentReportTab == 3 ? 'active-report-tab' : ''}
												onClick={() => setCurrentReportTab(3)}>
												Interviewer&apos;s guide
											</div>
											<span className="tooltiptext">Literature review on assessment basics</span>
										</div>
									)
								) : ''
							}
							{
								companyFeatures['freeTextSubmissions'] ? (<div className="tooltip">
									<div
										className={currentReportTab == 4 ? 'active-report-tab' : ''}
										onClick={() => setCurrentReportTab(4)}>
										Free Text Submissions
									</div>
									<span className="tooltiptext">View and score free text submissions</span>
								</div>
								) : ''
							}
						</div>

						{
							!isOpen && companyFeatures['reports'] && gotVidResult ? (
								chat?.productId && chat?.productId.customId === 'Video' ? '' : (
									<PDFDownloadLink document={chat?.productId && chat?.productId.customId && (chat?.productId.customId.toLowerCase().includes("chatbotbcgriseai")) ? (<BCGReport videoCardData={videoCardData} doughnutRef={doughnutRef} candidateData={chat} />) : (chat?.productId && chat?.productId.customId && (chat?.productId.customId.toLowerCase().includes("sfs") || chat?.productId.customId.toLowerCase().includes("smm")) ? <PDFTranscript candidateData={chat} /> : <PdfReport doughnutRef={doughnutRef} barChartRef={barChartRef} candidateData={chat} reportWorkFunctions={workFunctions} detailedReport={checkCompanyFeatureAccess(companyFeatures, 'detailedReport')} />)} fileName={chat?.name ? `${chat?.name.split(' ').join('_')}_${location.state.workflowName ? location.state.workflowName.split(' ').join('_') : driveDetails.name.split(' ').join('_')}.pdf` : 'report.pdf'}>
										{({ loading }) =>
											loading ? (
												<div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
													<img src={downloadactiveicon} alt="" style={{ width: "24px", height: "24px" }} />
													<p>Download</p>
												</div>
											) : (
												<div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} id='reviewedFlow-selector-16'>
													<img src={downloadactiveicon} alt="" style={{ width: "24px", height: "24px" }} />
													<p>Download</p>
												</div>
											)
										}
									</PDFDownloadLink>
								)
							) : ''
						}
					</div>
				</div>
				<div className="scrollblue" onScroll={handleReportScroll}>
					{
						currentReportTab == 2 ?
							candidateChatArr.length > 0 ? candidateChatArr.map((row, index) => {
								return <div key={index} className="row-team">
									<div style={{ display: "flex", marginBottom: "15px" }}>
										<p style={{ flex: "1", fontWeight: "600" }}>
											Q.{index + 1} &nbsp;
											{getQuestionText(row, index === expanded)}
										</p>
										<div style={{ width: "50px" }}>
											<ExpandMore
												expand={index === expanded}
												onClick={() => { handleExpandClick(index) }}
												aria-expanded={index === expanded}
												aria-label="expand"
											>
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={24} height={24}>
													<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
												</svg>
											</ExpandMore>
										</div>
									</div>
									<Collapse in={index === expanded} timeout="auto">
										{(typeof row.ans === 'string' || row.ans instanceof String) ? <div style={{ color: "rgb(110,110,110)" }}>
											<b>Answer- </b> {(row.ans.includes('.mp4') || row.ans.includes('.webm') || row.ans.includes('.mp3')) ? <a rel='noreferrer' style={{ color: 'inherit' }} href="javascript:void(0)" target="_blank" onClick={(e) => { e.preventDefault(); getCandVideo(row.ans) }}>Click Here</a> : (row.ans.toLowerCase().includes('excel submitted') && row.detailed_score[0].sheetid ? (
												<a rel='noreferrer' style={{ color: 'inherit' }} href={`https://docs.google.com/spreadsheets/d/${row.detailed_score[0].sheetid}/edit`} target="_blank">Click Here</a>
											) : row.ans.replaceAll('::', ". "))}
										</div> : null}
									</Collapse>
								</div>
							})
								: <EmptyTab text={"Couldn't find chats for this candidate"} />
							: ""
					}
					{
						currentReportTab == 1 ?
							chat?.test?.chat?.length > 0 ?
								(<div>
									{
										videoCardLoading ? (
											<div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="videobox-loader" /></div>
										) : (
											<div className="videobox-wrapper" style={{ justifyContent: (videoCardData.length > 0) ? 'normal' : 'center' }}>
												{videoCardData.length == 0 ? 'No Video' : videoCardData.map((val) => <VideoCard key={val.cid} ques={val.ques} src={val.src} score={val.score} cid={val.cid} notes={val.notes} candidateData={chat} setChange={setChange} change={change} />)}
											</div>
										)
									}
								</div>)
								: <EmptyTab text={"Couldn't find video submission for this candidate"} />
							: ""
					}
					{
						currentReportTab == 0 ?
							chat?.test?.chat?.length > 0 ?
								chat?.productId && chat?.productId.customId === 'Video' ? '' : (
									<ReportTab candidateData={chat} setCurrentReportTab={setCurrentReportTab} workFunctions={workFunctions} />
								)
								: <EmptyTab text={"Couldn't find report for this candidate"} />
							: ""
					}
					{
						currentReportTab == 3 ? <InterviewerGuide productId={chat?.productId} reportWorkFunctions={workFunctions} /> : ""
					}
					{
						currentReportTab == 4 ?
							freeTextData.length > 0 ? freeTextData.map((row, index) => {
								return <div key={index} className="row-team">
									<div style={{ display: "flex", marginBottom: "15px" }}>
										<p style={{ flex: "1", fontWeight: "600" }}>
											Q.{index + 1} &nbsp;
											{getQuestionText(row, index === expanded)}
										</p>
										<div style={{ width: "50px" }}>
											<ExpandMore
												expand={index === expanded}
												onClick={() => { handleExpandClick(index) }}
												aria-expanded={index === expanded}
												aria-label="expand"
											>
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={24} height={24}>
													<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
												</svg>
											</ExpandMore>
										</div>
									</div>
									<Collapse in={index === expanded} timeout="auto">
										{(typeof row.ans === 'string' || row.ans instanceof String) ?
											<div style={{ color: "rgb(110,110,110)" }}>
												<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
													<div style={{ width: '80%' }}><b>Answer- </b> {row.ans.includes('.mp4') || row.ans.includes('.webm') ? <a rel='noreferrer' style={{ color: 'inherit' }} href="javascript:void(0)" target="_blank" onClick={(e) => { e.preventDefault(); getCandVideo(row.ans) }}>Click Here</a> : row.ans.replaceAll('::', ". ")}</div>
													<div style={{ width: '20%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: 20 }}>
														<input
															type="text"
															style={{
																height: 40,
																backgroundColor: '#e6e6e6',
																width: 80,
																borderRadius: 80,
																fontWeight: '700',
																paddingVertical: 5,
																paddingHorizontal: 10,
																marginBottom: 17,
																color: '#000',
																textAlign: 'center',
																fontSize: 14,
																outline: 'none',
																border: 'none'
															}}
															value={row.score ? row.score : 0}
															onChange={(e) => onChangeFreeTextScore(e, index)}
														/>
														<button
															style={{
																textAlign: 'center',
																padding: 10,
																backgroundColor: '#03C04A',
																color: '#fff',
																borderRadius: 80,
																overflow: 'hidden',
																width: 80,
																outline: 'none',
																border: 'none'
															}}
															onClick={() => onClickApprove(row.cid, index)}
														>
															Approve
														</button>
													</div>
												</div>
											</div> : null}
									</Collapse>
								</div>
							})
								: <EmptyTab text={"Couldn't find free text submissions for this candidate"} />
							: ""
					}
				</div>
			</div >
		</Modal >
	)
}

AnalyseModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	chat: PropTypes.object,
	changeStatus: PropTypes.func,
	doughnutRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	barChartRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	isOpen: PropTypes.bool,
	companyFeatures: PropTypes.object,
	change: PropTypes.number,
	setChange: PropTypes.func,
	driveDetails: PropTypes.object,
	workFunctions: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
	companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseModal);
