import {
	ADD_NOTIFICATION,
	ADD_USER,
	SET_WORKFLOW_FUNNEL_DATA,
	GET_CANDIDATES_BY_TYPE,
	EDIT_CANDIDATE_NAME,
	RESET_SUCCESS_MSG,
	RESET_RERENDER,
	DELETE_CANDIDATE,
	EDIT_DRIVE_NAME,
	USER_SIGN_IN,
	AUTH_ERROR_MSG,
	SET_REPORT_MODEL,
	USER_LOGOUT,
	FETCH_NOTIFICATIONS,
	UPDATE_NOTIFICATION,
	VERIFY_USER,
	SEND_FORGOT_PASSWORD_LINK,
	SET_PASSWORD,
	AUTH_SUCCESS_MSG,
	SET_USER_SETTINGS,
	GET_DRIVE_DETAILS,
	ADD_COMPANY,
	ADD_BOTS,
	DELETE_BOTS,
	GET_SUPERUSER,
	ADD_SUPERUSER,
	DELETE_SUPERUSER,
	GET_FEEDBACK,
	FETCH_MY_DETAILS,
	UPDATE_MY_DETAILS,
	CHANGE_MY_PASSWORD,
	ADD_PRE_SCREENING_BOT,
	DELETE_PRE_SCREENING_BOT,
	FETCH_PRE_SCREENING_BOTS,
	VERIFY_LINK_TOKEN,
	FETCH_SAAS_PLAN_LIST,
	FETCH_SAAS_PLAN_DETAILS,
	UPDATE_SAAS_PLAN_DETAILS,
	EDIT_PRE_SCREENING_BOT,
	UPDATE_ASSESSMENT_BOT_LOGO,
	UPDATE_DASHBOARD_LOGO,
	SET_DASHBOARD_LOGO,
	CREATE_WORKFLOW_JOB_ROLE,
	SET_JOB_ROLE_DETAILS,
	SEND_PRE_SCREENING_TEST,
	FETCH_TRIAL_USERS_LIST,
	SEND_UPDATE_ALL,
	UPDATE_COMPANY_FEATURES,
	FETCH_COMPANY_DETAILS,
	UPDATE_COMPANY_DETAILS,
	FETCH_BOTS,
	FETCH_COMPANY_BOTS,
	UPDATE_BOT_DETAILS,
	UPDATE_COMPANY_GUIDELINES,
	FETCH_COMPANIES,
	SEND_SUPPORT_MSG,
	SET_COMPANY_NAME,
	UPDATE_PRESCREENING_DETAILS,
	FETCH_SUPPORT_MSGS,
	SET_CURRENT_WALKTHROUGH_TYPE,
	SET_CURRENT_WALKTHROUGH_STEP,
	SET_WALKTHROUGH_FLOWS,
	FETCH_WORKFLOWS,
	FETCH_CHAT_EVENTS,
	SET_WALKTHROUGH_WELCOME,
	VERIFY_CODE,
	CHANGE_USER_PASSWORD,
	RESTART_CANDIDATE_TEST,
	FETCH_SKILL_CODES,
	UPDATE_SKILL_CODE,
	ADD_SKILL_CODE,
	DELETE_SKILL_CODE,
	FETCH_COMPANY_USERS,
	UPDATE_COMPANY_USER,
	DELETE_COMPANY_USER,
	DEACTIVATE_COMPANY,
	ACTIVATE_COMPANY,
	UPDATE_WORKFLOW_ADMIN,
	UPDATE_CANDIDATE_RESUME,
	UPDATE_VIDEO_ANSWER
} from "../constants/app";

export const editCandidateName = (data) => ({
	type: EDIT_CANDIDATE_NAME,
	data
});

export const editDriveName = (data) => ({
	type: EDIT_DRIVE_NAME,
	data
});

export const deleteCandidate = (data) => ({
	type: DELETE_CANDIDATE,
	data
});

export const resetSuccessMsg = () => ({
	type: RESET_SUCCESS_MSG
});

export const resetRerender = () => ({
	type: RESET_RERENDER
});

export const fetchBots = (data) => ({
	type: FETCH_BOTS,
	data
});

export const fetchCompanyBots = (data) => ({
	type: FETCH_COMPANY_BOTS,
	data
});

export const addUser = (data) => ({
	type: ADD_USER,
	data
});

export const addNotification = (data) => ({
	type: ADD_NOTIFICATION,
	data
});

export const setWorkFlowFunnelData = (data) => ({
	type: SET_WORKFLOW_FUNNEL_DATA,
	data
});

export const setReportModel = (data) => ({
	type: SET_REPORT_MODEL,
	data
});

export const getCandidatesByType = (data) => ({
	type: GET_CANDIDATES_BY_TYPE,
	data
});

export const userSignIn = (data) => ({
	type: USER_SIGN_IN,
	data
});


export const setAuthErrorMsg = (data) => ({
	type: AUTH_ERROR_MSG,
	data
});

export const setAuthSuccessMsg = (data) => ({
	type: AUTH_SUCCESS_MSG,
	data
});

export const logoutUser = (data) => ({
	type: USER_LOGOUT,
	data
});

export const fetchNotifications = (data) => ({
	type: FETCH_NOTIFICATIONS,
	data
});

export const updateNotification = (data) => ({
	type: UPDATE_NOTIFICATION,
	data
});

export const verifyUser = (data) => ({
	type: VERIFY_USER,
	data
});

export const sendForgotPasswordLink = (data) => ({
	type: SEND_FORGOT_PASSWORD_LINK,
	data
});

export const verificationCode = (data) => ({
	type: VERIFY_CODE,
	data
});

export const setUserPassword = (data) => ({
	type: SET_PASSWORD,
	data
});

export const updateUserSettings = (data) => ({
	type: SET_USER_SETTINGS,
	data
});

export const fetchDriveDetails = (data) => ({
	type: GET_DRIVE_DETAILS,
	data
});

export const fetchCompanies = () => ({
	type: FETCH_COMPANIES
});

export const addCompany = (data) => ({
	type: ADD_COMPANY,
	data
});

export const deactivateCompany = (data) => ({
	type: DEACTIVATE_COMPANY,
	data
});

export const activateCompany = (data) => ({
	type: ACTIVATE_COMPANY,
	data
});

export const addBots = (data) => ({
	type: ADD_BOTS,
	data
});

export const deleteBots = (data) => ({
	type: DELETE_BOTS,
	data
});

export const getSuperUser = () => ({
	type: GET_SUPERUSER
});

export const addSuperUser = (data) => ({
	type: ADD_SUPERUSER,
	data
});

export const deleteSuperUser = (data) => ({
	type: DELETE_SUPERUSER,
	data
});

export const getFeedback = (data) => ({
	type: GET_FEEDBACK,
	data
});

export const fetchMyDetails = (data) => ({
	type: FETCH_MY_DETAILS,
	data
});

export const updateMyDetails = (data) => ({
	type: UPDATE_MY_DETAILS,
	data
});

export const changeMyPassword = (data) => ({
	type: CHANGE_MY_PASSWORD,
	data
});

export const addPreScreeningBot = (data) => ({
	type: ADD_PRE_SCREENING_BOT,
	data
});

export const editPreScreeningBot = (data) => ({
	type: EDIT_PRE_SCREENING_BOT,
	data
});

export const deletePreScreeningBot = (data) => ({
	type: DELETE_PRE_SCREENING_BOT,
	data
});

export const fetchPreScreeningBots = (data) => ({
	type: FETCH_PRE_SCREENING_BOTS,
	data
});

export const verifyLinkToken = (data) => ({
	type: VERIFY_LINK_TOKEN,
	data
});

export const fetchSaasPlanList = () => ({
	type: FETCH_SAAS_PLAN_LIST
});

export const fetchSaasPlanDetails = (data) => ({
	type: FETCH_SAAS_PLAN_DETAILS,
	data
});

export const updateSaasPlanDetails = (data) => ({
	type: UPDATE_SAAS_PLAN_DETAILS,
	data
});

export const updateAssessmentBotLogo = (data) => ({
	type: UPDATE_ASSESSMENT_BOT_LOGO,
	data
});

export const updateDashboardBotLogo = (data) => ({
	type: UPDATE_DASHBOARD_LOGO,
	data
});

export const setDashboardLogo = (data) => ({
	type: SET_DASHBOARD_LOGO,
	data
});

export const createWorkflowJobRole = (data) => ({
	type: CREATE_WORKFLOW_JOB_ROLE,
	data
});

export const updateJobRoleDetails = (data) => ({
	type: SET_JOB_ROLE_DETAILS,
	data
});

export const sendPreScreeningTest = (data) => ({
	type: SEND_PRE_SCREENING_TEST,
	data
});

export const fetchTrialUsersList = (data) => ({
	type: FETCH_TRIAL_USERS_LIST,
	data
});

export const sendUpdateAll = (data) => ({
	type: SEND_UPDATE_ALL,
	data
});

export const updateCompanyFeatures = (data) => ({
	type: UPDATE_COMPANY_FEATURES,
	data
});

export const fetchCompanyDetails = (data) => ({
	type: FETCH_COMPANY_DETAILS,
	data
});

export const updateCompanyDetails = (data) => ({
	type: UPDATE_COMPANY_DETAILS,
	data
});

export const updateBotDetails = (data) => ({
	type: UPDATE_BOT_DETAILS,
	data
});

export const updateCompanyGuidelines = (data) => ({
	type: UPDATE_COMPANY_GUIDELINES,
	data
});

export const sendSupportMsg = (data) => ({
	type: SEND_SUPPORT_MSG,
	data
});

export const setCompanyName = (data) => ({
	type: SET_COMPANY_NAME,
	data
});

export const updatePreScreeningDetails = (data) => ({
	type: UPDATE_PRESCREENING_DETAILS,
	data
});

export const updateCurrentWalkthroughType = (data) => ({
	type: SET_CURRENT_WALKTHROUGH_TYPE,
	data
});

export const updateCurrentWalkthroughStep = (data) => ({
	type: SET_CURRENT_WALKTHROUGH_STEP,
	data
});

export const fetchSupportMsgs = (data) => ({
	type: FETCH_SUPPORT_MSGS,
	data
});

export const updateWalkthroughFlows = (data) => ({
	type: SET_WALKTHROUGH_FLOWS,
	data
});

export const fetchWorkflows = (data) => ({
	type: FETCH_WORKFLOWS,
	data
});

export const fetchChatEvents = (data) => ({
	type: FETCH_CHAT_EVENTS,
	data
});

export const updateWalkthroughWelcome = (data) => ({
	type: SET_WALKTHROUGH_WELCOME,
	data
});

export const changeUserPassword = (data) => ({
	type: CHANGE_USER_PASSWORD,
	data
});

export const restartCandidateTest = (data) => ({
	type: RESTART_CANDIDATE_TEST,
	data
});

export const fetchSkillCodes = (data) => ({
	type: FETCH_SKILL_CODES,
	data
});

export const updateSkillCode = (data) => ({
	type: UPDATE_SKILL_CODE,
	data
});

export const addSkillCode = (data) => ({
	type: ADD_SKILL_CODE,
	data
});

export const deleteSkillCode = (data) => ({
	type: DELETE_SKILL_CODE,
	data
});

export const fetchCompanyUsers = (data) => ({
	type: FETCH_COMPANY_USERS,
	data
});

export const updateCompanyUser = (data) => ({
	type: UPDATE_COMPANY_USER,
	data
});

export const deleteCompanyUser = (data) => ({
	type: DELETE_COMPANY_USER,
	data
});

export const updateWorkflowAdmin = (data) => ({
	type: UPDATE_WORKFLOW_ADMIN,
	data
});

export const updateCandidateResume = (data) => ({
	type: UPDATE_CANDIDATE_RESUME,
	data
});

export const updateVideoAnswer = (data) => ({
	type: UPDATE_VIDEO_ANSWER,
	data
})
