import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { Bar, Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSVLink } from "react-csv";
// import { DataGrid } from "@mui/x-data-grid";
import { Drawer } from "@mui/material";
import { Link, useLocation, useHistory } from "react-router-dom";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTour } from '@reactour/tour';

import AccountMenu from "../components/AccountMenu";
import AnalyseModal from "../components/common/AnalyseModal";
import Avatar from "../components/common/Avatar";
import axios from "../utils/axios";
import BottomBar from "../components/common/BottomBar";
import Button from '../components/common/Button';
import DataTable from "../components/common/DataTable";
import Header from "../components/header/header";
import IconButton from "../components/common/IconButton";
import Modal from '../components/common/CustomModal';
import NotificationBell from "../components/notifications/NotificationBell";
import PdfTranscript from "../components/PdfTranscript";
import ProctoringModal from "../components/common/ProctoringModal";
import Snackbar from "../components/common/Snackbar";
import WalkthroughOverlay from "../components/common/WalkthroughOverlay";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";
import { barChartData, doughnutOptions, reportWorkFunctions } from "../constants/reports";
import { doughnutData, getCandidateStatus } from "../utils/reports";
import { getCandidatesList, getRerender, getIsReportModelOpen, getUserRole, getApplicantsLoading, getCurrentWalkthroughStep, getCurrentWalkthroughType, getDriveDetails, getSkillCodeList } from '../selectors/app';
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	editCandidateName as editCandidateNameRequest,
	resetSuccessMsg as resetSuccessMsgRequest,
	resetRerender as resetRerenderRequest,
	deleteCandidate as deleteCandidateRequest,
	setReportModel as setReportModelRequest,
	updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
	updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest,
	updateWalkthroughFlows as updateWalkthroughFlowsRequest,
	fetchDriveDetails as fetchDriveDetailsRequest,
	fetchSkillCodes as fetchSkillCodesRequest,
} from '../actions/app';
import { redirectUnauthorisedUser } from "../helper/helperFunction";
import { useWindowDimensions } from "../utils/windowUtils";

import Cancel from '../assets/verification-error.png';
import CandidateCVIcon from '../assets/candidate-cv.png';
import Edit from '../assets/Icons/Edit.png';
import emptyRowImg from '../assets/emptyrow2.png';
import Subtract from '../assets/Icons/Subtract.png';
import TickBlueIcon from '../assets/right-blue-icon.svg';
import Trash from '../assets/Icons/Trash.png';

import "../styles/reviewApplicants.scss";
// import PdfReport from "../components/PdfReport";

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const ReviewApplicants = (props) => {
	const {
		candidates,
		getCandidatesByType,
		rerender,
		editCandidateName,
		resetRerender,
		deleteCandidate,
		isReportModelOpen,
		setReportModel,
		notification,
		userRole,
		applicantsLoading,
		currentWalkthroughStep,
		currentWalkthroughType,
		updateCurrentWalkthroughStep,
		updateCurrentWalkthroughType,
		updateWalkthroughFlows,
		fetchDriveDetails,
		driveDetails,
		fetchSkillCodes,
		skillCodeList,
	} = props;

	const settings = JSON.parse(localStorage.getItem('settings'))

	let history = useHistory();
	let location = useLocation();
	const { isOpen } = useTour();
	const windowDimensions = useWindowDimensions();

	const [deleteModal, setDeleteModal] = useState(false);
	const [chat, setChat] = useState({});
	const [change, setChange] = useState(0);
	const [data, setData] = useState([]);
	const [rows, setRows] = useState(data);
	const [CSVData, setCSVData] = useState([]);
	// const [searchText, setSearchText] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	// const [pageSize, setPageSize] = useState(15);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [totalScore, setTotalScore] = useState(0);
	const [barData, setBarData] = useState({ ...barChartData });
	const [transcriptPdfLoaded, setTranscriptPdfLoaded] = useState(true);
	const [bottomDrawer, setBottomDrawer] = useState(false);
	const [proctoringModal, setProctoringModal] = useState(false);
	const [proctoringData, setProctoringData] = useState({})
	const mediaQueryMobile = window.matchMedia('(max-width: 768px)');
	const [checkboxOptions, setCheckboxOptions] = useState({
		shortlist: false,
		reject: false,
		keptInView: false,
	});
	const [nextWalkthroughStep, setNextWalkthroughStep] = useState(4);
	const [filterScoreKeysArr, setFilterScoreKeysArr] = useState([]);
	const [filterScoreKey, setFilterScoreKey] = useState([]);
	const [workFunctions, setWorkFunctions] = useState({});

	const editNameRef = useRef(null);
	const doughnutRef = useRef();
	const barChartRef = useRef();
	const transcriptDownloadRef = useRef();

	const onOpenDeleteModal = () => {
		if (mediaQueryMobile.matches) {
			if (selectedRow.length > 0) setDeleteModal(true);
			else {
				setSnackErr('Please select atleast one candidate');
				handleSnackClick();
			}
		}
		else {
			if (selectionModel.length == 0) {
				setSnackErr('Please select atleast one candidate');
				handleSnackClick();
			} else setDeleteModal(true);
		}
	};

	const onCloseDeleteModal = () => setDeleteModal(false);

	const onOpenModal = (e, params) => {
		if(params.row.productId.customId === 'Video') {
			history.push({
				pathname: `/video-applicant/${params.row._id}`,
				state: {
					sidebar: location.state.sidebar,
					workflowId: location.state.workflowId,
					workflowName: location.state.workflowName
				}
			});
		}
		else {
			e.stopPropagation();
			if (mediaQueryMobile.matches) {
				if (selectedRow.length === 0) {
					setSnackErr('Please select a candidate');
					handleSnackClick();
				} else if (selectedRow.length > 1) {
					setSnackErr('Please select only one candidate');
					handleSnackClick();
				} else {
					filterBarData(params);
				}
			} else {
				filterBarData(params);
			}
		}
	}

	const filterBarData = (params) => {
		setChat({ ...params.row });
		if (params.row.productId && params.row.productId.customId === 'Video') {
			setTotalScore(0);
		} else {
			setTotalScore(parseInt((params.row.test.result.total || 0) * 100));
		}

		const newBarData = { ...barData };

		newBarData.labels = [];
		newBarData.datasets[0].data = [];
		newBarData.datasets[1].data = [];

		if (params.row.test.chat && params.row.test.chat.length > 0 && Object.prototype.hasOwnProperty.call(params.row.test.chat[0], 'idealqtime')) {
			const newChatArr = [];
			const cids = [];
			params.row.test.chat.forEach((item) => {
				if (!cids.includes(item.cid)) {
					cids.push(item.cid);
					newChatArr.push(item);
				}
			});

			newChatArr.forEach((item, index) => {
				newBarData.labels.push('Q' + (1 + index));
				newBarData.datasets[0].data.push(item.idealqtime);
				newBarData.datasets[1].data.push(item.userqtime);
			});
		}

		setBarData({ ...newBarData });
	}

	useEffect(() => {
		if (chat.productId) {
			setReportModel(true);
		}
	}, [barData]);

	const onCloseModal = () => setReportModel(false);

	useEffect(() => {
		if (rows.length == 0) return;

		let temp = [];

		rows.map((val) => {
			var score = 0.0;
			var index = 0;

			try {
				if (Object.prototype.hasOwnProperty.call(val.test.result || {}, 'total'))
					score = val.test.result['total']
				else {
					for (let keys in val.test.result) {
						score += val.test.result[keys]
						index += 1
					}
					if (index > 0) score = score / index;
				}
				if (score > 0) score *= 100;
			}
			catch (err) {
				console.log(err);
			}

			let x = { name: val.name, email: val.email, id: val.id, status: getCandidateStatus(val.status), score: score }
			temp.push(x);
		});

		setCSVData(temp);
	}, [rows]);

	useEffect(() => {
		const reqData = {
			status: "review",
			id: location.state.workflowId
		};

		getCandidatesByType({ reqData, history });
	}, [change]);

	const nameEdit = function (row) {
		editCandidateName({ row, newName: editNameRef.current.value, history });
	};

	const handleDelete = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		})
		deleteCandidate({ id: IDs, history });
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	useEffect(() => {
		if (!location.state.workflowName) {
			fetchDriveDetails({ workflowId: location.state.workflowId, history });
		}
		fetchSkillCodes();
	}, []);

	useEffect(() => {
		const wfKeyArr = [];
		const newRwf = {};
		Object.keys(reportWorkFunctions).forEach((rwf) => {
			wfKeyArr.push(rwf);
			newRwf[rwf] = { ...reportWorkFunctions[rwf] };
		});
		Object.keys(skillCodeList).forEach((scl) => {
			if (!wfKeyArr.includes(scl)) {
				newRwf[scl] = { ...skillCodeList[scl] };
			}
		});
		setWorkFunctions({ ...newRwf });
	}, [skillCodeList]);

	useEffect(() => {
		if (!candidates) return;

		candidates.forEach((candidate) => {
			if (candidate.test && candidate.test.result && Object.prototype.hasOwnProperty.call(candidate.test.result, 'total')) {
				setFilterScoreKeysArr([...Object.keys(candidate.test.result)]);
				candidate.score = candidate.test.result.total;
			} else {
				if (candidate.test && candidate.test.chat && candidate.test.chat.length > 0) {
					let total = 0;
					candidate.test.chat.forEach((chat) => {
						total = total + chat.score;
					});
					const finalTotal = total / 10;
					candidate.score = finalTotal;
					candidate.test['result'] = {
						total: finalTotal
					}
				} else {
					candidate.score = 0;
					candidate['test'] = {
						result: {
							total: 0
						}
					}
				}
			}
			candidate.id = parseInt(candidate.id);
		});
		setData([...candidates]);
		setRows([...candidates]);
	}, [candidates]);

	const pdfurl = process.env.REACT_APP_BASE_SERVER_URL_FULL + '/pdfreport'//`https://test.aspiringconsultants.com/api/pdfreport`//`http://localhost:5000/api/pdfreport`

	const pdfDownload = (candidate) => {
		console.log(candidate)
		if (candidate.productId.customId && (candidate.productId.customId.toLowerCase().includes('bcgriseconsulting') || candidate.productId.customId.toLowerCase().includes('bcgriseyoga') || userRole.includes('Super'))) {
			setChat(candidate);
			setTranscriptPdfLoaded(true);
		} else {
			fetch(pdfurl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					candidate: candidate,
				}),
			}).then(async res => {
				if (res.status === 200) {
					const blob = await res.blob();
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = `${candidate.name}_${location.state.workflowName ? location.state.workflowName : driveDetails.name}.pdf`;
					link.click();
				}
			})
		}
	}

	const requestSearch = (searchValue) => {
		// setSearchText(searchValue);

		if (searchValue == '') {
			setRows(data);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = data.filter((row) => {
				// return Object.keys(row).some((field) => {
				// 	return searchRegex.test(row[field].toString());
				// });
				if (searchRegex.test(row.name) || searchRegex.test(row.email)) {
					return true;
				}
			});

			setRows(filteredRows);
		}
	};

	const changeStatus = (status, rowSelected) => {
		if (rowSelected.length == 0) {
			handleSnackClick();
			setSnackErr('Please select atleast one candidate')
			return;
		}

		axios.post('/statusupdatebyid', { candidates: rowSelected, status: status })
			.then(() => {
				setChange(change + 1)
				setSuccessMsg("Status updated successfully");
				handleGreenSnackClick();
				setSelectionModel([]);

				if (isReportModelOpen) onCloseModal();
			})
			.catch(err => {
				redirectUnauthorisedUser(err, history);

				setSnackErr("Could not update status at the moment");
				handleSnackClick();
			})
	}
	useEffect(() => {
		if (!transcriptPdfLoaded) {
			const timer = setInterval(function () {
				if (transcriptDownloadRef.current) {
					clearInterval(timer);
					transcriptDownloadRef.current.parentNode.click();
				}
			}, 200);
		}
	}, [transcriptPdfLoaded]);

	const formatScore = (row) => {
		let score = 0.0;
		// if (row.test && row.test.result && Object.prototype.hasOwnProperty.call(row.test.result, 'total'))
		// 	score = row.test.result.total
		// else {
		// 	if (typeof row.productId === 'string' && row.productId.toLowerCase().includes('sale')) {
		// 		if (row.test.result.dab)
		// 			score += 0.2 * row.test.result.dab
		// 		if (row.test.result.lpg)
		// 			score += 0.2 * row.test.result.lpg
		// 		if (row.test.result.stk)
		// 			score += 0.1 * row.test.result.stk
		// 		if (row.test.result.ema)
		// 			score += 0.5 * row.test.result.ema
		// 	} else {
		// 		if (row.test && row.test.result) {
		// 			for (let keys in row.test.result) {
		// 				score += row.test.result[keys]
		// 				index += 1
		// 			}
		// 			if (index > 0) score = score / index
		// 		}
		// 	}
		// }
		if (filterScoreKey.length > 0) {
			filterScoreKey.forEach((nfsr) => {
				if (row.test && row.test.result && Object.prototype.hasOwnProperty.call(row.test.result, nfsr)) {
					score = score + row.test.result[nfsr];
				}
			});
		} else {
			if (row.test && row.test.result && Object.prototype.hasOwnProperty.call(row.test.result, 'total')) {
				score = row.test.result.total;
			}
		}
		if (score > 0) score *= 100
		return score.toFixed();
	}

	useEffect(() => {
		if (!transcriptPdfLoaded) {
			const timer = setInterval(function () {
				if (transcriptDownloadRef.current) {
					clearInterval(timer);
					transcriptDownloadRef.current.parentNode.click();
				}
			}, 200);
		}
	}, [transcriptPdfLoaded]);

	const checkProctoring = (params) => {
		let proctoring = false;
		if (params.test && params.test.proctoringEvents && params.test.proctoringEvents.length > 0) {
			let count = 0;
			params.test.proctoringEvents.forEach((pr) => {
				if (pr.eventType === 'tabChange' || pr.eventType === 'copypaste' || pr.eventType === 'repeatedTest' || pr.eventType === 'ipChange') {
					if (!pr.resolved) {
						count++;
					}
				} else if (pr.eventType === 'proctoring' && pr.eventData && pr.eventData.Summary && pr.eventData.Summary.RedFlag && pr.eventData.Summary.RedFlag.Mobile_time) {
					if (!pr.mpResolved && pr.eventData.Summary.RedFlag.Mobile_time.length > 0) {
						count++;
					}
				} else if (pr.eventType === 'proctoring' && pr.eventData && pr.eventData.Summary && pr.eventData.Summary.RedFlag && pr.eventData.Summary.RedFlag.Multi_Face) {
					if (!pr.mfResolved && pr.eventData.Summary.RedFlag.Multi_Face.length > 0) {
						count++;
					}
				}
			});
			if(count > 0) {
				proctoring = true;
			}
		}
		return proctoring;
	}

	const onOpenProctoringModal = (e, params) => {
		e.stopPropagation();
		setProctoringModal(true);
		let tabChangeCount = 0;
		let copyPaste = 0;
		let phoneDetected = 0;
		let faceDetected = 0;
		let ipChange = 0;
		let repeatedTest = 0;
		params.test.proctoringEvents.forEach((pr) => {
			if (pr.eventType === 'tabChange') {
				if(!pr.resolved) {
					tabChangeCount++;
				}
			}
			if (pr.eventType === 'copypaste') {
				if(!pr.resolved) {
					copyPaste++;
				}
			}
			if (pr.eventType === 'repeatedTest') {
				if(!pr.resolved) {
					repeatedTest++;
				}
			}
			if (pr.eventType === 'ipChange') {
				if(!pr.resolved) {
					ipChange++;
				}
			}
			if (pr.eventType === 'proctoring' && pr.eventData && pr.eventData.Summary && pr.eventData.Summary.RedFlag && pr.eventData.Summary.RedFlag.Mobile_time) {
				if (!pr.mpResolved) {
					phoneDetected = pr.eventData.Summary.RedFlag.Mobile_time.length;
				}
			}
			if (pr.eventType === 'proctoring' && pr.eventData && pr.eventData.Summary && pr.eventData.Summary.RedFlag && pr.eventData.Summary.RedFlag.Multi_Face) {
				if (!pr.mfResolved) {
					faceDetected = pr.eventData.Summary.RedFlag.Multi_Face.length;
				}
			}
		});

		const procotoringConcerns = [];
		if(tabChangeCount > 0) {
			procotoringConcerns.push('tabchange');
		}
		if(copyPaste > 0) {
			procotoringConcerns.push('copypaste');
		}
		if(phoneDetected > 0) {
			procotoringConcerns.push('phonedetection');
		}
		if(faceDetected > 0) {
			procotoringConcerns.push('facedetection');
		}
		if(ipChange > 0) {
			procotoringConcerns.push('ipChange');
		}
		if(repeatedTest > 0) {
			procotoringConcerns.push('repeatedTest');
		}
		let proctoringConcern = 'Low';
		if(procotoringConcerns.length >= 3) {
			proctoringConcern = 'High';
		} else if(procotoringConcerns.length >= 2) {
			proctoringConcern = 'Medium';
		}
		setProctoringData({ tabChangeCount, copyPaste, proctoringEvents: params.test.proctoringEvents, userId: params.test.userId, candidateData: params, phoneDetected, proctoringConcern, faceDetected, ipChange, repeatedTest });
		handleSelectApplicant(params);
	}

	const onCloseProctoringModal = () => {
		setProctoringData({});
		setProctoringModal(false);
	}

	const onClickScoreFilter = (e, key, callback) => {
		e.stopPropagation();
		const newFilterScoreKey = [...filterScoreKey];
		if (newFilterScoreKey.includes(key)) {
			const fIndex = newFilterScoreKey.indexOf(key);
			newFilterScoreKey.splice(fIndex, 1);
		} else {
			newFilterScoreKey.push(key);
		}
		if (newFilterScoreKey.length > 0) {
			candidates.forEach((candidate) => {
				candidate.score = 0;
				candidate.id = parseInt(candidate.id);
				newFilterScoreKey.forEach((nfsr) => {
					if (candidate.test && candidate.test.result && Object.prototype.hasOwnProperty.call(candidate.test.result, nfsr)) {
						candidate.score = candidate.score + candidate.test.result[nfsr];
					}
				});
			});
		} else {
			candidates.forEach((candidate) => {
				if (candidate.test && candidate.test.result && Object.prototype.hasOwnProperty.call(candidate.test.result, 'total')) {
					candidate.score = candidate.test.result.total;
				} else {
					candidate.score = 0;
				}
				candidate.id = parseInt(candidate.id);
			});
		}
		setRows([...candidates]);
		setFilterScoreKey([...newFilterScoreKey]);
		callback()
	}

	const navigateApplicant = (id) => {
		history.push({
			pathname: `/applicant/${id}`,
			state: {
				sidebar: "reviewApplicants",
				workflowId: location.state.workflowId,
				workflowName: location.state.workflowName
			}
		})
	}

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: windowDimensions.width / 14,
			headerAlign: 'left',
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} className="userListUser" style={{ cursor: 'pointer' }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: "name",
			headerName: "Name",
			width: windowDimensions.width / 6,
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} className="userListUser" style={{ fontSize: 16, fontWeight: "400", display: 'flex', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.name}</div>
							<span className='tooltiptext datagrid'>{params.row.name}</span>
						</div>
						{
							params.row.resume ? (
								<div style={{ marginLeft: 15, display: 'flex' }}><a href={`https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/${params.row.resume}`} download><img src={CandidateCVIcon} style={{ height: 20 }} alt='' /></a></div>
							) : ''
						}
						{
							checkProctoring(params.row) ? (
								<div className="tooltip" style={{ width: '100%' }}>
									<div style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, background: '#E92525', marginLeft: 15, cursor: 'pointer', flexShrink: 0 }} onClick={(e) => onOpenProctoringModal(e, params.row)}>
										<div style={{ width: 15, height: 2, background: '#fff' }}></div>
									</div>
									<span className='tooltiptext datagrid' style={{ top: '110%' }}>Red flag prediction for proctoring</span>
								</div>
							) : ''
						}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			width: windowDimensions.width / 6,
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} className="userListUser" style={{ fontSize: 16, fontWeight: "400", width: '100%', cursor: 'pointer' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.email}</div>
							<span className='tooltiptext datagrid'>{params.row.email}</span>
						</div>
					</div>
				);
			},
		},
		{
			field: "score",
			headerName: "Score",
			width: windowDimensions.width / 12,
			headerAlign: "left",
			highlightHeader: currentWalkthroughStep === 3 && currentWalkthroughType === 'evaluate',
			filterable: true,
			hideColumn: (candidates.length > 0 && (candidates[0].productId.customId === 'Video' || candidates[0].productId.customId === 'BPAD1'  || candidates[0].productId.customId === 'BPAD2')),
			renderFilter: (callback) => {
				return (
					<div className="row-column-filter">
						{
							filterScoreKeysArr.map((rtr) => {
								return rtr !== 'total' ? (
									<div key={rtr} onClick={(e) => onClickScoreFilter(e, rtr, callback)} style={{ display: 'flex', alignItems: 'center' }}>
										<div className="filter-checkbox">
											<label>
												<input type="checkbox" checked={filterScoreKey.includes(rtr)} onChange={() => { }} />
												<span className="checkmark"></span>
											</label>
										</div>
										<div style={{ whiteSpace: 'break-spaces' }}>{rtr !== 'total' ? (workFunctions[rtr.toLowerCase()] ? workFunctions[rtr.toLowerCase()].label : rtr) : 'total'}</div>
									</div>
								) : ''
							})
						}
					</div>
				)
			},
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} className="userListUser" style={{ width: "100%", textAlign: "center", cursor: 'pointer' }}>
						{formatScore(params.row)}%
					</div>
				);
			},
		},
		{
			field: "report",
			headerName: "Report",
			headerAlign: 'center',
			highlightColumn: currentWalkthroughStep === 4 && currentWalkthroughType === 'evaluate',
			width: windowDimensions.width / 12,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: 15, display: 'flex', alignItems: 'center' }}>
						{

							(params.row.test && params.row.test.result && Object.prototype.hasOwnProperty.call(params.row.test.result, 'total') && !(params.row.productId.customId && (params.row.productId.customId.toLowerCase().includes('bcgriseconsulting') || params.row.productId.customId.toLowerCase().includes('bcgriseyoga')))) || (params.row.productId.customId === 'Video') ? (
								<div className="tooltip">
									<Button
										className="productView"
										style={{
											background: '#479BD2',
											fontWeight: 'bold',
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: '5px 12px',
											margin: 0
										}}
										size="small"
										variant="contained"
										onClick={(e) => onOpenModal(e, params)}
									>
										<span style={{ fontSize: 14, color: '#fff' }}>Analyse</span>
									</Button>
									<span className="tooltiptext datagrid" style={{ width: 250, lineHeight: '1.5' }}>View reports, video answers, chat transcripts etc.</span>
								</div>
							) : (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<svg
										width={24}
										height={24}
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										onClick={(e) => {
											e.stopPropagation();
											pdfDownload(params.row);
										}}
										style={{ cursor: "pointer" }}
									>
										<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
									</svg>
								</div>
							)
						}
					</div>
				);
			}
		}
	]

	// if (userRole.includes('Super')) {
	// 	columns.push({
	// 		field: "report",
	// 		headerName: "Report",
	// 		headerAlign: 'center',
	// 		highlightColumn: currentWalkthroughStep === 4 && currentWalkthroughType === 'evaluate',
	// 		width: windowDimensions.width / 12,
	// 		renderCell: (params) => {
	// 			return (
	// 				<div style={{ marginRight: 15, display: 'flex', alignItems: 'center' }}>
	// 					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
	// 						<svg
	// 							width={24}
	// 							height={24}
	// 							xmlns="http://www.w3.org/2000/svg"
	// 							viewBox="0 0 24 24"
	// 							fill="currentColor"
	// 							onClick={(e) => {
	// 								e.stopPropagation();
	// 								pdfDownload(params.row);
	// 							}}
	// 							style={{ cursor: "pointer" }}
	// 						>
	// 							<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
	// 						</svg>
	// 					</div>
	// 				</div>
	// 			);
	// 		}
	// 	});
	// }

	if (settings) {
		if (settings.vidanswer)
			columns.push(
				{
					field: "video",
					headerName: "Video",
					width: 150,
					// sortable: false,
					// filterable: false,
					headerAlign: 'center',
					// align: 'center',
					// flex: 0.3,
					renderCell: (params) => {
						return (
							<>
								<Button
									className="productView"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
										textTransform: 'none'
									}}
									size="small"
									variant="contained"
								>
									<Link
										to={{
											pathname: "/video-submission",
											state: {
												workflowId: location.state.workflowId,
												workflowName: location.state.workflowName ? location.state.workflowName : driveDetails.name,
												sidebar: "reviewApplicants",
												candidateData: params.row
											}
										}}
										style={{
											fontSize: '14px',
											color: 'inherit',
											textDecoration: 'none'
										}}
									>
										Analyse
									</Link>
								</Button>
							</>
						);
					}
				}
			)
	}

	if (userRole.includes('Admin')) {
		columns.push(
			{
				headerName: "Action",
				headerAlign: 'center',
				highlightColumn: currentWalkthroughStep === 5 && currentWalkthroughType === 'evaluate',
				renderCell: (params) => {
					return (
						<div>
							<div className="tooltip">
								<IconButton>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										strokeWidth={1.5}
										stroke="currentColor"
										onClick={(e) => {
											e.stopPropagation();
											changeStatus('Shortlisted', [params.row]);
										}}
										className="check-btn"
										id="reviewApplicantsFlow-selector-7"
									>
										<path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</IconButton>
								<span className="tooltiptext" style={{ top: '90%', lineHeight: 'normal', marginLeft: '-35px' }}>Shortlist</span>
							</div>
							<div className="tooltip">
								<IconButton>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										strokeWidth={1.5}
										stroke="currentColor"
										onClick={(e) => {
											e.stopPropagation();
											changeStatus('Rejected', [params.row]);
										}}
										className="cancel-btn"
										id="reviewApplicantsFlow-selector-8"
									>
										<path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</IconButton>
								<span className="tooltiptext" style={{ top: '90%', lineHeight: 'normal', marginLeft: '-35px' }}>Reject</span>
							</div>
							<div className="tooltip">
								<IconButton>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										strokeWidth={1.5}
										stroke="currentColor"
										onClick={(e) => {
											e.stopPropagation();
											changeStatus('Kept In View', [params.row]);
										}}
										className="visibility-btn"
										id="reviewApplicantsFlow-selector-9"
									>
										<path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
										<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
									</svg>
								</IconButton>
								<span className="tooltiptext" style={{ top: '90%', lineHeight: 'normal', marginLeft: '-35px' }}>Keep in view</span>
							</div>
						</div>
					);
				}
			});
	}

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);
		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
		else {
			let temp = [];

			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...rows.filter((row) => selectionModel[i] == row.id))
			}

			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (mediaQueryMobile.matches) {
			if (selectedRow.length > 1 || selectedRow.length === 0) {
				setSnackErr('Please select a candidate');
				handleSnackClick();
			}
			else {
				setEditModal(true);
			}
		}
		else {
			if (selectionModel.length == 1) setEditModal(true);
			else {
				setSnackErr('Please select atleast one candidate');
				handleSnackClick();
			}
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setGreenSnackOpen(false);
		setSuccessMsg(null);
	};

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}

	const MenuItems = [
		{
			name: 'Edit',
			action: () => {
				onOpenEditModal();
			}
		},
		{
			name: 'Delete',
			action: () => {
				onOpenDeleteModal();
			}
		},
		{
			name: 'Mark as',
			action: () => {
				setBottomDrawer(true);
			}
		},
		{
			name: 'Analyse',
			action: () => onOpenModal(null, { row: selectedRow[0] })
		},
		{
			name: 'Export',
			CSVData: CSVData,
		}
	]

	const handleSelectApplicant = (row) => {
		if (selectedRow.length !== 0 && selectedRow.some(el => el._id === row._id)) {
			setSelectedRow(selectedRow.filter(el => el._id !== row._id));
		}
		else {
			setSelectedRow([...selectedRow, row]);
		}
	}

	const resolveProctoringStatus = (eventType, candidateRow) => {
		if (candidateRow.length == 0) {
			handleSnackClick();
			setSnackErr('Please select atleast one candidate')
			return;
		}

		axios.post('/test/resolveproctoring', { eventType, candidate: candidateRow[0] })
			.then(() => {
				setChange(change + 1)
				setSuccessMsg("Proctoring resolved updated successfully");
				handleGreenSnackClick();
				setSelectionModel([]);
			})
			.catch(err => {
				redirectUnauthorisedUser(err, history);

				setSnackErr("Could not update status at the moment");
				handleSnackClick();
			})
	}

	return (
		<div className="productList">
			{mediaQueryMobile.matches && <Header MenuItems={MenuItems} />}
			<div className="inner-div" style={{ marginBottom: '20px' }}>
				<div className='top-icon-box'>
					<WalkthroughWelcome />
					{
						userRole.includes('Admin') ? (
							<div className="tooltip">
								<IconButton aria-label="edit" onClick={onOpenEditModal} id="reviewApplicantsFlow-selector-3">
									<img src={Edit} alt="" width={30} height={30} />
								</IconButton>
								<span className="tooltiptext">Edit</span>
							</div>
						) : ''
					}
					{
						userRole.includes('Admin') ? (
							<div className="tooltip">
								<IconButton onClick={() => onOpenDeleteModal()} aria-label="delete"><img src={Trash} alt="" width={30} height={30} /></IconButton>
								<span className="tooltiptext">Delete</span>
							</div>
						) : ''
					}
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div >
			</div >
			{
				chat.test && chat.test.result && Object.prototype.hasOwnProperty.call(chat.test.result, 'total') && !(chat.productId && chat.productId.customId && (chat.productId.customId.toLowerCase().includes('bcgriseconsulting') || chat.productId.customId.toLowerCase().includes('bcgriseyoga'))) ? (
					<>
						{
							chat.productId && chat.productId.customId === 'Video' ? '' : (
								<Doughnut ref={doughnutRef} data={doughnutData(totalScore)} options={doughnutOptions} style={{ width: 100, height: 100, display: 'none' }} />
							)
						}
						{
							barData.labels.length > 0 ? (
								<Bar ref={barChartRef} data={barData} options={barData.options} style={{ display: 'none' }} />
							) : ''
						}
					</>
				) : ''
			}
			{
				chat.productId && chat.productId.customId && (chat.productId.customId.toLowerCase().includes('bcgriseconsulting') || chat.productId.customId.toLowerCase().includes('bcgriseyoga') || userRole.includes('Super')) ? (
					<div style={{ display: 'none' }}>
						{/* <PDFDownloadLink document={userRole.includes('Super') ? <PdfReport doughnutRef={doughnutRef} barChartRef={barChartRef} candidateData={chat} /> : <PdfTranscript candidateData={chat} />} fileName={chat.name ? `${chat.name.split(' ').join('_')}_${location.state.workflowName ? location.state.workflowName.split(' ').join('_') : driveDetails.name.split(' ').join('_')}.pdf` : 'report.pdf'}>
							{({ loading }) => {
								if (loading) {
									return (
										<div>Loading...</div>
									)
								} else {
									setTranscriptPdfLoaded(false);
									return (
										<div className="tooltip">
											<div ref={transcriptDownloadRef}>Download</div>
											<span className="tooltiptext">Download candidate report</span>
										</div>
									)
								}
							}
							}
						</PDFDownloadLink> */}
						<PDFDownloadLink document={<PdfTranscript candidateData={chat} />} fileName={chat.name ? `${chat.name.split(' ').join('_')}_${location.state.workflowName ? location.state.workflowName.split(' ').join('_') : driveDetails.name.split(' ').join('_')}.pdf` : 'report.pdf'}>
							{({ loading }) => {
								if (loading) {
									return (
										<div>Loading...</div>
									)
								} else {
									setTranscriptPdfLoaded(false);
									return (
										<div className="tooltip">
											<div ref={transcriptDownloadRef}>Download</div>
											<span className="tooltiptext">Download candidate report</span>
										</div>
									)
								}
							}
							}
						</PDFDownloadLink>
					</div>
				) : ''
			}
			<div className="userListTitleContainer" style={{ padding: "0 20px" }}>
				<div className="inner-div-details">
					<h2>Review Applicants</h2>
					<p style={{ wordBreak: 'break-all' }}>{location.state.workflowName ? location.state.workflowName : driveDetails.name}</p>
				</div>
				<div className="search-box review-applicants">
					<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
				</div>
				{
					userRole.includes('Admin') ? (
						<div className="notification-btns-box review-applicants">
							<div className="tooltip">
								<IconButton>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										strokeWidth={1.5}
										stroke="currentColor"
										onClick={() => changeStatus('Shortlisted', selectedRow)}
										className="check-btn"
										id="reviewApplicantsFlow-selector-10"
									>
										<path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</IconButton>
								<span className="tooltiptext">Shortlist</span>
							</div>
							<div className="tooltip">
								<IconButton>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										strokeWidth={1.5}
										stroke="currentColor"
										onClick={() => changeStatus('Rejected', selectedRow)}
										className="cancel-btn"
										id="reviewApplicantsFlow-selector-11"
									>
										<path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</IconButton>
								<span className="tooltiptext">Reject</span>
							</div>
							<div className="tooltip">
								<IconButton>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										strokeWidth={1.5}
										stroke="currentColor"
										onClick={() => changeStatus('Kept In View', selectedRow)}
										className="visibility-btn"
										id="reviewApplicantsFlow-selector-12"
									>
										<path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
										<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
									</svg>
								</IconButton>
								<span className="tooltiptext">Keep in view</span>
							</div>
						</div>
					) : ''
				}
				<div className="export-btn review-applicants">
					<div className="tooltip">
						<Button
							disabled={!CSVData.length}
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: "8px 20px",
							}}
							size="small"
							variant="contained"
						>
							<CSVLink filename="Data.csv" data={CSVData} target="_blank" style={{ color: "white", textDecoration: "none", fontSize: '16px', whiteSpace: 'nowrap' }} id="reviewApplicantsFlow-selector-2">
								Export
								<img src={Subtract} alt="" style={{ height: "18px", width: "18px", transform: `translate(3px, 4px)` }} />
							</CSVLink>
						</Button>
						<span className="tooltiptext">Export report</span>
					</div>
				</div>
			</div >
			{
				selectionModel.length < 0 ?
					<div style={{ background: "lightgrey", minHeight: "6vh", padding: "1vh 20px 1vh", display: "flex", justifyContent: "space-between", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
						<div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
							<Button onClick={() => { changeStatus('Withdraw', selectedRow) }} style={{ color: "#fff", background: "#0C95BA ", fontSize: "12px", height: "40px", borderRadius: "25px", padding: "5px 20px", marginRight: "10px" }}>Withdraw</Button>
						</div>
					</div> : ""
			}
			{mediaQueryMobile.matches &&
				<div className='review-applicant-box__content'>
					{rows.length === 0 ?
						<>
							<img
								src={emptyRowImg}
								alt="no rows"
								style={{
									height: "auto",
									width: "100%"
								}}
							/>
							<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E", textAlign: "center" }}>No data to show</p>
						</> : null}
					{rows.map((row, index) => (
						<div key={index} className='applicant-box__row' onClick={() => handleSelectApplicant(row)}>
							<div className='applicant-box__row-left'>
								<div className="applicant-box__row-left-image-box">
									<Avatar
										name={parseInt(row.id)}
										styles={{
											text: {
												backgroundColor: "#EBEBEB",
												color: "#404040",
											}
										}}
									/>
									{selectedRow.length !== 0 && selectedRow.some(el => el._id === row._id) && <img src={TickBlueIcon} alt="right-blue-icon" />}
								</div>
								<div className="applicant-box-row-left-text-box">
									<p className='applicant-box__row-left-text-name'>{row.name}</p>
									<p className='applicant-box__row-left-text-role'>{row.email}</p>
								</div>
							</div>
							<div className='applicant-box__row-right'>
								<p className='applicant-box__row-right-score'>{formatScore(row)}%</p>
								<p className="applicant-box__row-left-text-role">Score</p>
							</div>
						</div>
					))}
				</div>
			}
			{!mediaQueryMobile.matches &&
				<div style={{ minHeight: 500, height: 'max-content', padding: 10, background: '#fff', borderRadius: 25, overflow: 'hidden', display: 'flex', flex: 1 }}>
					<DataTable
						loading={applicantsLoading}
						noRowsComponent={<NoRowsOverlay />}
						rows={
							isOpen || (currentWalkthroughStep > 0 && currentWalkthroughType === 'evaluate' && rows.length === 0) ?
								[{
									"_id": "61876d63673eccda481f16ef",
									"name": "Nancy Brown",
									"email": [
										"nancy534@gmail.com"
									],
									"id": "30",
									"code": "2a10RHuOLbRUdj9qZsEStxo4Xe3Tbem2GnbbGUTwrHkGy3FKJu3KbJ5G",
									"status": "Shortlisted",
									"lastActive": "2021-11-07T06:11:30.544Z",
									"createdDate": "2021-11-07T06:08:35.734Z",
									"company": "61730080f94921c9089ea99f",
									"workflow": "6178704387c9e1c238ae4f58",
									"__v": 0,
									"problem": "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
									"productId": "ChatId4",
									"title": "HR Evaluative",
									test: {
										result: {
											total: 0
										}
									} 
								}] : rows
						}
						columns={columns}
						checkboxSelection={userRole.includes('Admin')}
						onSelectionModelChange={(newSelectionModel) => {
							setSelectionModel(newSelectionModel);
						}}
						selectionModel={selectionModel}
						pageSize={15}
					/>
				</div>
			}
			{mediaQueryMobile.matches &&
				<Drawer
					anchor="bottom"
					open={bottomDrawer}
					onClose={() => {
						setBottomDrawer(false);
						setCheckboxOptions({
							shortlist: false,
							reject: false,
							keptInView: false,
						});
					}}
					PaperProps={{
						style: {
							padding: '1rem 20px',
							borderTopLeftRadius: '20px',
							borderTopRightRadius: '20px',
						}
					}}
				>
					<div className="bottomDrawer">
						<div className="bottomDrawer__img-box">
							<img src={Cancel} alt="cancel-icon" width={20} height={20} onClick={() => setBottomDrawer(false)} />
						</div>
						<div className="bottomDrawer__checkbox-grp">
							<input type="checkbox" id="shortlisted" checked={checkboxOptions.shortlist} onChange={() => {
								setCheckboxOptions({ shortlist: !checkboxOptions.shortlist, reject: false, interview: false })
							}} />
							<label htmlFor="shortlisted">Shortlist</label>
						</div>
						<div className="bottomDrawer__checkbox-grp">
							<input type="checkbox" id="rejected" checked={checkboxOptions.reject} onChange={() => {
								setCheckboxOptions({ reject: !checkboxOptions.reject, shortlist: false, interview: false })
							}} />
							<label htmlFor="rejected">Reject</label>
						</div>
						<div className="bottomDrawer__checkbox-grp">
							<input type="checkbox" id="keptinview" checked={checkboxOptions.keptInView} onChange={() => {
								setCheckboxOptions({ keptInView: !checkboxOptions.keptInView, shortlist: false, reject: false })
							}} />
							<label htmlFor="keepinview">Keep in view</label>
						</div>

						<Button
							className="btn-primary"
							onClick={() => {
								if (checkboxOptions.shortlist) {
									changeStatus('Shortlisted', selectedRow)
								} else if (checkboxOptions.reject) {
									changeStatus('Rejected', selectedRow)
								}
								else {
									changeStatus('Kept in view', selectedRow)
								}
								setBottomDrawer(false);
							}}
							disabled={!checkboxOptions.shortlist && !checkboxOptions.reject && !checkboxOptions.keptInView}
							id="quickViewFlow-selector-8"
						>
							Mark
						</Button>
					</div>
				</Drawer>
			}
			<AnalyseModal
				open={isReportModelOpen}
				onClose={onCloseModal}
				chat={chat}
				changeStatus={changeStatus}
				doughnutRef={doughnutRef}
				barChartRef={barChartRef}
				isOpen={isOpen}
				change={change}
				setChange={setChange}
				driveDetails={driveDetails}
				workFunctions={workFunctions}
			/>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to delete this candidate?
				</p>
				<div className="button-div">
					<Button
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
							handleDelete(selectedRow)
						}}>
						Yes
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
						}}>
						No
					</Button>
				</div>
			</Modal>
			<Modal open={proctoringModal} onClose={onCloseProctoringModal} center>
				<ProctoringModal
					proctoringData={proctoringData}
					onCloseProctoringModal={onCloseProctoringModal}
					selectedRow={selectedRow}
					resolveProctoringStatus={resolveProctoringStatus}
					changeStatus={changeStatus}
				/>
			</Modal>
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit candidate&apos;s name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="reviewApplicantsFlow-selector-4"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="reviewApplicantsFlow-selector-5"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						<span style={{ fontSize: '16px' }}>Ok</span>
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						<span style={{ fontSize: '16px' }}>Cancel</span>
					</Button>
				</div>
			</Modal>
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose} msg={snackErr ? snackErr : ''} type="error" />
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose} msg={successMsg ? successMsg : ''} type="success" />
			{mediaQueryMobile.matches && <BottomBar />}
			{
				currentWalkthroughStep > 0 && currentWalkthroughType === 'evaluate' ? (
					<WalkthroughOverlay
						showWalkthroughNextBtn={true}
						onClickDone={() => {
							if (nextWalkthroughStep <= 5) {
								updateCurrentWalkthroughStep(nextWalkthroughStep);
								setNextWalkthroughStep(nextWalkthroughStep + 1);
							} else {
								updateCurrentWalkthroughStep(0);
								updateCurrentWalkthroughType('');
								let wArr = [];
								if (localStorage.getItem('walkthroughFlows')) {
									wArr = JSON.parse(localStorage.getItem('walkthroughFlows'));

								}
								wArr.push('evaluate');
								localStorage.setItem('walkthroughFlows', JSON.stringify(wArr));
								updateWalkthroughFlows(wArr);
							}
						}}
					/>
				) : ''
			}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList(),
	rerender: getRerender(),
	isReportModelOpen: getIsReportModelOpen(),
	userRole: getUserRole(),
	applicantsLoading: getApplicantsLoading(),
	currentWalkthroughStep: getCurrentWalkthroughStep(),
	currentWalkthroughType: getCurrentWalkthroughType(),
	driveDetails: getDriveDetails(),
	skillCodeList: getSkillCodeList()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
	editCandidateName: (data) => dispatch(editCandidateNameRequest(data)),
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	resetRerender: () => dispatch(resetRerenderRequest()),
	deleteCandidate: (data) => dispatch(deleteCandidateRequest(data)),
	setReportModel: (data) => dispatch(setReportModelRequest(data)),
	updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
	updateCurrentWalkthroughType: (data) => dispatch(updateCurrentWalkthroughTypeRequest(data)),
	updateWalkthroughFlows: (data) => dispatch(updateWalkthroughFlowsRequest(data)),
	fetchDriveDetails: (data) => dispatch(fetchDriveDetailsRequest(data)),
	fetchSkillCodes: (data) => dispatch(fetchSkillCodesRequest(data)),
});

ReviewApplicants.propTypes = {
	candidates: PropTypes.array,
	getCandidatesByType: PropTypes.func,
	rerender: PropTypes.number,
	editCandidateName: PropTypes.func,
	resetRerender: PropTypes.func,
	deleteCandidate: PropTypes.func,
	isReportModelOpen: PropTypes.bool,
	setReportModel: PropTypes.func,
	notification: PropTypes.object,
	userRole: PropTypes.string,
	applicantsLoading: PropTypes.bool,
	currentWalkthroughStep: PropTypes.number,
	currentWalkthroughType: PropTypes.string,
	updateCurrentWalkthroughStep: PropTypes.func,
	updateCurrentWalkthroughType: PropTypes.func,
	updateWalkthroughFlows: PropTypes.func,
	fetchDriveDetails: PropTypes.func,
	driveDetails: PropTypes.object,
	fetchSkillCodes: PropTypes.func,
	skillCodeList: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewApplicants);
