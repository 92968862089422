import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from "@material-ui/core";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from "react-router-dom";

import Avatar from '../common/Avatar';
import Button from '../common/Button';
import Snackbar from '../common/Snackbar';
import SupportModal from '../common/SupportModal';
// import { checkCompanyFeatureAccess, checkUserPlanAccess } from "../../utils/featureAccess";
import { getDashboardLogo, getUserName, getUserPlan, getSaasPlanDetails, getCompanyFeatures, getCurrentWalkthroughStep, getCurrentWalkthroughType } from '../../selectors/app';
import { sendSupportMsg as sendSupportMsgRequest, updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest } from '../../actions/app';

import ContactsIcon from '../../assets/Icons/contacts.png';
import DashboardActive from '../../assets/Icons/dashboard-active.png';
import Logo from '../../assets/Logo.png';
import PreScreening from '../../assets/pre-screening.svg';
import PreScreeningActive from '../../assets/pre-screening-active.svg';
import TasksIcon from '../../assets/Icons/tasks.png';
import WidgetAlt from '../../assets/Icons/Widget_alt.png';

import "../../styles/sidebar.scss";
import WalkthroughOverlay from '../common/WalkthroughOverlay';

const Sidebar1 = (props) => {
	const { userName, dashboardLogo, openDrawer, closeDrawer, currentWalkthroughStep, currentWalkthroughType, updateCurrentWalkthroughStep } = props;
	// const [allowPreScreening] = useState(checkCompanyFeatureAccess(companyFeatures, 'preScreening', null) && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'preScreening', null));
	const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

	const options = [
		{
			icon: TasksIcon,
			name: "Quick Overview",
		},
		{
			icon: DashboardActive,
			name: "Manage Invitations",
		},
		{
			icon: WidgetAlt,
			name: "Review Applicants",
		},
	];

	const [contactModal, setContactModal] = useState();
	const [errSnackOpen, setErrSnackOpen] = useState(false);
	const [successSnackOpen, setSuccessSnackOpen] = useState(false);
	const [snackErrMsg, setSnackErrMsg] = useState('');
	const [snackSuccessMsg, setSnackSuccessMsg] = useState('');

	const handleSuccessSnackClose = () => {
		setSuccessSnackOpen(false);
	};

	const handleErrSnackClose = () => {
		setErrSnackOpen(false);
	};

	const onClickTeam = () => {
		if(currentWalkthroughType === 'team' && currentWalkthroughStep > 0) {
			updateCurrentWalkthroughStep(2);
		}
	}

	return (
		<>
			{mediaQueryMobile.matches ? (
				<>
					<Drawer open={openDrawer} onClose={closeDrawer}>
						<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '80vw' }}>
							<div style={{ display: 'flex', alignItems: 'center', padding: '10px 16px' }}>
								<Avatar
									name={userName}
									styles={{
										text: {
											color: "#fff",
											marginRight: "1rem",
										}
									}}
								/>
								<h4>
									Welcome,
									<br /> {userName}
								</h4>
							</div>
							<hr style={{ width: '100%', border: 0, height: 0, borderTop: '1px solid rgb(0, 0, 0, 0.12)' }} />
							<div style={{ display: 'flex', flexDirection: 'column', flex: '1', paddingBottom: 0 }}>
								<div style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
									<div
										className={
											window.location.pathname === "/workflows"
												? "sidebarMenu activesidebarMenu"
												: "sidebarMenu"
										}
									>
										<Link
											to={{
												pathname: "/workflows",
												state: { sidebar: "workflows" },
											}}
											className="link"
										>
											<Button>
												<img src={DashboardActive} alt="" />
												<span>&nbsp;Recruitment Drives</span>
											</Button>
										</Link>
									</div>
								</div>
								<div style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
									<div className={window.location.pathname === "/prescreening/dashboard" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
										<Link to={{ pathname: "/prescreening/dashboard", state: { sidebar: "preScreening" } }} className="link">
											<Button>
												<img src={window.location.pathname === "/prescreening/dashboard" ? PreScreeningActive : PreScreening} alt="" style={{ margin: "0 -2px -2px" }} />
												<span>&nbsp;Pre-screening Stage</span>
											</Button>
										</Link>
									</div>
								</div>
								{options.map((option, idx) => (
									<div key={idx} style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
										<div className='sidebarMenu'>
											<Button disabled>
												<img src={option.icon} alt="" />
												<span>&nbsp;{option.name}</span>
											</Button>
										</div>
									</div>
								))}
								<div className='dashboard-ninth-step' style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
									<div
										className={
											window.location.pathname === "/team"
												? "sidebarMenu activesidebarMenu"
												: "sidebarMenu"
										}
									>
										<Link
											to={{ pathname: "/team", state: { sidebar: "team" } }}
											style={{ textDecoration: "none" }}
										>
											<Button>
												<img src={ContactsIcon} alt="" />
												<span>&nbsp;Manage Team</span>
											</Button>
										</Link>
									</div>
								</div>
								<div style={{ padding: '15px 10px', borderTop: '1px solid #EBEFF2', marginTop: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
									<div style={{ fontSize: 11, color: '#334D6E', fontWeight: '400', marginRight: 15 }}>All rights reserved ©</div>
									<div style={{ display: 'flex' }}>
										<img src={Logo} alt='' style={{ height: 14 }} />
									</div>
								</div>
							</div>
						</div>
					</Drawer>
				</>
			) : (
				<div style={{ width: "20vw", position: 'relative' }}>
					<div className="sidebar" style={{ display: 'flex', flexDirection: 'column' }}>
						<div className="sidebarWrapper" style={{ flex: 1, position: 'relative' }} >
							<div className="logo-div">
								<img style={{ height: "auto", width: "11vw" }} src={dashboardLogo} alt="logo" />
							</div>
							<div style={{ position: 'relative' }}>
								<div className="user-div">
									<div className="user-details">
										<h4 style={{ left: "0", padding: '0 8px', fontSize: '14px' }}>Welcome,<br /> {userName}</h4>
									</div>
								</div>
								<div className={window.location.pathname === "/workflows" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
									<Link to={{ pathname: "/workflows", state: { sidebar: "workflows" } }} className="link">
										<div className="tooltip">
											<Button>
												<img src={DashboardActive} alt="" />&nbsp;
												<span>Recruitment Drives</span>
											</Button>
											<span className="tooltiptext">List of all drives</span>
										</div>
									</Link>
								</div>
								{/* {allowPreScreening ? ( */}
								<div className={window.location.pathname === "/prescreening/dashboard" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
									<Link to={{ pathname: "/prescreening/dashboard", state: { sidebar: "preScreening" } }} className="link">
										<div className="tooltip">
											<Button>
												<img src={window.location.pathname === "/prescreening/dashboard" ? PreScreeningActive : PreScreening} alt="" style={{ margin: "0 -2px -2px" }} id="prescreening-selector-28" />&nbsp;
												<span>Pre-screening Stage</span>
											</Button>
											<span className="tooltiptext" style={{ width: '10vw' }}>Add pre-screening questions</span>
										</div>
									</Link>
								</div>
								{/* ) : "" */}
								{/* } */}
								<div className="sidebarMenu">
									<div
										style={{ textDecoration: "none" }}>
										<Button disabled>
											<img src={TasksIcon} alt="" />&nbsp;
											<span>Quick Overview</span>
										</Button>
									</div>
								</div>
								<div className="sidebarMenu">
									<div
										style={{ textDecoration: "none" }}>
										<Button disabled>
											<img src={DashboardActive} alt="" />&nbsp;
											<span>Manage Invitations</span>
										</Button>
									</div>
								</div>
								<div className="sidebarMenu">
									<div
										style={{ textDecoration: "none" }}>
										<Button disabled>
											<img src={WidgetAlt} alt="" style={{ marginLeft: "-2px" }} />&nbsp;
											<span>Review Applicants</span>
										</Button>
									</div>
								</div>
								<div className={window.location.pathname === "/team" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ position: 'relative' }} id='teamStep1'>
									<Link to={{ pathname: "/team", state: { sidebar: "team" } }} style={{ textDecoration: "none", position: 'relative', zIndex: currentWalkthroughStep === 1 && currentWalkthroughType === 'team' ? 1000 : 'auto', background: '#fff', display: 'flex' }} onClick={onClickTeam}>
										<div className="tooltip">
											<Button className='dashboard-ninth-step'>
												<img src={ContactsIcon} alt="" />&nbsp;
												<span>Manage Team</span>
											</Button>
											<span className="tooltiptext" style={{ width: '15vw' }}>Add your team members to the dashboard</span>
										</div>
									</Link>
								</div>
							</div>
						</div>
						<Button size="small" variant='contained' style={{ fontSize: 14, width: 'fit-content', margin: '10px auto', fontWeight: '700', borderRadius: 10 }} onClick={() => setContactModal(true)} id="supportFlow-selector-1">Need support ?</Button>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px 10px', borderTop: '1px solid #EBEFF2' }}>
							<div style={{ fontSize: 11, color: '#334D6E', fontWeight: '400', marginRight: 15 }}>All rights reserved ©</div>
							<div style={{ display: 'flex' }}>
								<img src={Logo} alt='' style={{ height: 14 }} />
							</div>
						</div>
					</div>
					<SupportModal
						open={contactModal}
						onClose={() => setContactModal(false)}
						setErrSnackOpen={setErrSnackOpen}
						setSuccessSnackOpen={setSuccessSnackOpen}
						setSnackErrMsg={setSnackErrMsg}
						setSnackSuccessMsg={setSnackSuccessMsg}
					/>
					<Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={handleSuccessSnackClose} msg={snackSuccessMsg ? snackSuccessMsg : ''} type="success" />
					<Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose} msg={snackErrMsg ? snackErrMsg : ''} type="error" />
					{
						(currentWalkthroughStep === 1 && (currentWalkthroughType === 'team')) ? (
							<WalkthroughOverlay />
						) : ''
					}
				</div>
			)}
		</>
	);
};

Sidebar1.propTypes = {
	userName: PropTypes.string,
	dashboardLogo: PropTypes.string,
	saasPlanDetails: PropTypes.object,
	companyFeatures: PropTypes.object,
	userPlan: PropTypes.object,
	openDrawer: PropTypes.func,
	closeDrawer: PropTypes.func,
	currentWalkthroughStep: PropTypes.number,
	currentWalkthroughType: PropTypes.string,
	updateCurrentWalkthroughStep: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	userName: getUserName(),
	dashboardLogo: getDashboardLogo(),
	saasPlanDetails: getSaasPlanDetails(),
	companyFeatures: getCompanyFeatures(),
	userPlan: getUserPlan(),
	currentWalkthroughStep: getCurrentWalkthroughStep(),
	currentWalkthroughType: getCurrentWalkthroughType()
});

const mapDispatchToProps = (dispatch) => ({
	sendSupportMsg: (data) => dispatch(sendSupportMsgRequest(data)),
	updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar1);
