import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSVLink } from "react-csv";
// import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useHistory } from "react-router-dom";
import { useTour } from '@reactour/tour';

import AccountMenu from "../components/AccountMenu";
import Avatar from "../components/common/Avatar";
import BottomBar from "../components/common/BottomBar";
import Button from '../components/common/Button';
import DataTable from "../components/common/DataTable";
import Header from "../components/header/header";
import IconButton from "../components/common/IconButton";
import Modal from '../components/common/CustomModal';
import NotificationBell from "../components/notifications/NotificationBell";
import Snackbar from "../components/common/Snackbar";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	editCandidateName as editCandidateNameRequest,
	resetRerender as resetRerenderRequest,
	deleteCandidate as deleteCandidateRequest,
} from '../actions/app';
import { getApplicantsLoading, getCandidatesList, getRerender, getUserRole } from '../selectors/app';
import { useWindowDimensions } from '../utils/windowUtils';

import Edit from '../assets/Icons/Edit.png';
import emptyRowImg from '../assets/emptyrow2.png';
import InfoIcon from '../assets/info-roles.png';
import Subtract from '../assets/Icons/Subtract.png';
import CandidateCVIcon from '../assets/candidate-cv.png';
import TickBlueIcon from '../assets/right-blue-icon.svg';

import "../styles/AllApplicants.scss";
import { reportWorkFunctions } from "../constants/reports";
import { getCandidateStatus } from "../utils/reports";
import TranscriptsModal from "../components/common/TranscriptsModal";

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const AllApplicants = (props) => {
	const {
		candidates,
		getCandidatesByType,
		rerender,
		editCandidateName,
		resetRerender,
		deleteCandidate,
		notification,
		userRole,
		applicantsLoading,
	} = props;

	const { isOpen } = useTour();
	const location = useLocation();
	const history = useHistory();

	const editNameRef = useRef(null);

	const [deleteModal, setDeleteModal] = useState(false);
	const [change, setChange] = useState(0);
	const [data, setData] = useState([]);
	const [rows, setRows] = useState(data);
	const [CSVData, setCSVData] = useState([]);
	// const [searchText, setSearchText] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	// const [pageSize, setPageSize] = useState(15);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [showStatusModal, setShowStatusModal] = useState(false);
	const [filterScoreKey, setFilterScoreKey] = useState([]);
	const [filterStatusKey, setFilterStatusKey] = useState([]);
	const [filterScoreKeysArr, setFilterScoreKeysArr] = useState([]);
	const [filterStatusKeysArr] = useState([
		'Added',
		'Applied',
		'Invited',
		'PS passed',
		'PS failed',
		'Opened (0% completed)',
		'x% completed',
		'Submitted',
		'Shortlisted',
		'Rejected',
		'Kept in View'
	]);
	const [modalOpen, setModalOpen] = useState(false);
	const [chat, setChat] = useState({});
	const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

	const onCloseModal = () => {
		setModalOpen(false);
	}

	const onOpenDeleteModal = () => {
		if (mediaQueryMobile.matches && selectedRow.length > 0) {
			setDeleteModal(true);
		}
		else {
			setSnackErr('Please select atleast one candidate');
			handleSnackClick();
		}
	}
	const onCloseDeleteModal = () => setDeleteModal(false);

	useEffect(() => {
		let temp = [];

		if (rows.length == 0) return;

		rows.map((val) => {
			let x = { name: val.name, email: val.email, id: val.id, status: getCandidateStatus(val.status), score: val.score }
			temp.push(x);
		});

		setCSVData(temp);
	}, [rows])

	useEffect(() => {
		const reqData = {
			status: "candidates",
			id: location.state.workflowId
		};

		getCandidatesByType({ reqData, history });
	}, [change])

	useEffect(() => {
		if (!candidates) return;
		candidates.forEach((candidate) => {
			if (candidate.test && candidate.test.result && Object.prototype.hasOwnProperty.call(candidate.test.result, 'total')) {
				setFilterScoreKeysArr([...Object.keys(candidate.test.result)]);
				candidate.score = candidate.test.result.total;
			} else {
				candidate.score = 0;
			}
			candidate.id = parseInt(candidate.id);
			const candidateStatus = getCandidateStatus(candidate.status);
			candidate.tableStatus = candidateStatus;
		});
		setData([...candidates]);
		setRows([...candidates]);
	}, [candidates])

	const nameEdit = function (row) {
		editCandidateName({ row, newName: editNameRef.current.value, history })
	}

	const handleDelete = () => {
		const IDs = [];
		selectedRow.map((val) => {
			IDs.push(val._id);
		});

		deleteCandidate({ id: IDs, history });
		setSelectedRow([]);
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	const getScore = function (params) {
		var score = 0.0;
		// var index = 0;

		try {
			// if (params.row.test && params.row.test.result && Object.prototype.hasOwnProperty.call(params.row.test.result, filterScoreKey))
			// 	score = params.row.test.result[filterScoreKey]
			if (filterScoreKey.length > 0) {
				filterScoreKey.forEach((nfsr) => {
					if (params.row.test && params.row.test.result && Object.prototype.hasOwnProperty.call(params.row.test.result, nfsr)) {
						score = score + params.row.test.result[nfsr];
					}
				});
			} else {
				if (params.row.test && params.row.test.result && Object.prototype.hasOwnProperty.call(params.row.test.result, 'total')) {
					score = params.row.test.result.total;
				}
			}
			// else {
			// 	if (params.row.productId.customId.toLowerCase().includes('sale')) {
			// 		if (params.row.test.result.dab)
			// 			score += 0.2 * params.row.test.result.dab
			// 		if (params.row.test.result.lpg)
			// 			score += 0.2 * params.row.test.result.lpg
			// 		if (params.row.test.result.stk)
			// 			score += 0.1 * params.row.test.result.stk
			// 		if (params.row.test.result.ema)
			// 			score += 0.5 * params.row.test.result.ema
			// 	}
			// 	else {
			// 		for (let keys in params.row.test.result) {
			// 			score += params.row.test.result[keys]
			// 			index += 1
			// 		}
			// 		if (index > 0) score = score / index
			// 	}
			// }
			if (score > 0) score *= 100
		} catch (e) {
			console.log(e);
			console.log("Error in scores");
		}
		return score.toFixed();
	}

	const onClickScoreFilter = (e, key, callback) => {
		e.stopPropagation();
		const newFilterScoreKey = [...filterScoreKey];
		if (newFilterScoreKey.includes(key)) {
			const fIndex = newFilterScoreKey.indexOf(key);
			newFilterScoreKey.splice(fIndex, 1);
		} else {
			newFilterScoreKey.push(key);
		}
		if (newFilterScoreKey.length > 0) {
			candidates.forEach((candidate) => {
				candidate.score = 0;
				candidate.id = parseInt(candidate.id);
				newFilterScoreKey.forEach((nfsr) => {
					if (candidate.test && candidate.test.result && Object.prototype.hasOwnProperty.call(candidate.test.result, nfsr)) {
						candidate.score = candidate.score + candidate.test.result[nfsr];
					}
				});
			});
		} else {
			candidates.forEach((candidate) => {
				if (candidate.test && candidate.test.result && Object.prototype.hasOwnProperty.call(candidate.test.result, 'total')) {
					candidate.score = candidate.test.result.total;
				} else {
					candidate.score = 0;
				}
				candidate.id = parseInt(candidate.id);
			});
		}
		setRows([...candidates]);
		setFilterScoreKey([...newFilterScoreKey]);
		callback()
	}

	const onClickStatusFilter = (e, key, callback) => {
		e.stopPropagation();
		const newCandidates = [];
		const newFilterStatusKey = [...filterStatusKey];
		if (newFilterStatusKey.includes(key)) {
			const fIndex = newFilterStatusKey.indexOf(key);
			newFilterStatusKey.splice(fIndex, 1);
		} else {
			newFilterStatusKey.push(key);
		}
		if (newFilterStatusKey.length > 0) {
			candidates.forEach((candidate) => {
				candidate.id = parseInt(candidate.id);
				const candidateStatus = getCandidateStatus(candidate.status);
				newFilterStatusKey.forEach((nfsk) => {
					if (nfsk === 'x% completed') {
						if (candidateStatus.includes('% completed') && !candidateStatus.includes('Opened')) {
							candidate.tableStatus = candidateStatus;
							newCandidates.push(candidate);
						}
					} else if (nfsk === candidateStatus) {
						candidate.tableStatus = candidateStatus;
						newCandidates.push(candidate);
					}
				});
			});
		} else {
			candidates.forEach((candidate) => {
				candidate.id = parseInt(candidate.id);
				const candidateStatus = getCandidateStatus(candidate.status);
				candidate.tableStatus = candidateStatus;
				newCandidates.push(candidate);
			});
		}
		setRows([...newCandidates]);
		setFilterStatusKey([...newFilterStatusKey]);
		callback();
	}

	const onOpenModal = (e, params) => {
		e.stopPropagation();
		setModalOpen(true);
		setChat({ ...params.row });
	}

	const checkTranscripts = () => {
		let hideTranscripts = true;
		candidates.forEach((candidate) => {
			if(candidate.preScreeningTest) {
				hideTranscripts = false;
			}
		});
		return hideTranscripts;
	}

	const navigateApplicant = (id) => {
		history.push({
			pathname: `/applicant/${id}`,
			state: {
				sidebar: "applicants",
				workflowId: location.state.workflowId,
				workflowName: location.state.workflowName
			}
		})
	}

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: 80,
			headerAlign: 'left',
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} className="userListUser" style={{ width: "100%", cursor: 'pointer' }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: "name",
			headerName: "Name",
			width: useWindowDimensions().width / 6,
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} style={{ fontSize: 16, fontWeight: "400", display: 'flex', alignItems: 'center', width: '100%', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.name}</div>
							<span className='tooltiptext datagrid'>{params.row.name}</span>
						</div>
						{
							params.row.resume ? (
								<div style={{ marginLeft: 15, display: 'flex' }}><a onClick={(e) => { e.stopPropagation() }} href={`https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/${params.row.resume}`} download={`CV_${params.row.name.split(' ').join('_')}`}><img src={CandidateCVIcon} style={{ height: 20 }} alt='' /></a></div>
							) : ''
						}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			width: useWindowDimensions().width / 6,
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} style={{ fontSize: 16, fontWeight: "400", width: '100%', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.email}</div>
							<span className='tooltiptext datagrid'>{params.row.email}</span>
						</div>
					</div>
				);
			},
		},
		{
			field: "tableStatus",
			headerName: "Status",
			headerAlign: 'center',
			width: useWindowDimensions().width / 6,
			filterable: true,
			renderFilter: (callback) => {
				return (
					<div className="row-column-filter">
						{
							filterStatusKeysArr.map((fska, i) => (
								<div key={i} onClick={(e) => onClickStatusFilter(e, fska, callback)} style={{ display: 'flex', alignItems: 'center' }}>
									<div className="filter-checkbox">
										<label><input type="checkbox" checked={filterStatusKey.includes(fska)} onChange={() => { }} /><span className="checkmark"></span></label>
									</div>
									<div style={{ whiteSpace: 'break-spaces' }}>{fska}</div>
								</div>
							))
						}
					</div>
				)
			},
			renderHeader: () => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ fontFamily: "Poppins" }}>
						Status
					</div>
					<div onClick={(e) => { e.stopPropagation(); setShowStatusModal(true) }} style={{ display: 'flex', marginLeft: 10 }}>
						<img src={InfoIcon} alt='' style={{ height: 20 }} />
					</div>
				</div>
			),
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} className="userListUser" style={{ width: "100%", cursor: 'pointer' }}>
						{getCandidateStatus(params.row.status)}
					</div>
				);
			}
		},
		{
			field: "score",
			headerName: "Score",
			headerAlign: "center",
			filterable: true,
			width: useWindowDimensions().width / 6,
			renderFilter: (callback) => {
				return (
					<div className="row-column-filter">
						{
							filterScoreKeysArr.map((rtr) => {
								return rtr !== 'total' ? (
									<div key={rtr} onClick={(e) => onClickScoreFilter(e, rtr, callback)} style={{ display: 'flex', alignItems: 'center' }}>
										<div className="filter-checkbox">
											<label><input type="checkbox" checked={filterScoreKey.includes(rtr)} onChange={() => { }} /><span className="checkmark"></span></label>
										</div>
										<div style={{ whiteSpace: 'break-spaces' }}>{rtr !== 'total' ? (reportWorkFunctions[rtr.toLowerCase()] ? reportWorkFunctions[rtr.toLowerCase()].label : rtr) : 'total'}</div>
									</div>
								) : ''
							})
						}
					</div>
				)
			},
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} className="userListUser" style={{ marginRight: "4ch", width: "100%", cursor: 'pointer' }}>
						{getScore(params)}%
					</div>
				);
			},
		},
		{
			headerName: "Pre-screen",
			headerAlign: 'center',
			width: useWindowDimensions().width / 12,
			hideColumn: checkTranscripts(),
			renderCell: (params) => {
				return (
					<div onClick={() => navigateApplicant(params.row._id)} style={{ marginRight: 15, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
						{
							params.row.preScreeningTest ? (
								<div className="tooltip">
									<Button
										className="productView"
										style={{
											background: '#479BD2',
											fontWeight: 'bold',
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: '5px 12px',
											margin: 0
										}}
										size="small"
										variant="contained"
										onClick={(e) => onOpenModal(e, params)}
									>
										<span style={{ fontSize: 14, color: '#fff' }}>Analyse</span>
									</Button>
									<span className="tooltiptext datagrid" style={{ width: 250, lineHeight: '1.5' }}>View pre-screening transcripts etc.</span>
								</div>
							) : ''
						}
					</div>
				);
			}
		}
	];

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);
		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (mediaQueryMobile.matches) {
			if (selectedRow.length > 1 || selectedRow.length === 0) {
				setSnackErr('Please select a candidate');
				handleSnackClick();
			}
			else {
				setEditModal(true);
			}
		}
		else {
			if (selectionModel.length == 1) setEditModal(true);
			else {
				setSnackErr('Please select atleast one candidate');
				handleSnackClick();
			}
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setGreenSnackOpen(false);
		setSuccessMsg(null);
	};

	const requestSearch = (searchValue) => {
		// setSearchText(searchValue);

		if (searchValue == '') {
			setRows(data);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = data.filter((row) => {
				if (searchRegex.test(row.name) || searchRegex.test(row.email)) {
					return true;
				}
			});

			setRows(filteredRows);
		}
	};

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}

	const MenuItems = [
		{
			name: 'Edit',
			action: () => {
				onOpenEditModal();
			}
		},
		{
			name: 'Delete',
			action: () => {
				onOpenDeleteModal();
			}
		},
		{
			name: 'Export',
			CSVData: CSVData,
		}
	]

	const handleSelectApplicant = (row) => {
		if (selectedRow.length !== 0 && selectedRow.some(el => el._id === row._id)) {
			setSelectedRow(selectedRow.filter(el => el._id !== row._id));
		}
		else {
			setSelectedRow([...selectedRow, row]);
		}
	}

	return (
		<div className="allApplicants productList">
			{mediaQueryMobile.matches && <Header MenuItems={MenuItems} />}
			<div className="inner-div">
				<div className='top-icon-box'>
					<WalkthroughWelcome />
					{
						userRole.includes('Admin') ? (
							<div className="tooltip">
								<IconButton aria-label="edit" onClick={onOpenEditModal} id="applicantsFlow-selector-3">
									<img src={Edit} alt="" width={30} height={30} />
								</IconButton>
								<span className="tooltiptext">Edit</span>
							</div>
						) : ''
					}
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			<div className="userListTitleContainer" style={{ padding: "20px 20px 0" }}>
				<div className="inner-div-details">
					<div>
						<h2>All Applicants</h2>
						<p style={{ wordBreak: 'break-all' }}>{location.state.workflowName}</p>
					</div>
					{mediaQueryMobile.matches && <p>Total : {candidates.length}</p>}
				</div>
				<div className="search-box">
					<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
				</div>
				<div className="export-btn">
					<div className="tooltip">
						<Button
							disabled={!CSVData.length}
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: "8px 20px",
								display: 'flex',
								alignItems: 'center'
							}}
							size="small"
							variant="contained"
						>
							<CSVLink filename="Data.csv" data={CSVData} target="_blank" style={{ color: "white", textDecoration: "none", fontSize: 16, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }} id="applicantsFlow-selector-2">
								Export <img src={Subtract} alt="" style={{ height: 18, width: 18, marginLeft: 5 }} />
							</CSVLink>
						</Button>
						<span className="tooltiptext">Export report</span>
					</div>
				</div>
			</div>
			<div className="userListApplicantContainer">
				{mediaQueryMobile.matches &&
					<div className='applicant-box__content'>
						{rows.length === 0 ?
							<>
								<img
									src={emptyRowImg}
									alt="no rows"
									style={{
										height: "auto",
										width: "100%"
									}}
								/>
								<p style={{ fontSize: 20, fontWeight: "700", color: "#334D6E", textAlign: "center" }}>No data to show</p>
							</> : null}
						{rows.map((row, index) => (
							<div key={index} className='applicant-box__row' onClick={() => handleSelectApplicant(row)}>
								<div className='applicant-box__row-left'>
									<div className="applicant-box__row-left-image-box">
										<Avatar
											name={parseInt(row.id)}
											styles={{
												text: {
													backgroundColor: "#EBEBEB",
													color: "#404040",
												}
											}}
										/>
										{selectedRow.length !== 0 && selectedRow.some(el => el._id === row._id) && <img src={TickBlueIcon} alt="right-blue-icon" />}
									</div>
									<div className="applicant-box-row-left-text-box">
										<p className='applicant-box__row-left-text-name'>{row.name}</p>
										<p className='applicant-box__row-left-text-role'>{row.email}</p>
									</div>
								</div>
								<div className='applicant-box__row-right'>
									<p className="applicant-box__row-left-text-role">
										{getCandidateStatus(row.status)}
									</p>
								</div>
							</div>
						))}
					</div>
				}
				{!mediaQueryMobile.matches &&
					<DataTable
						loading={applicantsLoading}
						noRowsComponent={<NoRowsOverlay />}
						rows={
							!isOpen ? rows :
								[{
									"_id": "61789a40333ed5317facc044",
									"name": "yA",
									"email": [
										"y@s.com"
									],
									"id": "3",
									"code": "2a10Rz2E5CIIkAEVqZCG5U2bwqLadsZBbTxEhi7HftAb8WBXZXnui",
									"status": "Shortlist",
									"lastActive": "2021-10-27T00:25:06.399Z",
									"createdDate": "2021-10-27T00:16:00.341Z",
									"company": "61730080f94921c9089ea99f",
									"workflow": "6178704387c9e1c238ae4f58",
									"__v": 0,
									"problem": "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
									"productId": "ChatId4",
									"title": "HR Evaluative"
								}]
						}
						columns={columns}
						checkboxSelection={userRole.includes('Admin')}
						onSelectionModelChange={(newSelectionModel) => {
							console.log(newSelectionModel);
							setSelectionModel(newSelectionModel);
						}}
						selectionModel={selectionModel}
						pageSize={15}
					/>
				}
			</div>
			<Modal open={editModal} onClose={onCloseEditModal} center classNames={{ modal: 'editModalApplicants' }}>
				<p className="modal-text">
					Edit candidate&apos;s name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="applicantsFlow-selector-4"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="applicantsFlow-selector-5"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						<span style={{ fontSize: '16px' }}>Ok</span>
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						<span style={{ fontSize: '16px' }}>Cancel</span>
					</Button>
				</div>
			</Modal>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to delete the candidate from workflow?
				</p>
				<div className="button-div">
					<Button
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px',
							color: '#fff',
							marginLeft: '10px'
						}}
						onClick={() => {
							onCloseDeleteModal();
							handleDelete();
						}}
					>
						OK
					</Button>
					<Button
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px',
							color: '#fff'
						}}
						onClick={() => {
							onCloseDeleteModal()
						}}
					>
						Cancel
					</Button>
				</div>
			</Modal>
			<Modal open={showStatusModal} onClose={() => setShowStatusModal(false)} center>
				<div style={{ padding: '20px 40px', fontSize: 14 }}>
					<div style={{ fontSize: 24, fontWeight: '700', marginBottom: 20 }}>More details on status tags</div>
					<div><strong>Added:</strong> All candidates who have been added to the dashboard</div>
					<div style={{ marginTop: 15 }}><strong>Applied:</strong> All candidates who have applied on the job opening via HQ job opening link</div>
					<div style={{ marginTop: 15 }}><strong>Invited:</strong> All candidates who have been invited to attempt the HQ virtual interviewer</div>
					<div style={{ marginTop: 15 }}><strong>PS passed:</strong> All candidates who have qualified the pre-screening stage (meet the criteria defined in all pre-screening questions)</div>
					<div style={{ marginTop: 15 }}><strong>PS failed:</strong> Candidates who have been rejected from the pre-screening stage (do not qualify at least one of the pre-screening questions)</div>
					<div style={{ marginTop: 15 }}><strong>Opened (0% completed):</strong> Candidates who have just opened the link but have not started attempting the questions yet</div>
					<div style={{ marginTop: 15 }}><strong>x% completed:</strong> Candidates who are either attemting the assessment, or have prevoiously attempted but dropped midway (x = % of assessments that was attempted). Given these assessments were not submitted, they are not open for evaluation</div>
					<div style={{ marginTop: 15 }}><strong>Submitted:</strong> Candidates who have  successfully completed and submitted the assessment</div>
					<div style={{ marginTop: 15 }}><strong>Shortlisted:</strong> Candidates who have been shortlisted by the admin</div>
					<div style={{ marginTop: 15 }}><strong>Rejected:</strong> Candidates who have been rejected by the admin</div>
					<div style={{ marginTop: 15 }}><strong>Kept in View:</strong> Candidates who have been Kept in View by the admin. Decision of these candidates can be taken later in the ‘Reviewed’ tab’ tab</div>
				</div>
			</Modal >
			<TranscriptsModal
				open={modalOpen}
				onClose={onCloseModal}
				chat={chat}
			/>
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose} msg={successMsg ? successMsg : ''} type="success" />
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose} msg={snackErr ? snackErr : ''} type="error" />
			{mediaQueryMobile.matches && <BottomBar />}
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList(),
	rerender: getRerender(),
	userRole: getUserRole(),
	applicantsLoading: getApplicantsLoading()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
	editCandidateName: (data) => dispatch(editCandidateNameRequest(data)),
	resetRerender: () => dispatch(resetRerenderRequest()),
	deleteCandidate: (data) => dispatch(deleteCandidateRequest(data)),
});

AllApplicants.propTypes = {
	candidates: PropTypes.array,
	getCandidatesByType: PropTypes.func,
	rerender: PropTypes.number,
	editCandidateName: PropTypes.func,
	deleteCandidate: PropTypes.func,
	notification: PropTypes.object,
	resetRerender: PropTypes.func,
	userRole: PropTypes.string,
	applicantsLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllApplicants);