import React, { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/common/CustomModal';
import Invoice from './Invoice';
import { PDFDownloadLink } from '@react-pdf/renderer';

import StripeTrigger from './checkout/StripeTrigger';
import Checkbox from '../common/Checkbox';
import SupportModal from '../common/SupportModal';
import Snackbar from '../common/Snackbar';

import '../../styles/billingAndPurchases.scss';

const BillingAndPurchases = (props) => {
	const { userPlan, onErrorAlertOpen, setErrorAlertMsg, onSuccessAlertOpen, setSuccessAlertMsg } = props;
	const [planName] = useState(userPlan.name ? userPlan.name : "N/A")
	const [lastPurchaseDate] = useState(userPlan.lastPurchase ? new Date(userPlan.lastPurchase).toLocaleDateString() : "N/A")
	const [expiry] = useState(userPlan.currentExpiry ? new Date(userPlan.currentExpiry).toLocaleDateString() : "N/A")
	const [creditLimit] = useState(userPlan.creditLimit ? userPlan.creditLimit : -1)
	const [totalCredits] = useState(50)
	const [currentUsedCredits] = useState(0)
	const [saasId] = useState(userPlan._id ? userPlan._id : "")
	const [companyId] = useState(localStorage.getItem("company"))
	const [infoModal, setInfoModal] = useState(false);
	const [creditAlertModal, setCreditAlertModal] = useState(false);
	const [upgradePlanModal, setUpgradePlanModal] = useState(false);
	const [saasPlan, setSaasPlan] = useState('Growth');
	const [addCreditModal, setAddCreditModal] = useState(false);
	const [credits, setCredits] = useState('50');
	const [customCredits, setCustomCredits] = useState('');
	const [manualCredits, setManualCredits] = useState(false);
	const [addFeatures, setAddFeatures] = useState({});
	const [onAddCustomCredits, setOnAddCustomCredits] = useState(false);
	const [openCancelSubscription, setOpenCancelSubscription] = useState(0);
	const [otherCancelReason, setOtherCancelReason] = useState("");
	const [otherRemarkReason, setOtherRemarkReason] = useState("");
	const [totalDollars, setTotalDollars] = useState(parseInt(credits, 10) * 10);
	const features = [
		'Random shit 1',
		'Random shit 2',
		'Random shit 3',
		'Random shit 4',
		'Random shit 5',
	]
	const [cancelReasons, setCancelReasons] = useState({
		reason1: false,
		reason2: false,
		reason3: false,
		reason4: false,
		reason5: false,
	})
	const [remarkReasons, setRemarkReasons] = useState({
		reason1: false,
		reason2: false,
		reason3: false,
		reason4: false,
		reason5: false,
	});
	const [snackbar, setSnackbar] = useState({
		open: false,
		type: 'success',
		msg: ''
	});
	const [contactModal, setContactModal] = useState(false);

	useEffect(() => {
		const newAddFeatures = { ...addFeatures };
		features.forEach((feature) => {
			newAddFeatures[feature] = false;
		});
		setAddFeatures({ ...newAddFeatures });
		// setCreditAlertModal(true);
	}, [])

	const onCloseCancelSubscriptionModal = () => {
		setOpenCancelSubscription(0);
		setCancelReasons({
			reason1: false,
			reason2: false,
			reason3: false,
			reason4: false,
			reason5: false,
		});
		setOtherCancelReason("");
	}

	const handleProceedCancelSubscription = () => {
		setOpenCancelSubscription(2);
	}

	const handleAlertModal = () => {
		setCreditAlertModal(false);
		setAddCreditModal(true);
	}

	const handleSorrySubmit = () => {
		if (Object.keys(cancelReasons).some(k => cancelReasons[k])) {
			setOpenCancelSubscription(3);
		}
		else {
			setSnackbar({
				open: true,
				type: 'error',
				msg: 'Please select a reason'
			});
		}
	}

	const handleRemarksSubmit = () => {
		if (Object.keys(cancelReasons).some(k => cancelReasons[k])) {
			setOpenCancelSubscription(4);
		}
		else {
			setSnackbar({
				open: true,
				type: 'error',
				msg: 'Please select a reason'
			});
		}
	}

	const onSnackClose = () => {
		setSnackbar({
			open: false,
			type: 'success',
			msg: ''
		});
	}

	const handleAddCustomCredits = () => {
		if (customCredits === "") {
			setSnackbar({
				open: true,
				type: 'error',
				msg: 'Please enter valid credits'
			});
		}
		else if (onAddCustomCredits) {
			setOnAddCustomCredits(false);
		}
		else {
			setTotalDollars(parseInt(customCredits, 10) * 10);
			setOnAddCustomCredits(true);
		}
	}

	const handleRequestDemo = () => {

	}

	return (
		<div className='billingPage'>
			<div className='purchase-history'>
				<div className='purchase-history__title'>Purchase history</div>
				<div className='purchase-history__box'>
					<div className='purchase-history__box-single'>
						<div className='purchase-history__box-title'>Active Plan</div>
						<div className='purchase-history__box-text'>{planName}</div>
					</div>
					<div className='purchase-history__box-single'>
						<div className='purchase-history__box-title'>Last Purchase</div>
						<div className='purchase-history__box-text'>{lastPurchaseDate}</div>
					</div>
					<div className='purchase-history__box-single'>
						<div className='purchase-history__box-title'>Valid till</div>
						<div className='purchase-history__box-text'>{expiry}</div>
					</div>
					<div className='purchase-history__box-single'>
						<div className='purchase-history__box-title'>Download Invoice</div>
						<PDFDownloadLink document={<Invoice />} fileName="invoice.pdf">
							{({ loading }) => (loading ? 'Loading document...' : (
								<div className='purchase-history__box-btn'>Download</div>
							))}
						</PDFDownloadLink>
					</div>
				</div>
			</div>
			<div className='credit-meter'>
				<div>
					<div className='credit-meter__title'>Credit meter</div>
					<div className='credit-meter__box'>
						<div className='credit-meter__animation'>
							<div className="box gauge--1">
								<div className="mask">
									<div className="semi-circle"></div>
									<div className="semi-circle--mask" style={{ "--rotationCircleDegree": `${totalCredits * 1.8}deg` }}></div>
								</div>
								<div className='needle' style={{ "--rotationNeedleDegree": `${(totalCredits * 1.8) - 90}deg` }}>
									<div className='needle-box-1'></div>
									<div className='needle-box-2'>
										<div className='needle-box-3'></div>
										<div className='needle-box-4'></div>
									</div>
								</div>
							</div>
							<div>Total credits allotted = {creditLimit > -1 ? creditLimit : 'inf'}</div>
						</div>
						<div className='credit-meter__credit-box'>
							<div className='credit-meter__credits-used'>
								<div className='credit-meter__credit-box-title'>Credits Used</div>
								<div className='credit-meter__credit-box-text'>{currentUsedCredits}</div>
							</div>
							<div className='credit-meter__info-box'>
								<div className='credit-meter__info' onClick={() => setInfoModal(!infoModal)}>i</div>
								{infoModal && <div className='credit-meter__info-modal'>1 credit = 1 invitation sent</div>}
							</div>
						</div>
					</div>
				</div>
				<div className='credit-meter__buttons-box'>
					<div className='credit-meter__add-credits' onClick={() => setAddCreditModal(true)}>Add credits</div>
					<div className='credit-meter__upgrade-now' onClick={() => setUpgradePlanModal(true)}>Upgrade now</div>
				</div>
			</div>
			<div className='cancel-subscription'>
				<div>
					<div className='cancel-subscription__title'>Cancel subscription</div>
					<div className='cancel-subscription__text'>Need any support? Reach out to us at recruiter-support@hirequotient.com </div>
				</div>
				<div className='cancel-subscription__btn' onClick={() => setOpenCancelSubscription(1)}>Cancel subscription</div>
			</div>
			<Modal open={creditAlertModal} onClose={() => setCreditAlertModal(false)} center classNames={{ modal: 'creditAlertModal' }}>
				<p className='creditAlertModal__title'>You have used 70 % of
					your credits</p>
				<p className='creditAlertModal__text'> You can upgrade now or reach out to{' '}
					<a href="mailto:recruiter-support@hirequotient.com">recruiter-support@hirequotient.com</a>
					{' '}for more assistance</p>
				<div className='creditAlertModal__credit-btn' onClick={handleAlertModal}>Add Credits</div>
			</Modal>
			<Modal open={upgradePlanModal} onClose={() => setUpgradePlanModal(false)} center classNames={{ modal: 'upgradePlanModal' }}>
				<p className='upgradePlanModal__title'>Pick a plan that works for you!</p>
				<div className='upgradePlanModal__box'>
					<div className={`${saasPlan === 'Emerging' ? 'active' : ''} plan-item`} onClick={() => setSaasPlan('Emerging')}>
						<div className="plan-top">
							<div className="plan-price">$200</div>
							<div className="plan-name">Emerging</div>
							<div className="plan-description">10 tests to close your next open position</div>
						</div>
						<div className="plan-bottom">
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Test 10 candidates</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Valid for 1 month</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Free Add On: Customized Video Questions</div>
							</div>
						</div>
					</div>
					<div className={`${saasPlan === 'Growth' ? 'active' : ''} plan-item`} onClick={() => setSaasPlan('Growth')}>
						<div className="plan-popular">Most popular!</div>
						<div className="plan-top">
							<div className="plan-price">$500</div>
							<div className="plan-name">Growth</div>
							<div className="plan-description">Grow and build your teams across verticals</div>
						</div>
						<div className="plan-bottom">
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Test 50 candidates</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Valid for 1 month</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Free Add On: Customized Video Questions</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">
									<div>Test candidates across :</div>
									<ul>
										<li>Sales and Marketing</li>
										<li>Product/Project Manager</li>
										<li>Strategy</li>
										<li>Customer Success</li>
										<li>and more....</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className={`${saasPlan === 'Rocketship' ? 'active' : ''} plan-item`} onClick={() => setSaasPlan('Rocketship')}>
						<div className="plan-top">
							<div className="plan-price">$1000</div>
							<div className="plan-name">Rocketship</div>
							<div className="plan-description">Full power of HQ to grow across all verticals</div>
						</div>
						<div className="plan-bottom">
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Test 100 candidates</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Valid for 2 months</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Free Add On: Customized Video Questions</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">
									<div>Test candidates across :</div>
									<ul>
										<li>Sales and Marketing</li>
										<li>Product/Project Manager</li>
										<li>Strategy</li>
										<li>Customer Success</li>
										<li>and more....</li>
									</ul>
								</div>
							</div>
							<div className="plan-feature-item">
								<div className="plan-feature-img"></div>
								<div className="plan-feature-text">Premium onboarding service</div>
							</div>
						</div>
					</div>
				</div>
				<div className='upgradePlanModal__btnBox'>
					<div className='upgradePlanModal__salesBtn' onClick={() => {
						console.log('contatc clikes');
						setContactModal(true);
					}}>Contact support team</div>
					<div className='upgradePlanModal__proceedBtn' onClick={() => { }}><StripeTrigger saasId={saasId} companyId={companyId} /></div>
				</div>
			</Modal>
			<Modal open={addCreditModal} onClose={() => setAddCreditModal(false)} center classNames={{ modal: 'addCreditModal' }} showCloseIcon={false}>
				<div className='addCreditModal__header'>
					<p className='addCreditModal__header-title'>Upgrade Now</p>
					<p className='addCreditModal__header-total'>
						Total: <span>${totalDollars}</span>
					</p>
				</div>
				<div className='addCreditModal__credits'>
					<p className='addCreditModal__credits-title'>Add credits</p>
					<div className='addCreditModal__credits-box'>
						{!manualCredits ? ['10', '30', '50', '100', 'more...'].map((item, index) => (
							<div className={`${credits === item ? 'active' : ''} addCreditModal__credits-item`} key={index} onClick={() => {
								if (item === 'more...') {
									setManualCredits(true);
								}
								else {
									setCredits(item);
									setTotalDollars(parseInt(item, 10) * 10);
								}
							}}>
								+{item}
							</div>
						)) : (
							<>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={() => setManualCredits(false)}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
								</svg>
								{!onAddCustomCredits ?
									<input className='addCreditModal__credits-input' placeholder='Enter value' type='number' value={customCredits} onChange={(e) => setCustomCredits(e.target.value)} />
									:
									<div className='addCreditModal__credits-item active custom-credits'>
										+{customCredits}
									</div>
								}
								<div
									className='addCreditModal__credits-addBtn'
									onClick={handleAddCustomCredits}>
									{onAddCustomCredits ? 'Edit' : 'Add'}
								</div>
							</>
						)}
					</div>
				</div>
				{/* <div className='addCreditModal__features'>
					<p className='addCreditModal__features-title'>Add Additional Features</p>
					<div className='addCreditModal__features-box'>
						<FormGroup>
							{features.map((item, index) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={addFeatures[item]}
											onChange={handleFeatureChange}
											name={item}
										/>
									}
									label={item}
									key={index}
								/>
							))}
						</FormGroup>
					</div>
				</div> */}
				<div className='addCreditModal__requestBtn' onClick={handleRequestDemo}>
					<StripeTrigger saasId={saasId} companyId={companyId} />
				</div>
			</Modal>
			<Modal open={openCancelSubscription >= 1} onClose={onCloseCancelSubscriptionModal} center classNames={{ modal: 'cancelSubscriptionModal' }}>
				{openCancelSubscription === 1 ? (
					<>
						<p className='cancelSubscriptionModal__heading'>Are you sure you want to cancel the subscription?</p>
						<div className='cancelSubscriptionModal__action-btns-box'>
							<div className="yes-btn" onClick={handleProceedCancelSubscription}>Yes</div>
							<div className="no-btn" onClick={onCloseCancelSubscriptionModal}>No</div>
						</div>
					</>
				) : null}
				{openCancelSubscription === 2 ? (
					<>
						<p className='cancelSubscriptionModal__title'>We’re sorry to see you go!</p>
						<p className='cancelSubscriptionModal__subtitle'>Before you cancel, can you share how did we fall short?</p>
						<Checkbox
							checked={cancelReasons.reason1}
							onChange={(e) => setCancelReasons({ ...cancelReasons, reason1: e.target.checked })}
							label='Assessments are not relevant'
							value='Assessments are not relevant'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						<Checkbox
							checked={cancelReasons.reason2}
							onChange={(e) => setCancelReasons({ ...cancelReasons, reason2: e.target.checked })}
							label='Don’t have the hiring need at the moment'
							value='Don’t have the hiring need at the moment'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						<Checkbox
							checked={cancelReasons.reason3}
							onChange={(e) => setCancelReasons({ ...cancelReasons, reason3: e.target.checked })}
							label='Didn’t have a good product experience'
							value='Didn’t have a good product experience'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						<Checkbox
							checked={cancelReasons.reason4}
							onChange={(e) => setCancelReasons({ ...cancelReasons, reason4: e.target.checked })}
							label='Monthly price is not justified'
							value='Monthly price is not justified'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						<Checkbox
							checked={cancelReasons.reason5}
							onChange={(e) => setCancelReasons({ ...cancelReasons, reason5: e.target.checked })}
							label='Others'
							value='Others'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						{cancelReasons.reason5 &&
							<textarea className='cancelSubscriptionModal__textarea-reason' placeholder='For others, mention the reason here' value={otherCancelReason} onChange={(e) => setOtherCancelReason(e.target.value)} />
						}
						<div className='cancelSubscriptionModal__submit-btn' onClick={handleSorrySubmit}>Submit</div>
						<div className='cancelSubscriptionModal__cancel-btn' onClick={onCloseCancelSubscriptionModal}>Never mind, I don’t want to cancel</div>
					</>
				) : null}
				{openCancelSubscription === 3 ? (
					<>
						<p className='cancelSubscriptionModal__title'>Before you Leave</p>
						<p className='cancelSubscriptionModal__subtitle'>We hope it wasn’t all bad! Was there anything you liked about us?</p>
						<Checkbox
							checked={remarkReasons.reason1}
							onChange={(e) => setRemarkReasons({ ...remarkReasons, reason1: e.target.checked })}
							label='Assessments are relevant'
							value='Assessments are relevant'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						<Checkbox
							checked={remarkReasons.reason2}
							onChange={(e) => setRemarkReasons({ ...remarkReasons, reason2: e.target.checked })}
							label='Good support service'
							value='Good support service'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						<Checkbox
							checked={remarkReasons.reason3}
							onChange={(e) => setRemarkReasons({ ...remarkReasons, reason3: e.target.checked })}
							label='Helpful product features'
							value='Helpful product features'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						<Checkbox
							checked={remarkReasons.reason4}
							onChange={(e) => setRemarkReasons({ ...remarkReasons, reason4: e.target.checked })}
							label='Justified pricing'
							value='Justified pricing'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						<Checkbox
							checked={remarkReasons.reason5}
							onChange={(e) => setRemarkReasons({ ...remarkReasons, reason5: e.target.checked })}
							label='Others'
							value='Others'
							classNames={{
								input: 'green-input',
								checkmark: 'green-checkmark'
							}}
							styles={{
								container: {
									margin: '0.5rem 0'
								}
							}}
						/>
						{remarkReasons.reason5 &&
							<textarea className='cancelSubscriptionModal__textarea-reason' placeholder='For others, mention the reason here' value={otherRemarkReason} onChange={(e) => setOtherRemarkReason(e.target.value)} />
						}
						<div className='cancelSubscriptionModal__submit-btn' onClick={handleRemarksSubmit}>Submit</div>
						<div className='cancelSubscriptionModal__cancel-btn' onClick={onCloseCancelSubscriptionModal}>Never mind, I don’t want to cancel</div>
					</>
				) : null}
				{openCancelSubscription === 4 ? (
					<>
						<p className='cancelSubscriptionModal__title miss-you'>We&apos;ll miss you :(</p>
						<p className='cancelSubscriptionModal__subtitle miss-you'>You can use your existing credits till the end of this billing cycle.</p>
						<div className='cancelSubscriptionModal__submit-btn' onClick={onCloseCancelSubscriptionModal}>Okay</div>
					</>
				) : null}
			</Modal>
			<SupportModal
				open={contactModal}
				onClose={() => setContactModal(false)}
				setErrSnackOpen={onErrorAlertOpen}
				setSuccessSnackOpen={onSuccessAlertOpen}
				setSnackErrMsg={setErrorAlertMsg}
				setSnackSuccessMsg={setSuccessAlertMsg}
			/>
			<Snackbar open={snackbar.open} autoHideDuration={2500} onClose={onSnackClose} msg={snackbar.msg} type={snackbar.type} />
		</div>
	)
}

BillingAndPurchases.propTypes = {
	userPlan: PropTypes.object,
	setErrorAlertMsg: PropTypes.func,
	onErrorAlertOpen: PropTypes.func,
	setSuccessAlertMsg: PropTypes.func,
	onSuccessAlertOpen: PropTypes.func,
}

export default BillingAndPurchases;
