export const checkEmptyString = (str) => {
	if (typeof str === 'string' && str.trim().length === 0) {
		return true;
	}
	else {
		return false;
	}
}

export const checkValidEmail = (val) => {
	if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
		return true;
	}
	return false;
}

const isEmpty = (value) => {
	return (
		value === undefined ||
		value === null ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		(typeof value === 'string' && value.trim().length === 0)
	);
};

export const validateSignInInput = (data) => {
	let errors = {};

	data.email = !isEmpty(data.email) ? data.email : '';
	data.password = !isEmpty(data.password) ? data.password : '';
	if(process.env.ENV !== 'development') {
		data.captchaValue = !isEmpty(data.captchaValue) ? data.captchaValue : '';
	}

	if (!checkValidEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (checkEmptyString(data.email)) {
		errors.email = 'Email field is required'
	}

	if (checkEmptyString(data.password)) {
		errors.password = 'Name field is required'
	}

	if (process.env.NODE_ENV !== 'development' && !errors.email && !data.email.includes('hirequotient.com') && checkEmptyString(data.captchaValue)) {
		errors.captchaValue = 'Captcha field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateDemoInput = (data) => {
	let errors = {}

	data.name = !isEmpty(data.name) ? data.name : ''
	data.email = !isEmpty(data.email) ? data.email : ''

	if (!checkValidEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (checkEmptyString(data.email)) {
		errors.email = 'Email field is required'
	}

	if (checkEmptyString(data.name)) {
		errors.name = 'Name field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateFreeSigninInput = (data) => {
	let errors = {}

	data.email = !isEmpty(data.email) ? data.email : ''
	data.password = !isEmpty(data.password) ? data.password : ''

	if (!checkValidEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (checkEmptyString(data.email)) {
		errors.email = 'Email field is required'
	}

	if (checkEmptyString(data.password)) {
		errors.password = 'Password field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateEmail = (email) => {
	let errors = {}

	email = !isEmpty(email) ? email : ''

	if (!checkValidEmail(email)) {
		errors.email = 'Email is invalid'
	}

	if (checkEmptyString(email)) {
		errors.email = 'Email field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateResetInput = (data) => {
	let errors = {}

	data.firstName = !isEmpty(data.firstName) ? data.firstName : ''
	data.lastName = !isEmpty(data.lastName) ? data.lastName : ''
	data.institution = !isEmpty(data.institution) ? data.institution : ''

	if (checkEmptyString(data.firstName)) {
		errors.firstName = 'First name is required'
	}

	if (checkEmptyString(data.lastName)) {
		errors.lastName = 'Last name is required'
	}

	if (checkEmptyString(data.institution)) {
		errors.institution = 'Institution is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validatePassword = (password) => {
	let errors = {}

	password = !isEmpty(password) ? password : ''

	if (checkEmptyString(password)) {
		errors.password = 'Password field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateCreateNewDrive = (name, desc) => {
	let errors = {};

	name = !isEmpty(name) ? name : '';
	
	if (checkEmptyString(name)) {
		errors.name = 'Name field is required';
	}
	
	if (!validateSpoofText(name)) {
		errors.name = 'Enter Valid Name';
	}

	if (checkHtmlTags(desc)) {
		errors.desc = 'Enter Valid Description';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateCreateNewVideoDrive = (name, desc, role) => {
	let errors = {};

	name = !isEmpty(name) ? name : '';
	role = !isEmpty(role) ? role : '';
	
	if (checkEmptyString(name)) {
		errors.name = 'Name field is required';
	}
	
	if (!validateSpoofText(name)) {
		errors.name = 'Enter Valid Name';
	}

	if (checkHtmlTags(desc)) {
		errors.desc = 'Enter Valid Description';
	}

	if (checkEmptyString(role)) {
		errors.role = 'Role field is required';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const checkHtmlTags = (val) => {
	return /<\/?[a-z][\s\S]*>/i.test(val);
}

export const validateSpoofText = (val) => {
	return  /^(?!\s)[\w\s-]*$/.test(val)
}
