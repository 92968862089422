import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { Bar, Doughnut } from "react-chartjs-2";
import { Collapse, Drawer } from '@mui/material';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSVLink } from "react-csv";
// import { DataGrid } from "@mui/x-data-grid";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { styled } from '@mui/material/styles';
import { useLocation, useHistory } from 'react-router-dom';
import { useTour } from '@reactour/tour';

import AccountMenu from "../components/AccountMenu";
import AddRejectEmail from "../components/reviewedApplicants/AddRejectEmail";
import AddShortlistEmail from "../components/reviewedApplicants/AddShortlistEmail";
import AnalyseModal from "../components/common/AnalyseModal";
import Avatar from "../components/common/Avatar";
import axios from "../utils/axios";
import BottomBar from "../components/common/BottomBar";
import Button from '../components/common/Button';
import DataTable from "../components/common/DataTable";
import EmailTemplate from "../components/finalized/emailTemplate";
import Header from "../components/header/header";
import IconButton from "../components/common/IconButton";
import Modal from '../components/common/CustomModal';
import NotificationBell from "../components/notifications/NotificationBell";
import PdfTranscript from "../components/PdfTranscript";
import Select from "../components/common/Select";
import Snackbar from "../components/common/Snackbar";
import Switch from "../components/common/SwitchBtn";
import UploadShortlist from "../components/reviewedApplicants/UploadShortlist";
import UploadReject from "../components/reviewedApplicants/UploadReject";
import WalkthroughOverlay from "../components/common/WalkthroughOverlay";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";
import { barChartData, doughnutOptions, reportWorkFunctions } from "../constants/reports";
import { doughnutData } from "../utils/reports";
import {
	editCandidateName as editCandidateNameRequest,
	resetSuccessMsg as resetSuccessMsgRequest,
	resetRerender as resetRerenderRequest,
	deleteCandidate as deleteCandidateRequest,
	updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
	updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest,
	updateWalkthroughFlows as updateWalkthroughFlowsRequest,
	fetchSkillCodes as fetchSkillCodesRequest,
	fetchDriveDetails as fetchDriveDetailsRequest
} from '../actions/app';
import { getCurrentWalkthroughStep, getCurrentWalkthroughType, getDriveDetails, getRerender, getSkillCodeList, getUserRole } from '../selectors/app';
import { redirectUnauthorisedUser } from "../helper/helperFunction";
import { useWindowDimensions } from "../utils/windowUtils";

import Cancel from '../assets/verification-error.png';
import Edit from '../assets/Icons/Edit.png';
import emptyRowImg from '../assets/emptyrow2.png';
import Subtract from '../assets/Icons/Subtract.png';
import TickBlueIcon from '../assets/right-blue-icon.svg';
import Trash from '../assets/Icons/Trash.png';

import "../styles/reviewedApplicants.scss";

const ExpandMore = styled((props) => {
	const { ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const buttonStyle = {
	color: "#fff",
	background: "#479BD2",
	fontWeight: 'bold',
	height: "40px",
	borderRadius: "25px",
	padding: "5px 20px",
	marginRight: "10px",
	fontSize: "14px"
}

const ReviewedApplicants = (props) => {
	const { editCandidateName, resetRerender, deleteCandidate, rerender, notification, userRole, currentWalkthroughStep, updateCurrentWalkthroughStep, currentWalkthroughType, updateCurrentWalkthroughType, updateWalkthroughFlows, fetchSkillCodes, skillCodeList, driveDetails, fetchDriveDetails } = props;

	const { isOpen } = useTour();
	const location = useLocation();
	const history = useHistory();
	const windowDimensions = useWindowDimensions();

	const [deleteModal, setDeleteModal] = useState(false);
	const [bulkMailModal, setBulkMailModal] = useState(false);
	const [bulkMailShortlistTemp, setBulkMailShortlistTemp] = useState(0);
	const [bulkMailRejectTemp, setBulkMailRejectTemp] = useState(0);
	const [switchShortlistTemp, setSwitchShortlistTemp] = useState(false);
	const [switchRejectTemp, setSwitchRejectTemp] = useState(false);
	const [bulkMailLoading, setBulkMailLoading] = useState(false);
	const [chat, setChat] = useState({})
	const [modal, setModal] = useState(false);
	const [change, setChange] = useState(0);
	const [shortlistMailModalOpen, setShortlistMailModalOpen] = useState(false);
	const [rejectMailModalOpen, setRejectMailModalOpen] = useState(false);
	const [data, setData] = useState([])
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false)
	const [CSVData, setCSVData] = useState([]);
	const [tempInv, setTempInv] = useState([]);
	const [tempRem, setTempRem] = useState([]);
	const [tempp, setTemplate] = useState();
	const [tempr, setTemplateR] = useState();
	const [selectedTemp, setSelectedTemp] = useState(0);
	const [tempChange, setTempChange] = useState(0);
	// const [searchText, setSearchText] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	// const [pageSize, setPageSize] = useState(15);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [expanded, setExpanded] = useState("");
	const [bulkStatus, setBulkStatus] = useState(null);
	const [totalScore, setTotalScore] = useState(0);
	const [barData, setBarData] = useState({ ...barChartData });
	const [reportModal, setReportModal] = useState(false);
	const [shortlistedRows, setShortlistedRows] = useState([]);
	const [rejectedRows, setRejectedRows] = useState([]);
	const [transcriptPdfLoaded, setTranscriptPdfLoaded] = useState(true);
	const [emailTemplateOpen, setEmailTemplateOpen] = useState(false);
	const mediaQueryMobile = window.matchMedia('(max-width: 768px)');
	const [bottomDrawer, setBottomDrawer] = useState({
		open: false,
		name: '',
	});
	const [checkboxOptions, setCheckboxOptions] = useState({
		shortlist: false,
		reject: false,
		keptInView: false,
	});
	const [nextWalkthroughStep, setNextWalkthroughStep] = useState(4);
	const [workFunctions, setWorkFunctions] = useState({});

	const editNameRef = useRef(null);
	const doughnutRef = useRef();
	const barChartRef = useRef();
	const transcriptDownloadRef = useRef();

	const settings = JSON.parse(localStorage.getItem('settings'));

	useEffect(() => {
		if (chat.productId) {
			setReportModal(true);
		}
	}, [barData]);

	const onOpenReportModal = (e, params) => {
		e.stopPropagation();
		
		if (mediaQueryMobile.matches) {
			if (selectedRow.length === 0) {
				setSnackErr('Please select a candidate');
				handleSnackClick();
			}
			else if (selectedRow.length > 1) {
				setSnackErr('Please select only one candidate');
				handleSnackClick();
			}
			else {
				filterBarData(params);
			}
		}
		else {
			filterBarData(params);
		}
	}

	const filterBarData = (params) => {
		setChat(params.row);
		if (params.row.productId && params.row.productId.customId === 'Video') {
			setTotalScore(0);
		} else {
			setTotalScore(parseInt((params.row.test.result.total || 0) * 100));
		}

		const newBarData = { ...barData };

		newBarData.labels = [];
		newBarData.datasets[0].data = [];
		newBarData.datasets[1].data = [];

		if (params.row.test.chat.length > 0 && Object.prototype.hasOwnProperty.call(params.row.test.chat[0], 'idealqtime')) {
			const newChatArr = [];
			const cids = [];
			params.row.test.chat.forEach((item) => {
				if (!cids.includes(item.cid)) {
					cids.push(item.cid);
					newChatArr.push(item);
				}
			});

			newChatArr.forEach((item, index) => {
				newBarData.labels.push('Q' + (1 + index));
				newBarData.datasets[0].data.push(item.idealqtime);
				newBarData.datasets[1].data.push(item.userqtime);
			});
		}

		setBarData({ ...newBarData });
	}

	const onCloseReportModal = () => setReportModal(false);

	const onOpenModal = () => setModal(true);

	const onCloseModal = () => setModal(false);

	const onOpenDeleteModal = () => {
		if (mediaQueryMobile.matches && selectedRow.length > 0) {
			setDeleteModal(true);
		}
		else {
			if (selectionModel.length > 0) setDeleteModal(true);
			else {
				setSnackErr("Please select atleast one candidate");
				handleSnackClick();
			}
		}
	}

	const onCloseDeleteModal = () => setDeleteModal(false);

	const onOpenBulkMailModal = () => {
		setBulkMailModal(true);
		if (currentWalkthroughStep > 0 && currentWalkthroughType === 'sendemails') {
			updateCurrentWalkthroughStep(4);
		}
	};

	const onCloseBulkMailModal = () => {
		setBulkMailModal(false)
	};

	const nameEdit = (row) => {
		editCandidateName({ row, newName: editNameRef.current.value, history });
	}

	const handleDelete = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		});

		deleteCandidate({ id: IDs, history });
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	const sendBulkMail = function () {
		let shortlistCandidates = [];
		let rejectedCandidates = [];

		setBulkMailLoading(true);

		rows.map((val) => {
			if (val.status === "Shortlisted") shortlistCandidates.push(val);
			else if (val.status === "Rejected") rejectedCandidates.push(val);
		})

		if (switchShortlistTemp && switchRejectTemp) {
			let shortlist = axios.post('/company/workflow/candidate/shortlist', { workflowId: shortlistCandidates[0].workflow, candlist: shortlistCandidates, emailIndex: bulkMailShortlistTemp });
			let reject = axios.post('/company/workflow/candidate/reject', { workflowId: rejectedCandidates[0].workflow, candlist: rejectedCandidates, emailIndex: bulkMailRejectTemp });

			Promise.all([shortlist, reject])
				.then(() => {
					setSuccessMsg("Shortlist & Rejection Mail Sent")
					handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					redirectUnauthorisedUser(err, history);

					setSnackErr("Not able to send mail at the moment");
					handleSnackClick();
					setBulkMailLoading(false);
				});
		} else if (switchShortlistTemp && !switchRejectTemp) {
			axios.post('/company/workflow/candidate/shortlist', { workflowId: shortlistCandidates[0].workflow, candlist: shortlistCandidates, emailIndex: bulkMailShortlistTemp })
				.then(() => {
					setSuccessMsg("Shortlist Mail Sent")
					handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					redirectUnauthorisedUser(err, history);

					setSnackErr("Not able to send mail at the moment");
					handleSnackClick();
					setBulkMailLoading(false);
				});
		} else {
			axios.post('/company/workflow/candidate/reject', { workflowId: rejectedCandidates[0].workflow, candlist: rejectedCandidates, emailIndex: bulkMailRejectTemp })
				.then(() => {
					setSuccessMsg("Rejection Mail Sent")
					handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					redirectUnauthorisedUser(err, history);

					setSnackErr("Not able to send mail at the moment");
					handleSnackClick();
					setBulkMailLoading(false);
				});
		}
	}

	const onOpenShortlistMailModal = () => setShortlistMailModalOpen(true);

	const onCloseShortlistMailModal = () => setShortlistMailModalOpen(false);

	const onOpenRejectMailModal = () => setRejectMailModalOpen(true);

	const onCloseRejectMailModal = () => setRejectMailModalOpen(false);

	useEffect(() => {

		if (rows.length == 0) return;

		let temp = [];
		let shortlistCandidates = [];
		let rejectedCandidates = [];
		rows.map((val) => {
			let x = { name: val.name, email: val.email, status: val.status };
			temp.push(x);

			if (val.status === "Shortlisted") shortlistCandidates.push(val);
			else if (val.status === "Rejected") rejectedCandidates.push(val);
		})
		setShortlistedRows(shortlistCandidates);
		setRejectedRows(rejectedCandidates);
		setCSVData(temp);
	}, [rows]);

	useEffect(() => {
		if (!transcriptPdfLoaded) {
			const timer = setInterval(function () {
				if (transcriptDownloadRef.current) {
					clearInterval(timer);
					transcriptDownloadRef.current.parentNode.click();
				}
			}, 200);
		}
	}, [transcriptPdfLoaded]);

	useEffect(async () => {
		setLoading(true);
		try {
			let shortlisted = await axios.post("/gettype", { status: "shortlisted", id: location.state.workflowId });
			let rejected = await axios.post("/gettype", { status: "rejected", id: location.state.workflowId });
			let keptInView = await axios.post("/gettype", { status: "kiv", id: location.state.workflowId });

			let totalData = [...shortlisted.data, ...rejected.data, ...keptInView.data];
			totalData.forEach((candidate) => {
				if (candidate.test && candidate.test.result && Object.prototype.hasOwnProperty.call(candidate.test.result, 'total')) {
					candidate.score = candidate.test.result.total;
				} else {
					candidate.score = 0;
				}
				candidate.id = parseInt(candidate.id);
			});
			setData([...totalData]);
			setRows([...totalData]);
			setLoading(false);
		}
		catch (err) {
			console.log(err);
			setLoading(false);
			redirectUnauthorisedUser(err, history);
		}
	}, [change]);

	useEffect(() => {
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'shortlistEmail' }).then((res) => {
			setTempInv(res.data.mails);
			setTemplate(res.data.template);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		});

		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'rejectEmail' }).then((res) => {
			setTempRem(res.data.mails)
			setTemplateR(res.data.template);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		});
	}, [change, tempChange]);

	const pdfurl = process.env.REACT_APP_BASE_SERVER_URL_FULL + '/pdfreport'//`https://test.aspiringconsultants.com/api/pdfreport`//`http://localhost:5000/api/pdfreport`

	const pdfDownload = (candidate) => {
		if (candidate.productId.customId && (candidate.productId.customId.toLowerCase().includes('bcgriseconsulting') || candidate.productId.customId.toLowerCase().includes('bcgriseyoga'))) {
			setChat(candidate);
			setTranscriptPdfLoaded(true);
		}
		else {
			fetch(pdfurl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					candidate: candidate,
				}),
			},
				{ responseType: 'arraybuffer' }
			)
				.then(async res => {
					if (res.status === 200) {
						const blob = await res.blob();
						const file = new Blob([blob], { type: 'application/pdf' });
						const fileURL = URL.createObjectURL(file);

						window.open(fileURL);
					}
				})
		}
	}

	const requestSearch = (searchValue) => {
		// setSearchText(searchValue);

		if (searchValue == '') {
			setRows(data);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = data.filter((row) => {
				// return Object.keys(row).some((field) => {
				// 	return searchRegex.test(row[field].toString());
				// });
				if (searchRegex.test(row.name) || searchRegex.test(row.email)) {
					return true;
				}
			});

			setRows(filteredRows);
		}
	};

	const changeStatus = (status) => {
		if (selectedRow.length == 0) {
			handleSnackClick();
			setSnackErr('Please select atleast one candidate')
			return;
		}

		axios.post('/statusupdatebyid', { candidates: selectedRow, status: status })
			.then(() => {
				setChange(change + 1);
				setSuccessMsg("Status updated successfully");
				handleGreenSnackClick();
				setSelectionModel([]);
			})
			.catch(err => {
				console.log(err);
				redirectUnauthorisedUser(err, history);

				setSnackErr("Could not update status at the moment");
				handleSnackClick();
			});
	}

	const sendShortlistMail = function (rowSelected) {
		axios.post('/company/workflow/candidate/shortlist', { workflowId: rowSelected[0].workflow, candlist: rowSelected, emailIndex: selectedTemp })
			.then(() => {
				setSuccessMsg("Mail sent");
				handleGreenSnackClick();
			})
			.catch(err => {
				console.log(err)
				redirectUnauthorisedUser(err, history);

				setSnackErr("Not able to send mail at the moment");
				handleSnackClick();
			})
	}

	const sendRejectMail = function (rowSelected) {
		axios.post('/company/workflow/candidate/reject', { workflowId: rowSelected[0].workflow, candlist: rowSelected, emailIndex: selectedTemp })
			.then(() => {
				setSuccessMsg("Mail sent");
				handleGreenSnackClick();
			})
			.catch(err => {
				console.log(err)
				redirectUnauthorisedUser(err, history);

				setSnackErr("Not able to send mail at the moment");
				handleSnackClick();
			})
	}

	function formatScore(score, row) {
		var index = 0;
		if (row.test && row.test.result && Object.prototype.hasOwnProperty.call(row.test.result, 'total'))
			score = row.test.result.total
		else {
			if (row.productId.toLowerCase().includes('sale')) {
				if (row.test.result.dab)
					score += 0.2 * row.test.result.dab
				if (row.test.result.lpg)
					score += 0.2 * row.test.result.lpg
				if (row.test.result.stk)
					score += 0.1 * row.test.result.stk
				if (row.test.result.ema)
					score += 0.5 * row.test.result.ema
			}
			else {
				for (let keys in row.test.result) {
					score += row.test.result[keys]
					index += 1
				}
				if (index > 0) score = score / index
			}
		}
		if (score > 0) score *= 100
		return score
	}

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: windowDimensions.width / 14,
			headerAlign: 'left',
			//align: 'center',
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "5ch", width: "100%" }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: "name",
			headerName: "Name",
			width: windowDimensions.width / 8,
			//flex: 0.3,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: 16, fontWeight: "400", width: '100%' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.name}</div>
							<span className='tooltiptext datagrid'>{params.row.name}</span>
						</div>
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			width: windowDimensions.width / 8,
			//flex: 0.3,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: 16, fontWeight: "400", width: '100%' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.email}</div>
							<span className='tooltiptext datagrid'>{params.row.email}</span>
						</div>
					</div>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: windowDimensions.width / 8,
			//flex: 0.3,
			headerAlign: "center",
			//align: 'center',
			//type: 'singleSelect',
			//valueOptions: ['Shortlisted', 'Rejected', 'Kept In View'],
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "2ch", width: "100%" }}>
						{params.row.status}
					</div>
				)
			}
		},
		{
			field: "score",
			headerName: "Score",
			width: windowDimensions.width / 15,
			//minWidth: 125,
			hideColumn: (rows.length > 0 && rows[0].productId.customId === 'Video'),
			renderCell: (params) => {
				var score = 0.0;
				try {
					score = formatScore(score, params.row)
				}
				catch {
					console.log("Error in scores");
				}
				return (
					<div className="userListUser">
						{score.toFixed()}%
					</div>
				);
			},
		},
		{
			field: "report",
			headerAlign: "center",
			// align: 'center',
			headerName: "Report",
			// flex: 0.3,
			renderCell: (params) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
						{
							params.row.test && params.row.test.result && Object.prototype.hasOwnProperty.call(params.row.test.result, 'total') && !(params.row.productId.customId && (params.row.productId.customId.toLowerCase().includes('bcgriseconsulting') || params.row.productId.customId.toLowerCase().includes('bcgriseyoga'))) || (params.row.productId.customId === 'Video') ? (
								<div className="tooltip">
									<Button
										id="reviewedFlow-selector-12"
										className="productView"
										style={{
											background: '#479BD2',
											fontWeight: 'bold',
											fontFamily: "inherit",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: '5px 12px',
											marginLeft: 0
										}}
										size="small"
										variant="contained"
										onClick={(e) => onOpenReportModal(e, params)}
									>
										<span style={{ fontSize: '14px', color: '#fff' }}>Analyse</span>
									</Button>
									<span className="tooltiptext" style={{ width: 250, lineHeight: '1.5' }}>View reports, video answers, chat transcripts etc.</span>
								</div>
							) : (
								<svg
									width={24}
									height={24}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									onClick={(e) => {
										e.stopPropagation();
										pdfDownload(params.row);
									}}
									style={{ cursor: "pointer", marginRight: '1ch' }}
								>
									<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
								</svg>
							)
						}
					</div>
				)
			}
		}
	];

	if (userRole.includes('Admin')) {
		columns.push(
			{
				field: "action",
				headerName: "Action",
				width: windowDimensions.width / 8,
				// flex: 0.3,
				headerAlign: "center",
				// align: 'center',
				renderCell: (params) => {
					return (
						<>
							{params.row.status == 'Shortlisted' ?
								<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Button
										id="reviewedFlow-selector-8"
										className="productView"
										style={{
											background: '#479BD2',
											fontWeight: 'bold',
											fontFamily: "inherit",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: '9px 12px',
										}}
										size="small"
										variant="contained"
										onClick={(e) => {
											e.stopPropagation();
											onOpenShortlistMailModal();
											setSelectedRow([params.row]);
										}}
									>
										<span style={{ fontSize: '12px', color: '#fff' }}>Send Shortlist Mail</span>
									</Button>
								</div>
								:
								<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Button
										id="reviewedFlow-selector-17"
										className="productView"
										style={{
											background: '#479BD2',
											fontWeight: 'bold',
											fontFamily: "inherit",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: '9px 12px',
										}}
										size="small"
										variant="contained"
										onClick={(e) => {
											e.stopPropagation();
											onOpenRejectMailModal();
											setSelectedRow([params.row]);
										}}
									>
										<span style={{ fontSize: '12px', color: '#fff' }}>Send Rejection Mail</span>
									</Button>
								</div>
							}
						</>
					);
				}
			}
		)
	}

	if (settings) {
		if (settings.transcript)
			columns.splice(5, 0,
				{
					field: "chat",
					headerName: "Chat",
					width: 120,
					headerAlign: "center",
					// align: 'center',
					// flex: 0.3,
					renderCell: (params) => {
						return (
							<>
								<Button
									className="productView"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "inherit",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
										marginRight: '5ch'
									}}
									size="small"
									variant="contained"
									onClick={(e) => { setChat(params.row); onOpenModal(); e.stopPropagation(); }}
								>
									<span style={{ fontSize: '14px', color: '#fff' }}>View</span>
								</Button>
							</>
						);
					}
				})
	}

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);

		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
		else {
			let temp = [];

			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...rows.filter((row) => selectionModel[i] == row.id))
			}

			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (mediaQueryMobile.matches) {
			if (selectedRow.length > 1 || selectedRow.length === 0) {
				setSnackErr('Please select a candidate');
				handleSnackClick();
			}
			else {
				setEditModal(true);
			}
		}
		else {
			if (selectionModel.length == 1) setEditModal(true);
			else {
				setSnackErr('Please select atleast one candidate')
				handleSnackClick();
			}
		}
	}

	const onCloseEditModal = () => setEditModal(false);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setGreenSnackOpen(false);
		setSuccessMsg(null);
	};

	useEffect(() => {
		if (selectedRow.length >= 1) {
			let status = selectedRow[0].status;

			for (let i = 1; i < selectedRow.length; i++) {
				if (status !== selectedRow[i].status) {
					setBulkStatus(null);
					return;
				}
			}

			setBulkStatus(status);
		}
		else {
			setBulkStatus(null);
		}
	}, [selectedRow]);

	const handleExpandClick = index => {
		if (expanded === index) {
			setExpanded("");
		} else {
			setExpanded(index);
		}
	}

	const getCandVideo = (key) => {
		axios.post('/cand-video', { key }).then((res) => {
			window.open(res.data.signedUrl);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		})
	}

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					className="emptyRowImg"
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}

	const MenuItems = [
		{
			name: 'Edit',
			action: () => {
				onOpenEditModal();
			}
		},
		{
			name: 'Delete',
			action: () => {
				onOpenDeleteModal();
			}
		},
		{
			name: 'Analyse',
			action: () => onOpenReportModal(null, { row: selectedRow[0] })
		},
		{
			name: 'Mark as',
			action: () => {
				setBottomDrawer({
					open: true,
					name: 'mark'
				});
			}
		},
		{
			name: 'Send email',
			action: () => {
				setBottomDrawer({
					open: true,
					name: 'email'
				});
			}
		},
		{
			name: 'Export',
			CSVData: CSVData
		}
	]

	const handleSelectApplicant = (row) => {
		if (selectedRow.length !== 0 && selectedRow.some(el => el._id === row._id)) {
			setSelectedRow(selectedRow.filter(el => el._id !== row._id));
		}
		else {
			setSelectedRow([...selectedRow, row]);
		}
	}

	useEffect(() => {
		if (!location.state.workflowName) {
			fetchDriveDetails({ workflowId: location.state.workflowId, history });
		}
		fetchSkillCodes();
	}, []);

	useEffect(() => {
		const wfKeyArr = [];
		const newRwf = {};
		Object.keys(reportWorkFunctions).forEach((rwf) => {
			wfKeyArr.push(rwf);
			newRwf[rwf] = { ...reportWorkFunctions[rwf] };
		});
		Object.keys(skillCodeList).forEach((scl) => {
			if (!wfKeyArr.includes(scl)) {
				newRwf[scl] = { ...skillCodeList[scl] };
			}
		});
		setWorkFunctions({ ...newRwf });
	}, [skillCodeList]);

	const tempInvObjArr = tempInv?.length &&
		tempInv.map((con, index) => {
			return {
				label: con.name,
				value: index,
			}
		}) || []

	const tempRemObjArr = tempRem?.length &&
		tempRem.map((con, index) => {
			return {
				label: con.name,
				value: index,
			}
		}) || []

	return (
		<div className="productList finalizedContainer">
			{mediaQueryMobile.matches && <Header MenuItems={MenuItems} />}
			<div className="inner-div" style={{ marginBottom: '20px' }}>
				<div className='top-icon-box'>
					<WalkthroughWelcome />
					<div className="tooltip">
						<IconButton aria-label="edit" onClick={onOpenEditModal} id="reviewedFlow-selector-3">
							<img src={Edit} alt="" width={30} height={30} />
						</IconButton>
						<span className="tooltiptext">Edit</span>
					</div>
					<div className="tooltip">
						<IconButton onClick={() => onOpenDeleteModal()} aria-label="delete" id="reviewedFlow-selector-6">
							<img src={Trash} alt="" width={30} height={30} />
						</IconButton>
						<span className="tooltiptext">Delete</span>
					</div>
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			{
				chat.test && chat.test.result && Object.prototype.hasOwnProperty.call(chat.test.result, 'total') && !(chat.productId && chat.productId.customId && (chat.productId.customId.toLowerCase().includes('bcgriseconsulting') || chat.productId.customId.toLowerCase().includes('bcgriseyoga'))) ? (
					<>
						{
							chat.productId && chat.productId.customId === 'Video' ? '' : (
								<Doughnut ref={doughnutRef} data={doughnutData(totalScore)} options={doughnutOptions} style={{ width: 100, height: 100, display: 'none' }} />
							)
						}
						{
							barData.labels.length > 0 ? (
								<Bar ref={barChartRef} data={barData} options={barData.options} style={{ display: 'none' }} />
							) : ''
						}
					</>
				) : ''
			}
			{
				chat.productId && chat.productId.customId && (chat.productId.customId.toLowerCase().includes('bcgriseconsulting') || chat.productId.customId.toLowerCase().includes('bcgriseyoga')) ? (
					<div style={{ display: 'none' }}>
						<PDFDownloadLink document={<PdfTranscript candidateData={chat} />} fileName={chat.name ? `${chat.name.split(' ').join('_')}_${location.state.workflowName.split(' ').join('_')}.pdf` : 'report.pdf'}>
							{({ loading }) => {
								if (loading) {
									return (
										<div>Loading...</div>
									)
								} else {
									setTranscriptPdfLoaded(false);
									return (
										<div className="tooltip">
											<div ref={transcriptDownloadRef}>Download</div>
											<span className="tooltiptext">Download candidate report</span>
										</div>
									)
								}
							}
							}
						</PDFDownloadLink>
					</div>
				) : ''
			}
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to delete this candidate?
				</p>
				<div className="button-div">
					<Button
						id="reviewedFlow-selector-7"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
							handleDelete(selectedRow)
						}}>
						Yes
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
						}}>
						No
					</Button>
				</div>
			</Modal>
			<Modal open={bulkMailModal} onClose={onCloseBulkMailModal} center classNames={{ modal: 'invitationModal' }}>
				<div style={{ zIndex: currentWalkthroughStep === 4 && currentWalkthroughType === 'sendemails' ? 1000 : 1, position: 'relative', background: '#fff' }}>
					<div className="modal-heading-workflow">
						Send email to
					</div>
					<div style={{ marginTop: 20, minWidth: 400 }}>
						{
							(shortlistedRows.length > 0 && tempInv.length > 0) || (currentWalkthroughStep === 4 && currentWalkthroughType === 'sendemails') ? (
								<>
									<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
										<p>All shortlisted candidates</p>
										<Switch checked={switchShortlistTemp} onChange={(e) => { setSwitchShortlistTemp(e.target.checked) }} />
									</div>
									<div style={{ minHeight: 90 }}>
										{switchShortlistTemp || (currentWalkthroughStep === 4 && currentWalkthroughType === 'sendemails') ?
											(<div style={{ margin: "15px 0", borderRadius: 25 }}>
												<Select
													label="Shortlist template"
													selectedValue={tempInvObjArr.find(e => e.value === bulkMailShortlistTemp)?.label}
													onChangeSelect={(val) => setBulkMailShortlistTemp(val)}
													styles={{
														root: {
															borderRadius: 25,
															border: '1px solid #bbb',
															width: '100%',
															padding: '0.3rem 0'
														},
														wrapper: {
															borderBottom: 'unset'
														}
													}}
													dropdownData={tempInvObjArr}
												/>
											</div>)
											: ""}
									</div>
								</>
							) : ''
						}
						{
							(rejectedRows.length > 0 && tempRem.length > 0) || (currentWalkthroughStep === 4 && currentWalkthroughType === 'sendemails') ? (
								<>
									<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
										<p>All rejected candidates</p>
										<Switch checked={switchRejectTemp} onChange={(e) => { setSwitchRejectTemp(e.target.checked) }} />
									</div>
									<div style={{ minHeight: 90 }}>
										{switchRejectTemp || (currentWalkthroughStep === 4 && currentWalkthroughType === 'sendemails') ?
											(<div style={{ margin: "15px 0", borderRadius: 25 }}>
												<Select
													label="Reject template"
													selectedValue={tempRemObjArr.find(e => e.value === bulkMailRejectTemp)?.label}
													onChangeSelect={(val) => setBulkMailRejectTemp(val.value)}
													styles={{
														root: {
															borderRadius: 25,
															border: '1px solid #bbb',
															width: '100%',
															padding: '0.3rem 0'
														},
														wrapper: {
															borderBottom: 'unset'
														}
													}}
													dropdownData={tempRemObjArr}
												/>
											</div>)
											: ""}
									</div>
								</>
							) : ''
						}
					</div>
					<div className="button-div">
						<Button
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "inherit",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: "6px 20px",
								fontSize: '16px',
							}}
							size="small"
							variant="contained"
							disabled={(bulkMailShortlistTemp == null && bulkMailRejectTemp == null) || bulkMailLoading}
							onClick={sendBulkMail}>
							{bulkMailLoading ? 'Loading' : 'Send'}
						</Button>
					</div>
				</div>
				{
					currentWalkthroughStep === 4 && currentWalkthroughType === 'sendemails' ? (
						<WalkthroughOverlay
							showWalkthroughNextBtn={true}
							onClickDone={() => { updateCurrentWalkthroughStep(nextWalkthroughStep); setNextWalkthroughStep(nextWalkthroughStep + 1); }}
						/>
					) : ''
				}
			</Modal>
			<div className="userList">
				<div className="userListTitleContainer">
					<div className="inner-div-details" >
						<div>
							<h2>Shortlisted/Rejected</h2>
							<p style={{ wordBreak: 'break-all' }}>{location.state.workflowName}</p>
						</div>
						{mediaQueryMobile.matches && <p>Total : {rows.length}</p>}
					</div>
					<div className="search-box" style={{ width: "35vw" }}>
						<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
					</div>
					{
						userRole.includes('Admin') ? (
							<div className="reviewIconBox">
								{
									(shortlistedRows.length > 0 && tempInv.length > 0) || (rejectedRows.length > 0 && tempRem.length > 0) || (currentWalkthroughStep === 3 && currentWalkthroughType === 'sendemails') ? (
										<div className="tooltip">
											<IconButton
												style={{
													zIndex: (currentWalkthroughStep === 3 && currentWalkthroughType === 'sendemails') ? 1000 : 1,
													position: 'relative',
													background: '#fff'
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													width={24}
													height={24}
													strokeWidth={1.5}
													stroke="currentColor"
													onClick={onOpenBulkMailModal}
													className="check-btn"
												>
													<path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
												</svg>
											</IconButton>
											<span className="tooltiptext">Send bulk emails</span>
										</div>
									) : ''
								}
								<div className="tooltip" style={{ pointerEvents: bulkStatus === 'Shortlisted' ? 'none' : 'unset' }}>
									<IconButton>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											width={24}
											height={24}
											strokeWidth={1.5}
											stroke="currentColor"
											onClick={() => changeStatus('Shortlisted')}
											className="check-btn"
										>
											<path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
										</svg>
									</IconButton>
									<span className="tooltiptext">Shortlist</span>
								</div>
								<div className="tooltip" style={{ pointerEvents: bulkStatus === 'Rejected' ? 'none' : 'unset' }}>
									<IconButton>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											width={24}
											height={24}
											strokeWidth={1.5}
											stroke="currentColor"
											onClick={() => changeStatus('Rejected')}
											className="cancel-btn"
										>
											<path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
										</svg>
									</IconButton>
									<span className="tooltiptext">Reject</span>
								</div>
								<div className="tooltip" style={{ pointerEvents: bulkStatus === 'Kept In View' ? 'none' : 'unset' }}>
									<IconButton>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											width={24}
											height={24}
											strokeWidth={1.5}
											stroke="currentColor"
											onClick={() => changeStatus('Kept In View')}
											className="visibility-btn"
										>
											<path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
											<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
										</svg>
									</IconButton>
									<span className="tooltiptext">Keep in view</span>
								</div>
							</div>
						) : ''
					}
					<div className="export-btn">
						<div className="tooltip">
							<Button
								disabled={!CSVData.length}
								style={{
									background: '#479BD2',
									fontWeight: 'bold',
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: "8px 20px",
								}}
								size="small"
								variant="contained"
							>
								<CSVLink filename="Data.csv" data={CSVData} target="_blank" style={{ color: "white", textDecoration: "none", fontSize: '16px', whiteSpace: 'nowrap' }} id="reviewedFlow-selector-2">
									Export <img src={Subtract} alt="" style={{ height: "18px", width: "18px", transform: `translate(3px, 4px)` }} />
								</CSVLink>
							</Button>
							<span className="tooltiptext">Export report</span>
						</div>
					</div>
				</div>
				{!mediaQueryMobile.matches &&
					<div className="cards-container">
						<div className="cards">
							<div className='add-card-container'>
								<div className='add-card-wrapper'>
									{isOpen ? <AddShortlistEmail type="shortlistEmail" index={1} field={""} /> : null}
									{isOpen ? <AddRejectEmail type="rejectEmail" index={1} field={""} /> : null}

									{tempp ? <AddShortlistEmail type="shortlistEmail" index={tempInv.length} field={tempp} tempChange={tempChange} setTempChange={setTempChange} /> : null}
									{tempr ? <AddRejectEmail type="rejectEmail" index={tempRem.length} field={tempr} tempChange={tempChange} setTempChange={setTempChange} /> : null}
								</div>
								<div className='upload-title'>
									<h3>Add templates</h3>
								</div>
							</div>
							{isOpen ?
								<UploadShortlist
									type="shortlistEmail"
									isDefault={false}
									index={1}
									field={{
										"name": "Invitation Template",
										"image": "default.png",
										"subject": "Invitation to HireQuotient Online Assessment",
										"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
										"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
										"button": "Let's Begin",
										"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
									}} /> : null}
							{isOpen ?
								<UploadReject
									type="rejectEmail"
									isDefault={false}
									index={1}
									field={{
										"name": "Invitation Template",
										"image": "default.png",
										"subject": "Invitation to HireQuotient Online Assessment",
										"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
										"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
										"button": "Let's Begin",
										"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
									}} /> : null}

							{tempInv?.length > 0 ?
								tempInv.map((temp, index) => {
									return (<UploadShortlist key={index} type="shortlistEmail" tempChange={tempChange} setTempChange={setTempChange} isDefault={false} index={index} field={temp} />)
								}) : null}
							{tempRem?.length > 0 ?
								tempRem.map((temp, index) => {
									return (<UploadReject key={index} type="rejectEmail" tempChange={tempChange} setTempChange={setTempChange} isDefault={false} index={index} field={temp} />)
								}) : null}
						</div>
					</div>
				}
				{!mediaQueryMobile.matches && bulkStatus && bulkStatus !== 'Kept In View' && selectionModel.length > 0 ?
					<div style={{ background: "lightgrey", minHeight: "6vh", padding: "1vh 20px 1vh", display: "flex", justifyContent: "space-between", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							{bulkStatus == 'Shortlisted' ? <Button size="small" variant="contained" onClick={onOpenShortlistMailModal} style={{ ...buttonStyle }}>Send Shortlist Mail</Button> : ''}
							{bulkStatus == "Rejected" ? <Button size="small" variant="contained" onClick={onOpenRejectMailModal} style={{ ...buttonStyle }}>Send Reject Mail</Button> : ''}
						</div>
					</div> : ""}
				{mediaQueryMobile.matches &&
					<div className='new-applicant-box__content'>
						{rows.length === 0 ?
							<>
								<img
									src={emptyRowImg}
									alt="no rows"
									style={{
										height: "auto",
										width: "100%"
									}}
								/>
								<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E", textAlign: "center" }}>No data to show</p>
							</> : null}
						{rows.map((row, index) => (
							<div key={index} className='applicant-box__row' onClick={() => handleSelectApplicant(row)}>
								<div className='applicant-box__row-left'>
									<div className="applicant-box__row-left-image-box">
										<Avatar
											name={parseInt(row.id)}
											styles={{
												text: {
													backgroundColor: "#EBEBEB",
													color: "#404040",
												}
											}}
										/>
										{selectedRow.length !== 0 && selectedRow.some(el => el._id === row._id) && <img src={TickBlueIcon} alt="right-blue-icon" />}
									</div>
									<div className="applicant-box-row-left-text-box">
										<p className='applicant-box__row-left-text-name'>{row.name}</p>
										<p className='applicant-box__row-left-text-role'>{row.email}</p>
									</div>
								</div>
								<div className='applicant-box__row-right'>
									<p className='applicant-box__row-right-score'>{formatScore(0.0, row).toFixed()}%</p>
									<p className="applicant-box__row-left-text-role">{row.status}</p>
								</div>
							</div>
						))}
					</div>
				}
				{!mediaQueryMobile.matches &&
					<DataTable
						loading={loading}
						noRowsComponent={<NoRowsOverlay />}
						rows={
							!isOpen ? rows :
								[{
									"_id": "618606360984b02fca8869ab",
									"name": "Nancy Brown",
									"email": [
										"nancy534@gmail.com"
									],
									"id": "18",
									"code": "2a104GcAi8JsoK4HFFTGhCX5TSWQmKOEZThjJW8KiU0sLRb4JOQLOioO",
									"status": "Shortlisted",
									"lastActive": "2021-11-06T04:40:34.716Z",
									"createdDate": "2021-11-06T04:36:06.803Z",
									"company": "61730080f94921c9089ea99f",
									"workflow": "6178704387c9e1c238ae4f58",
									"__v": 0,
									"problem": "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
									"productId": "ChatId4",
									"title": "HR Evaluative"
								}]
						}
						columns={columns}
						checkboxSelection={userRole.includes('Admin')}
						onSelectionModelChange={(newSelectionModel) => {
							console.log(newSelectionModel);
							setSelectionModel(newSelectionModel);
						}}
						selectionModel={selectionModel}
						pageSize={15}
					/>
				}
			</div>
			<Modal open={shortlistMailModalOpen} onClose={onCloseShortlistMailModal} classNames={{ modal: 'customModal-workflow' }}>
				<div className="modal-heading-workflow">
					Customise your shortlist mail
				</div>
				<Select
					id="reviewedFlow-selector-9"
					label="Select template for shortlisting"
					selectedValue={tempInvObjArr.find(e => e.value === selectedTemp)?.label}
					onChangeSelect={(val) => setSelectedTemp(val.value)}
					styles={{
						root: {
							width: 380,
							height: 45,
							margin: '20px 0',
							borderRadius: 30,
							padding: '0.3rem 0',
							border: '1px solid #bbb',
						},
						wrapper: {
							borderBottom: 'unset'
						}
					}}
					dropdownData={tempInvObjArr}
					dropdownId="reviewedFlow-selector-10"
				/>
				<div className="button-div">
					<Button
						id="reviewedFlow-selector-11"
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
						}}
						onClick={() => {
							sendShortlistMail(selectedRow);
							onCloseShortlistMailModal();
						}}
					>
						Send Mail
					</Button>
					<Button
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
						}}
						onClick={onCloseShortlistMailModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>
			<Modal open={rejectMailModalOpen} onClose={onCloseRejectMailModal} classNames={{ modal: 'customModal-workflow' }}>
				<div className="modal-heading-workflow">
					Customise your rejection mail
				</div>
				<Select
					id="reviewedFlow-selector-13"
					label="Select template for rejection"
					selectedValue={tempRemObjArr.find(e => e.value === selectedTemp)?.label}
					onChangeSelect={(val) => setSelectedTemp(val.value)}
					styles={{
						root: {
							width: 380,
							height: 45,
							margin: '20px 0',
							borderRadius: 30,
							padding: '0.3rem 0',
							border: '1px solid #bbb',
						},
						wrapper: {
							borderBottom: 'unset'
						}
					}}
					dropdownData={tempRemObjArr}
					dropdownId="reviewedFlow-selector-14"
				/>
				<div className="button-div">
					<Button
						id="reviewedFlow-selector-15"
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
						}}
						onClick={() => {
							sendRejectMail(selectedRow);
							onCloseRejectMailModal();
						}}
					>
						Send Reject Mail
					</Button>
					<Button
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
						}}
						onClick={onCloseRejectMailModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>
			<AnalyseModal
				open={reportModal}
				onClose={onCloseReportModal}
				chat={chat}
				changeStatus={changeStatus}
				doughnutRef={doughnutRef}
				barChartRef={barChartRef}
				isOpen={isOpen}
				change={change}
				setChange={setChange}
				driveDetails={driveDetails}
				workFunctions={workFunctions}
			/>
			<Modal open={modal} onClose={onCloseModal} center classNames={{ modal: 'customModalChats' }} styles={{ modal: { width: '100%' } }}>
				<div className="container-team" style={{ padding: "0 20px 20px", margin: "0" }}>
					<div className="heading-team" style={{ margin: "0 0 20px 0" }}>
						<div style={{ fontSize: "24px", fontWeight: "700", color: "#479BD2" }}>
							Chat Report : <span style={{ fontWeight: "400" }}>{chat.name}</span>
						</div>
					</div>
					<div style={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden", padding: "10px" }}>
						{
							chat?.test?.chat?.length > 0 ? chat.test.chat.map((row, index) => {
								return <div className="row-team" key={index}>
									<div style={{ display: "flex", marginBottom: "15px" }}>
										<p style={{ flex: "1", fontWeight: "600" }}>
											Q.{index + 1} &nbsp; {(Array.isArray(row.ques) ? row.ques[row.ques.length - 1].split('***::')[1] : row.ques.split('***::')[row.ques.split('***::').length - 1])}
										</p>
										<div style={{ width: "50px" }}>
											<ExpandMore
												expand={index === expanded}
												onClick={() => { handleExpandClick(index) }}
												aria-expanded={index === expanded}
												aria-label="expand"
											>
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={24} height={24}>
													<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
												</svg>
											</ExpandMore>
										</div>
									</div>
									<Collapse in={index === expanded} timeout="auto">
										{(typeof row.ans === 'string' || row.ans instanceof String) ? <div>
											<b>Answer- </b> {row.ans.includes('.mp4') || row.ans.includes('.webm') ? <a rel='noreferrer' href="javascript:void(0)" target="_blank" onClick={(e) => { e.preventDefault(); getCandVideo(row.ans) }}>Click Here</a> : row.ans.replaceAll('::', ". ")}
										</div> : null}
									</Collapse>
								</div>
							}) : "No Chat"
						}
					</div>
				</div>
			</Modal>
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit candidate&apos;s name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="reviewedFlow-selector-4"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="reviewedFlow-selector-5"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						<span style={{ fontSize: '16px' }}>Ok</span>
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						<span style={{ fontSize: '16px' }}>Cancel</span>
					</Button>
				</div>
			</Modal>
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose} msg={snackErr ? snackErr : ''} type="error" />
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose} msg={successMsg ? successMsg : ''} type="success" />
			{mediaQueryMobile.matches && <BottomBar emailTemplate={emailTemplateOpen} setEmailTemplate={setEmailTemplateOpen} />}
			{mediaQueryMobile.matches &&
				<Drawer
					anchor="bottom"
					open={bottomDrawer.open}
					onClose={() => {
						setBottomDrawer({ open: false });
					}}
					PaperProps={{
						style: {
							padding: '1rem 20px',
							borderTopLeftRadius: '20px',
							borderTopRightRadius: '20px',
						}
					}}
				>
					<div className="bottomDrawer">
						<div className="bottomDrawer__img-box">
							<img src={Cancel} alt="cancel-icon" width={20} height={20} onClick={() => setBottomDrawer({ open: false })} />
						</div>
						{bottomDrawer.name === 'mark' &&
							<>
								<div className="bottomDrawer__checkbox-grp">
									<input type="checkbox" id="shortlisted" checked={checkboxOptions.shortlist} onChange={() => {
										setCheckboxOptions({ shortlist: !checkboxOptions.shortlist, reject: false, interview: false })
									}} />
									<label htmlFor="shortlisted">Shortlist</label>
								</div>
								<div className="bottomDrawer__checkbox-grp">
									<input type="checkbox" id="rejected" checked={checkboxOptions.reject} onChange={() => {
										setCheckboxOptions({ reject: !checkboxOptions.reject, shortlist: false, interview: false })
									}} />
									<label htmlFor="rejected">Reject</label>
								</div>
								<div className="bottomDrawer__checkbox-grp">
									<input type="checkbox" id="keptinview" checked={checkboxOptions.keptInView} onChange={() => {
										setCheckboxOptions({ keptInView: !checkboxOptions.keptInView, shortlist: false, reject: false })
									}} />
									<label htmlFor="keepinview">Keep in view</label>
								</div>

								<Button
									className="btn-primary"
									onClick={() => {
										if (checkboxOptions.shortlist) {
											changeStatus('Shortlisted');
										} else if (checkboxOptions.reject) {
											changeStatus('Rejected');
										}
										else {
											changeStatus('Kept in view');
										}
										setBottomDrawer({ open: false });
									}}
									disabled={!checkboxOptions.shortlist && !checkboxOptions.reject && !checkboxOptions.keptInView}
									id="quickViewFlow-selector-8"
								>
									Mark
								</Button>
							</>
						}
						{bottomDrawer.name === 'email' &&
							<>
								<div className="modal-heading-workflow">
									Customise your shortlist mail
								</div>
								<br />
								<Select
									id="reviewedFlow-selector-9"
									label="Select template for shortlisting"
									selectedValue={tempInvObjArr.find(e => e.value === selectedTemp)?.label}
									onChangeSelect={(val) => setSelectedTemp(val.value)}
									styles={{
										root: {
											width: "100%",
											height: 45,
											borderRadius: 20,
											border: '1px solid #bbb',
											margin: '0.5rem 0'
										},
										wrapper: {
											borderBottom: 'unset'
										}
									}}
									dropdownData={tempInvObjArr}
									dropdownId="reviewedFlow-selector-10"
								/>
								<div className="button-div">
									<Button
										id="reviewedFlow-selector-11"
										style={{
											background: '#479BD2',
											margin: '0 0 0 10px',
											fontWeight: 'bold',
											fontFamily: "inherit",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: "8px 20px",
											color: '#fff',
										}}
										onClick={() => {
											if (selectedRow.length === 0) {
												setSnackErr('Please select atleast one candidate');
												handleSnackClick();
												setBottomDrawer({ open: false });
											}
											else {
												sendShortlistMail(selectedRow);
												setCheckboxOptions({ shortlist: false, reject: false, keptInView: false });
												setBottomDrawer({ open: false });
											}
										}}
									>
										Send Mail
									</Button>
									<Button
										style={{
											background: '#479BD2',
											margin: '0 10px',
											fontWeight: 'bold',
											fontFamily: "inherit",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: "8px 20px",
											color: '#fff',
										}}
										onClick={() => {
											setBottomDrawer({ open: false });
										}}
									>
										Cancel
									</Button>
								</div>
							</>
						}
					</div>
				</Drawer>
			}
			<EmailTemplate
				show={emailTemplateOpen}
				close={() => setEmailTemplateOpen(false)}
				shortlistMails={tempInv}
				rejectMails={tempRem}
				tempChange={tempChange}
				setTempChange={setTempChange}
			/>
			{
				currentWalkthroughStep > 0 && currentWalkthroughStep < 4 && currentWalkthroughType === 'sendemails' ? (
					<WalkthroughOverlay
						showWalkthroughNextBtn={true}
						onClickDone={
							() => {
								if (nextWalkthroughStep <= 3) {
									updateCurrentWalkthroughStep(nextWalkthroughStep);
									setNextWalkthroughStep(nextWalkthroughStep + 1);
								} else {
									if (shortlistedRows.length === 0 && tempInv.length === 0) {
										setBulkMailModal(false);
									}
									updateCurrentWalkthroughStep(0);
									updateCurrentWalkthroughType('');
									let wArr = [];
									if (localStorage.getItem('walkthroughFlows')) {
										wArr = JSON.parse(localStorage.getItem('walkthroughFlows'));

									}
									wArr.push('sendemails');
									localStorage.setItem('walkthroughFlows', JSON.stringify(wArr));
									updateWalkthroughFlows(wArr);
								}
							}
						}
					/>
				) : ''
			}
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	rerender: getRerender(),
	userRole: getUserRole(),
	currentWalkthroughStep: getCurrentWalkthroughStep(),
	currentWalkthroughType: getCurrentWalkthroughType(),
	skillCodeList: getSkillCodeList(),
	driveDetails: getDriveDetails(),
});

const mapDispatchToProps = (dispatch) => ({
	editCandidateName: (data) => dispatch(editCandidateNameRequest(data)),
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	resetRerender: () => dispatch(resetRerenderRequest()),
	deleteCandidate: (data) => dispatch(deleteCandidateRequest(data)),
	updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
	updateCurrentWalkthroughType: (data) => dispatch(updateCurrentWalkthroughTypeRequest(data)),
	updateWalkthroughFlows: (data) => dispatch(updateWalkthroughFlowsRequest(data)),
	fetchDriveDetails: (data) => dispatch(fetchDriveDetailsRequest(data)),
	fetchSkillCodes: (data) => dispatch(fetchSkillCodesRequest(data)),
});

ReviewedApplicants.propTypes = {
	editCandidateName: PropTypes.func,
	resetRerender: PropTypes.func,
	deleteCandidate: PropTypes.func,
	rerender: PropTypes.number,
	notification: PropTypes.object,
	userRole: PropTypes.string,
	currentWalkthroughStep: PropTypes.number,
	updateCurrentWalkthroughStep: PropTypes.func,
	currentWalkthroughType: PropTypes.string,
	updateCurrentWalkthroughType: PropTypes.func,
	updateWalkthroughFlows: PropTypes.func,
	fetchSkillCodes: PropTypes.func,
	skillCodeList: PropTypes.object,
	fetchDriveDetails: PropTypes.func,
	driveDetails: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewedApplicants);
