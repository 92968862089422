import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../common/Button';
import Select from '../common/Select';

import DeleteIcon from '../../assets/Icons/Trash-prescreen.png';

const EditPreScreeningModal = (props) => {
	const { editBotModal, onCloseEditBotModal, editPreScreeningBot, setSnackSuccessMsg, handleSuccessSnackClick, selectedRow, setSnackErrMsg, handleErrSnackClick } = props;

	const history = useHistory();

	const [editBotName, setEditBotName] = useState('');
	const [editAboutBot, setEditAboutBot] = useState('');
	const mediaQueryMobile = window.matchMedia('(max-width:768px)');
	const [editBotQuestionData, setEditBotQuestionData] = useState([]);

	const onChangeQuestionText = (e, index) => {
		const newEditBotQuestionData = [...editBotQuestionData];
		newEditBotQuestionData[index].question = e.target.value;
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	const onChangeQuestionType = (val, index) => {
		const newEditBotQuestionData = [...editBotQuestionData];
		newEditBotQuestionData[index].questionType = val;
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	const handleOptionCheck = (e, j, i) => {
		const newEditBotQuestionData = [...editBotQuestionData];
		newEditBotQuestionData[i].options[j].correct = e.target.checked;
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	const handleOptionKeyDown = (e, index) => {
		if (e.key === 'Enter') {
			const newEditBotQuestionData = [...editBotQuestionData];
			const newOptionObj = {
				text: e.target.value,
				correct: false
			}
			newEditBotQuestionData[index].options.push(newOptionObj);
			setEditBotQuestionData([...newEditBotQuestionData]);
			e.target.value = '';
		}
	}

	const handleOptionKeyDownAdded = (e, index, j) => {
		if (e.key === 'Enter') {
			const newAddBotQuestionData = [...editBotQuestionData];
			newAddBotQuestionData[index].options[j].text = e.target.value;
			setEditBotQuestionData([...newAddBotQuestionData]);
		}
	}

	const onChangeOption = (e, index, j) => {
		const newAddBotQuestionData = [...editBotQuestionData];
		newAddBotQuestionData[index].options[j].text = e.target.value;
		setEditBotQuestionData([...newAddBotQuestionData]);
	}

	const editNewQuestion = () => {
		const editBotQuestion = {
			question: '',
			questionType: '',
			options: []
		};
		const newEditBotQuestionData = [...editBotQuestionData];
		newEditBotQuestionData.push(editBotQuestion);
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	const onEditPreScreeningBot = () => {
		if (editBotName === '') {
			setSnackErrMsg('Enter valid Name');
			handleErrSnackClick();
		} else if (checkQuestionsEmpty()) {
			setSnackErrMsg('Enter valid questions');
			handleErrSnackClick();
		} else {
			const finalEditBotQuestionData = [];
			editBotQuestionData.forEach((abqd) => {
				if (abqd.questionType === '' && abqd.question === '' && abqd.options.length === 0) {
					//continue;
				} else {
					finalEditBotQuestionData.push(abqd);
				}
			});

			const botData = {
				botId: selectedRow._id,
				name: editBotName,
				about: editAboutBot,
				questionData: finalEditBotQuestionData
			};

			editPreScreeningBot({ botData, history });
			onCloseEditBotModal();
			setSnackSuccessMsg('Pre-Screening Bot Added Successfully')
			handleSuccessSnackClick();
		}
	}

	const checkQuestionsEmpty = () => {
		let emptyQuestionField = false;
		if (editBotQuestionData.length > 1) {
			editBotQuestionData.forEach((abqd, i) => {
				if (i < editBotQuestionData.length - 1) {
					if (abqd.questionType === '' || abqd.question === '') emptyQuestionField = true;
					if (abqd.questionType.value === 'singleAnswerMcq' || abqd.questionType.value === 'multiAnswerMcq') {
						if (abqd.options.length === 0) emptyQuestionField = true;
						else {
							abqd.options.forEach((option) => {
								if (option.text === '') emptyQuestionField = true;
							});
						}
					}
				}
			});
			if (!emptyQuestionField) {
				const lastQ = editBotQuestionData[editBotQuestionData.length - 1];
				let lastQEmpty = true;
				if (lastQ.questionType === '' && lastQ.question === '') {
					// lastQ.options.forEach((option) => {
					// 	if(option.text !== '') {
					// 		lastQEmpty = false;
					// 	}
					// });
					lastQEmpty = true;
				}
				else if (lastQ.questionType.value === 'singleAnswerMcq' || lastQ.questionType.value === 'multiAnswerMcq') {
					if (lastQ.options.length === 0) emptyQuestionField = true;
					else {
						lastQ.options.forEach((option) => {
							if (option.text === '') emptyQuestionField = true;
						});
					}
				} else {
					lastQEmpty = false;
				}
				if (!lastQEmpty) {
					if (lastQ.questionType === '' || lastQ.question === '') emptyQuestionField = true;
					if (lastQ.questionType.value === 'singleAnswerMcq' || lastQ.questionType.value === 'multiAnswerMcq') {
						if (lastQ.options.length === 0) emptyQuestionField = true;
						else {
							lastQ.options.forEach((option) => {
								if (option.text === '') emptyQuestionField = true;
							});
						}
					}
				}

			}
		} else {
			editBotQuestionData.forEach((abqd) => {
				if (abqd.questionType === '' || abqd.question === '') emptyQuestionField = true;
				if (abqd.questionType.value === 'singleAnswerMcq' || abqd.questionType.value === 'multiAnswerMcq') {
					if (abqd.options.length === 0) emptyQuestionField = true;
					else {
						abqd.options.forEach((option) => {
							if (option.text === '') emptyQuestionField = true;
						});
					}
				}
			});
		}
		return emptyQuestionField;
	}

	const deleteNewQuestion = (index) => {
		const newEditBotQuestionData = [...editBotQuestionData];
		if (index === editBotQuestionData.length - 1) {
			const editBotQuestion = {
				question: '',
				questionType: '',
				options: []
			};
			newEditBotQuestionData.pop();
			newEditBotQuestionData.push(editBotQuestion);
		} else {
			newEditBotQuestionData.splice(index, 1);
		}
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	useEffect(() => {
		if (editBotModal) {
			setEditBotName(selectedRow.name);
			setEditAboutBot(selectedRow.about);
			setEditBotQuestionData([...selectedRow.questionData]);
		}
	}, [editBotModal, selectedRow]);

	return (
		<div className='add-prescreening-bot-wrapper'>
			<div className='add-questions-box'>
				<div className='add-questions'>
					<div style={{ fontSize: 22, fontWeight: '600', whiteSpace: 'nowrap', display: "flex", alignItems: "center", color: mediaQueryMobile.matches ? "#ADADAD" : 'unset' }}>
						{mediaQueryMobile.matches &&
							<svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" viewBox="0 0 24 24" stroke="#ADADAD" strokeWidth="2" onClick={onCloseEditBotModal}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
							</svg>
						}
						{mediaQueryMobile.matches ? 'Edit prescreening tests' : 'Edit Questions'}
					</div>
					<div style={{ marginTop: 15 }}>
						<input style={{ color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b', boxSizing: mediaQueryMobile.matches ? 'border-box' : 'unset', width: mediaQueryMobile.matches ? '100%' : 'unset' }} type="text" placeholder='PS Name' value={editBotName} onChange={(e) => setEditBotName(e.target.value)} />
					</div>
				</div>
				<div style={{ marginTop: mediaQueryMobile.matches ? 15 : 'unset' }}>
					<div style={{ fontSize: 14, fontWeight: '600', color: '#595959' }}>About us:</div>
					<div style={{ marginTop: mediaQueryMobile.matches ? 0 : 15 }}>
						<input style={{ color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b', boxSizing: mediaQueryMobile.matches ? 'border-box' : 'unset', width: mediaQueryMobile.matches ? '100%' : 400 }} type="text" placeholder='Add description here' value={editAboutBot} onChange={(e) => setEditAboutBot(e.target.value)} />
					</div>
				</div>
			</div>
			<div style={{ borderRadius: 20, boxShadow: '0px 4px 5px 2px #B7B7B761', padding: mediaQueryMobile.matches ? '15px' : '15px 30px', marginTop: 30, marginBottom: 35 }}>
				<div style={{ fontSize: 15, color: '#878787', fontWeight: '700' }}>List of questions</div>
				{
					editBotQuestionData.map((abqd, i) => (
						<div style={{ display: 'flex', marginTop: 15, flexDirection: mediaQueryMobile.matches ? 'column' : 'row' }} key={i}>
							<div style={{ fontSize: 16, fontWeight: '700', color: '#878787', marginRight: 15, marginBottom: mediaQueryMobile.matches ? 8 : 0 }}>Q. {i + 1}</div>
							<div style={{ flex: 1, padding: mediaQueryMobile.matches ? '15px' : '15px 30px', borderRadius: 25, boxShadow: '0px 2px 3px 1px #B0B0B061' }}>
								<div style={{ display: 'flex', flexWrap: mediaQueryMobile.matches ? 'wrap' : 'unset' }}>
									<div style={{ flex: mediaQueryMobile.matches ? 'unset' : 1, marginRight: mediaQueryMobile.matches ? 0 : 25, width: mediaQueryMobile.matches ? '100%' : 'unset', marginBottom: mediaQueryMobile.matches ? 8 : 0 }}>
										<input style={{ lineHeight: '21px', width: '100%', color: '#858585', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #c4c4c4' }} type='text' placeholder='Type new question ...' value={abqd.question} onChange={(e) => onChangeQuestionText(e, i)} />
									</div>
									<Select
										classNames={{
											root: 'prescreening-selector-11',
										}}
										selectedValue={abqd.questionType.value ? abqd.questionType.label : 'Select question type'}
										onChangeSelect={(val) => onChangeQuestionType(val, i)}
										dropdownData={[
											{ label: 'Single Answer MCQ', value: 'singleAnswerMcq' },
											{ label: 'Multi Answer MCQ', value: 'mutiAnswerMcq' },
											{ label: 'Free Text Answer', value: 'freeText' }
										]}
										styles={{
											root: {
												position: 'relative',
												backgroundColor: 'transparent',
												zIndex: mediaQueryMobile.matches ? '2' : (editBotQuestionData.length - i)
											},
											wrapper: {
												color: '#8B8B8B',
												fontSize: 13,
												textAlign: mediaQueryMobile.matches ? 'left' : 'center',
												padding: mediaQueryMobile.matches ? '0px' : 'unset',
											},
											dropdown: {
												fontSize: 13
											}
										}}
									/>
								</div>
								<div className='options'>
									{
										abqd.options.map((option, j) => (
											<div key={j} style={{ display: 'flex', marginTop: 10, alignItems: 'center', justifyContent: 'space-between' }}>
												<div style={{ display: 'flex', marginRight: mediaQueryMobile.matches ? 16 : 50, alignItems: 'center' }}>
													<div style={{ width: 16, height: 16, border: '2px solid #b4b4b4', borderRadius: '50%', marginRight: 10 }}></div>
													{/* <div style={{ fontSize: 13, color: '#6b6b6b' }}>{option.text}</div> */}
													<input style={{ width: mediaQueryMobile.matches ? '100%' : 200, color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b' }} type='text' placeholder='Add Option' value={option.text} onKeyDown={(e) => handleOptionKeyDownAdded(e, i, j)} onChange={(e) => onChangeOption(e, i, j)} className="prescreening-selector-12" />
												</div>
												<div className="option-item" key={j}>
													<label>Acceptable<input type="checkbox" name={option.text} checked={option.correct} onClick={(e) => handleOptionCheck(e, j, i)} /><span className="checkmark prescreening-selector-13"></span></label>
												</div>
											</div>
										))
									}
								</div>
								{
									abqd.questionType.value !== 'freeText' ? <div style={{ marginTop: mediaQueryMobile.matches ? 8 : 25, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
										<input style={{ width: mediaQueryMobile.matches ? '100%' : 200, color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b' }} type='text' placeholder='Add Option' onKeyDown={(e) => handleOptionKeyDown(e, i)} />
										<div className='delete-question-btn' onClick={() => deleteNewQuestion(i)}>
											<img src={DeleteIcon} alt="Delete icon" width={24} height={'auto'} />
										</div>
									</div> : ''
								}
								{
									abqd.questionType.value === 'freeText' ? <div className='delete-question-btn' onClick={() => deleteNewQuestion(i)}>
										<img src={DeleteIcon} alt="Delete icon" width={24} height={'auto'} />
									</div> : ''
								}
							</div>
							{
								i === editBotQuestionData.length - 1 ? (
									<div className='add-question-btn' id="prescreening-selector-15" onClick={editNewQuestion}></div>
								) : ''
							}
						</div>
					))
				}
			</div>
			<div className="button-div">
				<Button
					id="prescreening-selector-16"
					style={{
						background: mediaQueryMobile.matches ? "#197BBD" : '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: mediaQueryMobile.matches ? '8px' : '25px',
						padding: '5px 12px',
						fontSize: '16px',
						width: mediaQueryMobile.matches ? '100%' : 'unset',
						color: '#fff',
					}}
					size="small"
					variant="contained"
					onClick={onEditPreScreeningBot}>
					<span style={{ fontSize: '16px' }}>Save</span>
				</Button>
			</div>
		</div>
	)
}

EditPreScreeningModal.propTypes = {
	editBotModal: PropTypes.bool,
	onCloseEditBotModal: PropTypes.func,
	editPreScreeningBot: PropTypes.func,
	setSnackSuccessMsg: PropTypes.func,
	handleSuccessSnackClick: PropTypes.func,
	selectedRow: PropTypes.array,
	setSnackErrMsg: PropTypes.func,
	handleErrSnackClick: PropTypes.func
}

export default EditPreScreeningModal;
