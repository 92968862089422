import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid } from '@mui/x-data-grid';

import Button from '../components/common/Button';
import Modal from '../components/common/CustomModal';
import { getCompaniesList, getWorkflowList } from '../selectors/app';

import emptyRowImg from '../assets/emptyrow1.png'
import { fetchWorkflows as fetchWorkflowsRequest, fetchCompanies as fetchCompaniesRequest, updateWorkflowAdmin as updateWorkflowAdminRequest } from '../actions/app';
import { useEffect } from 'react';

const ManageWorkflows = (props) => {
	const { updateWorkflowAdmin, allCompanies, workflowList, fetchCompanies, fetchWorkflows } = props;

	const [editModal, setEditModal] = useState(false);
	const [selectedWorkflow, setSelectedWorkflow] = useState();
	const [selectedCompany, setSelectedCompany] = useState();
	const [assessmentAcceptText, setAssessmentAcceptText] = useState('');
	const [pageSize, setPageSize] = useState(15);

	useEffect(() => {
		fetchCompanies();
	}, []);

	const onOpenEditModal = (e, val) => {
		e.stopPropagation();
		setSelectedWorkflow(val);
		if(val.assessmentAcceptText) {
			setAssessmentAcceptText(val.assessmentAcceptText);
		} else {
			setAssessmentAcceptText('I will be assessing your application for this role. This assessment will take about 30 minutes in total to complete.::::Should you encounter any technical difficulties, please close this tab and visit the link that was shared with you - you will not lose your place in the test. Should you continue to experience difficulties, please email support@hirequotient.com')
		}
		setEditModal(true);
	}

	const onCloseEditModal = () => {
		setAssessmentAcceptText('');
		setEditModal(false);
	}

	const onSubmitAcceptText = () => {
		const data = {
			assessmentAcceptText,
			companyId: selectedCompany,
			workflowId: selectedWorkflow._id
		};

		updateWorkflowAdmin(data);
		onCloseEditModal();
	}

	const NoRowsOverlay = () => {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	const onChangeCompany = (e) => {
		setSelectedCompany(e.target.value);
		fetchWorkflows({ companyId: e.target.value });
	}

	const columns = [
		{
			field: "name",
			headerName: "Name",
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			renderCell: (params) => {
				return (
					<>
						{params.row.name}
					</>
				);
			}
		},
		{
			field: "createdDate",
			headerName: "Date Created",
			flex: 1,
			renderCell: (params) => {
				const options = { year: 'numeric', month: 'short', day: 'numeric' };
				const date = new Date(params.row.createdDate).toLocaleDateString("en-US", options);
				return (
					<>
						{date}
					</>
				);
			}
		},
		{
			field: "action",
			headerName: "Action",
			headerAlign: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Button
							className="btn-datagrid"
							size="small"
							variant="contained"
							onClick={(e) => onOpenEditModal(e, params.row)}
						>
							Edit
						</Button>
					</>
				);
			},
		},
	];

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", flex: 1, minHeight: "100vh" }}>
			<Modal open={editModal} onClose={onCloseEditModal} classNames={{ modal: 'modal__container' }}>
				<div className="modal__heading">
					{selectedWorkflow && selectedWorkflow.name}
				</div>
				<div>Change First Bubble Text</div>
				<div className="modal__inputbox">
					<textarea rows={2} type="text" placeholder="First Bubble Text" value={assessmentAcceptText} onChange={(e) => setAssessmentAcceptText(e.target.value)} style={{ width: '90%' }} />
				</div>
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onSubmitAcceptText}
					>
						Submit
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseEditModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>

			<div className="notificationbar"></div>
			<div className="actionbar" />
			<div style={{ padding: 40, fontSize: 24, fontWeight: 'bold', background: '#fff' }}>Workflows</div>
			<div style={{ display: 'flex', marginTop: 25, justifyContent: 'space-between' }}>
				<select onChange={onChangeCompany}>
					<option>Select Company</option>
					{
						allCompanies.map((comp) => (
							<option key={comp._id} value={comp._id}>{comp.name}</option>
						))
					}
				</select>
			</div>

			<DataGrid
				rows={workflowList}
				getRowId={(row) => row._id}
				disableSelectionOnClick
				columns={columns}
				components={{ NoRowsOverlay }}
				autoHeight
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 15]}
				className="datagrid"
			/>
		</div>
	)
}

ManageWorkflows.propTypes = {
	workflowList: PropTypes.array,
	fetchWorkflows: PropTypes.func,
	allCompanies: PropTypes.array,
	fetchCompanies: PropTypes.func,
	updateWorkflowAdmin: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	workflowList: getWorkflowList(),
	allCompanies: getCompaniesList()
});

const mapDispatchToProps = (dispatch) => ({
	fetchWorkflows: (data) => dispatch(fetchWorkflowsRequest(data)),
	updateWorkflowAdmin: (data) => dispatch(updateWorkflowAdminRequest(data)),
	fetchCompanies: () => dispatch(fetchCompaniesRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageWorkflows);
