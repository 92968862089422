import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import axios from "../../utils/axios";
import Button from '../common/Button';
import Snackbar from '../common/Snackbar';
import { redirectUnauthorisedUser } from '../../helper/helperFunction';

const VideoCard = (props) => {
	const { ques, src, score, cid, notes, candidateData, mediaType } = props;

	const history = useHistory();

	const [vidNotes, setVidNotes] = useState();
	const [inputScore, setInputScore] = useState(0);
	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');
	const [errSnackOpen, setErrSnackOpen] = useState(false);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);

	const handleErrSnackClick = () => {
		setErrSnackOpen(true);
	};

	const handleErrSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setErrSnackOpen(false);
	};

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setGreenSnackOpen(false);
	};

	const handleSaveNotes = function () {
		if (inputScore > 100) {
			setErrMsg("Please enter a valid score");
			handleErrSnackClick();
			return;
		}

		setLoading(true);
		axios.post('/savenotes', { notes: vidNotes, score: inputScore, cid, testid: candidateData.test._id })
			.then(() => {
				if(score != inputScore) {
					setSuccessMsg('Score saved successfully');
				} else {
					setSuccessMsg('Notes saved successfully');
				}
				handleGreenSnackClick();
				setLoading(false);
				// setChange(change + 1);
			})
			.catch(err => {
				setErrMsg("Couldn't save changes at the moment");
				handleErrSnackClick();
				setLoading(false);
				redirectUnauthorisedUser(err, history);
			});
	}

	useEffect(() => {
		setInputScore(score)
	}, [score]);

	useEffect(() => {
		if (notes) {
			setVidNotes(notes);
		}
	}, [notes]);

	return (
		<div className="videobox">
			<div className="questionbox">
				<p>
					{
						Array.isArray(ques) ? (
							<>
								{
									ques[ques.length - 1].split('::').map(function (item, key) {
										return (
											<span key={key}>
												{item.split('***').map(function (item2, key2) {
													return (<span key={key2}>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)
		
												})}
												<br />
											</span>
										)
									})
								}
							</>
						) : <>{ques.replaceAll('::', ' ').replaceAll('*', '')}</>
					}</p>
			</div>
			{
				mediaType === 'audio' ? (
					<div className="video" dangerouslySetInnerHTML={{ __html: `<audio controls='true' playsinline src="${src}" type="audio/mpeg" ></audio>` }} />
				) : (
					<div className="video" dangerouslySetInnerHTML={{ __html: `<video controls='true' playsinline src="${src}" type="video/mp4" ></video>` }} />
				)
			}
			<div className="card-container">
				<div className="items">
					<p className="score">Total Score</p>
					<p className="input percent">
						<input
							value={inputScore}
							onChange={(e) => setInputScore(e.target.value)}
							type="number"
							className='score-input'
						/> %
					</p>
				</div>
				<hr />
				<div className="items">
					<textarea
						placeholder="Notes"
						value={vidNotes}
						onChange={(e) => setVidNotes(e.target.value)}
					></textarea>
				</div>
				<div style={{ padding: '5px' }}>
					<Button
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "5px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={handleSaveNotes}
						disabled={loading}
					>
						Save
					</Button>
				</div>
			</div>
			<Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose} msg={errMsg ? errMsg : ''} type="error" />
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose} msg={successMsg} type="success" />
		</div>
	)
}

VideoCard.propTypes = {
	ques: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string
	]),
	src: PropTypes.string,
	score: PropTypes.number,
	cid: PropTypes.number,
	notes: PropTypes.string,
	candidateData: PropTypes.object,
	mediaType: PropTypes.string
}

export default VideoCard;
