import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getCurrentWalkthroughStep, getCurrentWalkthroughType } from '../../selectors/app';
import { updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest, updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest } from '../../actions/app';

import '../../styles/walkthroughOverlay.scss';

const WalkthroughOverlay = (props) => {
    const { onClickDone, showWalkthroughNextBtn, currentWalkthroughStep, updateCurrentWalkthroughStep, currentWalkthroughType, updateCurrentWalkthroughType } = props;

    const [stepMessage, setStepMessage] = useState('');
    const [stepMessageTop, setStepMessageTop] = useState(0);
    const [stepMessageRight, setStepMessageRight] = useState(0);
    const [leftArrow, setLeftArrow] = useState(false);
    const [rightArrow, setRightArrow] = useState(false);
    const [topArrow, setTopArrow] = useState(false);
    const [bottomArrow, setBottomArrow] = useState(false);
    const [stepCountMessage, setStepCountMessage] = useState('');

    useEffect(() => {
        if (currentWalkthroughType === 'workflow') {
            let elem = document.getElementById(`workflowStep${currentWalkthroughStep}`);
            if (currentWalkthroughStep === 6) {
                elem.scrollIntoView();
            }
            if (currentWalkthroughStep === 7) {
                if (!elem) {
                    elem = document.getElementById('testNewDriveFlow-selector-5');
                }
                elem.scrollIntoView();
            }
            let rect;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to add a new drive to your<br />dashboard');
                    setTopArrow(true);
                    setStepMessageTop(175);
                    setStepMessageRight(20);
                    setStepCountMessage('Step 1 of 9');
                    break;
                case 2: setStepMessage("Start creating your first assessment<br />(or choose one of the default<br />assessments from above)");
                    setTopArrow(false);
                    setLeftArrow(true)
                    setStepMessageTop(560);
                    setStepMessageRight(150);
                    setStepCountMessage('Step 2 of 9');
                    break;
                case 3: setStepMessage('Add a name and brief description for<br />your drive');
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(rect.y + 20);
                    setStepMessageRight(50);
                    setStepCountMessage('Step 3 of 9');
                    break;
                case 4: setStepMessage('Select the role for which you want to<br />create the assessment');
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(rect.y - 20);
                    setStepMessageRight(0);
                    setStepCountMessage('Step 4 of 9');
                    break;
                case 5: setStepMessage('De-select Critical Work Functions<br />and Key Tasks that are not relevant');
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(450);
                    setStepMessageRight(20);
                    setStepCountMessage('Step 5 of 9');
                    break;
                case 6: setStepMessage('Add advanced customisations<br />like your own video questions<br />(upto 2) and customising<br />the problem statement to suit<br />your context');
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(400);
                    setStepMessageRight(20);
                    setStepCountMessage('Step 6 of 9');
                    break;
                case 7: setStepMessage('<div style="text-align: center"><strong>Done !</strong><br />Click here to save your customisations</div>');
                    setLeftArrow(false);
                    setBottomArrow(true);
                    setStepMessageTop(rect.y - 120);
                    setStepMessageRight(window.innerWidth - rect.x - 250);
                    setStepCountMessage('Step 7 of 9');
                    break;
                case 8: setStepMessage('You can see the created Recruitment Drive from here. You can click on View now');
                    setTopArrow(true);
                    setBottomArrow(false);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 8 of 9');
                    break;
                case 9: setStepMessage('To view your customised assessment, copy<br />the chatbot link from here and paste it in a new tab');
                    setTopArrow(true);
                    setBottomArrow(false);
                    setStepMessageTop(175);
                    setStepMessageRight(250);
                    setStepCountMessage('Step 9 of 9');
                    break;
                default: setStepMessage('');
            }
            // if (currentWalkthroughStep === 5) {
            //     if (document.getElementById('specifyKeyTask')) {
            //         document.getElementById('specifyKeyTask').style.transform = 'scale(1.05)';
            //         setTimeout(function () {
            //             document.getElementById('specifyKeyTask').style.transform = 'scale(1)';
            //         }, 500);
            //     } else if (document.getElementById('specifyFunctionalSkill')) {
            //         setStepMessage('Here you can lay out<br/> the expected profile of the ideal<br/> candidate, by selecting the relevant<br/> functional and core skills.');
            //         document.getElementById('specifyFunctionalSkill').style.transform = 'scale(1.05)';
            //         setTimeout(function () {
            //             document.getElementById('specifyFunctionalSkill').style.transform = 'scale(1)';
            //         }, 500);
            //         setStepMessage('Here you can lay out<br/> the expected profile of the ideal<br/> candidate, by selecting the relevant functional <br/>and core skills.');

            //     }
            // }
        } else if (currentWalkthroughType === 'invite') {
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 8');
                    break;
                case 2: setStepMessage("Go to the 'Manage Invitation' tab<br />from the side panel here");
                    setTopArrow(false);
                    setLeftArrow(true)
                    setStepMessageTop(410);
                    setStepMessageRight(window.innerWidth - 610);
                    setStepCountMessage('Step 2 of 8');
                    break;
                case 3: setStepMessage('Customise the invitation email template<br />by adding your company logo and<br />changing the mail content to suit your<br />company style');
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(330);
                    setStepMessageRight((window.innerWidth / 2) + 250);
                    setStepCountMessage('Step 3 of 8');
                    break;
                case 4: setStepMessage("Add candidate individually/<br />upload the list of candidates in<br />csv format");
                    setTopArrow(true);
                    setStepMessageTop(180);
                    setStepMessageRight(40);
                    setStepCountMessage('Step 4 of 8');
                    break;
                case 5: setStepMessage('You can choose to have a pre-<br />screening stage from here. Choose the<br />created pre-screening bot and<br />customisaed emai ltemplate after<br />enabling from toggle switch');
                    setTopArrow(true);
                    setStepMessageTop(310);
                    setStepMessageRight((window.innerWidth / 2) - 50);
                    setStepCountMessage('Step 5 of 8');
                    break;
                case 6: setStepMessage('You can choose to have a pre-screening<br />stage from here. Choose the created pre-<br />screening bot and customisaed email<br />template afteer enabling from toggle<br />switch');
                    setTopArrow(true);
                    setStepMessageTop(380);
                    setStepMessageRight((window.innerWidth / 2) - 290);
                    setStepCountMessage('Step 6 of 8');
                    break;
                case 7: setStepMessage('If you want to remind candidates to<br >attempt the assessment, you can schedule<br />the auto-reminder mails from here. You<br />can also allow recurring reminders to be<br />sent at a defined time period.');
                    setTopArrow(false);
                    setBottomArrow(true);
                    setStepMessageTop(250);
                    setStepMessageRight((window.innerWidth / 2) - 420);
                    setStepCountMessage('Step 7 of 8');
                    break;
                case 8: setStepMessage('You can set a deadline for submission,<br />after which the link will expire. It can be<br />later modified at candidate level.');
                    setTopArrow(false);
                    setBottomArrow(true);
                    setStepMessageTop(420);
                    setStepMessageRight((window.innerWidth / 2) - 410);
                    setStepCountMessage('Step 8 of 8');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'evaluate') {
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 5');
                    break;
                case 2: setStepMessage("Go to the 'Review Applicants' tab<br />from the side panel here");
                    setTopArrow(false);
                    setLeftArrow(true)
                    setStepMessageTop(470);
                    setStepMessageRight(window.innerWidth - 610);
                    setStepCountMessage('Step 2 of 5');
                    break;
                case 3: setStepMessage('The total scores for all<br />submissions are reflected here.<br />You can sort these scores to see<br />the top performing candidates');
                    setTopArrow(true);
                    setStepMessageTop(250);
                    setStepMessageRight((window.innerWidth / 2) - 300);
                    setStepCountMessage('Step 3 of 5');
                    break;
                case 4: setStepMessage("View the reports, score their videos &<br />view their transcripts by clicking on<br />'Analyse' button. You will also be<br />provided with an Interviewer's Guide<br />educating you on the basis for the<br />questions");
                    setTopArrow(false);
                    setRightArrow(true);
                    setStepMessageTop(200);
                    setStepMessageRight((window.innerWidth / 2) - 250);
                    setStepCountMessage('Step 4 of 5');
                    break;
                case 5: setStepMessage("You are all set now to<br />shortlist and reject candidates<br />from here!");
                    setTopArrow(false);
                    setRightArrow(true);
                    setStepMessageTop(200);
                    setStepMessageRight((window.innerWidth / 2) - 450);
                    setStepCountMessage('Step 5 of 5');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'prescreening') {
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to create a pre-screening bot.<br /><br /><span style="text-decoration: underline">Note:</span> Pre-screening bots are meant to ensure<br />candidates qualify the basic criteria, like educational<br />qualifications and past experience. Only the candidates<br />who clear the criteria will be sent the screening skill<br />assessment.');
                    setTopArrow(true);
                    setStepMessageTop(175);
                    setStepMessageRight(20);
                    setStepCountMessage('Step 1 of 10');
                    break;
                case 2: setStepMessage("Add the name to the pre-<br />screening bot");
                    setTopArrow(false);
                    setRightArrow(true);
                    setStepMessageTop(230);
                    setStepMessageRight(window.innerWidth / 2 + 400);
                    setStepCountMessage('Step 2 of 10');
                    break;
                case 3: setStepMessage("Add brief description about<br />your company");
                    setRightArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(200);
                    setStepMessageRight(0);
                    setStepCountMessage('Step 3 of 10');
                    break;
                case 4: setStepMessage("You can add your first question<br />here.");
                    setLeftArrow(true);
                    setStepMessageTop(350);
                    setStepMessageRight(window.innerWidth / 2 - 350);
                    setStepCountMessage('Step 4 of 10');
                    break;
                case 5: setStepMessage("Select the type of question from the<br />dropdown. Irrespective of whether it<br />is single or multi correct, you can<br />choose as many acceptable answers as<br />possible (there's no one correct<br />answers herre, right!)");
                    setStepMessageTop(350);
                    setStepMessageRight(window.innerWidth / 2 - 650);
                    setStepCountMessage('Step 5 of 10');
                    break;
                case 6: setStepMessage("Start adding the options here. Press<br />'Enter/ Return' to go to the next<br />option.");
                    setStepMessageTop(420);
                    setStepMessageRight(window.innerWidth / 2 - 350);
                    setStepCountMessage('Step 6 of 10');
                    break;
                case 7: setStepMessage("Choose the options that will be<br />acceptable for consideration. If a<br />candidate selects at least one option<br />which is not 'Acceptable', they will not<br />move ahead to the next screening<br />round.");
                    setStepMessageTop(380);
                    setStepMessageRight(70);
                    setStepCountMessage('Step 7 of 10');
                    break;
                case 8: setStepMessage("Use this icon to add the next<br />question");
                    setStepMessageTop(320);
                    setStepMessageRight(window.innerWidth / 2 - 650);
                    setStepCountMessage('Step 8 of 10');
                    break;
                case 9: setStepMessage("Once you've added all<br />questions, options and<br />selected the question type<br />and acceptable answers,<br />click on 'Create'.");
                    setStepMessageTop(620);
                    setStepMessageRight(window.innerWidth / 2 - 650);
                    setStepCountMessage('Step 9 of 10');
                    break;
                case 10: setStepMessage("You can see the created pre-<br />screening bot here. You can<br />edit from here, and also send<br />a test email to yourself to<br />see the bot interface from<br />candidate's side.");
                    setLeftArrow(false);
                    setTopArrow(true);
                    setRightArrow(false);
                    setStepMessageRight(180);
                    setStepMessageTop(330);
                    setStepCountMessage('Step 10 of 10');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'createjob') {
            let elem = document.getElementById(`createjobStep${currentWalkthroughStep}`);
            let rect;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 7');
                    break;
                case 2: setStepMessage("You can create a job portal where candidates can<br />directly apply. Once applied, they will get the<br />assessment for the next stage");
                    setTopArrow(true);
                    setStepMessageTop(175);
                    setStepMessageRight(20);
                    setStepCountMessage('Step 2 of 7');
                    break;
                case 3: setStepMessage("Add the name of the role and<br />it's brief description here");
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(125);
                    setStepMessageRight(50);
                    setStepCountMessage('Step 3 of 7');
                    break;
                case 4: setStepMessage("Upload the relevant JD to support<br />your job opening. In case you <br />don't have a JD, click<br />here to create a new JD now");
                    setLeftArrow(true);
                    setStepMessageTop(400);
                    setStepMessageRight(10);
                    setStepCountMessage('Step 4 of 7');
                    break;
                case 5: setStepMessage("We recommend you to add the<br />pre-screening stage to the flow.<br />Candidates who apply will instantly<br />get a pre-screening bot. Only<br />if they meet the required criteria<br />they will move forward and receive<br />the screening assessment.");
                    setLeftArrow(true);
                    setStepMessageTop(450);
                    setStepMessageRight(10);
                    setStepCountMessage('Step 5 of 7');
                    break;
                case 6: setStepMessage("Create the link from here");
                    setStepMessageTop(rect.y - 25);
                    setStepMessageRight(window.innerWidth / 2 - 400);
                    setStepCountMessage('Step 6 of 7');
                    break;
                case 7: setStepMessage("You can get the generated<br />link from here. Copy the link<br />and paste it on Linkedin and<br />other job postings.");
                    setLeftArrow(false);
                    setBottomArrow(true);
                    if (rect) {
                        setStepMessageTop(rect.y - 180);
                    } else {
                        setStepMessageTop(500);
                    }
                    setStepMessageRight(window.innerWidth / 2 - 450);
                    setStepCountMessage('Step 7 of 7');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'sendemails') {
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 4');
                    break;
                case 2: setStepMessage("If you want to view all shortlisted and<br />rejected candidates, and send out<br />customised mails to inform them of the<br />outcome, click here.");
                    setTopArrow(false);
                    setLeftArrow(true)
                    setStepMessageTop(300);
                    setStepMessageRight(window.innerWidth - 650);
                    setStepCountMessage('Step 2 of 4');
                    break;
                case 3: setStepMessage("You can send the email from 'Action'<br />column below, or click here to send<br />respective emails to all shortlisted and<br />rejected candidates at once.");
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(170);
                    setStepMessageRight(window.innerWidth / 2 - 440);
                    setStepCountMessage('Step 3 of 4');
                    break;
                case 4: setStepMessage("Select the category(s) where<br />you want to send the email and<br />the custom email template from<br />dropdown. You're good to send<br />emails! ");
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(250);
                    setStepMessageRight(window.innerWidth / 2 - 600);
                    setStepCountMessage('Step 4 of 4');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'whitelabelling') {
            let elem = document.getElementById(`whitelabellingStep${currentWalkthroughStep}`);
            let rect;
            if (currentWalkthroughStep === 3) {
                elem.scrollIntoView();
            }
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 5');
                    break;
                case 2: setStepMessage("Click here to change the settings of the<br />drive.");
                    setLeftArrow(true);
                    setStepMessageTop(380);
                    setStepMessageRight(window.innerWidth - 680);
                    setStepCountMessage('Step 2 of 5');
                    break;
                case 3: setStepMessage("To change the logo in the dashboard<br />and the virtual interviewer interface,<br />click 'Change logo' from here.");
                    setBottomArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(rect.y - 150);
                    setStepMessageRight(130);
                    setStepCountMessage('Step 3 of 5');
                    break;
                case 4: setStepMessage("You can upload the relevant logo here");
                    setBottomArrow(false);
                    setTopArrow(true);
                    setStepMessageTop(200);
                    setStepMessageRight(window.innerWidth / 2 - 250);
                    setStepCountMessage('Step 4 of 5');
                    break;
                case 5:
                    setTimeout(() => {
                        setStepMessageTop(rect.y + 70);
                        setStepMessage("Click here to save your changes");
                    }, 1000)
                    setStepMessageRight(window.innerWidth / 2 - 250);
                    setStepCountMessage('Step 5 of 5');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'automaticemails') {
            let elem = document.getElementById(`automaticemailsStep${currentWalkthroughStep}`);
            if (currentWalkthroughStep === 3 || currentWalkthroughStep === 4) {
                elem.scrollIntoView();
            }
            let rect;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 4');
                    break;
                case 2: setStepMessage("Go to this tab for selecting drive-specific<br />settings, in this case 'Post-assessment emails'.");
                    setLeftArrow(true);
                    setStepMessageTop(380);
                    setStepMessageRight(window.innerWidth - 700);
                    setStepCountMessage('Step 2 of 4');
                    break;
                case 3: setStepMessage("You can enable this feature to trigger<br />automatic emails to candidates post<br />they have completed the assessment.");
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(rect.y + 100);
                    setStepMessageRight(window.innerWidth / 2);
                    setStepCountMessage('Step 3 of 4');
                    break;
                case 4: setStepMessage("Click here to customise the email - add<br />your company logo, change the body<br />and make it completely customised!");
                    setStepMessageTop(rect.y + 100);
                    setStepMessageRight(window.innerWidth / 2 - 500);
                    setStepCountMessage('Step 4 of 4');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'videoproctoring') {
            let elem = document.getElementById(`videoproctoringStep${currentWalkthroughStep}`);
            if (currentWalkthroughStep === 3) {
                elem.scrollIntoView();
            }
            let rect;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 3');
                    break;
                case 2: setStepMessage("Go to this tab for selecting drive-specific<br />settings, in this case 'Video proctoring'.");
                    setLeftArrow(true);
                    setStepMessageTop(380);
                    setStepMessageRight(window.innerWidth - 700);
                    setStepCountMessage('Step 2 of 3');
                    break;
                case 3: setStepMessage("Enable the feature from the toggle button on the right.<br />If video proctoring is enabled, candidates' videos will be<br />recorded during they are attempting the assessemnt and<br />flagged off by our AI based technology if any red flag is<br />predicted.");
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(rect.y + 100);
                    setStepMessageRight(280);
                    setStepCountMessage('Step 3 of 3');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'team') {
            let elem = document.getElementById(`teamStep${currentWalkthroughStep}`);
            let rect;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage("To add your team members to the<br />dashboard, go to 'Manage Team' from<br />here");
                    setLeftArrow(true);
                    if (rect) {
                        setStepMessageTop(rect.y - 20);
                    }
                    setStepMessageRight(window.innerWidth - 650);
                    setStepCountMessage('Step 1 of 5');
                    break;
                case 2: setStepMessage("Add the name of the team member<br />you want to invite.");
                    setLeftArrow(false);
                    setBottomArrow(true);
                    if (rect) {
                        setTimeout(() => {
                            setStepMessageTop(rect.y - 130);
                        }, 100)
                    }
                    setStepMessageRight(750);
                    setStepCountMessage('Step 2 of 5');
                    break;
                case 3: setStepMessage("Add their email ID");
                    if (rect) {
                        setStepMessageTop(rect.y - 130);
                    }
                    setStepMessageRight(window.innerWidth / 2 - 200);
                    setStepCountMessage('Step 3 of 5');
                    break;
                case 4: setStepMessage("Set access level of the member to be invited:<br />Admin: Has authority to perform actions like<br />inviting, shortlisting etc<br />Member: Only has authority to view lists, but not<br />perform any function<br />Bias-free: Has all admin rights, but in an<br />unbiased way (personal identification<br />information is masked)");
                    if (rect) {
                        setStepMessageTop(rect.y - 130);
                    }
                    setStepMessageRight(130);
                    setStepCountMessage('Step 4 of 5');
                    break;
                case 5: setStepMessage("All done! Invite the member by clicking<br />here. They shall receive an email from<br />no-reply@hirequotient.com with the<br />dashboard credentials.");
                    setBottomArrow(true);
                    if (rect) {
                        setStepMessageTop(rect.y - 130);
                    }
                    setStepMessageRight(0);
                    setStepCountMessage('Step 5 of 5');
                    break;
                default: setStepMessage('');
            }
        }
    }, [currentWalkthroughStep, currentWalkthroughType]);

    const onClickExit = () => {
        updateCurrentWalkthroughStep(0);
        updateCurrentWalkthroughType('');
    }

    return (
        <div className='walkthrough-overlay' style={{ backgroundColor: currentWalkthroughStep === 2 && currentWalkthroughType === 'workflow' && 'transparent' }}>
            {
                stepMessage !== '' ? (
                    <div className='step-box' style={{ top: stepMessageTop, right: stepMessageRight }}>
                        <div style={{ fontSize: 12, marginBottom: 5 }}>{stepCountMessage}</div>
                        <div dangerouslySetInnerHTML={{ __html: stepMessage }} />
                        {
                            leftArrow ? (
                                <div className='left-arrow'></div>
                            ) : ''
                        }
                        {
                            topArrow ? (
                                <div className='top-arrow'></div>
                            ) : ''
                        }
                        {
                            bottomArrow ? (
                                <div className='bottom-arrow'></div>
                            ) : ''
                        }
                        {
                            rightArrow ? (
                                <div className='right-arrow'></div>
                            ) : ''
                        }
                    </div>
                ) : ''
            }
            <div style={{ display: 'flex', padding: 50, justifyContent: 'space-between', width: '100%' }}>
                <div>
                    <div style={{ padding: '8px 32px', fontWeight: '600', background: '#479BD2', color: '#fff', borderRadius: 40, cursor: 'pointer' }} onClick={onClickExit} id="guidedWalkthroughFlow-selector-6">Exit Walkthrough</div>
                </div>
                {/* <div style={{ textAlign: 'center' }}>
                    <div  style={{ padding: '8px 32px', fontWeight: '600', background: '#479BD2', color: '#fff', borderRadius: 40, cursor: 'pointer' }} onClick={onClickSkip}>Skip</div>
                    <div style={{ marginTop: 10, color: '#fff', fontWeight: '600' }}>(Go to next step)</div>
                </div> */}
                {
                    showWalkthroughNextBtn && currentWalkthroughStep < 8 ? (
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ padding: '8px 32px', fontWeight: '600', background: '#479BD2', color: '#fff', borderRadius: 40, cursor: 'pointer' }} onClick={onClickDone} id="guidedWalkthroughFlow-selector-7">Next</div>
                            {/* <div style={{ marginTop: 10, color: '#fff', fontWeight: '600' }}>(Go to next step)</div> */}
                        </div>
                    ) : ''
                }
            </div>
        </div>
    )
}

WalkthroughOverlay.propTypes = {
    onClickExit: PropTypes.func,
    onClickDone: PropTypes.func,
    showWalkthroughNextBtn: PropTypes.bool,
    currentWalkthroughStep: PropTypes.number,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughStep: PropTypes.func,
    updateCurrentWalkthroughType: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType()
});

const mapDispatchToProps = (dispatch) => ({
    updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
    updateCurrentWalkthroughType: (data) => dispatch(updateCurrentWalkthroughTypeRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WalkthroughOverlay);
