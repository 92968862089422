import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import TextField from "@mui/material/TextField";
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import { DataGrid } from "@mui/x-data-grid";
import { InputAdornment } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useTour } from '@reactour/tour';

import axios from "../utils/axios";
import AccountMenu from "../components/AccountMenu";
import AddInviteEmail from "../components/manageInvitations/AddInviteEmail";
import AddRemindEmail from "../components/manageInvitations/AddRemindEmail";
import AddPsInviteEmail from "../components/manageInvitations/AddPsInviteEmail";
import Avatar from "../components/common/Avatar";
import BottomBar from "../components/common/BottomBar";
import Button from '../components/common/Button';
import DataTable from "../components/common/DataTable";
import Header from "../components/header/header";
import IconButton from '../components/common/IconButton';
import Modal from '../components/common/CustomModal';
import NewEmailTemplate from "../components/newApplicants/emailTemplate";
import NotificationBell from "../components/notifications/NotificationBell";
import Select from "../components/common/Select";
import Snackbar from "../components/common/Snackbar";
import Switch from "../components/common/SwitchBtn";
import UploadInvite from "../components/manageInvitations/UploadInvite";
import UploadRemind from "../components/manageInvitations/UploadRemind";
import UploadPsInvite from "../components/manageInvitations/UploadPsInvite";
import WalkthroughOverlay from "../components/common/WalkthroughOverlay";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";
import CustomInviteModal from "../components/common/CustomInviteModal";
import { checkCompanyFeatureAccess, checkUserPlanAccess } from "../utils/featureAccess";
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	editCandidateName as editCandidateNameRequest,
	resetSuccessMsg as resetSuccessMsgRequest,
	resetRerender as resetRerenderRequest,
	deleteCandidate as deleteCandidateRequest,
	fetchPreScreeningBots as fetchPreScreeningBotsRequest,
	updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
	updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest
} from '../actions/app';
import {
	getCandidatesList,
	getCompanyFeatures,
	getCurrentWalkthroughStep,
	getCurrentWalkthroughType,
	getPreScreeningBots,
	getRerender,
	getSaasPlanDetails,
	getTotalAssessments,
	getUserPlan,
	getUserRole
} from '../selectors/app';
import { redirectUnauthorisedUser } from '../helper/helperFunction';
import { useWindowDimensions } from '../utils/windowUtils';
import useFocusNext from "../utils/focusNext";

import alarmIcon from '../assets/Icons/Alarm.png';
import Edit from '../assets/Icons/Edit.png';
import emptyRowImg from '../assets/emptyrow2.png';
import InviteIcon from '../assets/Icons/inviteIcon.png';
import Trash from '../assets/Icons/Trash.png';
import UploadIcon from '../assets/Icons/Upload.png';
import BulkUploadIcon from '../assets/upload-csv.png';
import TickBlueIcon from '../assets/right-blue-icon.svg';
import RemindBellIcon from '../assets/remind-bell.svg';
import sampleCSV from '../assets/sampleCSV.csv';

import "../styles/manageInvitations.scss";

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const ManageInvitations = (props) => {
	const {
		rerender,
		editCandidateName,
		resetRerender,
		deleteCandidate,
		userPlan,
		totalAssessments,
		userRole,
		fetchPreScreeningBots,
		companyFeatures,
		saasPlanDetails,
		currentWalkthroughStep,
		currentWalkthroughType,
		updateCurrentWalkthroughStep,
		updateCurrentWalkthroughType
	} = props;

	const location = useLocation();
	const history = useHistory();
	const { isOpen, notification } = useTour();
	const windowDimensions = useWindowDimensions();
	const focusNextRef = useFocusNext();

	const editNameRef = useRef(null);

	const [deleteModal, setDeleteModal] = useState(false);
	const [tempChange, setTempChange] = useState(0);
	const [change, setChange] = useState(0);
	const [mailList, setMailList] = useState([]);
	const [data, setData] = useState([]);
	const [rows, setRows] = useState(data);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);
	const [open4, setOpen4] = useState(false);
	const [csvColumns, setCsvColumns] = useState([]);
	const [tempInv, setTempInv] = useState([]);
	const [tempRem, setTempRem] = useState([]);
	const [tempPsInv, setTempPsInv] = useState([]);
	const [personName, setPersonName] = useState('');
	const [personEmail, setPersonEmail] = useState('');
	const [tempp, setTemplate] = useState();
	const [tempr, setTemplateR] = useState();
	const [tempps, setTemplatePs] = useState();
	const [selectedRemTemp, setSelectedRemTemp] = useState(null);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [processedData, setProcessedData] = useState([]);
	// const [searchText, setSearchText] = useState('');
	const [successMsg] = useState('Candidate Updated');
	const [errMsg, setErrMsg] = useState(null);
	const [snackOpen, setSnackOpen] = useState(false);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [selectionModel, setSelectionModel] = useState([]);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	// const [pageSize, setPageSize] = useState(15);
	const [addedRows, setAddedRows] = useState([])
	const [invitedRows, setInvitedRows] = useState([])
	const [bulkMailModal, setBulkMailModal] = useState(false);
	const [bulkMailInvitedTemp, setBulkMailInvitedTemp] = useState(0);
	const [bulkMailRemindTemp, setBulkMailRemindTemp] = useState(0);
	const [switchInvitedTemp, setSwitchInvitedTemp] = useState(false);
	const [switchRemindTemp, setSwitchRemindTemp] = useState(false);
	const [bulkMailLoading, setBulkMailLoading] = useState(false);
	const [updateDealineCand, setUpdateDealineCand] = useState();
	const [updatedDeadline, setUpdatedDeadline] = useState(1)
	const [updateDeadlineModal, setUpdateDeadlineModal] = useState(false);
	const [inviteDisabled, setInviteDisabled] = useState(true);
	const [uploadExcelName, setUploadExcelName] = useState('');
	const [addLoader, setAddLoader] = useState(false);
	const [showWalkthrough, setShowWalkthrough] = useState(true);

	const onCloseUpdateDeadlineModal = () => {
		setUpdateDeadlineModal(false)
		setUpdateDealineCand()
		setUpdatedDeadline(1)
	};
	useEffect(() => {
		if (updateDealineCand)
			setUpdateDeadlineModal(true)
	}, [updateDealineCand])
	const updatedDeadlineCandDetails = () => {
		let data = { ...updateDealineCand, deadline: new Date(new Date().getTime() + updatedDeadline * 24 * 60 * 60 * 1000) }
		axios.post("/update-candidate", data).then(() => {
			setChange(change + 1);
			onCloseUpdateDeadlineModal();
		}).catch((err) => {
			console.log(err)
			onCloseUpdateDeadlineModal();
		})
	}
	const onOpenBulkMailModal = () => {
		setBulkMailModal(true);
		// if (selectionModel.length > 0) setBulkMailModal(true);
		// else {
		// 	setErrMsg('Please add candidates to send an email')
		// 	handleSnackClick();
		// }
	};

	const onCloseBulkMailModal = () => {
		setBulkMailModal(false)
	};

	useEffect(() => {
		const handleStorageUpdate = (e) => {
			if (e.key === 'updatePreScreeningBots') {
				const message = JSON.parse(e.newValue);
				if (message) {
					localStorage.removeItem('updatePreScreeningBots');
					fetchPreScreeningBots({ history });
				}
			}
		}

		window.addEventListener("storage", handleStorageUpdate);
		return () => {
			window.removeEventListener("storage", handleStorageUpdate);
		};
	}, []);
	const mediaQueryMobile = window.matchMedia('(max-width: 768px)');
	const [emailTemplateOpen, setEmailTemplateOpen] = useState(false);

	const onOpenDeleteModal = () => {
		if (mediaQueryMobile.matches && selectedRow.length > 0) {
			setDeleteModal(true);
		}
		else {
			if (selectionModel.length > 0) setDeleteModal(true);
			else {
				setErrMsg('Please select atleast one candidate')
				handleSnackClick();
			}
		}
	};
	const sendBulkMail = function () {
		let shortlistCandidates = [];
		let rejectedCandidates = [];

		setBulkMailLoading(true);

		rows.map((val) => {
			if (val.status === "Shortlisted") shortlistCandidates.push(val);
			else if (val.status === "Rejected") rejectedCandidates.push(val);
		})

		if (switchInvitedTemp && switchRemindTemp) {
			let invite = axios.post('/company/workflow/candidate/invite', { workflowId: addedRows[0].workflow, candlist: addedRows, emailIndex: bulkMailInvitedTemp });
			let remind = axios.post('/company/workflow/candidate/remind', { workflowId: invitedRows[0].workflow, candlist: invitedRows, emailIndex: bulkMailRemindTemp });

			Promise.all([invite, remind])
				.then(() => {
					//setSuccessMsg("Invitation & Reminder Mail Sent")
					//handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					redirectUnauthorisedUser(err, history);

					//setSnackErr("Not able to send mail at the moment");
					//handleSnackClick();
					setBulkMailLoading(false);
				});
		} else if (switchInvitedTemp && !switchRemindTemp) {
			axios.post('/company/workflow/candidate/invite', { workflowId: addedRows[0].workflow, candlist: addedRows, emailIndex: bulkMailInvitedTemp })
				.then(() => {
					//setSuccessMsg("Invite Mail Sent")
					//handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					redirectUnauthorisedUser(err, history);

					//setSnackErr("Not able to send mail at the moment");
					//handleSnackClick();
					setBulkMailLoading(false);
				});
		} else {
			axios.post('/company/workflow/candidate/remind', { workflowId: invitedRows[0].workflow, candlist: invitedRows, emailIndex: bulkMailRemindTemp })
				.then(() => {
					//setSuccessMsg("Reminder Mail Sent")
					//handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					redirectUnauthorisedUser(err, history);

					//setSnackErr("Not able to send mail at the moment");
					//handleSnackClick();
					setBulkMailLoading(false);
				});
		}
	}
	const onCloseDeleteModal = () => setDeleteModal(false);

	const nameEdit = (row) => {
		editCandidateName({ row, newName: editNameRef.current.value, history });
	}

	const handleDelete = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		});

		deleteCandidate({ id: IDs, history });
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender])

	useEffect(async () => {
		setLoading(true);
		try {
			let added = await axios.post("/gettype", { status: "added", id: location.state.workflowId });
			let invite = await axios.post("/gettype", { status: "invite", id: location.state.workflowId });
			//let psInvite = await axios.post("/gettype", { status: "psInvite", id: location.state.workflowId });
			setInvitedRows([...invite.data])
			setAddedRows([...added.data])
			let totalData = [...added.data, ...invite.data];
			totalData.forEach((candidate) => {
				candidate.id = parseInt(candidate.id);
			});
			setData([...totalData]);
			setRows([...totalData]);
			setLoading(false);
		}
		catch (err) {
			console.log(err);
			setLoading(false);

			redirectUnauthorisedUser(err, history);
		}
	}, [change]);

	const onOpenModal = () => {
		if (checkCompanyFeatureAccess(companyFeatures, 'roleCount', parseInt(totalAssessments)) && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'roleCount', parseInt(totalAssessments))) {
			setOpen(true);
			setShowWalkthrough(false);
		} else {
			setErrMsg('Your quota is full')
			handleSnackClick();
		}
	};

	const onCloseModal = () => {
		setInvalidEmail(false);
		setPersonEmail('');
		setPersonName('');
		setOpen(false);
		// updateCurrentWalkthroughStep(0);
		// updateCurrentWalkthroughType('');
	};

	const onOpenModal2 = () => {
		if (!userRole.includes('Admin')) {
			setErrMsg('You are not authorised to do this action')
			handleSnackClick();
		} else {
			setOpen2(true)
		}
	};

	const onCloseModal2 = () => setOpen2(false);

	const onOpenModal3 = () => {
		setOpen3(true);
	};

	const onCloseModal3 = () => {
		setOpen3(false)
	};

	const onOpenModal4 = () => setOpen4(true);

	const onCloseModal4 = () => setOpen4(false);

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const handleEmailCheck = (e) => {
		setPersonEmail(e.target.value);

		if (validateEmail(e.target.value)) {
			setInvalidEmail(false);
		} else {
			setInvalidEmail(true);
		}
	}

	const handleAddApplicant = () => {
		setCsvColumns([{ name: personName, email: personEmail }]);
		setPersonEmail('');
		setPersonName('');
	}

	const sendData = () => {
		const uri = "/company/workflow/candidate";
		const getData = {
			workflowId: location.state.workflowId,
			candlist: csvColumns
		};
		setAddLoader(true);

		axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				setAddLoader(false);
				setChange(change + 1);
				setMailList(res.data);
				onCloseModal2();
				onCloseModal()
				onOpenModal3();
				if (currentWalkthroughStep > 0 && currentWalkthroughType === 'invite') {
					updateCurrentWalkthroughStep(5);
					updateCurrentWalkthroughType('invite');
					setShowWalkthrough(true);
				}
			})
			.catch((err) => {
				console.log(err);
				setAddLoader(false);
				redirectUnauthorisedUser(err, history);
				onCloseModal2();
				onCloseModal()
			});
		//onCloseModal();

		//setMailList(csvColumns);
		setCsvColumns([]);
		//onCloseModal2();
	}

	const sendInvitetoAdded = (params) => {
		if (mediaQueryMobile.matches) {
			if (params.length === 0) {
				setErrMsg('Please select atleast one candidate')
				handleSnackClick();
			} else if (params.length > 1) {
				setErrMsg('Please select only one candidate')
				handleSnackClick();
			} else {
				const data1 = [];

				if (params)
					data1.push({ name: params[0].name, email: params[0].email, _id: params[0]._id });
				else if (selectionModel.length > 0) {
					for (let index in rows) {
						if (selectionModel.includes(index))
							data1.push({ ...rows[index] });
					}
				}

				setMailList(data1);
				onOpenModal3();
			}
		} else {
			const data1 = [];

			if (params)
				data1.push({ name: params.name, email: params.email, _id: params._id });
			else if (selectionModel.length > 0) {
				for (let index in rows) {
					if (selectionModel.includes(index))
						data1.push({ name: rows[index].name, email: rows[index].email, _id: rows[index]._id });
				}
			}

			setMailList(data1);
			onOpenModal3();
		}
	}

	const sendRemindertoAdded = (params) => {
		const data1 = [];

		if (params)
			data1.push({ name: params.name, email: params.email, _id: params._id });
		else if (selectionModel.length > 0) {
			for (let index in rows) {
				if (selectionModel.includes(index))
					data1.push({ name: rows[index].name, email: rows[index].email, _id: rows[index]._id });
			}
		}

		setMailList(data1);
		onOpenModal4();
	}

	useEffect(() => {
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'inviteEmail' }).then((res) => {
			setTempInv(res.data.mails);
			setTemplate(res.data.template);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		})
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'remindEmail' }).then((res) => {
			setTempRem(res.data.mails);
			setTemplateR(res.data.template);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		})
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'psInviteEmail' }).then((res) => {
			setTempPsInv(res.data.mails);
			setTemplatePs(res.data.template);
		}).catch((err) => {
			console.log(err);
			redirectUnauthorisedUser(err, history);
		})
	}, [change, tempChange]);

	const sendReminder = () => {
		const uri = "/company/workflow/candidate/remind";
		const getData = {
			workflowId: location.state.workflowId,
			emailIndex: selectedRemTemp,
			candlist: mailList
		};

		axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
			.then(() => {
				setChange(change + 1);
			})
			.catch((err) => {
				console.log(err);
				redirectUnauthorisedUser(err, history);
			});

		setMailList([]);
		onCloseModal4();
	}

	const processData = (sheetData) => {
		if (checkCompanyFeatureAccess(companyFeatures, 'roleCount', parseInt(totalAssessments)) && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'roleCount', parseInt(totalAssessments))) {
			const list = [];
			sheetData.forEach((item) => {
				if (item.name && item.email && item.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
					list.push(item);
				} else {
					return;
				}
			});

			setProcessedData(list);
		} else {
			setErrMsg('Your have exceeded your quota')
			handleSnackClick();
		}
	}

	const sendProcessedData = () => {
		if (inviteDisabled) {
			setErrMsg('Please upload the list of candidates in csv format');
			handleSnackClick();
		} else {
			setCsvColumns(processedData);
			setProcessedData([]);
			//onCloseModal2();
			//onOpenModal3();
		}
	}

	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		setUploadExcelName(file.name);
		const reader = new FileReader();
		reader.onload = (evt) => {
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_json(ws);
			let newKeys = {}
			for (var keys in data[0]) {
				newKeys[keys.toLowerCase().replace(/[^a-z]/g, "")] = keys
			}
			var tmpData = []
			for (var i in data) {
				try {
					if (!data[i][newKeys['name']] || !data[i][newKeys["email"]])
						continue
					tmpData.push({
						"name": data[i][newKeys['name']],
						"email": data[i][newKeys["email"]]
					})
				} catch (e) {
					console.log(e)
				}
			}
			setInviteDisabled(false);
			processData(tmpData);
		};
		reader.readAsBinaryString(file);
	}

	const requestSearch = (searchValue) => {
		// setSearchText(searchValue);

		if (searchValue == '') {
			setRows(data);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = data.filter((row) => {
				// return Object.keys(row).some((field) => {
				// 	return searchRegex.test(row[field].toString());
				// });
				if (searchRegex.test(row.name) || searchRegex.test(row.email)) {
					return true;
				}
			});

			setRows(filteredRows);
		}
	};

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackOpen(false);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setGreenSnackOpen(false);
	};

	const onOpenEditModal = () => {
		if (mediaQueryMobile.matches) {
			if (selectedRow.length > 1 || selectedRow.length === 0) {
				setErrMsg('Please select a candidate');
				handleSnackClick();
			}
			else {
				setEditModal(true);
			}
		}
		else {
			if (selectionModel.length == 1) setEditModal(true);
			else {
				setErrMsg('Please select atleast one candidate')
				handleSnackClick();
			}
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	useEffect(() => {
		if (csvColumns.length != 0)
			sendData()
	}, [csvColumns]);

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);

		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
		else {
			let temp = [];
			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...rows.filter((row) => selectionModel[i] == row.id))
			}
			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const onClickModifyDeadline = (params) => {
		setUpdateDealineCand(mediaQueryMobile.matches ? params : params.row);
		const createdD = new Date(mediaQueryMobile.matches ? params.createdDate : params.row.createdDate);
		const deadlineD = new Date(mediaQueryMobile.matches ? params.deadline : params.row.deadline);
		const timeDiff = (deadlineD.getTime() - createdD.getTime()) / (1000 * 60 * 60 * 24);
		setUpdatedDeadline(parseInt(timeDiff, 10));
	}

	const customCol = [
		{
			field: "id",
			headerName: "ID",
			width: 80,
			headerAlign: 'left',
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ width: "100%" }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: 'name',
			headerName: "Name",
			headerAlign: 'left',
			width: windowDimensions.width / 8,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: 16, fontWeight: "400", display: 'flex', alignItems: 'center', width: '100%' }}>
						{
							params.row.deadline ? (
								<img
									id='manageInvitation-selector-27'
									src={alarmIcon}
									onClick={() => onClickModifyDeadline(params)}
									style={{ height: '2rem', color: '#555', cursor: "pointer" }}
									alt='alarm-icon'
								/>
							) : ''
						}
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.name}</div>
							<span className='tooltiptext datagrid'>{params.row.name}</span>
						</div>
					</div>
				);
			},
		},
		{
			field: 'email',
			headerName: "Email",
			headerAlign: 'left',
			width: windowDimensions.width / 6,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400", width: '100%' }}>
						<div className="tooltip" style={{ width: '100%' }}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.email}</div>
							<span className='tooltiptext datagrid'>{params.row.email}</span>
						</div>
					</div>
				);
			},
		},
		{
			field: 'status',
			headerName: "Status",
			width: windowDimensions.width / 6,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "2ch", width: "100%" }}>
						{params.row.status}
					</div>
				)
			}
		}
	];

	if (userRole.includes('Admin')) {
		customCol.push(
			{
				field: 'action',
				headerName: "Action",
				width: windowDimensions.width / 6,
				headerAlign: 'center',
				renderCell: (params) => {
					return (
						<div style={{ width: 150, marginRight: '2ch' }}>
							{params.row.status == "Added" ?
								<Button
									id="manageInvitation-selector-13"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "inherit",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 28px',
									}}
									size="small"
									variant="contained"
									onClick={(e) => {
										e.stopPropagation();
										sendInvitetoAdded(params.row)
									}}
								>
									<span style={{ fontSize: 14, color: '#fff' }}>Invite</span>
								</Button>
								:
								<Button
									id="manageInvitation-selector-14"
									className="manageInvitations-remind-btn"
									size="small"
									variant="contained"
									onClick={(e) => {
										e.stopPropagation();
										sendRemindertoAdded(params.row);
									}}
								>
									<span style={{ fontSize: 14, color: '#fff' }}>Remind</span>
									{
										params.row.remind > 0 ? (
											<div className="notificationBell">
												<img src={RemindBellIcon} className='notificationBellIcon' />
												<span className="superscript">{params.row.remind}</span>
											</div>
										) : ''
									}
								</Button>
							}
						</div>
					)
				}
			}
		)
	}

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}

	const MenuItems = [
		{
			name: 'Edit',
			action: () => {
				onOpenEditModal();
			}
		},
		{
			name: 'Delete',
			action: () => {
				onOpenDeleteModal();
			}
		},
		{
			name: 'Invite',
			action: () => {
				sendInvitetoAdded(selectedRow);
			}
		},
		{
			name: 'Upload List',
			action: () => {
				onOpenModal2();
			}
		},
		{
			name: 'Modify Deadline',
			action: () => {
				onOpenModal2();
			}
		},
		{
			name: 'Send bulk emails',
			action: () => {
				onOpenBulkMailModal();
			}
		}
	]

	const handleSelectApplicant = (row) => {
		if (selectedRow.length !== 0 && selectedRow.some(el => el._id === row._id)) {
			setSelectedRow(selectedRow.filter(el => el._id !== row._id));
		}
		else {
			setSelectedRow([...selectedRow, row]);
		}
	}

	const tempInvObjArr = tempInv?.length &&
		tempInv.map((con, index) => {
			return {
				label: con.name,
				value: index,
			}
		}) || []

	const tempRemObjArr = tempRem?.length &&
		tempRem.map((con, index) => {
			return {
				label: con.name,
				value: index,
			}
		}) || [];

	return (
		<div className="container-div manage-invitations">
			{mediaQueryMobile.matches && <Header MenuItems={MenuItems} />}
			<div className="inner-div" style={{ marginBottom: '10px' }}>
				<div className='top-icon-box'>
					<WalkthroughWelcome />
					{
						userRole.includes('Admin') ? (
							<div className="tooltip">
								<IconButton aria-label="edit" onClick={onOpenEditModal} id="manageInvitation-selector-18">
									<img src={Edit} alt="" width={30} height={30} />
								</IconButton>
								<span className="tooltiptext">Edit</span>
							</div>
						) : ''
					}
					{
						userRole.includes('Admin') ? (
							<div className="tooltip">
								<IconButton aria-label="delete" onClick={() => onOpenDeleteModal()} id="manageInvitation-selector-21">
									<img src={Trash} alt="" width={30} height={30} />
								</IconButton>
								<span className="tooltiptext">Delete</span>
							</div>
						) : ''
					}
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			<div className="userList"
				style={{
					height: mediaQueryMobile.matches ? '100%' : 'unset',
					overflowX: 'hidden',
					position: 'relative'
				}}
			>
				<Modal open={bulkMailModal} onClose={onCloseBulkMailModal} center classNames={{ modal: 'invitationModal' }}>
					<div className="modal-heading-workflow">
						Send email to
					</div>
					<div
						tabIndex={1}
						className="invitationModal__wrapper1"
						onKeyDown={(e) => {
							if(e.key === 'Enter' && !((bulkMailInvitedTemp == null && bulkMailRemindTemp == null) || bulkMailLoading)) {
								e.preventDefault();
								sendBulkMail();
							}
						}}
					>
						{
							addedRows.length > 0 && tempInv.length > 0 ? (
								<>
									<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
										<p>All added candidates</p>
										<Switch checked={switchInvitedTemp} onChange={(e) => setSwitchInvitedTemp(e.target.checked)} />
									</div>
									<div style={{ minHeight: "90px" }}>
										{switchInvitedTemp ?
											(<div style={{ margin: "15px 0", borderRadius: 25 }}>
												<Select
													label="Invite template"
													selectedValue={tempInvObjArr.find(e => e.value === bulkMailInvitedTemp)?.label || ''}
													onChangeSelect={(val) => setBulkMailInvitedTemp(val.value)}
													styles={{
														root: {
															borderRadius: 25,
															border: '1px solid #bbb',
															width: '100%',
															padding: '0.3rem 0'
														},
														wrapper: {
															borderBottom: 'unset'
														}
													}}
													dropdownData={tempInvObjArr}
												/>
											</div>)
											: ""}
									</div>
								</>
							) : ''
						}
						{
							invitedRows.length > 0 && tempRem.length > 0 ? (
								<>
									<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
										<p style={{ marginRight: '1rem' }}>All invited candidates</p>
										<Switch checked={switchRemindTemp} onChange={(e) => { setSwitchRemindTemp(e.target.checked) }} />
									</div>
									<div style={{ minHeight: "90px" }}>
										{switchRemindTemp ?
											(<div style={{ margin: "15px 0", borderRadius: 25 }}>
												<Select
													label="Reminder template"
													selectedValue={tempRemObjArr.find(e => e.value === bulkMailRemindTemp)?.label || ''}
													onChangeSelect={(val) => setBulkMailRemindTemp(val.value)}
													styles={{
														root: {
															borderRadius: 25,
															border: '1px solid #bbb',
															width: '100%',
															padding: '0.3rem 0'
														},
														wrapper: {
															borderBottom: 'unset'
														}
													}}
													dropdownData={tempRemObjArr}
												/>
											</div>)
											: ""}
									</div>
								</>
							) : ''
						}
					</div>
					<div className="button-div">
						<Button
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "inherit",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: "6px 20px",
								fontSize: '16px',
								color: '#fff',
							}}
							size="small"
							variant="contained"
							disabled={(bulkMailInvitedTemp == null && bulkMailRemindTemp == null) || bulkMailLoading}
							onClick={sendBulkMail}>
							{bulkMailLoading ? 'Loading' : 'Send'}
						</Button>
					</div>
				</Modal>
				<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
					<p className="modal-text">
						Do you want to delete this candidate?
					</p>
					<div className="button-div">
						<Button
							id="manageInvitation-selector-22"
							className="modal-button"
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "inherit",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px'
							}}
							size="small"
							variant="contained"
							onClick={() => {
								handleDelete(selectedRow);
								onCloseDeleteModal();
							}}>
							Yes
						</Button>
						<Button
							className="cancel-modal-button"
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "inherit",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px'
							}}
							size="small"
							variant="contained"
							onClick={() => {
								onCloseDeleteModal()
							}}>
							No
						</Button>
					</div>
				</Modal>
				<div className="userListTitleContainer" style={{ marginBottom: 20, paddingTop: 20, marginTop: 0, position: 'relative' }}>
					<div className="inner-div-details manage-invitations">
						<div>
							<h2>Manage Invitations</h2>
							<p style={{ wordBreak: 'break-all' }}>{location.state.workflowName}</p>
						</div>
						{mediaQueryMobile.matches &&
							<div
								className="manage-icon"
								onClick={onOpenModal}
							>
								<img src={InviteIcon} alt="invite-profile-icon" />
							</div>
						}
					</div>
					<div className="search-box" style={{ width: "35vw" }}>
						<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
					</div>
					{!mediaQueryMobile.matches &&
						<div style={{ fontSize: "15px", display: "flex", alignItems: "center" }}>
							<div className="tooltip">
								<IconButton>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										strokeWidth={1.5}
										stroke="currentColor"
										onClick={onOpenBulkMailModal}
										className="check-btn"
									>
										<path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
									</svg>
								</IconButton>
								<span className="tooltiptext">Send bulk emails</span>
							</div>
						</div>
					}
					<div className="action-btns-box manage-invitations" style={{ position: 'relative' }}>
						<span
							style={{ display: "flex", alignItems: "center", marginRight: "10px", cursor: "pointer" }}
							onClick={onOpenModal2}
						>
							<div className="tooltip">
								<Button
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "inherit",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "8px 20px",
										color: '#fff',
										whiteSpace: 'nowrap'
									}}
									size="small"
									variant="contained"
									id='manageInvitation-selector-16'
								>
									<span style={{ fontSize: '14px' }}>Upload List</span>
									<img style={{ height: "18px", width: "18px", transform: 'translate(3px, 0)' }} src={UploadIcon} alt="" />
								</Button>
								<span className="tooltiptext">Upload list of candidates</span>
							</div>
						</span>
						<span style={{ display: "flex", alignItems: "center", cursor: "pointer", position: 'relative' }} onClick={onOpenModal}>
							<div className="tooltip">
								<Button
									className="inner-div-btn"
									id="manageInvitation-selector-2"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "8px 20px",
										color: '#fff',
										whiteSpace: 'nowrap',
										position: 'relative',
										zIndex: currentWalkthroughStep === 4 && currentWalkthroughType === 'invite' && showWalkthrough ? 1000 : 1
									}}
									size="small"
									variant="contained"
								>
									<span style={{ fontSize: '14px' }}>Add Applicant</span>
								</Button>
								<span className="tooltiptext">Add individual candidates</span>
							</div>
						</span>
					</div>
				</div>
				{!mediaQueryMobile.matches &&
					<div className="cards-container" style={{ position: 'relative' }}>
						<div className="cards" style={{ position: 'relative' }}>
							<div className='add-card-container' style={{ position: 'relative' }}>
								<div className='add-card-wrapper' style={{ position: 'relative' }}>
									{isOpen ? <AddInviteEmail type="inviteEmail" index={1} field={""} /> : null}
									{isOpen ? <AddRemindEmail type="remindEmail" index={1} field={""} /> : null}
									{isOpen ? <AddPsInviteEmail type="psInviteEmail" index={1} field={""} /> : null}
									{tempp ? <AddInviteEmail type="inviteEmail" index={tempInv.length} field={tempp} tempChange={tempChange} setTempChange={setTempChange} currentWalkthroughType={currentWalkthroughType} currentWalkthroughStep={currentWalkthroughStep} updateCurrentWalkthroughStep={updateCurrentWalkthroughStep} setShowWalkthrough={setShowWalkthrough} showWalkthrough={showWalkthrough} /> : null}
									{tempr ? <AddRemindEmail type="remindEmail" index={tempRem.length} field={tempr} tempChange={tempChange} setTempChange={setTempChange} /> : null}
									{tempps ? <AddPsInviteEmail type="psInviteEmail" index={tempPsInv.length} field={tempps} tempChange={tempChange} setTempChange={setTempChange} /> : null}
								</div>
								<div className='upload-title'>
									<h3>Add templates</h3>
								</div>
							</div>
							{isOpen ?
								<UploadInvite
									type="inviteEmail"
									isDefault={false}
									index={1}
									field={{
										"name": "Invitation Template",
										"image": "default.png",
										"subject": "Invitation to HireQuotient Online Assessment",
										"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
										"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
										"button": "Let's Begin",
										"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
									}} /> : null}
							{isOpen ?
								<UploadRemind
									type="remindEmail"
									isDefault={false}
									index={1}
									field={{
										"name": "Invitation Template",
										"image": "default.png",
										"subject": "Invitation to HireQuotient Online Assessment",
										"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
										"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
										"button": "Let's Begin",
										"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
									}} /> : null}
							{isOpen ?
								<UploadPsInvite
									type="psInviteEmail"
									isDefault={false}
									index={1}
									field={{
										"name": "Pre Screening Invitation Template",
										"image": "default.png",
										"subject": "Invitation to HireQuotient Online Assessment",
										"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
										"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
										"button": "Let's Begin",
										"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
									}} /> : null}
							{tempInv?.length > 0 ?
								tempInv.map((temp, index) => {
									return (<UploadInvite key={index} type="inviteEmail" isDefault={false} tempChange={tempChange} setTempChange={setTempChange} index={index} field={temp} />)
								}) : null}
							{tempRem?.length > 0 ?
								tempRem.map((temp, index) => {
									return (<UploadRemind key={index} type="remindEmail" isDefault={false} tempChange={tempChange} setTempChange={setTempChange} index={index} field={temp} />)
								}) : null}
							{tempPsInv?.length > 0 ?
								tempPsInv.map((temp, index) => {
									return (<UploadPsInvite key={index} type="psInviteEmail" isDefault={false} tempChange={tempChange} setTempChange={setTempChange} index={index} field={temp} />)
								}) : null}
						</div>
					</div>
				}
				{/* {
					selectionModel.length > 0 ?
						<div style={{ background: "lightgrey", position: "relative", top: "0vh", minHeight: "6vh", padding: "1vh 20px 1vh", display: "flex", justifyContent: "space-between", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
							<div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
								<Button
									id="manageInvitation-selector-23"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "6px 20px",
										fontSize: '14px'
									}}
									size="small"
									variant="contained"
									onClick={() => { onOpenDeleteModal() }}
								>
									Delete
								</Button>
							</div>
							{isOpen ?
								<UploadInvite
									fetchMyDetails={fetchMyDetails}
									type="inviteEmail"
									isDefault={false}
									index={1}
									field={{
										"name": "Invitation Template",
										"image": "default.png",
										"subject": "Invitation to HireQuotient Online Assessment",
										"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
										"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
										"button": "Let's Begin",
										"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
									}} /> : null}
							{isOpen ?
								<UploadRemind
									fetchMyDetails={fetchMyDetails}
									type="remindEmail"
									isDefault={false}
									index={1}
									field={{
										"name": "Invitation Template",
										"image": "default.png",
										"subject": "Invitation to HireQuotient Online Assessment",
										"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
										"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
										"button": "Let's Begin",
										"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
									}} /> : null}
							{tempInv?.length > 0 ?
								tempInv.map((temp, index) => {
									return (<UploadInvite fetchMyDetails={fetchMyDetails} key={index} type="inviteEmail" isDefault={false} tempChange={tempChange} setTempChange={setTempChange} index={index} field={temp} />)
								}) : null}
							{tempRem?.length > 0 ?
								tempRem.map((temp, index) => {
									return (<UploadRemind fetchMyDetails={fetchMyDetails} key={index} type="remindEmail" isDefault={false} tempChange={tempChange} setTempChange={setTempChange} index={index} field={temp} />)
								}) : null}
							{tempPsInv?.length > 0 ?
								tempPsInv.map((temp, index) => {
									return (<UploadPsInvite fetchMyDetails={fetchMyDetails} key={index} type="psInviteEmail" isDefault={false} tempChange={tempChange} setTempChange={setTempChange} index={index} field={temp} />)
								}) : null}
						</div>
						: ""
				} */}
				{
					mediaQueryMobile.matches &&
					<div className='new-applicant-box__content'>
						{rows.length === 0 ?
							<>
								<img
									src={emptyRowImg}
									alt="no rows"
									style={{
										height: "auto",
										width: "100%"
									}}
								/>
								<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E", textAlign: "center" }}>No data to show</p>
							</> : null}
						{rows.map((row, index) => (
							<div key={index} className='applicant-box__row' onClick={() => handleSelectApplicant(row)}>
								<div className='applicant-box__row-left'>
									<div className="applicant-box__row-left-image-box">
										<Avatar
											name={parseInt(row.id)}
											styles={{
												text: {
													backgroundColor: "#EBEBEB",
													color: "#404040",
												}
											}}
										/>
										{selectedRow.length !== 0 && selectedRow.some(el => el._id === row._id) && <img src={TickBlueIcon} alt="right-blue-icon" />}
									</div>
									<div className="applicant-box-row-left-text-box">
										<p className='applicant-box__row-left-text-name'>{row.name}</p>
										<p className='applicant-box__row-left-text-role'>{row.email}</p>
									</div>
								</div>
								<div className='applicant-box__row-right'>
									{row.deadline ? (
										<img
											src={alarmIcon}
											onClick={(e) => {
												e.preventDefault();
												onClickModifyDeadline(row);
											}}
											style={{ height: '1.5rem', color: '#555', cursor: "pointer", display: 'block' }} alt=''
										/>
									) : ''}
									<p className="applicant-box__row-left-text-role">{row.status}</p>
								</div>
							</div>
						))}
					</div>
				}
				{!mediaQueryMobile.matches &&
					<div style={{ minHeight: 300 }}>
						<DataTable
							loading={loading}
							noRowsComponent={<NoRowsOverlay />}
							rows={
								!isOpen ? rows :
									[{
										"_id": "61dc0299971cc34bfc531917",
										"name": "A",
										"email": [
											"ashu97j@gmail.com"
										],
										"id": "101",
										"code": "2a10jHHBkOsPKbUMB3QF5Cw4YuwaZ5uAV1Uuepy3ZbHvzFMsnYBnIZRq",
										"status": "Added",
										"lastActive": "2022-01-10T09:55:37.602Z",
										"createdDate": "2022-01-10T09:55:37.602Z",
										"company": "61730080f94921c9089ea99f",
										"workflow": "6178704387c9e1c238ae4f58",
										"productId": "ChatId4",
										"problem": "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
										"title": "HR Evaluative",
										"__v": 0
									}]
							}
							columns={customCol}
							checkboxSelection={userRole.includes('Admin')}
							onSelectionModelChange={(newSelectionModel) => {
								console.log(newSelectionModel);
								setSelectionModel(newSelectionModel);
							}}
							selectionModel={selectionModel}
							pageSize={15}
						/>
					</div>
				}
			</div>
			{updateDealineCand &&
				<Modal
					open={updateDeadlineModal}
					onClose={onCloseUpdateDeadlineModal}
					classNames={{ modal: 'scheduleInviteModal' }}
					center
				>
					<div className="modal-heading-workflow">
						Modify Deadline
					</div>
					<p className="schedule-subheading">Modify time after which the invitation link expires</p>
					<p className="schedule-subheading">Current Deadline : {new Date(updateDealineCand.deadline).toLocaleString()}</p>
					<div className="date-time-box" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<div>
							<TextField
								style={{ width: "80px" }}
								label="Days"
								variant="standard"
								value={updatedDeadline}
								onChange={(e) => { setUpdatedDeadline(e.target.value) }}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<img
												src={alarmIcon}
												style={{ height: '2rem', color: '#555' }}
												alt=''
											></img>
										</InputAdornment>
									),
								}}
							/>
						</div>
					</div>
					<div className="schedule-invite-btns-box">
						<div className="cancel-button" onClick={onCloseUpdateDeadlineModal}>Cancel</div>
						<div className="schedule-btn" onClick={updatedDeadlineCandDetails} id='manageInvitation-selector-28'>Modify</div>
					</div>
				</Modal>
			}
			<Modal open={open4} onClose={onCloseModal4} classNames={{ modal: 'customModal-workflow' }}>
				<div className="modal-heading-workflow">
					Customise your reminder
				</div>
				<div className="input-div-workflow">
					<Select
						label="Select Reminder template"
						id="manageInvitation-selector-15"
						selectedValue={tempRemObjArr.find(e => e.value === selectedRemTemp)?.label || ''}
						onChangeSelect={(val) => setSelectedRemTemp(val.value)}
						styles={{
							root: {
								width: 380,
								height: 45,
								margin: '20px 0 10px 0',
								border: '1px solid #bbb',
								borderRadius: 30,
								padding: '0.3rem 0'
							},
							wrapper: {
								borderBottom: 'unset'
							}
						}}
						dropdownData={tempRemObjArr}
					/>
				</div>
				<div className="button-div">
					<Button
						id="manageInvitation-selector-17"
						className="text-transform-none"
						style={{
							background: '#479BD2',
							marginLeft: 10,
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px',
						}}
						size="small"
						variant="contained"
						onClick={sendReminder}
						disabled={selectedRemTemp === null}
					>
						Remind now
					</Button>
					{/* <Button
						className="text-transform-none"
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px',
						}}
						size="small"
						variant="contained"
						onClick={onCloseModal4}
					>
						Remind later
					</Button> */}
				</div>
			</Modal>
			<Modal open={open2} onClose={onCloseModal2} classNames={{ modal: 'customModal-workflow' }} center>
				<div className="modal-heading-workflow" style={{ fontSize: 22 }}>
					Upload csv of<br />candidates to invite
				</div>
				{/* <div className="choose-file-div">
					<input
						type="file"
						accept=".csv"
						onChange={handleFileUpload}
					/>
				</div> */}
				<div style={{ marginTop: 30, position: 'relative', backgroundColor: '#f2f2f2', width: 350, borderRadius: 10, padding: '10px 20px', fontSize: 14, color: '#9E9E9E', boxSizing: 'border-box' }}>
					{uploadExcelName !== '' ? uploadExcelName : 'Choose csv file'}
					<input
						type='file'
						onChange={handleFileUpload}
						accept=".csv"
						id='manageInvitation-selector-26'
						style={{
							width: '100%',
							position: 'absolute',
							top: 0,
							left: 0,
							bottom: 0, 
							right: 0,
							opacity: 0,
							boxSizing: 'border-box',
							zIndex: 1
						}}
						onKeyDown={(e) => {
							if(e.key === "Enter" && !addLoader) {
								e.preventDefault();
								sendProcessedData();
							}
						}}
					/>
					<img src={BulkUploadIcon} alt="upload-icon" style={{ position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)', right: 16, width: 20, height: 'auto' }} />
				</div>
				<div style={{ marginTop: 10 }}>
					<a style={{ display: 'block', fontSize: 14, color: '#479BD2', textDecoration: 'none', paddingLeft: 20 }} href={sampleCSV} download="sample_input_hirequotient.csv">Download sample CSV file</a>
				</div>
				<div className="button-div" style={{ marginTop: 40, dispaly: 'flex', justifyContent: 'space-between' }}>
					<Button
						style={{
							background: '#479BD2',
							margin: 0,
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
							fontSize: '16px',
							width: 180,
							display: 'flex',
							alignItems: 'center'
						}}
						size="small"
						variant="contained"
						onClick={sendProcessedData}
						disabled={addLoader}
						id='manageInvitation-selector-25'
					>
						Add
						{addLoader &&
							<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 38 38" stroke="#fff" style={{ marginLeft: 16 }}>
								<g fill="none" fillRule="evenodd">
									<g transform="translate(1 1)" strokeWidth="3">
										<circle strokeOpacity=".5" cx="18" cy="18" r="18" />
										<path d="M36 18c0-9.94-8.06-18-18-18">
											<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
										</path>
									</g>
								</g>
							</svg>
						}
					</Button>
					<Button
						style={{
							background: '#479BD2',
							margin: 0,
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
							fontSize: '16px',
							width: 150
						}}
						size="small"
						variant="contained"
						onClick={onCloseModal2}
					>
						Cancel
					</Button>
				</div>
			</Modal>
			<Modal open={open} onClose={onCloseModal} classNames={{ modal: 'customModal-workflow' }} styles={{ modal: { width: 'fit-content' } }} center>
				<div className="invite-individual-content">
					<div className="modal-heading-workflow">
						Invite Individual
					</div>
					<div className="input-div-applicant">
						<input
							id="manageInvitation-selector-3"
							type="text"
							placeholder="Name"
							style={{
								background: "#F2F2F2",
								borderRadius: "30px"
							}}
							value={personName}
							onChange={(e) => { setPersonName(e.target.value) }}
							ref={focusNextRef}
						/>
						<br />
						<input
							id="manageInvitation-selector-4"
							type="email"
							placeholder="Email"
							style={{
								background: "#F2F2F2",
								borderRadius: "30px"
							}}
							value={personEmail}
							onChange={handleEmailCheck}
							ref={focusNextRef}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									handleAddApplicant();
								}
							}}
						/>
						{invalidEmail ? (<p>Invalid Email</p>) : ''}
					</div>
					<div className="button-div">
						<Button
							id="manageInvitation-selector-5"
							style={{
								background: invalidEmail || personName == '' || personEmail == '' ? 'rgba(71, 155, 210, 0.5)' : '#479BD2',
								marginLeft: '10px',
								fontWeight: 'bold',
								fontFamily: "inherit",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: "8px 20px",
								color: '#fff',
								fontSize: '16px'
							}}
							disabled={invalidEmail || personName == '' || personEmail == ''}
							onClick={handleAddApplicant}
						>
							Add
						</Button>
						<Button
							style={{
								background: '#479BD2',
								margin: '0 10px',
								fontWeight: 'bold',
								fontFamily: "inherit",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: "8px 20px",
								color: '#fff',
								fontSize: '16px'
							}}
							onClick={onCloseModal}
						>
							Cancel
						</Button>
					</div>
				</div>
			</Modal>
			<Modal open={editModal} onClose={onCloseEditModal} classNames={{ modal: 'editModalApplicants' }} center>
				<p className="modal-text">
					Edit candidate&apos;s name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="manageInvitation-selector-19"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="manageInvitation-selector-20"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						Ok
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<CustomInviteModal
				open={open3}
				onClose={onCloseModal3}
				setErrMsg={setErrMsg}
				handleSnackClick={handleSnackClick}
				change={change}
				setChange={setChange}
				tempInvObjArr={tempInvObjArr}
				showWalkthrough={showWalkthrough}
				tempRemObjArr={tempRemObjArr}
				mailList={mailList}
				setMailList={setMailList}
				tempPsInv={tempPsInv}
			/>
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose} msg={errMsg ? errMsg : ''} type="error" />
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose} msg={successMsg ? successMsg : ''} type="success" />
			{mediaQueryMobile.matches && <BottomBar emailTemplateInvite={emailTemplateOpen} setEmailTemplateInvite={setEmailTemplateOpen} />}
			<NewEmailTemplate
				show={emailTemplateOpen}
				close={() => setEmailTemplateOpen(false)}
				shortlistMails={tempInv}
				rejectMails={tempRem}
				psMails={tempPsInv}
				tempChange={tempChange}
				setTempChange={setTempChange}
			/>
			{
				currentWalkthroughStep > 2 && currentWalkthroughStep < 5 && currentWalkthroughType === 'invite' && showWalkthrough ? (
					<WalkthroughOverlay />
				) : ''
			}
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList(),
	rerender: getRerender(),
	totalAssessments: getTotalAssessments(),
	userPlan: getUserPlan(),
	userRole: getUserRole(),
	preScreeningBots: getPreScreeningBots(),
	companyFeatures: getCompanyFeatures(),
	saasPlanDetails: getSaasPlanDetails(),
	currentWalkthroughStep: getCurrentWalkthroughStep(),
	currentWalkthroughType: getCurrentWalkthroughType()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
	editCandidateName: (data) => dispatch(editCandidateNameRequest(data)),
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	resetRerender: () => dispatch(resetRerenderRequest()),
	deleteCandidate: (data) => dispatch(deleteCandidateRequest(data)),
	fetchPreScreeningBots: (data) => dispatch(fetchPreScreeningBotsRequest(data)),
	updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
	updateCurrentWalkthroughType: (data) => dispatch(updateCurrentWalkthroughTypeRequest(data)),
});

ManageInvitations.propTypes = {
	rerender: PropTypes.number,
	editCandidateName: PropTypes.func,
	resetRerender: PropTypes.func,
	deleteCandidate: PropTypes.func,
	preScreeningBots: PropTypes.array,
	userPlan: PropTypes.object,
	totalAssessments: PropTypes.number,
	userRole: PropTypes.string,
	fetchPreScreeningBots: PropTypes.func,
	companyFeatures: PropTypes.object,
	saasPlanDetails: PropTypes.object,
	currentWalkthroughStep: PropTypes.number,
	currentWalkthroughType: PropTypes.string,
	updateCurrentWalkthroughStep: PropTypes.func,
	updateCurrentWalkthroughType: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvitations);
