import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DownArrowIcon from '../../assets/downarrow.svg';
import CopyIcon from '../../assets/Icons/copy-icon.svg';

import '../../styles/dataTable.scss';

const DataTable = (props) => {
	const { loading, columns = [], rows, noRowsComponent, pageSize, checkboxSelection, onSelectionModelChange, highlightedBox, userRole = "Member", openCopyDriveModal = () => null } = props;

	const [tableRows, setTableRows] = useState([]);
	const [startingIndex, setStartingIndex] = useState(-1);
	const [tableTotalRows, setTableTotalRows] = useState([]);
	const [tableColumns, setTableColumns] = useState([]);
	const [currentPageNo, setCurrentPageNo] = useState(0);
	const [allRowChecked, setAllRowChecked] = useState(false);
	const [copyIcon, setCopyIcon] = useState({
		targetIndex: -1,
		copy: false
	});

	useEffect(() => {
		const newTableRows = tableTotalRows.slice((currentPageNo * pageSize), ((currentPageNo + 1) * pageSize));
		newTableRows.map((ntr) => {
			ntr['checked'] = false;
		})
		setTableRows([...newTableRows]);
	}, [tableTotalRows, currentPageNo]);

	useEffect(() => {
		const newTableTotalRows = [];
		rows.forEach((row) => {
			newTableTotalRows.push(row);
		});
		setTableTotalRows([...newTableTotalRows]);
	}, [rows]);

	useEffect(() => {
		const newTableColumns = [];
		columns.forEach((ntc) => {
			if (ntc.filterable) {
				ntc['showFilter'] = false;
			}
			newTableColumns.push(ntc);
		});
		setTableColumns([...newTableColumns]);
	}, [columns]);

	const handleRowCheck = (e, i) => {
		const newTableRows = [...tableRows];
		newTableRows[i]['checked'] = e.target.checked;
		const selectedRows = [];
		newTableRows.forEach((ntr) => {
			if (ntr.checked) {
				selectedRows.push(ntr.id);
			}
		})
		setTableRows([...newTableRows]);
		onSelectionModelChange([...selectedRows]);
	}

	const handleAllRowCheck = (e) => {
		setAllRowChecked(e.target.checked);
		const newTableRows = [...tableRows];
		const selectedRows = [];
		newTableRows.forEach((ntr, i) => {
			newTableRows[i].checked = e.target.checked;
			selectedRows.push(ntr.id);
		})
		setTableRows([...newTableRows]);
		onSelectionModelChange([...selectedRows]);
	}

	const sortColumn = (index) => {
		const newTableColumns = [...tableColumns];
		let newTableTotalRows = [];
		if (Object.prototype.hasOwnProperty.call(newTableColumns[index], 'sort')) {
			newTableTotalRows = [...tableTotalRows];
			newTableColumns[index]['sort'] = (newTableColumns[index]['sort'] * -1);
		} else {
			newTableTotalRows = [...rows];
			newTableColumns[index]['sort'] = 1;
		}
		newTableColumns[index]['showFilter'] = false;
		newTableColumns.forEach((ntr, i) => {
			if (i !== index && Object.prototype.hasOwnProperty.call(ntr, 'sort')) {
				delete ntr.sort;
			}
		});

		if (newTableColumns[index].sort > 0) {
			newTableTotalRows.sort(dynamicSort(newTableColumns[index].field, -1))
		} else {
			newTableTotalRows.sort(dynamicSort(newTableColumns[index].field, 1))
		}
		const newTableRows = newTableTotalRows.slice(0, pageSize);
		setCurrentPageNo(0);
		newTableRows.map((ntr) => {
			ntr['checked'] = false;
		})
		setTableColumns([...newTableColumns]);
		setTableRows([...newTableRows]);
		setTableTotalRows([...newTableTotalRows]);
	}

	const dynamicSort = (property, sortOrder) => {
		return function (a, b) {
			var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
			return result * sortOrder;
		}
	}

	const onToggleFilter = (e, index) => {
		e.stopPropagation()
		const newTableColumns = [...tableColumns];
		newTableColumns[index]['showFilter'] = !newTableColumns[index]['showFilter'];
		setTableColumns([...newTableColumns]);
	}

	const handleTableNavigation = (e) => {
		const value = startingIndex === -1 ? Number(e.target.value) : startingIndex;
		let tempArr = tableRows;
		if (e.key === "ArrowUp" && e.shiftKey) {
			const recentVal = Number(value);
			if (tempArr[recentVal].checked && recentVal !== 0) {
				tempArr[recentVal].checked = false;
			} else {
				tempArr[recentVal].checked = true;
			}
			setStartingIndex((prev) => {
				const newValue = recentVal - 1;
				if (newValue < 0) {
					return prev;
				}
				return newValue;
			});
		} else if (e.key === "ArrowDown" && e.shiftKey) {
			let tempArr = tableRows;
			const recentVal = Number(value) + 1;
			if (recentVal < tableRows.length) {
				if (tempArr[recentVal].checked) {
					tempArr[recentVal].checked = false;
				} else {
					tempArr[recentVal].checked = true;
				}
				setStartingIndex(recentVal);
			}
		}

		setTableRows([...tempArr]);
	}

	return (
		<div className='datatable dataGridScroll'>
			<div className='datatable-header'>
				{
					checkboxSelection ? (
						<div className="row-checkbox">
							<label><input type="checkbox" checked={allRowChecked} onClick={handleAllRowCheck} onChange={() => { }} /><span className="checkmark"></span></label>
						</div>
					) : ''
				}
				{
					tableColumns.map((column, index) => {
						return !column.hideColumn ? (
							<div className='row-header' key={index} style={{ justifyContent: column.headerAlign, width: column.width ? column.width : 'auto', flex: column.width ? 'none' : '', zIndex: column.highlightHeader ? 1000 : 1, position: 'relative', background: '#fff' }} onClick={() => sortColumn(index)}>
								{column.renderHeader ? column.renderHeader() : column.headerName}
								<div className='tooltip'>
									<div className={`row-header-sort tooltip ${column.sort === 1 ? 'desc' : (column.sort === -1 ? 'asc' : '')}`}>
										<img src={DownArrowIcon} />
									</div>
									<span className='tooltiptext' style={{ marginLeft: '-20px', minWidth: 'fit-content' }}>Sort</span>
								</div>
								{
									column.filterable ? (
										<>
											<div className='column-filter tooltip' onClick={(e) => onToggleFilter(e, index)}>
												<div />
												<span className='tooltiptext' style={{ marginLeft: '-20px', minWidth: 'fit-content' }}>Filter</span>
											</div>
											{
												column.showFilter ? (
													column.renderFilter(() => sortColumn(index))
												) : ''
											}
										</>
									) : ''
								}
							</div>
						) : ''
					})
				}
			</div>
			{
				loading ? (
					<div className='table-loading-wrapper'>
						<div className='table-loader'></div>
					</div>
				) : (
					<div
						className='datatable-body'
						tabIndex={2}
						onKeyDown={handleTableNavigation}
					>
						{
							tableRows.length > 0 ? (
								<>
									{
										tableRows.map((tableRow, i) => (
											<div
												key={i}
												className={`datatable-row ${tableRow.checked ? 'selected' : ''}`}
												style={{
													zIndex: (highlightedBox && i === 0) ? 1000 : 'auto',
													background: (highlightedBox && i === 0) ? '#fff' : tableRow.checked ? '#eff7ff' : 'transparent'
												}}
												onMouseEnter={() => setCopyIcon({
													targetIndex: i,
													copy: true
												})}
												onMouseLeave={() => setCopyIcon({
													targetIndex: i,
													copy: false
												})}
											>
												{
													checkboxSelection ? (
														<div className="row-checkbox">
															<label>
																<input
																	type="checkbox"
																	checked={tableRow.checked}
																	onChange={(e) => {
																		handleRowCheck(e, i)

																		// TODO: if some indexes are already selected then unselect them all.
																		// setStartingIndex(Number(e.target.value));
																	}}
																	value={i}
																/>
																<span className="checkmark"></span></label>
														</div>
													) : ''
												}
												{
													columns.map((column, j) => {
														return !column.hideColumn ? (
															<div key={j} style={{ textAlign: column.headerAlign, width: column.width ? column.width : 'auto', flex: column.width ? 'none' : 1, justifyContent: column.headerAlign, zIndex: column.highlightColumn ? 1000 : 'auto', position: 'relative', background: tableRow.checked ? '#eff7ff' : '#fff' }}>{column.renderCell({ row: tableRow })}</div>
														) : ''
													})
												}
												{(copyIcon.targetIndex === i && copyIcon.copy && userRole === 'Admin') && <img src={CopyIcon} alt="copy-icon" className='datatable-copy-icon' onClick={() => openCopyDriveModal(tableRow.workflowId)}/>}
											</div>
										))
									}
									<div className='datatable-footer'>
										<div className='datatable-footer-wrapper'>
											<div className='datatable-footer-pageinfo'>{`${(currentPageNo * pageSize) + 1}-${pageSize * (currentPageNo + 1) > rows.length ? rows.length : pageSize * (currentPageNo + 1)} of ${rows.length}`}</div>
											<div className='datatable-footer-navigation'>
												<div className={`datatable-footer-prev ${currentPageNo > 0 ? '' : 'disabled'}`} onClick={() => setCurrentPageNo(currentPageNo - 1)} style={{ pointerEvents: currentPageNo > 0 ? 'auto' : 'none' }}></div>
												<div className={`datatable-footer-next ${currentPageNo >= ((rows.length / pageSize) - 1) ? 'disabled' : ''}`} onClick={() => setCurrentPageNo(currentPageNo + 1)} style={{ pointerEvents: (currentPageNo > ((rows.length / pageSize) - 1)) ? 'none' : 'auto' }}></div>
											</div>
										</div>
									</div>
								</>
							) : noRowsComponent
						}
					</div>
				)
			}
		</div>
	)
}

DataTable.propTypes = {
	loading: PropTypes.bool,
	columns: PropTypes.array,
	rows: PropTypes.array,
	noRowsComponent: PropTypes.element,
	pageSize: PropTypes.number,
	checkboxSelection: PropTypes.bool,
	onSelectionModelChange: PropTypes.func,
	highlightedBox: PropTypes.bool,
	userRole: PropTypes.string,
	openCopyDriveModal: PropTypes.func
}

export default DataTable;
