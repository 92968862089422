import React from "react";
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

import AppRouter from './AppRouter';
import appSaga from './sagas/app';
import rootReducer from './reducers/app';

import "./styles/App.scss";
import './styles/Common.scss';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(appSaga);

const App = () => {
	return (
		<Provider store={store} >
			<div className="container">
				<Router>
					<AppRouter />
				</Router>
			</div>
		</Provider>
	);
}

export default App;

