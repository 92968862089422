import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid } from '@mui/x-data-grid';

import Button from '../components/common/Button';
import { fetchSkillCodes as fetchSkillCodesRequest, updateSkillCode as updateSkillCodeRequest, addSkillCode as addSkillCodeRequest, deleteSkillCode as deleteSkillCodeRequest } from '../actions/app';
import { getSkillCodeList } from '../selectors/app';

import emptyRowImg from '../assets/emptyrow1.png';
import Modal from '../components/common/CustomModal';

const ManageSkillCodes = (props) => {
    const { fetchSkillCodes, skillCodeList, updateSkillCode, addSkillCode, deleteSkillCode } = props;

    const [addModal, setAddModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [skillCodes, setSkillCodes] = useState([]);
    const [pageSize, setPageSize] = useState(15);
    const [selectedSkillCode, setSelectedSkillCode] = useState();
    const [newWorkFunctionCode, setNewWorkFunctionCode] = useState('');
    const [newWorkFunctionLabel, setNewWorkFunctionLabel] = useState('');

    const onOpenAddModal = () => {
        setAddModal(true);
    }

    const onCloseAddModal = () => {
        setNewWorkFunctionCode('');
        setNewWorkFunctionLabel('');
        setAddModal(false);
    }

    const onOpenDetailsModal = (val) => {
        setSelectedSkillCode(val)
        setDetailsModal(true);
    }

    const onCloseDetailsModal = () => {
        setSelectedSkillCode();
        setDetailsModal(false);
    }

    const onOpenEditModal = (val) => {
        setSelectedSkillCode(val);
        setNewWorkFunctionCode(val.code);
        setNewWorkFunctionLabel(val.label);
        setEditModal(true);
    }

    const onCloseEditModal = () => {
        setSelectedSkillCode();
        setEditModal(false);
    }

    const onOpenDeleteModal = (val) => {
        setSelectedSkillCode(val);
        setDeleteModal(true);
    }

    const onCloseDeleteModal = () => {
        setSelectedSkillCode();
        setDeleteModal(false);
    }

    useEffect(() => {
        const newSkillCodes = [];
        Object.keys(skillCodeList).forEach((sc) => {
            const newSkillCodeObj = {
                code: sc,
                label: skillCodeList[sc].label,
                remarks: skillCodeList[sc].remarks,
                skills: skillCodeList[sc].skills,
                typicalTasks: skillCodeList[sc].typicalTasks,
                associatedSkills: skillCodeList[sc].associatedSkills,
                id: skillCodeList[sc].id
            }
            newSkillCodes.push(newSkillCodeObj);
        });
        setSkillCodes([...newSkillCodes]);
    }, [skillCodeList]);

    useEffect(() => {
        fetchSkillCodes();
    }, []);

    const columns = [
        {
            field: "code",
            headerName: "Code",
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            renderCell: (params) => {
                return (
                    <>{params.row.code}</>
                );
            }
        },
        {
            field: "label",
            headerName: "Name",
            flex: 0.8,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                return (
                    <>{params.row.label}</>
                );
            }
        },
        // {
        // 	field: "createdDate",
        // 	headerName: "Date Created",
        // 	flex: 0.8,
        // 	renderCell: (params) => {
        // 		const options = { year: 'numeric', month: 'short', day: 'numeric' };
        // 		let date = new Date(params.row.createdDate).toLocaleDateString("en-US", options);
        // 		return (
        // 			<>
        // 				<Link className="datagrid__row" to={{ pathname: "/workflows", state: { company: params.row } }}>
        // 					{date}
        // 				</Link>
        // 			</>
        // 		);
        // 	}
        // },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            align: 'center',
            flex: 2,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            className="btn-datagrid"
                            size="small"
                            variant="contained"
                            onClick={() => onOpenDetailsModal(params.row)}
                        >
                            View
                        </Button>
                        <Button
                            className="btn-datagrid btn-danger"
                            size="small"
                            variant="contained"
                            onClick={() => onOpenEditModal(params.row)}
                        >
                            Edit
                        </Button>
                        <Button
                            className="btn-datagrid btn-danger"
                            size="small"
                            variant="contained"
                            onClick={() => onOpenDeleteModal(params.row)}
                        >
                            Delete
                        </Button>
                    </>
                );
            },
        },
    ];

    const NoRowsOverlay = () => {
        return (
            <div className="norowsoverlay__container">
                <img
                    src={emptyRowImg}
                    alt="no rows"
                    className="norowsoverlay__img"
                />
                <p className="norowsoverlay__text">Nothing to show</p>
            </div>
        )
    }

    const renderDate = (date) => {
        let createdDate = new Date(date);
        return (
            <>{createdDate.toLocaleString()}</>
        )
    }

    const handleDelete = (val) => {
        const data = {
            skillCodeId: val
        };

        deleteSkillCode(data);
        onCloseDeleteModal();
    }

    const handleEdit = () => {
        const data = {
            skillCodeId: selectedSkillCode.id,
            label: newWorkFunctionLabel,
            code: newWorkFunctionCode
        };

        updateSkillCode(data);
        onCloseEditModal();
    }

    const handleAdd = () => {
        const data = {
            label: newWorkFunctionLabel,
            code: newWorkFunctionCode
        };

        addSkillCode(data);
        onCloseAddModal();
    }

    return (
        <div className="mainbar" style={{ display: "flex", flexDirection: "column", flex: 1, minHeight: "100vh" }}>
            <div className="notificationbar">
                <button className="btn-primary" onClick={onOpenAddModal}>Add Skill Code</button>
            </div>
            <div className="actionbar" />

            <DataGrid
                rows={skillCodes}
                getRowId={(row) => row.id}
                disableSelectionOnClick
                columns={columns}
                components={{ NoRowsOverlay }}
                autoHeight
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15]}
                className="datagrid"
            />
            <Modal open={deleteModal} onClose={onCloseDeleteModal} center classNames={{ modal: 'modal__container' }}>
                <div className="modal__heading">
                    Do you want to delete this skill code?
                </div>
                {
                    selectedSkillCode ?
                        Object.keys(selectedSkillCode).map(key => (
                            <>
                                <div>
                                    <span><b>{key} : </b></span>
                                    <span>{key == "createdDate" ? renderDate(selectedSkillCode[key]) : selectedSkillCode[key]}</span>
                                </div><br />
                            </>
                        ))
                        :
                        ""
                }
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary btn-danger"
                        size="small"
                        variant="contained"
                        disabled={!selectedSkillCode}
                        onClick={() => handleDelete(selectedSkillCode.id)}>
                        Ok
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseDeleteModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <Modal open={detailsModal} onClose={onCloseDetailsModal} center classNames={{ modal: 'modal__container' }}>
                <div className="modal__heading">
                    Skill Code Details
                </div>
                {
                    selectedSkillCode ?
                        Object.keys(selectedSkillCode).map(key => (
                            <>
                                <div>
                                    <span><b>{key} : </b></span>
                                    <span>{key == "createdDate" ? renderDate(selectedSkillCode[key]) : selectedSkillCode[key]}</span>
                                </div><br />
                            </>
                        ))
                        :
                        ""
                }
            </Modal>

            <Modal open={addModal} onClose={onCloseAddModal} classNames={{ modal: 'modal__container' }}>
                <div className="modal__heading">
                    Add Skill Code
                </div>
                <div className="modal__inputbox">
                    <input type="text" placeholder="Code" value={newWorkFunctionCode} onChange={(e) => { setNewWorkFunctionCode(e.target.value) }} /><br />
                    <input type="text" placeholder="Label" value={newWorkFunctionLabel} onChange={(e) => { setNewWorkFunctionLabel(e.target.value) }} /><br />
                </div>
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={handleAdd}
                    >
                        Create
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseAddModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <Modal open={editModal} onClose={onCloseEditModal} classNames={{ modal: 'modal__container' }}>
                <div className="modal__heading">
                    Edit Skill Code
                </div>
                <div className="modal__inputbox">
                    <input type="text" placeholder="Code" value={newWorkFunctionCode} onChange={(e) => { setNewWorkFunctionCode(e.target.value) }} /><br />
                    <input type="text" placeholder="Label" value={newWorkFunctionLabel} onChange={(e) => { setNewWorkFunctionLabel(e.target.value) }} /><br />
                </div>
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={handleEdit}
                    >
                        Submit
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseEditModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

ManageSkillCodes.propTypes = {
    fetchSkillCodes: PropTypes.func,
    skillCodeList: PropTypes.object,
    updateSkillCode: PropTypes.func,
    addSkillCode: PropTypes.func,
    deleteSkillCode: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
    skillCodeList: getSkillCodeList()
});

const mapDispatchToProps = (dispatch) => ({
    fetchSkillCodes: (data) => dispatch(fetchSkillCodesRequest(data)),
    updateSkillCode: (data) => dispatch(updateSkillCodeRequest(data)),
    addSkillCode: (data) => dispatch(addSkillCodeRequest(data)),
    deleteSkillCode: (data) => dispatch(deleteSkillCodeRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSkillCodes);