import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AddCustomAssessment from './addNewDrive/AddCustomAssessment';
import AddCustomFields from './addNewDrive/AddCustomFields';
import AddDefaultDrive from './addNewDrive/AddDefaultDrive';
import AddVideoDrive from './addNewDrive/AddVideoDrive';
import AddVideoQuestions from './addNewDrive/AddVideoQuestions';
import InterviewerGuide from '../common/InterviewerGuide';
import SelectAssessmentType from './addNewDrive/SelectAssessmentType';
import { customAssessmentData, workFunctionData } from './newDriveData';
import { getCompanyFeatures } from '../../selectors/app';

import '../../styles/addNewDriveModal.scss';
import { reportWorkFunctions } from '../../constants/reports';

const AddNewDrive = (props) => {
	const { open, onClose, change, setChange, handleSnackClick, setSnackErr, companyName, selectBotMenu, currentWalkthroughStep, updateCurrentWalkthroughStep, currentWalkthroughType, companyFeatures } = props;

	const [showSelectType, setShowSelectType] = useState(true);
	const [showDefaultForm, setShowDefaultForm] = useState(false);
	const [showCustomForm, setShowCustomForm] = useState(false);
	const [showVideoOnlyForm, setShowVideoOnlyForm] = useState(false);
	const [showInterviewGuide, setShowInterviewGuide] = useState(false);
	const [driveRole, setDriveRole] = useState('');
	const [bot, setBot] = useState();
	const [videoQuestions, setVideoQuestions] = useState([]);
	const [showVideoQuestion, setShowVideoQuestion] = useState(false);
	const [addVideoCount, setAddVideoCount] = useState(0);
	const [showCreateCustomAssessment, setShowCreateCustomAssessment] = useState(false);
	const [customAssessmentProblemStatement, setCustomAssessmentProblemStatement] = useState('');
	const [customFieldsData, setCustomFieldsData] = useState({});
	const [workfunctionSelected, setWorkfunctionSelected] = useState([]);
	const [workfunctionOption, setWorkfunctionOption] = useState([]);
	const [jobRole, setJobRole] = useState('');
	const [keyTask, setKeyTask] = useState({});
	const [finalVal, setFinalVal] = useState({});
	const [functionalSkill, setFunctionalSkill] = useState([]);
	const [showKeyTask, setShowKeyTask] = useState(false);
	const [salesStages, setSalesStages] = useState([]);
	const [coreSkill, setCoreSkill] = useState([]);
	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');
	const [industry, setIndustry] = useState('');
	const [workflowFixed, setWorkflowFixed] = useState(true);
	const [showCustomAssessmentEditBtn, setShowCustomAssessmentEditBtn] = useState(false);
	const [nextWalkthroughStep, setNextWalkthroughStep] = useState(4);

	const openDefaultForm = (val) => {
		setBot(val);
		setName('');
		setDesc('');
		setShowSelectType(false);
		setShowDefaultForm(true);
		if (currentWalkthroughStep > 0 && currentWalkthroughType === 'workflow') {
			updateCurrentWalkthroughStep(3);
		}
	}

	const openCustomForm = () => {
		setDriveRole('');
		setShowKeyTask(false);
		setName('');
		setDesc('');
		setSalesStages([]);
		setShowSelectType(false);
		setShowCustomForm(true);
		if (currentWalkthroughStep > 0 && currentWalkthroughType === 'workflow') {
			updateCurrentWalkthroughStep(3);
		}
	}

	const openInterviewGuide = () => {
		setShowCustomForm(false);
		setShowInterviewGuide(true);
	}

	const openCustomAssessmentForm = () => {
		setShowCreateCustomAssessment(true);
		setShowCustomForm(false);
	}

	const closeCustomAssessmentForm = () => {
		setShowCreateCustomAssessment(false);
		setShowCustomForm(true);
	}

	const goBackCustomForm = () => {
		setShowCustomForm(true);
		setShowInterviewGuide(false);
		setShowVideoQuestion(false);
	}

	const openVideoQuestionForm = () => {
		if (videoQuestions.length === 0 || (videoQuestions.length !== addVideoCount)) {
			const videoQuesArr = [];
			for (let i = 0; i < addVideoCount; i++) {
				const videoQuesObj = {
					question: ''
				};
				videoQuesArr.push(videoQuesObj);
			}
			setVideoQuestions([...videoQuesArr]);
		}
		if (addVideoCount > 0) {
			setShowCustomForm(false);
			setShowVideoQuestion(true);
		}
	}

	const goBack = () => {
		setShowCustomForm(false);
		setShowDefaultForm(false);
		setShowSelectType(true);
	}

	useEffect(() => {
		if (open) {
			if (companyFeatures.videoOnlyBot) {
				setShowCustomForm(false);
				setShowDefaultForm(false);
				setShowVideoOnlyForm(true);
				setShowSelectType(false);
			} else {
				setShowVideoOnlyForm(false);
				setShowSelectType(true);
				if (selectBotMenu.length > 0) {
					setShowSelectType(true);
					setShowCustomForm(false);
				} else {
					setShowSelectType(false);
					setShowCustomForm(true);
				}
			}
			setShowDefaultForm(false);
			setShowInterviewGuide(false);
			setDriveRole('');
			setShowVideoQuestion(false);
			setShowCreateCustomAssessment(false);
			setVideoQuestions([]);
			setAddVideoCount(0);
		}
	}, [open]);

	const onChangeCustomAssessment = (val) => {
		if (val) {
			openCustomAssessmentForm();
		} else {
			closeCustomAssessmentForm();
		}
	}

	useEffect(() => {
		if (!driveRole) return;

		setDefaultSettings();
	}, [driveRole]);

	useEffect(() => {
		const selectedRole = jobRole ? jobRole : driveRole;
		let tempKeyTask = {};
		let tempfinalVal = {};

		workfunctionSelected.map((val) => {
			const keyTaskArr = [];
			workFunctionData[selectedRole][val.value].keyTasks.forEach((kt) => {
				keyTaskArr.push(kt.value);
			});
			tempKeyTask = { ...tempKeyTask, [val.value]: [...keyTaskArr] };
			tempfinalVal[selectedRole] = { ...tempfinalVal[selectedRole], [val.value]: [...keyTaskArr] };
		})

		setKeyTask(tempKeyTask);
		setFinalVal(tempfinalVal);
	}, [workfunctionSelected]);

	useEffect(() => {
		if (!jobRole) return;

		if (jobRole != "Custom") {
			if (currentWalkthroughStep > 0 && currentWalkthroughType === 'workflow') {
				setNextWalkthroughStep(6);
				updateCurrentWalkthroughStep(5);
			}
			let arr = [];

			Object.keys(workFunctionData[jobRole]).map((val) => {
				arr.push({ value: val, label: workFunctionData[jobRole][val].label })
			});

			setWorkfunctionOption(arr);
			setWorkfunctionSelected(arr);
		} else {
			setShowKeyTask(false);
			setWorkfunctionOption([]);
			setWorkfunctionSelected([]);
			setSalesStages([]);
		}
	}, [jobRole])

	useEffect(() => {
		if (!driveRole) return;

		if (driveRole == "Associate consultant" || driveRole == "Consultant") {
			let arr = {
				[driveRole]: [...functionalSkill]
			};

			setFinalVal(arr);

			const newCoreSkill = [
				{ value: 'Structuring', label: 'Structuring', weightage: 20 },
				{ value: 'Insights', label: 'Insights', weightage: 20 },
				{ value: 'Synthesis', label: 'Synthesis', weightage: 20 },
				{ value: 'Quantitative', label: 'Quantitative', weightage: 20 },
				{ value: 'Framing', label: 'Framing', weightage: 20 },
			];
			setCoreSkill([...newCoreSkill]);
		}
	}, [functionalSkill]);

	const setDefaultSettings = () => {
		if (driveRole != "Associate consultant" && driveRole != "Consultant" && driveRole != "Sales and Marketing") {
			let arr = [];

			Object.keys(workFunctionData[driveRole]).map((val) => {
				const wfObj = {
					value: val,
					label: workFunctionData[driveRole][val].label
				};

				if (driveRole == 'Associate consultant' || driveRole == 'Consultant') {
					wfObj.weightage = 20;
				}

				arr.push(wfObj)
			});

			setWorkfunctionOption(arr);
			setWorkfunctionSelected(arr);
		}

		if (driveRole == "Product Manager" || driveRole == "Project Manager") {
			if (currentWalkthroughStep > 0 && currentWalkthroughType === 'workflow') {
				setNextWalkthroughStep(6);
				updateCurrentWalkthroughStep(5);
			}
			let customAssessmentProbStatement = customAssessmentData[driveRole].problemStatement;
			customAssessmentData[driveRole].customFields.forEach((cf) => {
				const regex = new RegExp(`{{${cf.key}}}`, "g");
				if (customFieldsData[cf.label]) {
					customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>${customFieldsData[cf.label]}</b>`);
				} else {
					customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>[${cf.label}]</b>`);
				}
			});
			setCustomAssessmentProblemStatement(customAssessmentProbStatement);
		}

		if (driveRole == "Associate consultant" || driveRole == "Consultant") {
			if (currentWalkthroughStep > 0 && currentWalkthroughType === 'workflow') {
				setNextWalkthroughStep(6);
				updateCurrentWalkthroughStep(5);
			}
		}
	}

	useEffect(() => {
		if (currentWalkthroughStep === 6 && showCustomForm) {
			document.getElementById('walkthroughStep6').scrollIntoView();
		}
	}, [showCustomForm])

	return (
		<>
			<div id="addNewDriveModal">
				{
					showSelectType ? (
						<SelectAssessmentType
							selectBotMenu={selectBotMenu}
							openDefaultForm={openDefaultForm}
							openCustomForm={openCustomForm}
							updateCurrentWalkthroughStep={updateCurrentWalkthroughStep}
							nextWalkthroughStep={nextWalkthroughStep}
							currentWalkthroughStep={currentWalkthroughStep}
							setNextWalkthroughStep={setNextWalkthroughStep}
						/>
					) : ''
				}
				{
					showDefaultForm ? (
						<AddDefaultDrive
							goBack={goBack}
							bot={bot}
							companyName={companyName}
							change={change}
							setChange={setChange}
							onClose={onClose}
							handleSnackClick={handleSnackClick}
							setSnackErr={setSnackErr}
							currentWalkthroughStep={currentWalkthroughStep}
							updateCurrentWalkthroughStep={updateCurrentWalkthroughStep}
							nextWalkthroughStep={nextWalkthroughStep}
							setNextWalkthroughStep={setNextWalkthroughStep}
							currentWalkthroughType={currentWalkthroughType}
						/>
					) : ''
				}
				{
					showCustomForm ? (
						<AddCustomAssessment
							goBack={goBack}
							videoQuestions={videoQuestions}
							openInterviewGuide={openInterviewGuide}
							driveRole={driveRole}
							setDriveRole={setDriveRole}
							customFieldsData={customFieldsData}
							setAddVideoCount={setAddVideoCount}
							openVideoQuestionForm={openVideoQuestionForm}
							onChangeCustomAssessment={onChangeCustomAssessment}
							handleSnackClick={handleSnackClick}
							setSnackErr={setSnackErr}
							addVideoCount={addVideoCount}
							onClose={onClose}
							companyName={companyName}
							setDefaultSettings={setDefaultSettings}
							openCustomAssessmentForm={openCustomAssessmentForm}
							change={change}
							setChange={setChange}
							workfunctionSelected={workfunctionSelected}
							setWorkfunctionSelected={setWorkfunctionSelected}
							workfunctionOption={workfunctionOption}
							jobRole={jobRole}
							setJobRole={setJobRole}
							keyTask={keyTask}
							setKeyTask={setKeyTask}
							finalVal={finalVal}
							setFinalVal={setFinalVal}
							functionalSkill={functionalSkill}
							setFunctionalSkill={setFunctionalSkill}
							setShowKeyTask={setShowKeyTask}
							showKeyTask={showKeyTask}
							salesStages={salesStages}
							setSalesStages={setSalesStages}
							coreSkill={coreSkill}
							setCoreSkill={setCoreSkill}
							name={name}
							setName={setName}
							desc={desc}
							setDesc={setDesc}
							workflowFixed={workflowFixed}
							setWorkflowFixed={setWorkflowFixed}
							industry={industry}
							setIndustry={setIndustry}
							showCustomAssessmentEditBtn={showCustomAssessmentEditBtn}
							setShowCustomAssessmentEditBtn={setShowCustomAssessmentEditBtn}
							currentWalkthroughStep={currentWalkthroughStep}
							updateCurrentWalkthroughStep={updateCurrentWalkthroughStep}
							nextWalkthroughStep={nextWalkthroughStep}
							setNextWalkthroughStep={setNextWalkthroughStep}
							currentWalkthroughType={currentWalkthroughType}
						/>
					) : ''
				}
				{
					showInterviewGuide ? (
						<div>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
								<div className='back-btn' onClick={goBackCustomForm}>
									<div></div>
								</div>
							</div>
							<InterviewerGuide productId={driveRole} reportWorkFunctions={reportWorkFunctions} />
						</div>
					) : ''
				}
				{
					showVideoQuestion ? (
						<AddVideoQuestions
							goBackCustomForm={goBackCustomForm}
							videoQuestions={videoQuestions}
							setVideoQuestions={setVideoQuestions}
							currentWalkthroughStep={currentWalkthroughStep}
						/>
					) : ''
				}
				{
					showCreateCustomAssessment ? (
						<AddCustomFields
							closeCustomAssessmentForm={closeCustomAssessmentForm}
							driveRole={driveRole}
							customFieldsData={customFieldsData}
							setCustomFieldsData={setCustomFieldsData}
							setCustomAssessmentProblemStatement={setCustomAssessmentProblemStatement}
							customAssessmentProblemStatement={customAssessmentProblemStatement}
						/>
					) : ''
				}
				{
					showVideoOnlyForm ? (
						<AddVideoDrive
							companyName={companyName}
							change={change}
							setChange={setChange}
							onClose={onClose}
							handleSnackClick={handleSnackClick}
							setSnackErr={setSnackErr}
						/>
					) : ''
				}
			</div>
		</>
	)
}

AddNewDrive.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	change: PropTypes.number,
	setChange: PropTypes.func,
	handleSnackClick: PropTypes.func,
	setSnackErr: PropTypes.func,
	companyName: PropTypes.string,
	selectBotMenu: PropTypes.array,
	currentWalkthroughStep: PropTypes.number,
	updateCurrentWalkthroughStep: PropTypes.func,
	currentWalkthroughType: PropTypes.string,
	companyFeatures: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
	companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewDrive);
