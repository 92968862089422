import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Modal from '../../components/common/CustomModal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Menu, MenuItem } from '@mui/material';

import { editDriveName as editDriveNameRequest } from '../../actions/app';
import { getUserRole } from '../../selectors/app';

import options from '../../assets/Icons/options.png';

import '../../styles/workflows/singleDrive.scss';

const SingleDrive = (props) => {
	const { editDriveName, data, userRole, workflowDeleteMobile } = props;

	const history = useHistory();

	const editNameRef = useRef(null);

	const [anchorEl, setAnchorEl] = useState(null);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openRenameModal, setOpenRenameModal] = useState(false);

	const openEl = Boolean(anchorEl);

	const workflowEdit = (row) => {
		setOpenRenameModal(false);
		editDriveName({ row, newName: editNameRef.current.value, history });
	}

	const MenuItems = [
		{
			name: 'Rename',
			action: () => onOpenRenameModal(),
		},
		{
			name: 'Delete',
			action: () => onOpenDeleteModal(),
		}
	]

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const onOpenDeleteModal = () => {
		setAnchorEl(null);
		setOpenDeleteModal(true);
	};

	const onOpenRenameModal = () => {
		setAnchorEl(null);
		setOpenRenameModal(true);
	}

	const onCloseDeleteModal = () => setOpenDeleteModal(false);
	
	const onCloseRenameModal = () => setOpenRenameModal(false);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleWorkflowDelete = () => {
		setOpenDeleteModal(false);
		workflowDeleteMobile(data.workflowId);
	}

	return (
		<>
			<div className='single-drive'>
				<div className='single-drive__header-box'>
					<p className='single-drive__name'>{data.caseName}</p>
					{userRole.includes('Admin') ?
						<div
							className='single-drive__options-img'
							id="options-btn"
							aria-controls={openEl ? 'options-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={openEl ? 'true' : undefined}
							onClick={handleClick}
						>
							<img src={options} alt="more options" className='imgContain' />
						</div>
						: null
					}
					{MenuItems &&
						<Menu
							id="options-menu"
							anchorEl={anchorEl}
							open={openEl}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'options-btn',
							}}
						>
							{MenuItems.map((item, index) => (
								<MenuItem onClick={item.action} key={index} classes={{ root: 'single-drive__menuList-name' }}>{item.name}</MenuItem>
							))}
						</Menu>
					}
				</div>
				<div className='single-drive__main-box' onClick={() => history.push({
					pathname: '/workflows/' + data.caseName,
					state: { workflowId: data.workflowId, workflowName: data.caseName, sidebar: "quickLook" }
				})}>
					<div className='single-drive__invited-box'>
						<p className='single-drive__stats'>{data.totalCount}</p>
						<p className='single-drive__invited-text'>Invited</p>
					</div>
					<div className='single-drive__started-box'>
						<p className='single-drive__stats'>{data.startedCount}</p>
						<p className='single-drive__started-text'>Started</p>
					</div>
					<div className='single-drive__submitted-box'>
						<p className='single-drive__stats'>{data.underReviewCount}</p>
						<p className='single-drive__submitted-text'>Submitted</p>
					</div>
					<div className='single-drive__reviewed-box'>
						<p className='single-drive__stats'>{data.reviewedCount}</p>
						<p className='single-drive__reviewed-text'>Reviewed</p>
					</div>
				</div>
			</div>
			<Modal open={openDeleteModal} onClose={onCloseDeleteModal} center showCloseIcon={false}>
				<p className='modal-text'>Are you sure you want to delete this drive?</p>
				<div className='modal-box__button-box'>
					<p className='modal-box__button-no' onClick={onCloseDeleteModal}>Cancel</p>
					<p className='modal-box__button-yes' onClick={handleWorkflowDelete}>Delete</p>
				</div>
			</Modal>
			<Modal open={openRenameModal} onClose={onCloseRenameModal} center showCloseIcon={false}>
				<input ref={editNameRef} defaultValue={data.caseName} type="text" className='modal-box__rename-input' />
				<div className='modal-box__button-box'>
					<p className='modal-box__button-no' onClick={onCloseRenameModal}>Cancel</p>
					<p className='modal-box__button-yes' onClick={() => workflowEdit(data)}>Rename</p>
				</div>
			</Modal>
		</>
	)
}

SingleDrive.propTypes = {
	editDriveName: PropTypes.func,
	userRole: PropTypes.string,
	data: PropTypes.object,
	workflowDeleteMobile: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	userRole: getUserRole(),
});

const mapDispatchToProps = (dispatch) => ({
	editDriveName: (row, newName) => dispatch(editDriveNameRequest(row, newName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleDrive);
