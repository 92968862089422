import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, useHistory, useLocation } from "react-router-dom";

import Button from '../common/Button';
import { logoutUser as logoutUserRequest } from '../../actions/app';

import Logo from '../../assets/Logo.png';

import "../../styles/sidebarSuperAdmin.scss";

const SidebarSuperAdmin = (props) => {
	const { logoutUser } = props;

	const history = useHistory();
	const location = useLocation();

	const onLogout = () => {
		logoutUser({ userType: null, history });
	};

	return (
		<div className="sidebar" style={{ flexShrink: 0 }}>
			<div className="sidebar__wrapper">
				<div className="sidebar__logo-div">
					<img className="sidebar__logo" src={Logo} alt="" />
				</div>

				<div className="sidebar__user-div">
				</div>

				<div className={location.pathname === "/companies" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/companies", state: { sidebar: "companies" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Manage Companies</span>
						</Button>
					</Link>
				</div>

				<div className={location.pathname === "/bots" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/bots", state: { sidebar: "bots" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Manage Bots</span>
						</Button>
					</Link>
				</div>

				<div className={location.pathname === "/superUser" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/superUser", state: { sidebar: "superUser" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Manage Super User</span>
						</Button>
					</Link>
				</div>

				<div className={location.pathname === "/feedback" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/feedback", state: { sidebar: "feedback" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Feedbacks</span>
						</Button>
					</Link>
				</div>
				<div className={location.pathname === "/user-plans" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/user-plans", state: { sidebar: "userPlans" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Saas Plans</span>
						</Button>
					</Link>
				</div>
				<div className={location.pathname === "/manage-notifications" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/manage-notifications", state: { sidebar: "manageNotifications" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Notifications</span>
						</Button>
					</Link>
				</div>
				<div className={location.pathname === "/support-msgs" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/support-msgs", state: { sidebar: "supportMsgs" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Support Msgs</span>
						</Button>
					</Link>
				</div>
				<div className={location.pathname === "/behaviour-analytics" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/behaviour-analytics", state: { sidebar: "behaviourAnalytics" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Behaviour Analytics</span>
						</Button>
					</Link>
				</div>
				<div className={location.pathname === "/restart-test" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/restart-test", state: { sidebar: "restartTest" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Restart Test</span>
						</Button>
					</Link>
				</div>
				<div className={location.pathname === "/manage-skill-codes" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/manage-skill-codes", state: { sidebar: "manageSkillCodes" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Manage Skill Codes</span>
						</Button>
					</Link>
				</div>
				<div className={location.pathname === "/manage-company-users" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/manage-company-users", state: { sidebar: "manageCompanyUsers" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Manage Company Users</span>
						</Button>
					</Link>
				</div>

				<div className={location.pathname === "/manage-workflows" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/manage-workflows", state: { sidebar: "manageWorkflows" } }}
						className="sidebar__link">
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z"/>
							</svg>
							<span>&nbsp;Manage Workflows</span>
						</Button>
					</Link>
				</div>

				<div className="sidebar__menu">
					<div
						to="/signin"
						className="sidebar__link"
						onClick={onLogout}>
						<Button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM21 3H3v6h2V5h14v14H5v-4H3v6h18V3z"/>
							</svg>
							<span>&nbsp;Log out</span>
						</Button>
					</div>
				</div>

			</div>
		</div>
	);
}

SidebarSuperAdmin.propTypes = {
	logoutUser: PropTypes.func
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
	logoutUser: (data) => dispatch(logoutUserRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarSuperAdmin);
