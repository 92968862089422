import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Bar } from "react-chartjs-2";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useHistory } from "react-router-dom";

import { getCandidatesByType as getCandidatesByTypeRequest } from '../actions/app';
import { getCandidatesList } from '../selectors/app';

import '../styles/Analytics.scss';

const BarChart = (props) => {
	const { candidates, getCandidatesByType, workflow } = props;

	let location = useLocation();
	const history = useHistory();

	const [progress, setProgress] = useState([]);
	const [droppedOff, setDroppedOff] = useState(0);
	const [dataa, setDataa] = useState({ labels: ["Started", "Completed", "In progress", "Dropped off"], dataSet: [0, 0, 0, 0] })

	useEffect(() => {
		const reqData = {
			status: "progress",
			id: location.state.workflowId
		};

		getCandidatesByType({ reqData, history });
	}, [])

	useEffect(() => {
		if (!candidates) return;

		setProgress(candidates);
	}, [candidates])

	useEffect(() => {
		if (progress.length == 0) return;

		let temp = [];
		progress.map((val) => {
			let prevDate = new Date(val.createdDate);
			let currDate = Date.now();

			let diffDate = Math.abs(currDate - prevDate);
			let diffMin = Math.ceil(diffDate / (1000 * 60));

			if (diffMin >= 30) temp.push(diffMin);
		});

		setDroppedOff(temp.length);
	}, [progress]);

	const chartLabel = (tooltipItem) => {
		let val = 0;
		if (tooltipItem.parsed._custom) {
			val = tooltipItem.parsed._custom.barEnd - tooltipItem.parsed._custom.barStart;
		} else {
			val = tooltipItem.parsed.y;
		}
		return 'Value: ' + val;
	};
	useEffect(() => {
		const total =
			workflow.hired.length +
			workflow.progress.length +
			workflow.rejected.length +
			workflow.shortlisted.length +
			workflow.review.length +
			workflow.withdraw.length +
			workflow.kiv.length;

		const completed = total - workflow.progress.length;
		const inProgress = workflow.progress.length - droppedOff;


		setDataa(
			{
				labels: ["Started", "Completed", "In progress", "Dropped off"],
				dataSet: [total, completed, [completed, completed + inProgress], [inProgress + completed, completed + inProgress + droppedOff]]
			});
	}, [])
	return (
		<div className="App" style={{ height: "40vh", width: "30vw" }}>
			<Bar
				style={{ display: 'relative' }}
				pointStyle="star"
				data={{
					labels: dataa.labels,
					responsive: true,
					offset: false,
					datasets: [
						{
							label: "Value",
							pointStyle: "rectRounded",
							backgroundColor: ["#4FCA64", "#54C5EB", "#FFCC00", "#ED553B"],
							barThickness: 40,
							categoryPercentage: 1,
							data: dataa.dataSet
						},
					]
				}}
				height={220}
				options={{
					offsetGridLines: true,
					drawTicks: true,
					layout: {
						padding: {
							top: 30,
							right: 40,
							bottom: 40
						}
					},
					plugins: {
						tooltip: {
							callbacks: {
								label: chartLabel
							}
						},
						legend: {
							display: false,
							position: "right",
							align: "start",
							labels: {
								usePointStyle: true
							}
						}
					},
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						x: {
							stacked: true,
							grid: { display: false }
						},
						y: {
							title: {
								display: true,
								text: 'Number of Candidates'
							},
							stacked: false,
							grid: { display: true },
							beginAtZero: true
						},
					}
				}}
			/>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data))
});

BarChart.propTypes = {
	candidates: PropTypes.array,
	workflow: PropTypes.object,
	getCandidatesByType: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(BarChart);
