import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";

import axios from "../../../utils/axios";
import CheckoutForm from "./CheckoutForm";
import Modal from '../../../components/common/CustomModal';

const stripePromise = loadStripe("pk_test_51JgqFGLv4iGpyfo8eO3ARFgAAhZw5T0zrd4omTzqpT6MbuG3o56MTbH3BsGelraDriO7wNByiP1HHPZV5gcjdenL00zyv8YHv4");

const StripeTrigger = (props) => {
	const location = useLocation();
	const search = location.search;
	const paymentIntentClientSecret = new URLSearchParams(search).get('payment_intent_client_secret');

	const [clientSecret, setClientSecret] = useState(paymentIntentClientSecret ? paymentIntentClientSecret : "");
	const [openPayment, setOpenPayment] = useState(false);
	
	useEffect(() => {
		if (openPayment) {
			// Create PaymentIntent as soon as the page loads
			const data = {
				saasID: props.saasId,
				companyId: props.companyId,
				item: "62da83e6daf3f07d86299d77"
			}
			axios.post("/user-plan/create-payment-intent", data)
				.then((res) => setClientSecret(res.data.clientSecret));
		}
	}, [openPayment]);

	const appearance = {
		theme: 'stripe',
	};

	const options = {
		clientSecret,
		appearance,
	};

	return (
		<>
			<div onClick={() => setOpenPayment(true)}>Proceed to Pay</div>
			<div>
				{clientSecret && (
					<Modal open={openPayment} onClose={() => setOpenPayment(false)} center styles={{ modal: { padding: 0 }, closeIcon: { color: '#fff' } }}>
						<p style={{ backgroundColor: '#043840', padding: '1rem 3rem 1rem 2rem' }}>Payment Method</p>
						<Elements options={options} stripe={stripePromise}>
							<CheckoutForm />
						</Elements>
					</Modal>
				)}
			</div>
		</>
	);
}

StripeTrigger.propTypes = {
	saasId: PropTypes.string,
	companyId: PropTypes.string
}

export default StripeTrigger;
