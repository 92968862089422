import { useState, useEffect } from 'react'

// Function to get current window width and height
export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState({
		width: 0,
		height: 0,
	})

	useEffect(() => {
		setWindowDimensions({
			width: window.innerWidth,
			height: window.innerHeight,
		})

		function handleResize() {
			setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowDimensions
}
