import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';

import Modal from './CustomModal';
import { getWalkthroughFlows, getWalkthroughWelcome } from '../../selectors/app';
import { updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest, updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest, updateWalkthroughWelcome as updateWalkthroughWelcomeRequest } from '../../actions/app';

import WalkthroughDoneIcon from '../../assets/walkthrough-done.png';

import '../../styles/walkthroughWelcome.scss';

const WalkthroughWelcome = (props) => {
	const { updateCurrentWalkthroughStep, updateCurrentWalkthroughType, walkthroughFlows, updateWalkthroughWelcome, walkthroughWelcome } = props;

	const mediaQueryMobile = window.matchMedia('(max-width: 768px)');
	const history = useHistory();

	const [showExtraFeatures, setShowExtraFeatures] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		if (mediaQueryMobile.matches) {
			setModalOpen(false);
		} else {
			setModalOpen(walkthroughWelcome);
		}
	}, [walkthroughWelcome]);

	const onCloseModal = () => {
		setModalOpen(false);
		localStorage.setItem('walkthrough', false);
		updateWalkthroughWelcome(false);
	}

	const onClickBegin = (val) => {
		onCloseModal();
		updateCurrentWalkthroughStep(1);
		updateCurrentWalkthroughType(val);
		if (val === 'workflow' || val === 'invite' || val === 'evaluate' || val === 'sendemails' || val === 'whitelabelling' || val === 'videoproctoring' || val === 'automaticemails' || val === 'createjob') {
			history.push('/workflows');
		}
		if (val === 'prescreening') {
			history.push({
				pathname: '/prescreening/dashboard',
				state: {
					sidebar: 'preScreening'
				}
			});
		}
	}

	const onClickBeginExtra = () => {
		setShowExtraFeatures(true);
	}

	return (
		<>
			<div
				id='guidedWalkthroughFlow-selector-1'
				style={{
					textTransform: 'uppercase',
					color: '#334D6E',
					fontSize: 15,
					fontWeight: 700,
					padding: '10px 20px',
					borderRadius: 16,
					background: '#fff',
					boxShadow: '0px 4px 10px 0px #109CF13D',
					marginRight: 10,
					cursor: 'pointer'
				}}
				className="tooltip"
				onClick={() => {
					setModalOpen(true);
				}}
			>
				Guided walkthrough
				<span className="tooltiptext" style={{ textTransform: 'none', fontWeight: 500, marginLeft: '-110px' }}>Step by step walkthrough of major functions</span>
			</div>
			<Modal open={modalOpen} onClose={onCloseModal} center>
				<div className='walkthroughWelcome'>
					{
						!showExtraFeatures ? (
							<>
								<div style={{ fontSize: 24, color: '#686970', fontWeight: '600' }}>Welcome to HireQuotient Dashboard!</div>
								<div style={{ fontSize: 20, color: '#686970', fontWeight: '400', marginTop: 5 }}>To ensure your smooth onboarding, let us assist you to understand the product better.</div>
								<div style={{ marginTop: 25, color: '#686970' }}>
									<div style={{ display: 'flex' }}>
										<div style={{ width: 'calc(50% - 25px)', marginRight: 50, borderRadius: 10, padding: '20px 25px 25px', background: 'linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%)', boxShadow: '0px 4px 4px 0px #00000040', cursor: 'pointer', border: walkthroughFlows.includes('workflow') ? '2px solid #81DB57' : 'none' }} onClick={() => onClickBegin('workflow')} id='guidedWalkthroughFlow-selector-2'>
											<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
												<div style={{ fontWeight: '800', fontSize: 18 }}>Activation Step 1/3</div>
												{
													walkthroughFlows.includes('workflow') ? (
														<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
													) : ''
												}
											</div>
											<div style={{ marginTop: 15, fontWeight: '500', fontSize: 16 }}>Create your first drive and choose the relevant assessment</div>
										</div>
										<div style={{ width: 'calc(50% - 25px)', borderRadius: 10, padding: '20px 25px 25px', background: 'linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%)', boxShadow: '0px 4px 4px 0px #00000040', cursor: 'pointer', border: walkthroughFlows.includes('invite') ? '2px solid #81DB57' : 'none' }} onClick={() => onClickBegin('invite')} id='guidedWalkthroughFlow-selector-3'>
											<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
												<div style={{ fontWeight: '800', fontSize: 18 }}>Activation Step 2/3</div>
												{
													walkthroughFlows.includes('invite') ? (
														<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
													) : ''
												}
											</div>
											<div style={{ marginTop: 15, fontWeight: '500', fontSize: 16 }}>Start your campaign by inviting the first candidate</div>
										</div>
									</div>
									<div style={{ display: 'flex', marginTop: 60 }}>
										<div style={{ width: 'calc(50% - 25px)', marginRight: 50, borderRadius: 10, padding: '20px 25px 25px', background: 'linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%)', boxShadow: '0px 4px 4px 0px #00000040', cursor: 'pointer', border: walkthroughFlows.includes('evaluate') ? '2px solid #81DB57' : 'none' }} onClick={() => onClickBegin('evaluate')} id='guidedWalkthroughFlow-selector-4'>
											<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
												<div style={{ fontWeight: '800', fontSize: 18 }}>Activation Step 3/3</div>
												{
													walkthroughFlows.includes('evaluate') ? (
														<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
													) : ''
												}
											</div>
											<div style={{ marginTop: 15, fontWeight: '500', fontSize: 16 }}>Review your first submission</div>
										</div>
										<div style={{ width: 'calc(50% - 25px)', borderRadius: 10, padding: '20px 25px 25px', background: 'linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%)', boxShadow: '0px 4px 4px 0px #00000040', cursor: 'pointer' }} onClick={onClickBeginExtra} id='guidedWalkthroughFlow-selector-5'>
											<div style={{ fontWeight: '800', fontSize: 18 }}>Extra features</div>
											<div style={{ marginTop: 15, fontWeight: '500', fontSize: 16 }}>
												<ul style={{ paddingLeft: 15 }}>
													<li>Pre-screening builder</li>
													<li>Job application portal</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
									<div className='back-btn' onClick={() => setShowExtraFeatures(false)}>
										<div></div>
									</div>
									<div style={{ fontSize: 24, color: '#686970', fontWeight: '600' }}>Extra Features</div>
								</div>
								<div className='extra-features'>
									<div className={`extra-features-item ${walkthroughFlows.includes('prescreening') ? 'done' : ''}`} onClick={() => onClickBegin('prescreening')}>
										Check out the pre-screening questions builder
										{
											walkthroughFlows.includes('prescreening') ? (
												<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
											) : ''
										}
									</div>
									<div className={`extra-features-item ${walkthroughFlows.includes('createjob') ? 'done' : ''}`} onClick={() => onClickBegin('createjob')}>
										Create a job application portal
										{
											walkthroughFlows.includes('createjob') ? (
												<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
											) : ''
										}
									</div>
									<div className={`extra-features-item ${walkthroughFlows.includes('sendemails') ? 'done' : ''}`} onClick={() => onClickBegin('sendemails')}>
										Send emails to shortlisted and rejected candidates
										{
											walkthroughFlows.includes('sendemails') ? (
												<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
											) : ''
										}
									</div>
									<div className={`extra-features-item ${walkthroughFlows.includes('whitelabelling') ? 'done' : ''}`} onClick={() => onClickBegin('whitelabelling')}>
										Add your branding to dashboard and assessment interface
										{
											walkthroughFlows.includes('whitelabelling') ? (
												<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
											) : ''
										}
									</div>
									<div className={`extra-features-item ${walkthroughFlows.includes('automaticemails') ? 'done' : ''}`} onClick={() => onClickBegin('automaticemails')}>
										Set automatic emails to candidates post completion of assessment
										{
											walkthroughFlows.includes('automaticemails') ? (
												<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
											) : ''
										}
									</div>
									<div className={`extra-features-item ${walkthroughFlows.includes('videoproctoring') ? 'done' : ''}`} onClick={() => onClickBegin('videoproctoring')}>
										Enable/ disable recording of videos to proctoring candidates
										{
											walkthroughFlows.includes('videoproctoring') ? (
												<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
											) : ''
										}
									</div>
									<div className={`extra-features-item ${walkthroughFlows.includes('team') ? 'done' : ''}`} onClick={() => onClickBegin('team')}>
										Add team members to the dashboard
										{
											walkthroughFlows.includes('team') ? (
												<div><img style={{ width: 30, height: 30 }} src={WalkthroughDoneIcon} /></div>
											) : ''
										}
									</div>
								</div>
							</>)
					}
					<div style={{ marginTop: 50, display: 'flex', justifyContent: 'center' }}>
						<div style={{ color: '#686970', fontSize: 12, borderBottom: '1px dotted #686970' }} onClick={onCloseModal}>No thanks, I’ll take it from here </div>
					</div>
				</div>
			</Modal>
		</>
	)
}

WalkthroughWelcome.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	updateCurrentWalkthroughStep: PropTypes.func,
	updateCurrentWalkthroughType: PropTypes.func,
	walkthroughFlows: PropTypes.array,
	walkthroughWelcome: PropTypes.bool,
	updateWalkthroughWelcome: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	walkthroughFlows: getWalkthroughFlows(),
	walkthroughWelcome: getWalkthroughWelcome()
});

const mapDispatchToProps = (dispatch) => ({
	updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
	updateCurrentWalkthroughType: (data) => dispatch(updateCurrentWalkthroughTypeRequest(data)),
	updateWalkthroughWelcome: (data) => dispatch(updateWalkthroughWelcomeRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WalkthroughWelcome);
