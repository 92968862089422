import React from "react";
import PropTypes from 'prop-types';

import "../../styles/Button.scss";

const Button = (props) => {
	const {
		children,
		onClick,
		className = "",
		size = "medium",
		variant = "text",
		color = "primary",
		disabled = false,
		style = {},
		id = "",
		type = "button",
		...other
	} = props;

	return (
		<button
			id={id}
			style={style}
			className={`${className} ${size === "small"
					? "btn-small"
					: size === "large"
						? "btn-large"
						: "btn-medium"
				} ${variant === "text"
					? "btn-text"
					: variant === "contained"
						? "btn-contained"
						: "btn-outlined"
				}
			${color === "primary"
					? "button-primary"
					: color === "secondary"
						? "btn-secondary"
						: color === "success"
							? "btn-success"
							: color === "error"
								? "btn-error"
								: color === "info"
									? "btn-info"
									: "btn-warning"
				} custom-btn`}
			onClick={onClick}
			disabled={disabled}
			type={type}
			{...other}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	onClick: PropTypes.func,
	className: PropTypes.string,
	size: PropTypes.string,
	variant: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	id: PropTypes.string,
	type: PropTypes.string,
}

export default Button;
