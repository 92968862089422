import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from '../../utils/axios';
import Button from './Button';

import '../../styles/proctoringModal.scss';

const ProctoringModal = (props) => {
	const { proctoringData, onCloseProctoringModal, selectedRow, resolveProctoringStatus, changeStatus } = props;

	const [tabChangeExpanded, setTabChangeExpanded] = useState(false);
	const [copyPasteExpanded, setCopyPasteExpanded] = useState(false);
	const [multipleFaceExpanded, setMultipleFaceExpanded] = useState(false);
	const [mobilePhoneExpanded, setMobilePhoneExpanded] = useState(false);
	const [ipChangeExpanded, setIpChangeExpanded] = useState(false);
	// const [repeatedTestExpanded, setRepeatedTestExpanded] = useState(false);
	const [proctoringUrl, setProctoringUrl] = useState(`https://myproctvideos.s3.ap-southeast-1.amazonaws.com/${proctoringData.userId}.avi`);

	const renderTime = (pe) => {
		if (pe.createdDate) {
			const createdDate = new Date(proctoringData.candidateData.createdDate);
			const eventDate = new Date(pe.createdDate);
			const timeDiff = parseInt(((eventDate.getTime() - createdDate.getTime()) / 1000), 10);

			const timeDiffMins = Math.floor(timeDiff / 60);
			const timeDiffSecs = parseInt(timeDiff % 60)

			return `${timeDiffMins < 10 ? 0 : ''}${timeDiffMins}:${timeDiffSecs < 10 ? 0 : ''}${timeDiffSecs}`;
		} else {
			return '00:00';
		}
	}

	useEffect(() => {
		axios.post('/cand-proct-video', { key: `${proctoringData.userId}.avi` })
			.then((res) => {
				setProctoringUrl(res.data.signedUrl)
			})
			.catch((err) => {
				console.log(err);
			})
	}, [proctoringData])

	return (
		<div className='proctoringModal'>
			<div className="modal-text">
				Red flags
			</div>
			<div style={{ marginTop: 15 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, background: '#E92525', marginRight: 15 }}>
							<div style={{ width: 15, height: 2, background: '#fff' }}></div>
						</div>
						<div>
							<div style={{ fontSize: 24, fontWeight: 'bold', color: proctoringData.proctoringConcern === 'High' ? '#EA5858' : (proctoringData.proctoringConcern === 'Medium' ? '#FD8064' : '#FDC064') }}>{proctoringData.proctoringConcern}</div>
							<div style={{ color: '#014954', fontSize: 14 }}>Concern Level</div>
						</div>
					</div>
					<div>
						<a rel="noreferrer" href={proctoringUrl} target="_blank" style={{ color: '#014954', fontSize: 14, textDecoration: 'underline' }}>Watch proctoring video</a>
					</div>
				</div>
				{
					proctoringData.tabChangeCount > 0 ? (
						<div className='proctoring-accordian' style={{ marginTop: 35 }}>
							<div className='proctoring-box'>
								<div className='proctoring-count'>
									<div className='proctoring-type'>Switching tabs</div>
									<div className='proctoring-details'>Number of times tabs were changed - {proctoringData.tabChangeCount}</div>
								</div>
								<div className={`proctoring-expand-btn ${tabChangeExpanded ? 'active' : ''}`} onClick={() => setTabChangeExpanded(!tabChangeExpanded)}></div>
							</div>
							{
								tabChangeExpanded ? (
									<div className='proctoring-box-expanded'>
										<div className='proctoring-timestamps'>
											<div className='proctoring-timestamp-title'>Time stamp</div>
											<div className='proctoring-timestamp-details'>
												{
													proctoringData.proctoringEvents.map((pe, index) => {
														if (pe.eventType === 'tabChange') {
															return (
																<div key={index}>- {renderTime(pe)}</div>
															)
														}
													})
												}
											</div>
										</div>
										<div className='proctoring-resolve-btn'>
											<Button
												style={{
													background: '#479BD2',
													fontWeight: 'bold',
													letterSpacing: "0.01em",
													borderRadius: '25px',
													padding: "6px 20px",
													fontSize: '16px'
												}}
												size="small"
												variant="contained"
												onClick={() => resolveProctoringStatus('tabChange', selectedRow)}>
												Resolve
											</Button>
										</div>
									</div>
								) : ''
							}
						</div>
					) : ''
				}
				{
					proctoringData.copyPaste > 0 ? (
						<div className='proctoring-accordian' style={{ marginTop: 10 }}>
							<div className='proctoring-box'>
								<div className='proctoring-count'>
									<div className='proctoring-type'>Copy Paste Detected</div>
									<div className='proctoring-details'>Number of times copy paste detected - {proctoringData.copyPaste}</div>
								</div>
								<div className={`proctoring-expand-btn ${copyPasteExpanded ? 'active' : ''}`} onClick={() => setCopyPasteExpanded(!copyPasteExpanded)}></div>
							</div>
							{
								copyPasteExpanded ? (
									<div className='proctoring-box-expanded'>
										<div className='proctoring-timestamps'>
											<div className='proctoring-timestamp-title'>Time stamp</div>
											<div className='proctoring-timestamp-details'>
												{
													proctoringData.proctoringEvents.map((pe, index) => {
														if (pe.eventType === 'copypaste') {
															return (
																<div key={index}>- {renderTime(pe)}</div>
															)
														}
													})
												}
											</div>
										</div>
										<div className='proctoring-resolve-btn'>
											<Button
												style={{
													background: '#479BD2',
													fontWeight: 'bold',
													letterSpacing: "0.01em",
													borderRadius: '25px',
													padding: "6px 20px",
													fontSize: '16px'
												}}
												size="small"
												variant="contained"
												onClick={() => resolveProctoringStatus('copypaste', selectedRow)}>
												Resolve
											</Button>
										</div>
									</div>
								) : ''
							}
						</div>
					) : ''
				}
				{
					proctoringData.faceDetected > 0 ? (
						<div className='proctoring-accordian' style={{ marginTop: 10 }}>
							<div className='proctoring-box'>
								<div className='proctoring-count'>
									<div className='proctoring-type'>Multiple Face Detection</div>
									<div className='proctoring-details'>Face detected - Yes</div>
								</div>
								<div className={`proctoring-expand-btn ${multipleFaceExpanded ? 'active' : ''}`} onClick={() => setMultipleFaceExpanded(!multipleFaceExpanded)}></div>
							</div>
							{
								multipleFaceExpanded ? (
									<div className='proctoring-box-expanded'>
										<div className='proctoring-resolve-btn'>
											<Button
												style={{
													background: '#479BD2',
													fontWeight: 'bold',
													letterSpacing: "0.01em",
													borderRadius: '25px',
													padding: "6px 20px",
													fontSize: '16px'
												}}
												size="small"
												variant="contained"
												onClick={() => resolveProctoringStatus('multipleFace', selectedRow)}>
												Resolve
											</Button>
										</div>
									</div>
								) : ''
							}
						</div>
					) : ''
				}
				{
					proctoringData.phoneDetected > 0 ? (
						<div className='proctoring-accordian' style={{ marginTop: 10 }}>
							<div className='proctoring-box'>
								<div className='proctoring-count'>
									<div className='proctoring-type'>Mobile Phone Detection</div>
									<div className='proctoring-details'>Mobile Phone - Yes</div>
								</div>
								<div className={`proctoring-expand-btn ${mobilePhoneExpanded ? 'active' : ''}`} onClick={() => setMobilePhoneExpanded(!mobilePhoneExpanded)}></div>
							</div>
							{
								mobilePhoneExpanded ? (
									<div className='proctoring-box-expanded'>
										<div className='proctoring-resolve-btn'>
											<Button
												style={{
													background: '#479BD2',
													fontWeight: 'bold',
													letterSpacing: "0.01em",
													borderRadius: '25px',
													padding: "6px 20px",
													fontSize: '16px'
												}}
												size="small"
												variant="contained"
												onClick={() => resolveProctoringStatus('mobilePhone', selectedRow)}>
												Resolve
											</Button>
										</div>
									</div>
								) : ''
							}
						</div>
					) : ''
				}
				{
					proctoringData.ipChange > 0 ? (
						<div className='proctoring-accordian' style={{ marginTop: 10 }}>
							<div className='proctoring-box'>
								<div className='proctoring-count'>
									<div className='proctoring-type'>IP Change Detection</div>
									<div className='proctoring-details'>IP Change - Yes</div>
								</div>
								<div className={`proctoring-expand-btn ${ipChangeExpanded ? 'active' : ''}`} onClick={() => setIpChangeExpanded(!ipChangeExpanded)}></div>
							</div>
							{
								ipChangeExpanded ? (
									<div className='proctoring-box-expanded'>
										<div className='proctoring-resolve-btn'>
											<Button
												style={{
													background: '#479BD2',
													fontWeight: 'bold',
													letterSpacing: "0.01em",
													borderRadius: '25px',
													padding: "6px 20px",
													fontSize: '16px'
												}}
												size="small"
												variant="contained"
												onClick={() => resolveProctoringStatus('ipChange', selectedRow)}>
												Resolve
											</Button>
										</div>
									</div>
								) : ''
							}
						</div>
					) : ''
				}
				{/* {
					proctoringData.repeatedTest > 0 ? (
						<div className='proctoring-accordian' style={{ marginTop: 10 }}>
							<div className='proctoring-box'>
								<div className='proctoring-count'>
									<div className='proctoring-type'>Duplicate Candidate Detection</div>
									<div className='proctoring-details'>Duplicate Candidate - Yes</div>
								</div>
								<div className={`proctoring-expand-btn ${repeatedTestExpanded ? 'active' : ''}`} onClick={() => setRepeatedTestExpanded(!repeatedTestExpanded)}></div>
							</div>
							{
								repeatedTestExpanded ? (
									<div className='proctoring-box-expanded'>
										<div className='proctoring-resolve-btn'>
											<Button
												style={{
													background: '#479BD2',
													fontWeight: 'bold',
													letterSpacing: "0.01em",
													borderRadius: '25px',
													padding: "6px 20px",
													fontSize: '16px'
												}}
												size="small"
												variant="contained"
												onClick={() => resolveProctoringStatus('repeatedTest', selectedRow)}>
												Resolve
											</Button>
										</div>
									</div>
								) : ''
							}
						</div>
					) : ''
				} */}
			</div>
			<div className="button-div" style={{ marginTop: 15 }}>
				<Button
					className="modal-button"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: "6px 20px",
						fontSize: '16px'
					}}
					size="small"
					variant="contained"
					onClick={() => {
						onCloseProctoringModal();
						resolveProctoringStatus('all', selectedRow);
					}}>
					Resolve All
				</Button>
				<Button
					className="cancel-modal-button"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: "6px 20px",
						fontSize: '16px'
					}}
					size="small"
					variant="contained"
					onClick={() => {
						onCloseProctoringModal();
						changeStatus('Rejected', selectedRow)
					}}>
					Reject candidate
				</Button>
			</div>
		</div>
	)
}

ProctoringModal.propTypes = {
	proctoringData: PropTypes.object,
	onCloseProctoringModal: PropTypes.func,
	selectedRow: PropTypes.array,
	resolveProctoringStatus: PropTypes.func,
	changeStatus: PropTypes.func
}

export default ProctoringModal;
