import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid } from "@mui/x-data-grid";

import Button from '../components/common/Button';
import Modal from '../components/common/CustomModal';
import { getCompaniesList, getCompanyUserList } from '../selectors/app';
import { fetchCompanies as fetchCompaniesRequest, deleteCompanyUser as deleteCompanyUserRequest, updateCompanyUser as updateCompanyUserRequest, fetchCompanyUsers as fetchCompanyUsersRequest } from '../actions/app';

import emptyRowImg from '../assets/emptyrow1.png';

const ManageCompanyUsers = (props) => {
	const { companyUserList, fetchCompanyUsers, updateCompanyUser, deleteCompanyUser, fetchCompanies, allCompanies } = props;

	const [detailsModal, setDetailsModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
	const [pageSize, setPageSize] = useState(15);
	const [selectedCompanyUser, setSelectedCompanyUser] = useState();
	const [selectedCompany, setSelectedCompany] = useState();

	const onOpenDetailsModal = (val) => {
        setSelectedCompanyUser(val)
        setDetailsModal(true);
    }

    const onCloseDetailsModal = () => {
        setSelectedCompanyUser();
        setDetailsModal(false);
    }

	const onOpenDeleteModal = (val) => {
        setSelectedCompanyUser(val);
        setDeleteModal(true);
    }

    const onCloseDeleteModal = () => {
        setSelectedCompanyUser();
        setDeleteModal(false);
    }

	useEffect(() => {
		fetchCompanies();
		fetchCompanyUsers();
	}, []);

	const onChangeCompany = (e) => {
		setSelectedCompany(e.target.value);
		fetchCompanyUsers({ companyId: e.target.value });
	}

	const onResetPassword = (val) => {
		const data = {
			companyUserId: val._id,
		};

		updateCompanyUser(data);
	}

	const columns = [
		{
			field: "name",
			headerName: "name",
			headerAlign: "left",
			align: 'left',
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row datagrid__row-center">
							{params.row.name}
						</p>
					</>
				);
			}
		},
		{
			field: "email",
			headerName: "Email",
			headerAlign: "left",
			align: 'left',
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row datagrid__row-center">
							{params.row.email}
						</p>
					</>
				);
			}
		},
		{
			field: "action",
			headerName: "Actions",
			flex: 2,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<div className="datagrid__row datagrid__row-center">
						<Button
							className="btn-datagrid"
							size="small"
							variant="contained"
							onClick={() => onOpenDetailsModal(params.row)}
						>
							View
						</Button>
						<Button
							className="btn-datagrid"
							size="small"
							variant="contained"
							onClick={() => onResetPassword(params.row)}
						>
							Reset Password
						</Button>
						<Button
							className="btn-datagrid"
							size="small"
							variant="contained"
							onClick={() => onOpenDeleteModal(params.row)}
						>
							Delete
						</Button>
					</div>
				)
			}
		},
	]

	function renderDate(date) {
		let createdDate = new Date(date);
		return (
			<>{createdDate.toLocaleString()}</>
		)
	}

	function NoRowsOverlay() {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	const handleDelete = (val) => {
        const data = {
            companyUserId: val,
			companyId: selectedCompany
        };

        deleteCompanyUser(data);
        onCloseDeleteModal();
    }

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", width: "80vw", minHeight: "100vh" }}>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center classNames={{ modal: 'modal__container' }}>
                <div className="modal__heading">
                    Do you want to delete this company user?
                </div>
                {
                    selectedCompanyUser ?
                        Object.keys(selectedCompanyUser).map(key => (
                            <>
                                <div>
                                    <span><b>{key} : </b></span>
                                    <span>{key == "createdDate" ? renderDate(selectedCompanyUser[key]) : selectedCompanyUser[key]}</span>
                                </div><br />
                            </>
                        ))
                        :
                        ""
                }
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary btn-danger"
                        size="small"
                        variant="contained"
                        disabled={!selectedCompanyUser}
                        onClick={() => handleDelete(selectedCompanyUser._id)}>
                        Ok
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseDeleteModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <Modal open={detailsModal} onClose={onCloseDetailsModal} center classNames={{ modal: 'modal__container' }}>
                <div className="modal__heading">
                    Company user Details
                </div>
                {
                    selectedCompanyUser ?
                        Object.keys(selectedCompanyUser).map(key => (
                            <>
                                <div>
                                    <span><b>{key} : </b></span>
                                    <span>{key == "createdDate" ? renderDate(selectedCompanyUser[key]) : selectedCompanyUser[key]}</span>
                                </div><br />
                            </>
                        ))
                        :
                        ""
                }
            </Modal>
			<div className="notificationbar" />
			<div className="actionbar" />
			<div style={{ padding: 40, fontSize: 24, fontWeight: 'bold', background: '#fff' }}>Company Users</div>
			<div style={{ display: 'flex', marginTop: 25, justifyContent: 'space-between' }}>
				<select onChange={onChangeCompany}>
					<option>Select Company</option>
					{
						allCompanies.map((comp) => (
							<option key={comp._id} value={comp._id}>{comp.name}</option>
						))
					}
				</select>
			</div>
			<div className="datagrid__container">
				<DataGrid
					sortingOrder={['desc', 'asc', null]}
					components={{ NoRowsOverlay }}
					getRowId={(row) => row._id}
					rows={companyUserList}
					disableSelectionOnClick
					columns={columns}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					className="datagrid__scroll scroll-blue"
				/>
			</div>
		</div>
	);
}

ManageCompanyUsers.propTypes = {
	fetchCompanies: PropTypes.func,
	allCompanies: PropTypes.array,
	deleteCompanyUser: PropTypes.func,
	updateCompanyUser: PropTypes.func,
	fetchCompanyUsers: PropTypes.func,
	companyUserList: PropTypes.array
}

const mapStateToProps = createStructuredSelector({
	allCompanies: getCompaniesList(),
	companyUserList: getCompanyUserList(),
});

const mapDispatchToProps = (dispatch) => ({
	fetchCompanies: () => dispatch(fetchCompaniesRequest()),
	deleteCompanyUser: (data) => dispatch(deleteCompanyUserRequest(data)),
	updateCompanyUser: (data) => dispatch(updateCompanyUserRequest(data)),
	fetchCompanyUsers: (data) => dispatch(fetchCompanyUsersRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCompanyUsers);
