import React, { useState, useEffect, useRef } from 'react';
import D3Funnel from 'd3-funnel';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useTour } from '@reactour/tour';

import AccountMenu from "../components/AccountMenu";
import axios from "../utils/axios";
import BarC from '../components/BarChart';
import BottomBar from "../components/common/BottomBar";
import Header from "../components/header/header";
import NotificationBell from "../components/notifications/NotificationBell";
import WalkthroughWelcome from '../components/common/WalkthroughWelcome';
import { redirectUnauthorisedUser } from '../helper/helperFunction';

import '../styles/Analytics.scss';

const Analytics = (props) => {
	const { notification } = props;

	const { isOpen } = useTour();
	const location = useLocation();
	const history = useHistory();

	const [workflow, setWorkflow] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [finalData, setFinalData] = useState();
	const [rating, setRating] = useState('--');
	const [funnelData, setFunnelData] = useState();
	const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

	const fin = useRef();

	useEffect(() => {
		if (finalData)
			setLoading(false)
	}, [finalData])

	useEffect(() => {
		axios.post("/getfeedback/", { workflow: location.state.workflowId })
			.then((res) => {
				if (res.data > 0.0)
					setRating(res.data.toFixed(2))
			}).catch((err) => {
				console.log(err)
				redirectUnauthorisedUser(err, history);
			});
	}, [])


	useEffect(() => {
		axios.get(("/workflow/" + location.state.workflowId))
			.then((res) => {
				setWorkflow(res.data)
				setFinalData([
					<tr key='invited'>
						<td>
							<div style={{ width: "15px", height: "15px", backgroundColor: '#4FCA64', borderRadius: "50%" }}></div>
						</td>
						<td style={{ fontSize: '16px' }}>Invited</td>
						<td style={{ fontSize: '16px' }}>{res.data.candidates.length - res.data.added.length}</td>
						<td>
							<Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }} id="analyticsFlow-selector-1">
								View full list
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#4FCA64`, marginLeft: '0.5rem' }}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
								</svg>
							</Link>
						</td>
					</tr>,
					<tr key='submitted'>
						<td>
							<div style={{ width: "15px", height: "15px", backgroundColor: `#54C5EB`, borderRadius: "50%" }}></div>
						</td>
						<td style={{ fontSize: '16px' }}>Submitted</td>
						<td style={{ fontSize: '16px' }}>{res.data.hired.length + res.data.rejected.length + res.data.shortlisted.length + res.data.review.length + res.data.kiv.length}</td>
						<td>
							<Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }} id="analyticsFlow-selector-2">
								View full list
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#54C5EB`, marginLeft: '0.5rem' }}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
								</svg>
							</Link>
						</td>
					</tr>,
					<tr key='shortlisted'>
						<td>
							<div style={{ width: "15px", height: "15px", backgroundColor: `#FFB74A`, borderRadius: "50%" }}></div>
						</td>
						<td style={{ fontSize: '16px' }}>Shortlisted</td>
						<td style={{ fontSize: '16px' }}>{res.data.shortlisted.length}</td>
						<td>
							<Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }} id="analyticsFlow-selector-3">
								View full list
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#FFB74A`, marginLeft: '0.5rem' }}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
								</svg>
							</Link>
						</td>
					</tr>,
					<tr key='hired'>
						<td>
							<div style={{ width: "15px", height: "15px", backgroundColor: `#22242C`, borderRadius: "50%" }}></div>
						</td>
						<td style={{ fontSize: '16px' }}>Hired</td>
						<td style={{ fontSize: '16px' }}>{res.data.hired.length}</td>
					</tr>]);
				const dataf = [
					{ label: 'Invited', value: 100 },
					{ label: 'Submitted', value: ((res.data.review.length + res.data.shortlisted.length + res.data.hired.length + res.data.rejected.length + res.data.kiv.length) * 100.0 / res.data.candidates.length).toFixed() },
					{ label: 'Shortlisted', value: (res.data.shortlisted.length * 100.0 / res.data.candidates.length).toFixed() },
					{ label: 'Hired', value: (res.data.hired.length * 100.0 / res.data.candidates.length).toFixed() },
				];
				const optionf = {
					block: {
						dynamicHeight: true,
						dynamicSlope: false,
						minHeight: 10,
						fill: {
							type: 'gradient',
							scale: ['#4FCA64', '#54C5EB', '#FFB74A', '#22242C']
						}
					},
					tooltip: {
						enabled: true,
						format: '{l} : {f}%'
					},
					label: { enabled: false },
					chart: {
						curve: {
							width: mediaQueryMobile.matches ? '100%' : null,
							height: 15,
							enabled: true
						},
						totalCount: 200,
						inverted: false
					}
				};
				setFunnelData(<div className="chart" ref={fin} id='chart1' style={{ height: "150px" }}></div>)
				const chart = new D3Funnel(fin.current)
				chart.draw(dataf, optionf)
			})
			.catch((err) => {
				console.log(err)
				redirectUnauthorisedUser(err, history);
			})
	}, []);

	function renderData() {
		return finalData;
	}

	function renderFunnelData() {
		return funnelData;
	}

	return (
		<div className='quick-menu-mobile-handler'>
			{mediaQueryMobile.matches && <Header />}
			<div className='quick-menu-handler'>
				<div className="inner-div" style={{ marginBottom: '10px' }}>
					<div className='top-icon-box'>
						<WalkthroughWelcome />
						<NotificationBell notification={notification} />
						<AccountMenu />
					</div>
				</div>
				<div className='analytics-header'>
					<div className="inner-div-details" style={{ marginBottom: '10px' }}>
						<h2>Analytics</h2>
						<p>{!isLoading ? workflow.name : null}</p>
					</div>
				</div>
				<div className="container-div">
					<div className="chart-outer-div" style={{ height: "max-content" }}>
						<div className="chart-left-div">
							<div className="chart-inner-div">
								<div className="title">
									<h3>Overview</h3>
								</div>
							</div>
							{renderFunnelData()}
						</div>
						<div className="chart-right-div">
							<table>
								<tbody>
									{!isOpen ? "" :
										<>
											<tr>
												<td>
													<div style={{ width: "15px", height: "15px", backgroundColor: '#4FCA64', borderRadius: "50%" }}></div>
												</td>
												<td style={{ fontSize: '16px' }}>Invited</td>
												<td style={{ fontSize: '16px' }}>{50}</td>
												<td>
													<Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }}>
														View full list
														<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#4FCA64`, marginLeft: '0.5rem' }}>
															<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
														</svg>
													</Link>
												</td>
											</tr>
											<tr>
												<td>
													<div style={{ width: "15px", height: "15px", backgroundColor: `#54C5EB`, borderRadius: "50%" }}></div>
												</td>
												<td style={{ fontSize: '16px' }}>Submitted</td>
												<td style={{ fontSize: '16px' }}>{32}</td>
												<td>
													<Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }}>
														View full list
														<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#54C5EB`, marginLeft: '0.5rem' }}>
															<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
														</svg>
													</Link>
												</td>
											</tr>
											<tr>
												<td>
													<div style={{ width: "15px", height: "15px", backgroundColor: `#FFB74A`, borderRadius: "50%" }}></div>
												</td>
												<td style={{ fontSize: '16px' }}>Shortlisted</td>
												<td style={{ fontSize: '16px' }}>{23}</td>
												<td>
													<Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }}>
														View full list
														<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#FFB74A`, marginLeft: '0.5rem' }}>
															<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
														</svg>
													</Link>
												</td>
											</tr>
											<tr>
												<td>
													<div style={{ width: "15px", height: "15px", backgroundColor: `#22242C`, borderRadius: "50%" }}></div>
												</td>
												<td style={{ fontSize: '16px' }}>Hired</td>
												<td style={{ fontSize: '16px' }}>{15}</td>
											</tr>
										</>
									}
									{isOpen ? "" : renderData()}
								</tbody>
							</table>
						</div>
					</div>

					<div className="chart-outer-div2" style={{ marginTop: "20px" }}>
						<div className="chart-left-div">
							<div className="chart-inner-div">
								<div className="title">
									<h3>Insights</h3>
								</div>
							</div>
							<div>{workflow ? <BarC workflow={workflow} style={{ height: "30vh" }} /> : ''}</div>
						</div>
						<div className="chart-right-div">
							<table style={{ fontSize: "14px" }}>
								<tbody>
									<tr>
										<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "5vh" }}>
											<div style={{ minWidth: "8vh", height: "8vh", borderRadius: "50%", background: 'rgba(52, 188, 212, 1)', color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.8vh" }}>
												{process.env.REACT_APP_BASE_URL.includes('test.hirequotient.org') ? "$1100" : "$"}
											</div>
											<div className='stats-heading'>
												Saved&nbsp;
											</div>
											<div className='stats-text'>
												so far
											</div>
										</div>
									</tr>
									<tr>
										<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "5vh" }}>
											<div style={{ minWidth: "8vh", height: "8vh", borderRadius: "50%", background: 'rgba(52, 188, 212, 1)', color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.8vh" }}>
												{process.env.REACT_APP_BASE_URL.includes('test.hirequotient.org') ? "4.7" : rating}
											</div>
											<div className='stats-heading'>
												Overall rating&nbsp;
												{mediaQueryMobile && <span className="stats-text">given by candidates globally</span>}
											</div>
											{!mediaQueryMobile &&
												<div className='stats-text'>
													given by candidates globally
												</div>
											}
										</div>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{mediaQueryMobile.matches && <BottomBar />}
		</div>
	)
};

Analytics.propTypes = {
	notification: PropTypes.object,
	userPlan: PropTypes.object
}

export default Analytics;
