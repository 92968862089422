import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { DataGrid } from "@mui/x-data-grid";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from "react-router-dom";

import Button from '../components/common/Button';
import Modal from '../components/common/CustomModal';
import {
	addBots as addBotsRequest,
	deleteBots as deleteBotsRequest,
	fetchBots as fetchBotsRequest,
	updateBotDetails as updateBotDetailsRequest
} from '../actions/app';
import { getConvoList } from '../selectors/app';

import emptyRowImg from '../assets/emptyrow1.png';

const Bots = (props) => {
	const { addBots, deleteBots, convo, fetchBots, updateBotDetails } = props;

	const history = useHistory();

	const [newModal, setNewModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [name, setName] = useState("");
	const [productId, setProductId] = useState("");
	const [problem, setProblem] = useState("");
	const [botTime, setBotTime] = useState(30);
	const [botSectionCount, setBotSectionCount] = useState(1);
	const [pageSize, setPageSize] = useState(15);
	const [botToBeDeleted, setBotToBeDeleted] = useState({});
	const [selectedBotId, setSelectedBotId] = useState();

	const onOpenNewModal = () => setNewModal(true);

	const onCloseNewModal = () => {
		setName('');
		setProductId('');
		setProblem('');
		setBotTime(30);
		setNewModal(false);
	}

	const onOpenDeleteModal = (val) => {
		setBotToBeDeleted({ ...val });
		setDeleteModal(true)
	};

	const onCloseDeleteModal = () => {
		setDeleteModal(false);
	}

	const onOpenEditModal = (val) => {
		setSelectedBotId(val._id);
		setName(val.name);
		setProductId(val.productId);
		setProblem(val.problem);
		setBotTime(val.assessmentTime ? val.assessmentTime : 30);
		setEditModal(true);
	}

	const onCloseEditModal = () => {
		setEditModal(false);
	}

	useEffect(() => {
		fetchBots({ history });
	}, []);

	const onSubmitEdit = () => {
		const botData = {
			botId: selectedBotId,
			name,
			productId,
			problem,
			assessmentTime: botTime,
			sectionCount: botSectionCount
		};

		updateBotDetails(botData);
		onCloseEditModal();
	}

	const columns = [
		{
			field: "name",
			headerName: "Name",
			headerAlign: "left",
			align: "left",
			flex: 3
		},
		{
			field: "productId",
			headerName: "Product ID",
			headerAlign: "left",
			flex: 1
		},
		{
			field: "action",
			headerName: "Actions",
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<div className="datagrid__row datagrid__row-center">
						<Button
							className="btn-datagrid btn-danger"
							size="small"
							variant="contained"
							onClick={() => onOpenEditModal(params.row)}
						>
							Edit
						</Button>
						<Button
							className="btn-datagrid btn-danger"
							size="small"
							variant="contained"
							onClick={() => onOpenDeleteModal(params.row)}
						>
							Delete
						</Button>
					</div>
				)
			}
		}
	]

	const sendBots = () => {
		onCloseNewModal();
		addBots({ name: name, productId: productId, problem: problem, assessmentTime: botTime, sectionCount: botSectionCount });
	}

	const handleDelete = (id) => {
		onCloseDeleteModal();
		deleteBots({ botId: id });
	}

	const renderDate = (date) => {
		const createdDate = new Date(date);

		return (
			<>{createdDate.toLocaleString()}</>
		)
	}

	const NoRowsOverlay = () => {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", flex: 1, minHeight: "100vh" }}>
			<div className="notificationbar">
				<button className="btn-primary" onClick={onOpenNewModal}>Create new Bot</button>
			</div>
			<div className="actionbar" />
			
			<div className="datagrid__container">
				<DataGrid
					sortingOrder={['desc', 'asc']}
					components={{ NoRowsOverlay }}
					getRowId={(row) => row._id}
					rows={convo.length == 0 ? [] : convo}
					disableSelectionOnClick
					columns={columns}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					className="datagrid__scroll scroll-blue"
				/>
			</div>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center classNames={{ modal: 'modal__container' }}>
				<div className="modal__heading">
					Do you want to delete this bot?
				</div>
				{
					Object.keys(botToBeDeleted).map(key => (
						<>
							<div>
								<span><b>{key} : </b></span>
								<span>{key == "createdDate" ? renderDate(botToBeDeleted[key]) : botToBeDeleted[key]}</span>
							</div><br />
						</>
					))
				}
				<div className="modal__btnbox">
					<Button
						className="btn-primary btn-danger"
						size="small"
						variant="contained"
						disabled={botToBeDeleted.length == 0}
						onClick={() => handleDelete(botToBeDeleted._id)}>
						Ok
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseDeleteModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>
			<Modal open={newModal} onClose={onCloseNewModal} classNames={{ modal: 'modal__container'}}>
				<div className="modal__heading">
					Create new bot
				</div>
				<div className="modal__inputbox">
					<div style={{ marginLeft: 10 }}>Bot Name</div>
					<input type="text" placeholder="Bot Name" value={name} onChange={(e) => { setName(e.target.value) }} /><br />
					<div style={{ marginLeft: 10, marginTop: 10 }}>Product ID</div>
					<input type="text" placeholder="Product ID" value={productId} onChange={(e) => { setProductId(e.target.value) }} /><br />
					<div style={{ marginLeft: 10, marginTop: 10 }}>Problem</div>
					<textarea type="text" placeholder="Problem" value={problem} onChange={(e) => { setProblem(e.target.value) }} required /><br />
					<div style={{ marginLeft: 10, marginTop: 10 }}>Assessment Time</div>
					<input type="number" placeholder="Assessment Time" value={botTime} onChange={(e) => { setBotTime(e.target.value) }} /><br />
					<div style={{ marginLeft: 10, marginTop: 10 }}>Section Count</div>
					<input type="number" placeholder="Section Count" value={botSectionCount} onChange={(e) => { setBotSectionCount(e.target.value) }} /><br />
				</div>
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={sendBots}
					>
						Create
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseNewModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>
			<Modal open={editModal} onClose={onCloseEditModal} classNames={{ modal: 'modal__container' }}>
				<div className="modal__heading">
					Edit bot
				</div>
				<div className="modal__inputbox">
					<input type="text" placeholder="Bot Name" value={name} onChange={(e) => { setName(e.target.value) }} /><br />
					<input type="text" placeholder="Product ID" value={productId} onChange={(e) => { setProductId(e.target.value) }} /><br />
					<textarea type="text" placeholder="Problem" value={problem} onChange={(e) => { setProblem(e.target.value) }} required /><br />
					<input type="number" placeholder="Assessment Time" value={botTime} onChange={(e) => { setBotTime(e.target.value) }} /><br />
					<input type="number" placeholder="Section Count" value={botSectionCount} onChange={(e) => { setBotSectionCount(e.target.value) }} /><br />
				</div>
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onSubmitEdit}
					>
						Edit
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseEditModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>
		</div>
	);
}

Bots.propTypes = {
	addBots: PropTypes.func,
	deleteBots: PropTypes.func,
	convo: PropTypes.array,
	fetchBots: PropTypes.func,
	updateBotDetails: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	convo: getConvoList()
});

const mapDispatchToProps = (dispatch) => ({
	addBots: (data) => dispatch(addBotsRequest(data)),
	deleteBots: (data) => dispatch(deleteBotsRequest(data)),
	fetchBots: (data) => dispatch(fetchBotsRequest(data)),
	updateBotDetails: (data) => dispatch(updateBotDetailsRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Bots);